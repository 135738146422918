import { isMobile } from "../../../../helpers";

const parseDescription = ({ string, size, ellipsis }) => {
  const suffix = ellipsis ? "…" : "";

  if (isMobile()) {
    return `${string.slice(0, size)}${suffix}`;
  }

  return string + suffix;
};

export default parseDescription;
