/* eslint-disable no-useless-escape */
import React, { useEffect, useRef, useState } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";

import { Input } from "../../../../../components/NewInput";
import { GhostButton } from "../../../../../components/GhostButton";
import SquareButton from "../../../components/SquareButton";
import useAxios from "../../../../../hooks/useAxios";
import { Pencil, Lock } from "../../../../../libs/icons";
import validationDictionary from "../../../../../constants/validationDictionary";
import { useUserContext } from "../../../../../contexts/User";
import { useSnackBarContext } from "../../../../../contexts/SnackBar";
import { formatPhone } from "../../../../../helpers/format";
import { formatCNPJ } from "../../../../../helpers/validateCNPJ";
import { onlyNumbers } from "../../../../../helpers/format";

import FieldsGrid from "../../../../authentication/components/FieldsGrid";
import PasswordButton from "../PasswordButton";

import styles from "./styles.module.scss";

const RecruiterInfoForm = () => {
  const formRef = useRef(null);
  const [isLoading, setLoading] = useState(true);
  const { exec, loading } = useAxios();
  const { userData, setUserData } = useUserContext();
  const { showSnackBar } = useSnackBarContext();
  const currentStorage = localStorage.getItem("@user_data");
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  const handleSubmit = async (data) => {
    try {
      formRef.current.setErrors({});
      data.phone = data.phone.replace(/[^0-9]/g, "");

      const schema = Yup.object().shape({
        name: Yup.string()
          .min(3, validationDictionary.INVALID_NAME)
          .required(validationDictionary.INVALID_NAME),
        lastname: Yup.string()
          .min(3, validationDictionary.INVALID_LASTNAME)
          .required(validationDictionary.INVALID_LASTNAME),
        phone: Yup.string()
          .min(10, validationDictionary.INVALID_PHONE)
          .max(11, validationDictionary.INVALID_PHONE)
          .required(validationDictionary.INVALID_PHONE),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await exec({
        useCredentials: true,
        method: "POST",
        url: "/user/update",
        data: {
          name: data.name,
          lastname: data.lastname,
          phone: onlyNumbers(data.phone),
        },
      });

      const storage = JSON.parse(localStorage.getItem("@user_data"));
      const updatedStorage = {
        ...storage,
        name: response.data.name,
        lastname: response.data.lastname,
        phone: response.data.phone,
      };

      setUserData({ ...userData, ...updatedStorage });
      localStorage.setItem("@user_data", JSON.stringify(updatedStorage));

      showSnackBar({
        timeout: 2000,
        text: validationDictionary.MY_ACCOUNT_UPDATED_SUCCESFULLY,
      });
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  useEffect(() => {
    if (currentStorage) {
      const {
        name,
        lastname,
        email,
        cnpj,
        fantasy_name,
        phone,
        corporate_name,
      } = JSON.parse(currentStorage);

      setUserData({
        ...userData,
        name,
        lastname,
        email,
        cnpj,
        fantasy_name,
        corporate_name,
        phone,
      });
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStorage]);

  if (isLoading) {
    return <span />;
  }

  return (
    <Form
      ref={formRef}
      initialData={
        userData && {
          name: userData.name,
          lastname: userData.lastname,
          email: userData.email,
          phone: formatPhone(userData.phone),
          cnpj: formatCNPJ(userData.cnpj),
          fantasy_name: userData.fantasy_name,
          corporate_name: userData.corporate_name,
        }
      }
      onSubmit={handleSubmit}
    >
      <FieldsGrid>
        <Input
          label="Nome"
          name="name"
          placeholder="Digite seu nome"
          type="text"
          disabled={loading}
          icon={<Pencil color="#8F9BB3" />}
        />
        <Input
          label="Sobrenome"
          name="lastname"
          placeholder="Digite seu sobrenome"
          type="text"
          disabled={loading}
          icon={<Pencil color="#8F9BB3" />}
        />
        <Input
          label="Empresa"
          name="fantasy_name"
          placeholder="Digite sua empresa"
          type="text"
          disabled
          icon={<Lock color="#8F9BB3" />}
        />
        <Input
          label="Razão Social"
          name="corporate_name"
          placeholder="Digite a razão social da empresa"
          type="text"
          disabled
          icon={<Lock color="#8F9BB3" />}
        />
        <Input
          label="CNPJ"
          name="cnpj"
          placeholder="Digite o CNPJ da empresa"
          type="text"
          disabled
          icon={<Lock color="#8F9BB3" />}
          mask="99.999.999/9999-99"
        />
        <Input
          label="Celular ou telefone"
          name="phone"
          placeholder="Digite seu telefone"
          type="text"
          disabled={loading}
          icon={<Pencil color="#8F9BB3" />}
          mask="(99) 9999-99999"
        />
        <Input
          label="E-mail"
          name="email"
          placeholder={userData.email}
          type="text"
          disabled
          icon={<Lock color="#8F9BB3" />}
        />
      </FieldsGrid>
      <p
        className={styles.emailChangeMessage}
        data-testid="change-email-instruction"
      >
        <Link to="/para-empresas/contato">Contate a Inklua</Link> para editar seu e-mail
      </p>
      <PasswordButton />
      <SquareButton disabled={loading} text="Concluir" type="submit" />
      <GhostButton text="Voltar" name="back" onClick={handleGoBack} />
    </Form>
  );
};

export default RecruiterInfoForm;
