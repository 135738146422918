import React from "react";
import { string, bool } from "prop-types";

const Currency = ({ color, hidden }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11 17"
      aria-hidden={hidden}
    >
      <path
        fill={color}
        fillRule="evenodd"
        stroke={color}
        strokeWidth=".8"
        d="M10.224 9.848c-.66-1.211-2.197-1.904-4.222-1.904h-.048c-1.626 0-2.486-.516-2.75-1-.198-.362-.071-.68.07-.881.353-.505 1.303-.986 2.93-.806.791.086 1.606.351 2.55.656l.17.057a.873.873 0 0 0 1.097-.536.843.843 0 0 0-.55-1.07l-.17-.054c-.848-.275-1.659-.531-2.483-.668V1.846A.858.858 0 0 0 5.95 1c-.48 0-.868.38-.868.846v1.708c-1.434.101-2.603.643-3.243 1.559-.562.804-.625 1.786-.167 2.625.657 1.205 2.218 1.896 4.282 1.896h.028c1.544.03 2.443.517 2.709 1.006.16.295.137.614-.064.902-.343.493-1.281.96-2.879.78-.787-.086-1.593-.348-2.527-.652l-.186-.06a.877.877 0 0 0-1.097.535.842.842 0 0 0 .549 1.07l.184.06c.825.267 1.61.514 2.41.655v1.805c0 .466.39.844.87.844a.857.857 0 0 0 .867-.844v-1.722c1.432-.09 2.61-.61 3.244-1.518.564-.808.624-1.797.162-2.647"
      />
    </svg>
  );
};

Currency.propTypes = {
  color: string,
  hidden: bool,
};

Currency.defaultProps = {
  color: "#1B9B9E",
  hidden: true,
};

export default Currency;
