import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import SectionTitle from "../../components/SectionTitle";
import styles from "./styles.module.scss";
import SearchJobBlock from "../../components/SearchJobBlock";
import useMapTechSearch from "../../hooks/useMapTechSearch";
import EmptyResultsFeedback from "../../modules/Search/components/EmptyResultsFeedback";
import FiltersBlock from "../../components/FiltersBlock";
import JobsList from '../../components/JobsList';
import MobileLoadingFeedback from '../../modules/Search/components/MobileLoadingFeedback';
import Loader from './Loader';
import FiltersBlockMobile from '../../components/FiltersBlockMobile';
import useEnterPriseEnvCheck from '../../hooks/useEnterpriseEnvCheck';

const MetaTags = () => (
    <Helmet>
        <title>Mapeamento Tech | Inklua</title>
        <meta name="description" content="Encontre pessoas com deficiência (PcD) que atuam na área de tecnologia! Basta ler o briefing de cada candidato já mapeado por nosso time da consultoria." />
        <meta name="keywords" content="profissionais de tecnologia pcd, profissionais pcd, desenvolvedor pcd, designer pcd, analista pcd, QA pcd, recrutamento e seleção, inkua, empresa inklua, PcD." />
        <meta property="og:title" content="Mapeamento Tech | inklua" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Encontre suas vagas de emprego na Inklua, são centenas de novas vagas todos os dias no site que é referência em empregos para pessoas com deficiência (PcD)." />
        <meta property="og:image" content="https://s3.sa-east-1.amazonaws.com/public.inklua/logo_mini.png" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="200" />
        <meta property="og:image:height" content="200" />
        <meta property="og:url" content="https://inklua.com/para-empresas/mapeamento-tech" />
        <meta property="og:site_name" content=" Inklua" />
        <link rel="canonical" href="https://inklua.com/para-empresas/mapeamento-tech" />
    </Helmet>
);

const TechMap = () => {
    const [isMobile, setIsMobile] = useState(false);
    const { isEnterpriseEnv } = useEnterPriseEnvCheck();
    const {
        error,
        noResults,
        isLoading,
        filters,
        filtered,
        candidates,
        filtering,
        isLastPage,
        setFiltersApplied,
        setFiltering,
        resetFilters,
        handleFiltereds,
        handleSearchMore,
        setCurrentPage,
    } = useMapTechSearch();

    useEffect(() => {
        if (window.innerWidth <= 768) return setIsMobile(true);
    }, []);

    if (error) return <pre>Ocorreu um erro, por gentileza reinicie a página!</pre>;

    if (isLoading) return <Loader isMobile={isMobile} />;

    if (noResults) return (
        <EmptyResultsFeedback
            onClick={resetFilters}
            enterpriseVersion={isEnterpriseEnv}
        />
    );

    return (
        <>
            <MetaTags />
            <section className={styles.wrapper}>
                <SectionTitle
                    children="<b>Mapeamento</b> Tech"
                    extraStyle={[
                        styles.title,
                        isEnterpriseEnv && styles.secondaryLayout
                    ].join(" ")}
                    linebreak={false}
                    level="h2"
                />
                <p className={styles.description}>
                    Profissionais da área de tecnologia
                </p>
                <div className={styles.filters}>
                    <SearchJobBlock
                        isMobile={isMobile}
                        enterpriseVersion={isEnterpriseEnv}
                        handleFiltereds={handleFiltereds}
                        states={filters.states}
                        payments={filters.payments}
                        onSubmit={setFiltersApplied}
                        setLoading={setFiltering}
                    />
                    <FiltersBlockMobile
                        isMobile={isMobile}
                        filters={filters}
                        filtered={filtered}
                        handleFiltereds={handleFiltereds}
                        onSubmit={setFiltersApplied}
                        clearPage={setCurrentPage}
                        setLoading={setFiltering}
                    />
                    <div className={styles.content}>
                        <FiltersBlock
                            enterpriseVersion={isEnterpriseEnv}
                            filters={filters}
                            filtered={filtered}
                            onSubmit={setFiltersApplied}
                            setLoading={setFiltering}
                            clearPage={setCurrentPage}
                        />
                        <JobsList
                            enterpriseVersion={isEnterpriseEnv}
                            candidates={candidates}
                            seeMore={handleSearchMore}
                            showButton={!isLastPage}
                        />
                    </div>
                </div>
            </section>
            {filtering && <MobileLoadingFeedback active />}
        </>
    )
}

export default TechMap;
