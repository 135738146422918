import React from "react";
import { string, number, shape, bool } from "prop-types";

import LocationChip from "../../components/LocationChip";
import SquareLinkButton from "../../components/SquareLinkButton";
import ShareButton from "../../components/ShareButton";
import { toSlug, toDate, parseUrl, parseLocation, parseContractType } from "../../helpers";

import styles from "./styles.module.scss";

const Position = ({
  id,
  image,
  title,
  company,
  date,
  description,
  location,
  shares,
  altImage,
  contractType,
}) => {
  return (
    <article className={styles.wrapper}>
      <img
        src={image}
        className={styles.image}
        alt={altImage || `Imagem da vaga ${title}`}
        tabIndex="0"
      />
      <h1 className={styles.title} tabIndex="0">{title}</h1>
      <div className={styles.infoWrapper}>
        <span className={styles.company} tabIndex="0">{company}</span>
        <span className={styles.date} tabIndex="0">
          Publicada em: {toDate(date, "DD/MM/YYYY")}
        </span>
      </div>
      <p className={styles.description} tabIndex="0">{description}</p>
      <div className={styles.location}>
        <LocationChip
          location={location !== " - " ? location : parseContractType(contractType)}
          tabIndex="0"
        />
      </div>
      <div className={styles.footer}>
        <SquareLinkButton
          text="Ver detalhes"
          url={`/vaga/${toSlug(title)}/${id}`}
        />
        <ShareButton
          text="Compartilhar"
          title={title}
          url={parseUrl(`/vaga/${toSlug(title)}/${id}`)}
          id={id}
          shares={shares}
        />
      </div>
    </article>
  );
};

Position.propTypes = {
  id: number.isRequired,
  image: string.isRequired,
  title: string.isRequired,
  company: string.isRequired,
  date: string.isRequired,
  description: string.isRequired,
  location: string.isRequired,
  contractType: shape({
    hybrid: bool.isRequired,
    presential: bool.isRequired,
    remote: bool.isRequired,
  }),
  shares: shape({
    whatsapp: bool.isRequired,
    twitter: bool.isRequired,
    facebook: bool.isRequired,
    linkedin: bool.isRequired,
  }).isRequired,
  altImage: string.isRequired,
};

export default Position;
