import React, { useEffect, useState } from "react";
import { node } from "prop-types";

import UserContext from "./UserContext";

const UserProvider = ({ children }) => {
  const [inkoins, setInkoins] = useState(0);
  const [userData, setUserData] = useState(0);

  const updateInkoins = (quantity) => {
    setInkoins(quantity);
  };

  const addInkoins = (newQuantity) => {
    const newInkoinsQuantity = inkoins + newQuantity;
    setInkoins(newInkoinsQuantity);
    localStorage.setItem("@user_inkoins", JSON.stringify(newInkoinsQuantity));
  };

  const value = {
    updateInkoins,
    addInkoins,
    inkoins,
    setUserData,
    userData,
  };

  useEffect(() => {
    if (localStorage.getItem("@user_inkoins")) {
      setInkoins(JSON.parse(localStorage.getItem("@user_inkoins")));
    }

    if (localStorage.getItem("@user_data")) {
      setUserData(JSON.parse(localStorage.getItem("@user_data")));
    }
  }, []);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: node.isRequired,
};

export default UserProvider;
