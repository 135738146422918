const toCurrency = ({ value, hideCurrencySymbol }) => {
  if (!value) return null;

  const inCurrency = value.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  if (hideCurrencySymbol) {
    return inCurrency.replace("R$", "");
  }

  return inCurrency;
};

export default toCurrency;
