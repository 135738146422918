import React, { useRef, useEffect, useState } from "react";
import { useField } from "@unform/core";
import { string, arrayOf, bool } from "prop-types";

import { isExtensionValid } from "../../../helpers";
import { parseFilename } from "./parseFilename";

import styles from "./styles.module.scss";

const FileInput = ({
  name,
  label,
  defaultValue,
  suffix,
  placeholder,
  validExtensions,
  enableFlag,
}) => {
  const inputRef = useRef(null);
  const [isOnFocus, setIsOnFocus] = useState(false);
  const [fileName, setFileName] = useState(null);
  const { fieldName, registerField, error } = useField(name);

  const getFileNameFromUrl = (url) => {
    const fileUrlWithoutRelativeStringArray = url.split("/");
    const fileUrlWithoutRelativeString =
      fileUrlWithoutRelativeStringArray[
        fileUrlWithoutRelativeStringArray.length - 1
      ];

    if (fileUrlWithoutRelativeString.includes("?")) {
      return fileUrlWithoutRelativeString.split("?")[0];
    }

    return fileUrlWithoutRelativeString;
  };

  const handleChange = (event) => {
    const file = event.target.files[0];

    if (!file || !isExtensionValid(file.type, validExtensions)) return;

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onloadend = function () {
      inputRef.current = {
        value: {
          base64: reader.result,
          extension: file.type.split("/")[1],
          url: defaultValue,
        },
      };

      setFileName(parseFilename(file.name));
    };
  };

  const handleFocus = (v) => {
    setIsOnFocus(v);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return (
          ref?.current?.value || {
            base64: null,
            extension: null,
            url: defaultValue,
          }
        );
      },
    });
  }, [fieldName, registerField, defaultValue]);

  return (
    <fieldset className={styles.wrapper}>
      <label htmlFor={`image-field-${name}`} className={styles.label}>
        {label}{" "}
        {enableFlag && <span className={styles.optionalFlag}>(Opcional)</span>}
      </label>
      <div
        className={[
          styles.preview,
          error ? styles.error : "",
          isOnFocus ? styles.focused : "",
        ].join(" ")}
      >
        {Boolean(!fileName && !defaultValue) ? (
          <span className={styles.placeholder}>{placeholder}</span>
        ) : (
          <span className={styles.fieldValue}>
            {fileName || getFileNameFromUrl(defaultValue)}
          </span>
        )}
        <input
          type="file"
          name={name}
          encType="multipart/form-data"
          onChange={handleChange}
          className={styles.input}
          onFocus={() => handleFocus(true)}
          onBlur={() => handleFocus(false)}
          autoComplete="off"
        />
      </div>
      {suffix && <span className={styles.suffix}>{suffix}</span>}
      {error && (
        <span
          className={styles.errorMessage}
          id={`error_${name}`}
          data-testid={`error-message-${name}`}
        >
          Campo obrigatório
        </span>
      )}
    </fieldset>
  );
};

FileInput.propTypes = {
  label: string.isRequired,
  name: string.isRequired,
  defaultValue: string,
  suffix: string,
  placeholder: string,
  validExtensions: arrayOf(string),
  enableFlag: bool,
};

FileInput.defaultProps = {
  defaultValue: null,
  suffix: null,
  placeholder: null,
  validExtensions: ["pdf", "jpeg", "jpg", "png", "doc", "docx"],
  enableFlag: false,
};

export default FileInput;
