import React from "react";
import { string } from "prop-types";

const Copy = ({ color }) => {
  return (
    <svg
      width="21px"
      height="21px"
      viewBox="0 0 21 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M10.5,9.33333333 C9.85716667,9.33333333 9.33333333,9.85716667 9.33333333,10.5 L9.33333333,17.5 C9.33333333,18.1428333 9.85716667,18.6666667 10.5,18.6666667 L17.5,18.6666667 C18.144,18.6666667 18.6666667,18.1428333 18.6666667,17.5 L18.6666667,10.5 C18.6666667,9.85716667 18.144,9.33333333 17.5,9.33333333 L10.5,9.33333333 Z M10.8885,0 C12.6046667,0 14,1.39533333 14,3.1115 L14,7 L17.5,7 C19.4296667,7 21,8.57033333 21,10.5 L21,17.5 C21,19.4296667 19.4296667,21 17.5,21 L10.5,21 C8.57033333,21 7,19.4296667 7,17.5 L7,14 L3.1115,14 C1.39533333,14 0,12.6046667 0,10.8885 L0,3.1115 C0,1.39533333 1.39533333,0 3.1115,0 L10.8885,0 Z M10.8885,2.33333333 L3.1115,2.33333333 C2.68216667,2.33333333 2.33333333,2.68216667 2.33333333,3.1115 L2.33333333,10.8885 C2.33333333,11.3178333 2.68216667,11.6666667 3.1115,11.6666667 L7,11.666 L7,10.5 C7,8.57033333 8.57033333,7 10.5,7 L11.666,7 L11.6666667,3.1115 C11.6666667,2.68216667 11.3178333,2.33333333 10.8885,2.33333333 Z"
          id="path-copy-1"
        ></path>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-copy-1"></use>
        </mask>
        <use id="Icon-color" fill={color} xlinkHref="#path-copy-1"></use>
      </g>
    </svg>
  );
};

Copy.propTypes = {
  color: string,
};

Copy.defaultProps = {
  color: "#1B9B9E",
};

export default Copy;
