import { useEffect, useRef, useState } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";

import { errorParser, Input } from "../../../../components/NewInput";
import SelectInput from "../../../../components/SelectInput";
import SquareButton from "../../../../components/SquareButton";
import SquareLinkButton from "../../../../components/SquareLinkButton";
import validationDictionary from "../../../../constants/validationDictionary";
import { SectionTitle } from "../../../Account/components/SectionTitle";
import useAxios from "../../../../hooks/useAxios";
import { educationTypes } from "../../../../libs/data";
import { BooleanCheckbox } from "../../../../components/NewInput";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { toDate, focusElement } from "../../../../helpers";

import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";

import styles from "./styles.module.scss";
import { isValidDateMMYYYY } from "../../../Candidate/utils/isValidDate";

Yup.addMethod(Yup.string, 'dateMMYYYY', function (errorMessage) {
  return this.test('dateMMYYYY', errorMessage, function (value) {
    const { path, createError } = this;
    if (!value || !isValidDateMMYYYY(value)) {
      return createError({ path, message: errorMessage });
    }

    return true;
  });
});

const CandidateEditCourse = () => {
  const { id } = useParams();
  const { exec, loading } = useAxios();
  const [educationType, setEducationType] = useState(educationTypes[0].value);
  const [isOnGoing, setOnGoing] = useState(true);
  const history = useHistory();
  const formRef = useRef();
  const [formData, setFormData] = useState(null);

  const fetchCourseInformation = async () => {
    try {
      const result = await exec({
        method: "GET",
        url: `/candidate/education/${id}`,
        useCredentials: true,
      });

      const shouldDisplayAsOngoing = result?.end_at ? false : true;
      setOnGoing(shouldDisplayAsOngoing);

      setFormData(result);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleOngoing = ({ checked }) => {
    setOnGoing(checked);
  };

  const handleSubmit = async (data) => {
    try {
      data.type = educationType;
      data.id = id;

      const schema = Yup.object().shape({
        type: Yup.number().oneOf(
          educationTypes.map((l) => l.value),
          validationDictionary.INVALID_EDUCATION_TYPE
        ),
        course: Yup.string()
          .min(3, validationDictionary.INVALID_COURSE)
          .required(validationDictionary.INVALID_COURSE),
        institution: Yup.string()
          .min(3, validationDictionary.INVALID_INSTITUTION)
          .required(validationDictionary.INVALID_INSTITUTION),
        start_date: Yup.string()
          .dateMMYYYY(validationDictionary.INVALID_DATE)
          .min(3, validationDictionary.INVALID_DATE)
          .required(validationDictionary.INVALID_DATE),
        end_date:
          !isOnGoing &&
          Yup.string()
            .dateMMYYYY(validationDictionary.INVALID_DATE)
            .min(3, validationDictionary.INVALID_DATE)
            .required(validationDictionary.INVALID_DATE),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await exec({
        useCredentials: true,
        method: "PUT",
        url: `/candidate/education/${id}`,
        data,
      });

      if (response.error) {
        return;
      }

      history.goBack();

      return false;
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        const parsedErrors = errorParser(validationErrors);

        formRef.current.setErrors(parsedErrors);
      }
    }
  };

  useEffect(() => {
    fetchCourseInformation();

    focusElement('[data-testid="section-title"]');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!formData) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <AuthenticationWrapper>
        <SectionTitle>Formação acadêmica</SectionTitle>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          className={styles.form}
          initialData={{
            type: formData.level_education_id,
            course: formData.course,
            institution: formData.institute,
            start_date: toDate(formData.start_at, "MM/YYYY"),
            end_date: formData?.end_at
              ? toDate(formData.end_at, "MM/YYYY")
              : null,
          }}
        >
          <SelectInput
            name="type"
            label="Nível da formação"
            placeholder="Selecione o nível"
            setter={setEducationType}
            keys={{ label: "label", value: "value" }}
            options={educationTypes}
            defaultValue={formData.level_education_id}
          />
          <Input
            label="Nome do curso"
            name="course"
            placeholder="Insira o nome do curso"
            type="text"
          />
          <Input
            label="Nome da instituição"
            name="institution"
            placeholder="Insira o nome da instituição"
            type="text"
          />
          <Input
            label="Data de início"
            name="start_date"
            placeholder="Ex: 01/2010"
            type="text"
            mask="99/9999"
            ariaLabel="Preenchimento da data de início, no formato de dois digitos para o mês e quatro digitos para o ano. Só pode ser inserido números neste campo"
          />
          <BooleanCheckbox
            name="ongoing"
            onChange={handleOngoing}
            alternativeStyle
            checked={isOnGoing}
          >
            Estou cursando atualmente
          </BooleanCheckbox>
          {!isOnGoing && (
            <Input
              label="Data de término"
              name="end_date"
              placeholder="Ex: 01/2010"
              type="text"
              mask="99/9999"
              ariaLabel="Preenchimento da data de término, no formato de dois digitos para o mês e quatro digitos para o ano. Só pode ser inserido números neste campo"
            />
          )}
          <SquareButton
            testID="submit-button"
            text="Concluir"
            type="submit"
            disabled={loading}
          />
        </Form>
        <SquareLinkButton
          testID="back-to-education-list"
          text="Voltar"
          url="/candidato/minha-conta/formacao-academica"
          type="ghost"
        />
      </AuthenticationWrapper>
    </>
  );
};

export { CandidateEditCourse };
