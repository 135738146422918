import { node } from "prop-types";

import styles from "./styles.module.scss";

const AuthenticationParagraph = ({ children }) => {
  return <p className={styles.wrapper} tabIndex="0">{children}</p>;
};

AuthenticationParagraph.propTypes = {
  children: node.isRequired,
};

export default AuthenticationParagraph;
