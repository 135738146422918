import React from "react";

import ResendEmail from "../../authentication/ResendEmail";
import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";

const ValidateYourEmailEnterprise = () => {
  return (
    <AuthenticationWrapper>
      <ResendEmail />
    </AuthenticationWrapper>
  );
};

export default ValidateYourEmailEnterprise;
