/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import styles from './styles.module.scss';
import { useHistory } from "react-router-dom";

import SquareButton from "../../../../components/SquareButton";
import useAxios from "../../../../hooks/useAxios";
import { JobPostingCTA } from "../../components/JobPostingCTA";
import { PositionItem } from "../../components/PositionItem";
import { MyPostingsList } from "../../components/MyPostingsList";
import { tabText } from '../../components/PositionFilter/variables';
import { PageHeader } from "../../components/PageHeader";
import { JobPostingStatus } from "../../components/JobPostingStatus";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";

const MyListingsScnd = () => {
  const { exec, loading } = useAxios();
  const [data, setData] = useState([]);
  // const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [jobPositionStatus, setJobPositionStatus] = useState("ativas");
  const [customerDetails, setCustomerDetails] = useState(null);
  const history = useHistory();
  const userData = localStorage.getItem("@user_data")
  ? JSON.parse(localStorage.getItem("@user_data"))
  : undefined;

  const fetchData = async () => {
    const response = await exec({
      method: "GET",
      url: `/minhas-vagas?page=${currentPage}&status=${jobPositionStatus}&itemsPerPage=${
        currentPage === 1 ? 5 : 6
      }`,
      useCredentials: true,
    });

    setData(response.jobs);
    // setLastPage(response.myContents.last_page);
  };

  const handleLogout = () => {
    localStorage.removeItem("@user_data");
    localStorage.removeItem("@user_token");
    localStorage.removeItem("@user_shares");

    history.push("/");
  };

  // const updatePagination = () => {
  //   setCurrentPage(currentPage + 1);
  // };

  const returnCorrectStatus = () => {
    if (jobPositionStatus === "ativas") return <JobPostingCTA firstTime={customerDetails.hasFreePub} />
    else if (jobPositionStatus === "em_espera") {
      return (
        <JobPostingStatus title="Só mais um pouquinho" image="clock">
          <span>
            Aqui estão as vagas que você cadastrou e neste
            momento estão em análise pelo time da Inklua.
            <b> Logo, logo você receberá um e-mail sobre o próximo passo!</b>
          </span>
        </JobPostingStatus>
      );
    }
    return (
      <JobPostingStatus title="Humm faltou..." image="archive">
        <span>
          Aqui estão as sua vagas que já expiraram ou que
          necessitam de revisão de acordo com nossos
          <a href="/para-empresas/politica-de-privacidade" className={styles.terms} target="_blank"> termos de uso</a>.
        </span>
      </JobPostingStatus>
    );
  };

  const changeTab = (tab) => {
    setCurrentPage(1);
    // setLastPage(1);
    setJobPositionStatus(tab)
  };

  const fetchCustomerData = async () => {
    const { existContentForCNPJ, contentsFromUser } = await exec({
      method: "GET",
      url: '/minhas-vagas/status',
      useCredentials: true,
    });

    setCustomerDetails({ hasFreePub: !existContentForCNPJ, contentsPublished: contentsFromUser });
  };

  useEffect(() => {
    if(userData.inkluer) return window.location = window.location.origin + "/recrutador/minhas-vagas"

    fetchCustomerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
  }, [jobPositionStatus]);

  return (
    <div className={styles.wrapper}>
      <PageHeader
        title="Minhas Vagas"
        currentTab={tabText[jobPositionStatus]}
        logout={handleLogout}
        changeTab={changeTab}
      />
      {loading && <LoadingSpinner color="#438fcc" />}
      {!loading &&
       <MyPostingsList>
        {customerDetails && returnCorrectStatus()}
        {!loading &&
          data
            .map((position) => {
              return (
                <PositionItem
                  type="minhas-vagas"
                  key={`position-${position.id}`}
                  id={position.id}
                  title={position.title}
                  date={position.published}
                  salary={position.salary}
                  location={position.location}
                  status={position.status}
                  likes={position.likes}
                  link={position.path}
                />
              );
            })
        }
        </MyPostingsList>
      }
      {/* {currentPage < lastPage && (
        <div className={styles.moreContent}>
          <SquareButton
            onClick={() => updatePagination()}
            disabled={loading}
            text="Ver mais"
            type="button"
            outlined
          />
        </div>
      )} */}
    </div>
  );
};

export { MyListingsScnd };
