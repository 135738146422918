import React from "react";
import { string } from "prop-types";

const Pin = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 17">
      <g fill={color}>
        <path d="M7.15 0A6.65 6.65 0 0 0 .5 6.583c0 4.556 5.86 9.626 6.11 9.842a.831.831 0 0 0 1.08 0c.291-.216 6.11-5.286 6.11-9.841A6.65 6.65 0 0 0 7.15 0Zm0 14.672C5.762 13.35 2.162 9.684 2.162 6.584a4.987 4.987 0 1 1 9.976 0c0 3.075-3.6 6.766-4.988 8.088Z" />
        <path d="M7.15 3.325a2.91 2.91 0 1 0 0 5.819 2.91 2.91 0 0 0 0-5.819Zm0 4.156a1.247 1.247 0 1 1 0-2.493 1.247 1.247 0 0 1 0 2.493Z" />
      </g>
    </svg>
  );
};

Pin.propTypes = {
  color: string,
};

Pin.defaultProps = {
  color: "#1B9B9E",
};

export default Pin;
