import { useState, useRef } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

import { Input } from "../../../../components/NewInput";
import useAxios from "../../../../hooks/useAxios";
import SquareButton from "../../../../components/SquareButton";
import ButtonWithIcon from "../../../../components/ButtonWithIcon";
import { AlertTriangle } from "../../../../libs/icons";
import validationDictionary from "../../../../constants/validationDictionary";
import { useUserContext } from "../../../../contexts/User";
import { useSnackBarContext } from "../../../../contexts/SnackBar";

import AvailableInkoinsWarning from "../AvailableInkoinsWarning";

import styles from "./styles.module.scss";

const AccountExclusion = () => {
  const formRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { exec, loading } = useAxios();
  const history = useHistory();
  const { inkoins } = useUserContext();
  const { showSnackBar } = useSnackBarContext();

  const handleSubmit = async (data) => {
    try {
      const schema = Yup.object().shape({
        password: Yup.string()
          .min(8, validationDictionary.PASSWORD_MIN_CHARACTERS)
          .required(validationDictionary.INVALID_PASSWORD),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await exec({
        useCredentials: true,
        method: "POST",
        url: "/user/delete",
        data,
      });

      if (response.message === "Conta removida") {
        localStorage.removeItem("@user_data");
        localStorage.removeItem("@user_token");
        localStorage.removeItem("@user_inkoins");
        localStorage.removeItem("@user_shares");

        showSnackBar({
          timeout: 2000,
          text: "Sua conta foi excluída",
        });

        history.push("/");
      }

      if (response.status === 400) {
        showSnackBar({
          timeout: 2000,
          text: response.data.errors,
        });
      }
    } catch (error) {
      console.log({ error });

      const validationErrors = {};

      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttonWrapper}>
        <ButtonWithIcon onClick={() => setOpen(true)} text="Excluir conta">
          <AlertTriangle />
        </ButtonWithIcon>
      </div>
      {isOpen && (
        <>
          <p className={styles.paragraph}>
            <b>É bom avisar:</b> depois de excluir sua conta, você não
            conseguirá reativá-la. Suas Inkoins, doações e preferências de
            navegação também serão excluídas.
          </p>
          {Boolean(inkoins) && <AvailableInkoinsWarning />}
          <p className={styles.paragraph}>
            Para continuar com a exclusão digite sua senha e confirme a
            exclusão.
          </p>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input
              label="Senha"
              name="password"
              placeholder="Digite sua senha"
              type="password"
            />
            <SquareButton
              disabled={loading}
              text="Confirmar exclusão"
              type="submit"
              outlined
            />
            <button
              type="button"
              onClick={() => setOpen(false)}
              className={styles.cancelButton}
            >
              Cancelar
            </button>
          </Form>
        </>
      )}
    </div>
  );
};

export default AccountExclusion;
