import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';

import ModuleOne from "../../../../components/ModuleOne";
import Mention from '../../../../components/Mention';
import BannerImage from './assets/palestras-1.webp';
import Talker from './assets/palestras-2.webp';
import CallToAction from './assets/palestras-3.webp';
import styles from './styles.module.scss';
import Mail from '../../../../libs/icons/Mail';
import isMobile from '../../../../helpers/isMobile';

const MetaTags = () => (
  <Helmet>
    <title>Palestras | Inklua</title>
    <meta name="description" content="Oferecemos palestra gratuita para sua empresa sobre o capacitismo e a inclusão da pessoa com deficiência no mercado de trabalho, trazidas por nosso palestrante Marcelo Zig. " />
    <meta name="keywords" content="palestra gratuita, palestras sobre capacitismo, reflexão sobre o conceito do capacitismo, palestras sobre a experiência da pessoa com deficiência na sociedade, palestras sobre PcD, palestra eu não ligava, palestrante Marcelo Zig, Marcelo Zig. " />
    <meta property="og:title" content="Palestras | inklua" />
    <meta property="og:type" content="website" />
    <meta property="og:description" content="Oferecemos palestra gratuita para sua empresa sobre o capacitismo e sensibilização da pessoa com deficiência, trazidas por nosso palestrante Marcelo Zig." />
    <meta property="og:image" content="https://s3.sa-east-1.amazonaws.com/public.inklua/logo_mini.png" />
    <meta property="og:image:type" content="image/jpeg" />
    <meta property="og:image:width" content="200" />
    <meta property="og:image:height" content="200" />
    <meta property="og:url" content="https://inklua.com/para-empresas/palestras" />
    <meta property="og:site_name" content="Inklua" />
    <link rel="canonical" href="https://inklua.com/para-empresas/palestras" />
  </Helmet>
);

const Lecture = () => {
  const myRef = useRef(null);

  const executeScroll = () => {
    if (isMobile()) {
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else {
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  };

  return (
    <>
      <MetaTags />
      <main>
        <div className={styles.banner}>
          <ModuleOne
            subtitle="Palestras de <b>sensibilização</b>"
            description="<p>A sua empresa está preparada quando o assunto é sobre o capacitismo e a inclusão da pessoa com deficiência?</p>"
            image={[BannerImage]}
            action={
              <button
                className={styles.buttonCTA}
                onClick={executeScroll}>
                Vamos conversar?
              </button>
            }
          />
        </div>
        <div className={styles.mention}>
          <Mention
            author="Marcelo Zig"
            text="“É a ausência de acessibilidade que torna a pessoa com deficiência incapaz, não a deficiência dela.”"
          />
        </div>
        <div className={styles.talker}>
          <ModuleOne
            inverse
            title="Marcelo zig"
            subtitle="Nosso <b>sensibilizador</b>"
            description="<p>
                    Aos 21 anos de idade Marcelo Zig se acidentou e tornou-se pessoa com deficiência e hoje, aos 48 anos, o filósofo, 
                    palestrante, criador de conteúdo e idealizador do Projeto Mergulho Cidadão e do coletivo Quilombo PcD, compartilha 
                    as suas habilidades profissionais e emocionais desenvolvidas a partir da compreensão da importância da ocupação 
                    dos espaços sociais pelas pessoas com deficiência.
                    </p>"
            image={[Talker]}
          />
        </div>
        <div className={styles.call} ref={myRef}>
          {isMobile() ?
            <>
              <ModuleOne
                title="O que oferecemos"
                subtitle="Palestras <b>gratuitas</b>"
                description="
                                <p>
                                A Inklua oferece uma palestra gratuita para a sua empresa, onde o nosso palestrante e sensibilizador, Marcelo Zig, 
                                desenvolve cenários convidativos para a reflexão dos conceitos de capacitismo, acessibilidade e inclusão da pessoa 
                                com deficiência, através de abordagens elaboradas a partir das experiências pré e pós-acidente que o tornou uma pessoa com deficiência.
                                </p>"
                image={[CallToAction]}
              />
              <div className={styles.mailTo}>
                <p>
                  Entre em contato conosco e promova a inclusão em sua empresa.
                </p>
                <div>
                  <Mail color="#438FCC" />
                  <a href='mailto:contato@inklua.com'>contato@inklua.com</a>
                </div>
              </div>
            </>
            :
            <ModuleOne
              title="O que oferecemos"
              subtitle="Palestras <b>gratuitas</b>"
              description="
                                <p>
                                A Inklua oferece uma palestra gratuita para a sua empresa, onde o nosso palestrante e sensibilizador, Marcelo Zig, 
                                desenvolve cenários convidativos para a reflexão dos conceitos de capacitismo, acessibilidade e inclusão da pessoa 
                                com deficiência, através de abordagens elaboradas a partir das experiências pré e pós-acidente que o tornou uma pessoa com deficiência.
                                </p>
                                <p>
                                Entre em contato conosco e promova a inclusão em sua empresa.
                                </p>"
              image={[CallToAction]}
              action={
                <div className={styles.mailTo}>
                  <Mail color="#438FCC" />
                  <a href='mailto:contato@inklua.com'>contato@inklua.com</a>
                </div>
              }
            />
          }
        </div>
        <div className={styles.mentionFooter}>
          <Mention
            author="Marcelo Zig"
            text="“As oportunidades é que devem ser iguais, não as pessoas!”"
          />
        </div>
      </main>
    </>
  )
}

export default Lecture;