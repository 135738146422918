import React from "react";
import { string } from "prop-types";

const ForbiddenCircle = ({ color }) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-testid="icon-forbidden-circle"
    >
      <g
        id="⬅️➡️🖥️-07-fluxo:recrutador-cadastrando-vagas(DesktopView)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="09-minhas-vagas-detalhes-tooltip"
          transform="translate(-844.000000, -400.000000)"
        >
          <g
            id="card-vaga-1-copy"
            transform="translate(546.000000, 358.000000)"
          >
            <g id="bg">
              <g id="bg-shadow">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                ></use>
                <use
                  fill="#FFFFFF"
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                ></use>
              </g>
              <rect
                id="bg-solid"
                fill="transparent"
                x="0"
                y="0"
                width="348"
                height="314"
                rx="10"
              ></rect>
            </g>
            <path
              d="M310,42.3333333 C303.556678,42.3333333 298.333333,47.5566779 298.333333,54 C298.333333,60.4433221 303.556678,65.6666667 310,65.6666667 C316.443322,65.6666667 321.666667,60.4433221 321.666667,54 C321.666667,50.9058076 320.437504,47.9383453 318.249579,45.7504209 C316.061655,43.5624965 313.094192,42.3333333 310,42.3333333 Z M319.33334,54 C319.335911,56.0727642 318.641482,58.0861972 317.361667,59.7166667 L304.283333,46.6383333 C305.913803,45.358518 307.927236,44.664089 310,44.6666595 C315.154658,44.6666595 319.33334,48.8453423 319.33334,54 L319.33334,54 Z M300.66666,54 C300.664089,51.9272358 301.358518,49.9138028 302.638333,48.2833333 L315.716667,61.3616667 C314.086197,62.641482 312.072764,63.335911 310,63.3333405 C304.845342,63.3333405 300.66666,59.1546577 300.66666,54 L300.66666,54 Z"
              id="Shape"
              fill={color}
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

ForbiddenCircle.propTypes = {
  color: string,
};

ForbiddenCircle.defaultProps = {
  color: "#D72E2F",
};

export default ForbiddenCircle;
