import { string, func, bool } from "prop-types";

import { Plus } from "../../libs/icons";

import styles from "./styles.module.scss";

const AccordionItem = ({ title, description, handleClick, active }) => {

  const createDescription = () => ({
    __html: description
  });

  return (
    <>
      <dt
        onClick={handleClick}
        className={[styles.title, active && styles.open].join(" ")}
      >
        {title} <Plus color="#438FCC" />
      </dt>
      <dd
        aria-hidden={!active}
        className={[styles.description, active && styles.open].join(" ")}
        dangerouslySetInnerHTML={createDescription()}
      />
    </>
  );
};

AccordionItem.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  handleClick: func.isRequired,
  active: bool,
};

AccordionItem.defaultProps = {
  active: false,
};

export default AccordionItem;
