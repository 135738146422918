import { func, bool, string, number, oneOfType } from "prop-types";
import useEnterPriseEnvCheck from "../../../../hooks/useEnterpriseEnvCheck";

import styles from "./styles.module.scss";

const CheckboxFilter = ({
  label,
  value,
  counter,
  onChange,
  checked,
  field,
  disabled,
}) => {
  const { isEnterpriseEnv } = useEnterPriseEnvCheck();

  return (
    <div className={styles.label}>
      <button
        className={[
          styles.input,
          checked ? styles.active : styles.inactive,
          disabled ? styles.disabled : styles.enabled,
          isEnterpriseEnv && styles.secondaryLayout
        ].join(" ")}
        onClick={() => onChange({ value: value, ...(field && { field }) })}
        data-testid="checkbox-filter-button"
        type="button"
        disabled={disabled}
      >
        {label} ({counter})
      </button>
    </div>
  );
};

CheckboxFilter.propTypes = {
  label: string.isRequired,
  value: oneOfType([string, number]).isRequired,
  counter: number.isRequired,
  onChange: func.isRequired,
  checked: bool.isRequired,
  disabled: bool,
};

CheckboxFilter.defaultProps = {
  disabled: false,
};

export default CheckboxFilter;
