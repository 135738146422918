import React, { forwardRef } from "react";
import { oneOf } from "prop-types";

import { useSearchContext } from "../../contexts/Search";
import SearchBlock from "../../components/SearchBlock";
import { Plus } from "../../libs/icons";

import styles from "./styles.module.scss";

const SearchBlockWrapper = forwardRef(({ type }, ref) => {
  const isMobile = window.innerWidth <= 480 ? true : false;
  const { open, closeSearch } = useSearchContext();

  const handleClose = () => {
    if (closeSearch) {
      closeSearch();
    }
  };

  if (type === "mobile" && !isMobile) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={[
        styles.wrapper,
        type === "mobile" ? styles.mobile : styles.home,
        open ? styles.visible : "",
      ].join(" ")}
      data-testid={`search-block-wrapper-${type}`}
    >
      <SearchBlock successCallback={handleClose} />
      {type === "mobile" && (
        <>
          <button
            disabled={!open}
            className={[
              styles.button,
              styles.closeButton,
              isMobile && styles.closed,
            ].join(" ")}
            type="button"
            onClick={handleClose}
            aria-label="Fechar"
            data-testid="search-block-wrapper-close-button"
          >
            <Plus />
          </button>
          <button
            disabled={!open}
            onClick={handleClose}
            className={styles.cancelButton}
            data-testid="search-block-wrapper-cancel-button"
          >
            Cancelar
          </button>
        </>
      )}
    </div>
  );
});

SearchBlockWrapper.propTypes = {
  type: oneOf(["home", "mobile"]).isRequired,
};

export default SearchBlockWrapper;
