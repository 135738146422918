import React from "react";

import styles from "./styles.module.scss";

const PrivacyPolicyContent = () => (
  <main className={styles.wrapper}>
    <small className={styles.preffix}>
      A política de privacidade inicia vigência em 14 de setembro de 2022
    </small>
    <h1>Política de privacidade e Aviso Legal para Empresas</h1>
    <p>
      Para consultar o Aviso Legal para Candidatos <a href="https://inklua.com/politica-de-privacidade">clique aqui</a>.
    </p>
    <p>
      Para consultar os Termos de Uso da Busca de perfil <a href="https://inklua.com/plataforma/termos-uso-busca-perfil">clique aqui</a>.
    </p>
    <h2>1. Condições legais</h2>
    <p>
      O presente documento tem por objeto estabelecer as Condições Gerais de Uso do portal <a href="https://www.inklua.com">inklua.com</a> (doravante Portal) na Internet e todos os websites pertencentes a Inklua Serviços para Inclusão de PcD Ltda (doravante Inklua), inscrita no CNPJ 22.692.860/0001-76.
    </p>
    <p>
      A finalidade do Portal é a de ofertar uma plataforma que promova o encontro entre empresas e candidatos em um ambiente online.
    </p>
    <p>
      O Portal entende por Candidatos, os usuários pessoas físicas, que utilizem os serviços do Portal e/ou acessem o Portal.
    </p>
    <p>
      Nesse sentido, o Portal tem como objetivo disponibilizar ao candidato o acesso a vagas de emprego e às empresas o recebimento do currículo dos Candidatos que se registrem nas vagas anunciadas.
    </p>
    <p>
      A utilização por parte do Usuário de qualquer dos Serviços do Portal supõe e expressa sua adesão e aceitação expressa a todas as Condições Gerais de Uso e a Política de Privacidade e Proteção de Dados, na versão publicada no presente Website no momento em que o usuário acesse o Portal, assim como as Condições Particulares que, em seu caso, sejam aplicadas (doravante, coletivamente denominadas "Condições Legais"). O Usuário obrigatoriamente deve realizar a leitura atenta das Condições Legais previamente a utilização de qualquer serviço do Portal sob sua inteira responsabilidade.
    </p>
    <p>
      A utilização de certos serviços oferecidos aos Candidatos através do Portal, podem encontrar-se sujeitos a condições particulares próprias que, segundo os casos substituem, completam e/ou modificam as presentes Condições Legais. Portanto, antes da utilização dos referidos serviços, o Candidato também deve ler atentamente e aceitar inclusive as correspondentes Condições Particulares próprias.
    </p>
    <p>
      Para acessar o site, o usuário deve ter 16 anos de idade ou mais. Se o Utilizador tiver menos de 18 anos ou menos do que a maioria legal do seu país, a utilização do Portal e a assunção de qualquer obrigação devem estar sob a supervisão dos pais, tutor ou outro adulto responsável. Se o usuário acessar o Portal ou usá-lo como funcionário ou representante de uma empresa, ele declara e garante que tem autoridade para vincular a empresa às condições legais.
    </p>
    <br />
    <h2>2. Obrigações dos usuários no portal </h2>
    <p>
      As seguintes obrigações se aplicam a qualquer usuário que acesse e use o Portal, independentemente de ser um candidato ou uma empresa.
    </p>
    <p>
      O usuário concorda em:
    </p>
    <ol>
      <li>Fazer uso diligente do Portal e seus serviços e somente para o propósito do mesmo, deve cumprir todas as leis que resultam da aplicação e das Condições Legais do Portal, mantendo sempre o devido respeito a outros Usuários;</li>
      <li>Respeitar os direitos de terceiros ao usar os serviços do Portal. Portanto, a fica proibido a reprodução, distribuição, transmissão, adaptação ou modificação, por qualquer meio e sob qualquer forma, do conteúdo do Portal (textos, desenhos, gráficos, informações, bancos de dados, etc.), arquivos de som e / ou imagem, marcas, software, logotipos, etc.) e outros elementos deste Portal, a menos que previamente autorizado por seus legítimos proprietários ou quando permitido por lei;</li>
      <li>Publicar conteúdo no Portal de acordo com as disposições estabelecidas abaixo nestas Condições Legais;</li>
      <li>Não utilizar mecanismos, software ou scripts em relação à utilização do Website e não bloquear, sobrescrever, modificar ou copiar, a não ser que isso seja necessário para a correta utilização dos serviços dos websites. Por exemplo, cópias mediante tecnologias de buscador tipo "Robot/Crawler" não é necessário para a correta utilização dos serviços do Website, pelo que está expressamente proibido;</li>
      <li>Em caso de registro no Portal, o Usuário deve selecionar o identificador (ID ou login) e a senha comprometendo-se a conservá-la e utilizá-la com o devido cuidado; </li>
      <li>O Usuário se absterá de coletar ou usar dados e conteúdos de outros Usuários publicados no Portal para atividades promocionais ou de propaganda, bem como para o envio de comunicações comerciais; </li>
    </ol>
    <br />
    <h2>3. Publicação do conteúdo no portal</h2>
    <h4><b>I. Conteúdo do Usuário</b></h4>
    <p>
      Os usuários somente podem incluir ofertas de emprego no Portal e todas as informações que um usuário envia, exibe ou disponibiliza de alguma outra forma no Portal ou através dele é chamado de "Conteúdo do Usuário" nestas Condições Legais.
    </p>
    <p>
      O Usuário é o único responsável por todo o Conteúdo do Usuário que inclui no Portal e expressamente declara e garante que: (i) possui o Conteúdo do Usuário que enviar, publicar, exibir ou de outra forma disponibilizar no Portal ou através isto, ou não tem o direito de conceder a licença estabelecidos nesta seção, (ii) tal Conteúdo do Usuário e submissão, publicação, exibição ou disponibilidade no Portal ou através dele não viola a lei aplicável ou dos direitos de privacidade, direitos de publicidade, direitos autorais, direitos contratuais, direitos de propriedade intelectual ou qualquer outro direito de qualquer pessoa ou entidade, e (iii) tal envio, postagem, exibição ou disponibilidade no Portal ou através dele não resultará em nenhuma quebra de contrato entre o Usuário e um terceiro.
    </p>
    <p>
      O Usuário concorda expressamente em pagar todos os royalties, taxas, danos e qualquer outro dinheiro devido a qualquer pessoa ou entidade por causa de tal Conteúdo do Usuário enviado, publicado ou exibido no Portal ou através dele. Será de responsabilidade do Usuário manter toda a informação disponibilizada ao Portal permanentemente atualizada, de modo que corresponda em cada momento, à situação real do Usuário. O Usuário aceita defender, indenizar e isentar a Inklua de qualquer reivindicação resultante de tal Conteúdo disponibilizado pelo Usuário.
    </p>
    <p>
      Apenas na medida permitida por lei, se você como Usuário publica Conteúdo do Usuário na Inklua, permite a Inklua uma licença perpétua irrevogável totalmente paga, isenta de royalties, para sublicenciar o direito e uma licença não exclusiva, mundial, para fazer, usar, vender, sublicenciar, reproduzir, distribuir, executar, publicar, preparar trabalhos derivados deles e explorar, caso contrário todo o conteúdo e materiais com a finalidade de publicar material no Portal (ou outros portais pertencentes a este ou de sites parceiros, mantendo e promovendo o site sem restrições. Além disso, concede a Inklua, seus associados e sublicenciados a licença para usar o seu nome, nome de usuário ou marcas comerciais e logotipos em conexão com qualquer conteúdo ou materiais de marketing da Inklua, ou ações iniciadas pela Inklua para promover ou divulgar esse Conteúdo do Usuário (por exemplo, ofertas de emprego), incluindo o uso de palavras-chave em mecanismos de pesquisa da Internet de terceiros.
    </p>
    <h4><b>II. Regras para publicação do conteúdo do usuário</b></h4>
    <p>
      O Usuário concorda e compromete-se a cumprir as seguintes regras:
    </p>
    <p>
      O Conteúdo do Usuário fornecido pelo Usuário para a Inklua deve ser verdadeiro e preciso. Para esses fins, o Usuário garante a autenticidade de todos os dados comunicados no Portal da Web, bem como seu direito ou autorização para fornecê-los.
    </p>
    <p>
      O Usuário deve abster-se de usar, publicar ou disponibilizar no Portal qualquer Conteúdo do Usuário que seja ou possa ser interpretado como calunioso, difamatório, injurioso, discriminatório, abusivo, obsceno, contendo insultos de natureza sexual, religiosa ou étnica ou racial ou quem sabe que é falso, enganoso ou impreciso ou promovam qualquer outra violação dos direitos fundamentais e das liberdades reconhecidas pela Constituição Brasileira ou outras leis.
    </p>
    <p>
      O Usuário deve abster-se de usar, publicar ou disponibilizar no Portal qualquer Conteúdo de Usuário que seja de natureza pornográfica ou que viole as leis de proteção de menores, ou com a intenção de anunciar, oferecer ou distribuir produtos pornográficos ou que violem as leis de proteção de menores, incomodem outros usuários (especialmente através de spam).
    </p>
    <p>
      O Usuário deve abster-se de usar, publicar ou disponibilizar no Portal qualquer Conteúdo do Usuário que esteja legalmente protegido (em particular, pela legislação relacionada à propriedade intelectual e industrial, por exemplo: marcas registradas, patentes, modelos de utilidade ou modelos estéticos, segredos comerciais) sem ter o direito ou autorização para ele.
    </p>
    <p>
      O Usuário deve abster-se de usar, publicar ou disponibilizar no Portal qualquer Conteúdo do Usuário que deteriore ou possa prejudicar ou de alguma forma afetar a qualidade dos serviços oferecidos no Site.
    </p>
    <p>
      O Usuário deve abster-se de usar, publicar ou disponibilizar qualquer Conteúdo do Usuário que não reúnam os parâmetros de qualidade estabelecidos no Portal.
    </p>
    <p>
      O Usuário conhece e aceita que o uso dos serviços do Portal ocorre sob sua única e exclusiva responsabilidade.
    </p>
    <p>
      O Portal poderá eliminar conteúdos ilegais ou supostamente ilegais sem prévio aviso.
    </p>
    <p>
      O Portal não pode controlar todos e cada um dos conteúdos publicados, de maneira que não pode assumir a responsabilidade sobre os conteúdos.
    </p>
    <p>
      De todas as maneiras, periodicamente se revisam os conteúdos inseridos e publicados para assegurar os princípios de qualidade do Portal bem como as normas aqui indicadas.
    </p>
    <p>
      A Inklua reserva-se o direito de remover qualquer Conteúdo do Usuário ou parte dele que, a critério exclusivo da Inklua, infrinja as regras de publicação anteriores ou qualquer outra obrigação contida nestas Condições Legais. Se você considerar que um Conteúdo de Usuário não cumpre as disposições desta seção, entre em contato com nossa equipe de atendimento ao cliente para que possamos ter a oportunidade de analisar e considerar, se necessário, sua eliminação.
    </p>
    <p>
      O Usuário será responsável por danos de qualquer natureza que a Inklua possa sofrer como resultado de violação intencional ou culposa de qualquer uma das obrigações às quais está sujeito de acordo com estas Condições Legais e os regulamentos aplicáveis.
    </p>
    <br />
    <h4><b>III. Conteúdo de terceiros</b></h4>
    <p>
      O Portal também disponibiliza conteúdo de outros usuários, anunciantes e outros terceiros. Para esses fins, "Conteúdo de terceiros" significa qualquer trabalho de autoria ou informação, que você, como Usuário, encontra no Portal.
    </p>
    <p>
      Como não controlamos esse Conteúdo de Terceiros, você, como Usuário, entende e concorda que: (i) não somos responsáveis nem endossamos tal Conteúdo de Terceiros, incluindo publicidade e informações sobre produtos e serviços de terceiros, anúncios de emprego ou informações relacionadas com a empresa, avaliações da empresa e processos de seleção, bem como salários fornecidos por outros Usuários; (ii) não garantimos a precisão, atualidade, adequação, confiabilidade ou qualidade das informações contidas no referido Conteúdo de Terceiros; e (iii) não assumimos qualquer responsabilidade pelo Conteúdo de terceiros que seja questionável, impreciso, enganoso ou ilegal ou que não esteja em conformidade com estas Condições Legais disponibilizadas pelos Usuários, anunciantes e terceiros.
    </p>
    <p>
      O Portal pode conter links para sites de terceiros colocados por nós como um serviço para os interessados nessas informações ou publicados por outros Usuários ou anunciantes. O uso de todos esses links para sites de terceiros é por sua conta e risco. Nós não supervisionamos ou temos qualquer controle sobre sites de terceiros. Na medida em que esses links são fornecidos por nós, eles são fornecidos apenas como uma conveniência, e um link para um site de terceiros não implica em nosso endosso, adoção, patrocínio ou afiliação a tal website de terceiros.
    </p>
    <h2>4. Condições legais para Empresas </h2>
    <h4><b>1) Registro de empresas e perfil público</b></h4>
    <p>
      Se a Empresa decide cadastrar-se no Portal, serão solicitados os dados estritamente necessários para que se possa alcançar os fins e finalidades aos quais está destinando-o.
    </p>
    <p>
      Para poder cadastrar o usuário, o representante da Empresa deverá completar um formulário eletrônico inicial para fornecer todos os dados de identificação da Empresa, o qual implica fornecer alguns dados pessoais, como as informações de contato da pessoa que usa em nome da Empresa, seja ele funcionário autorizado ou representante legal. Especificamente, seus nomes e sobrenomes, e-mail corporativo e número de telefone fixo da empresa.
    </p>
    <p>
      A Inklua reserva-se o direito de não admitir o registo de uma empresa ou entidade, por motivos comerciais, ou devido a violações contratuais anteriores ou das Condições Legais, comprovadas ou baseadas em suspeitas bem fundamentadas de tal incumprimento, ou devido ao recebimento de reclamações de terceiros e atividades inapropriadas.
    </p>
    <p>
      No registro e uso da conta por parte da Empresa é obrigatório cumprir com as disposições seguintes:
    </p>
    <ol type="a">
      <li>Registrar apenas empresas existentes e devidamente constituídas de acordo com a regulamentação aplicável;</li>
      <li>Que o registro e uso dos serviços do Portal tenham sido autorizados pelos representantes legais da empresa. Com o cadastro no Portal, a pessoa de contato que cadastra a Empresa declara e garante que possui a autorização supracitada; </li>
      <li>Existir algum tipo de relação de trabalho ou profissional direta entre a pessoa física responsável pela realização do registro e a Empresa. A pessoa física ou representante da Empresa não pode ser um agente independente, nem representar e registrar mais de uma empresa na plataforma; </li>
      <li>Utilizar os serviços do Portal apenas para a publicação de ofertas de emprego, procura de candidatos. Quaisquer outros usos estão terminantemente proibidos; </li>
      <li>É expressamente proibido revender o acesso à conta, assim como o registro na conta de usuários alheios à organização da Empresa, incluindo seus próprios clientes, ou permitir seu uso por esses indivíduos terceiros; </li>
      <li>A Empresa ou seu representante legal deve utilizar o Portal para publicar os processos de seleção da sua organização. Excetua-se desta limitação aquelas empresas recrutadoras que utilizem o Portal no desenvolvimento de sua própria atividade, excluindo plataformas digitais, e sempre que elas não compitam ou pretendam competir contra o Inklua; </li>
      <li>Proteger suas senhas e não compartilhar o mesmo acesso com outras pessoas. Se outras pessoas utilizarem o Portal de forma inadequada usando sua conta, você corre o risco de perder o acesso ao serviço. Recomendamos que não use a mesma palavra de usuário para sua senha; </li>
      <li>O Portal poderá fornecer informações relativas a empresa contratante e vaga divulgada, sem que haja a necessidade de comunicar a empresa contratante previamente caso o Portal venha a ser intimado por uma autoridade pública legalmente autorizada, ou decorrentes de ordem judicial, ou por intimação do Ministério Público ou por autoridade policial regularmente constituída; </li>
    </ol>
    <br />
    <h2>5. Regras de publicação de vagas</h2>
    <p>
      O anúncio de vagas trata-se de um serviço diferente da consultoria de recrutamento e seleção realizado pela Inklua.
    </p>
    <p>
      Não existe qualquer garantia que ao anunciar vagas no Portal a Empresa conseguirá um ou mais candidatos.
    </p>
    <p>
      Somente pode ser anunciado uma vaga em cada anúncio, ou seja, uma vaga de Desenvolvedor Front-end é diferente de uma vaga de Desenvolvedor Back-end, assim como uma vaga de analista de compras é diferente de uma vaga de analista de contratos. Não há limitação quanto ao número de posições de cada anúncio, ou seja, a empresa pode anunciar 20 posições para Front-end em uma vaga.
    </p>
    <p>
      O preenchimento de todos os campos ao anunciar uma vaga é obrigatório.
    </p>
    <p>
      No campo de descrição deverá ser inserido todas as informações importantes sobre a vaga para o candidato.
    </p>
    <p>
      Mesmo que a vaga seja na modalidade remota, é obrigatório o preenchimento do estado e cidade na qual a sede da Empresa que irá contratar o profissional está localizada.
    </p>
    <p>
      Não é possível fazer upload de quaisquer imagens para ilustrar a vaga, apenas é permitido selecionar uma de nossas imagens já pré-selecionadas no momento do cadastro.
    </p>
    <p>
      Não é permitido utilizar HTML nos textos ou mudar a estilização da descrição do anúncio, exemplo: inserir negrito, sublinhando ou mudar a cor da fonte, tamanho da fonte. As únicas edições possíveis de texto são caixa alta e parágrafos.
    </p>
    <p>
      Para encaminhar uma candidatura é necessário inserir o endereço de e-mail para recebimento ou link externo da Empresa. Em caso de utilização de e-mail, este deve ser um endereço válido e para o qual serão direcionados os contatos dos candidatos (este e-mail pode ser diferente do cadastro do recrutador). Em se tratando de link externo, este obrigatoriamente deve ser lícito, podendo ser o direcionamento para o Site ou sistema da Empresa.
    </p>
    <p>
      Toda vaga passará por validação manual onde todas as informações cadastradas serão analisadas pela Inklua antes de ser publicada. O prazo para conclusão da validação é até 1 dia útil;
    </p>
    <p>
      Somente após a validação da vaga, o usuário poderá realizar o pagamento através de cartão de crédito ou boleto.
    </p>
    <p>
      Em caso de reprovação da vaga, o usuário será comunicado por seu e-mail de cadastro sobre os termos de cadastro do anúncio e poderá realizar a edição da vaga no sistema. Então a vaga será validada novamente pela Inklua.
    </p>
    <p>
      Quando a vaga for publicada, o usuário será comunicado por seu e-mail de cadastro. Sob nenhuma hipótese a Inklua assumirá a responsabilidade sobre seus conteúdos.
    </p>
    <p>
      A Inklua não grava informações de pagamento, ou seja, a cada anúncio de vaga o usuário deverá preencher os campos solicitados novamente.
    </p>
    <p>
      Não é possível anunciar e efetuar o pagamento de múltiplas vagas, o processo deve ser feito a cada anúncio.
    </p>
    <p>
      Após a confirmação sistêmica do pagamento, a vaga será publicada automaticamente.
    </p>
    <p>
      Em até 5 dias após a publicação, será gerado uma Nota Fiscal com os dados fornecidos no momento do pagamento e será enviado para o e-mail do usuário que cadastrou o anúncio. O envio da Nota Fiscal será feito pela pela Prefeitura do Município de São Paulo através do endereço de e-mail: nfe-auto@prefeitura.sp.gov.br. Recomendamos que o usuário verifique sua caixa de spam.
    </p>
    <p>
      A publicação da vaga ocorre após a confirmação do pagamento e o anúncio fica ativo por 20 dias na plataforma da Inklua, após este prazo o anúncio expira e não será mais visível para os candidatos.
    </p>
    <p>
      Não há opção para renovar o anúncio da vaga, caso deseje republicar a vaga, o usuário deverá efetuar novo processo de cadastro da vaga.
    </p>
    <br />
    <h2>6. Termos adicionais</h2>
    <p>
      As vagas devem ser publicadas de acordo com a área de atuação da Empresa e pelos próprios responsáveis da mesma, salvo exceções que são tomadas em comum acordo com Inklua através da equipe de cadastro de conteúdo.
    </p>
    <p>
      De acordo com a legislação brasileira, não é permitida a publicação de itens discriminatórios nos anúncios de emprego, tais como sexo, idade, raça, religião, condições de saúde, opção sexual, opinião política, nacionalidade, origem social, restrição creditícia ou comprovação de experiência profissional superior a seis meses. As informações citadas nas vagas são de total responsabilidade da empresa anunciante e contratante do serviço.
    </p>
    <p>
      Caso o Portal receba algum tipo de denúncia referente ao anúncio publicado pela empresa contratante no sentido de que a vaga contém algum item discriminatório, conforme definido no item acima, o Portal poder retirar a vaga do site sem aviso prévio.
    </p>
    <p>
      As vagas de emprego devem oferecer contratos legalmente válidos. Não serão aceitas vagas de empregos:
    </p>
    <ul>
      <li>Com discriminação de raça, sexo, religião, opinião, nacionalidade, deficiência ou qualquer outra circunstância pessoal ou social; </li>
      <li>Que exijam investimento em dinheiro por parte do candidato;</li>
      <li>De empresas "pirâmide" ou negócios multi-nível (Multi-Level Marketing); </li>
      <li>De oportunidades de negócio; </li>
      <li>De busca por "parceiros comerciais" e sócios; </li>
      <li>De cursos, palestras, produtos ou informações com outros objetivos distintos da contratação de candidatos; </li>
      <li>Relacionadas com a indústria de entretenimento para adultos ou lazer noturno; </li>
      <li>Inscrição de menores de idade sem a tutela de seus pais ou tutor; </li>
      <li>Susceptíveis a engano ou que conduzam a qualquer ilegalidade de acordo com as leis vigentes; </li>
      <li>Esta restrição se aplicará por analogia aos cursos oferecidos pelas instituições de ensino; </li>
    </ul>
    <p>
      A Inklua não permite a inserção de vagas de emprego que deteriorem a qualidade do serviço. Está proibida a inserção de vagas de emprego nos seguintes critérios:
    </p>
    <ol>
      <li>Não é permitida a publicação de vagas com perfil irreal apenas para atrair candidatos. Os candidatos devem ser captados para vagas nas qual ele se candidatou; </li>
      <li>As ofertas devem se referir a postos de trabalho disponíveis nas empresas. Não é permitido incluir URLs nas vagas de emprego que redirecionem o usuário a seção de emprego da companhia; </li>
      <li>Cada vaga de emprego deve corresponder a apenas um posto de trabalho. Para postos diferentes, deverá incluir vagas de empregos diferentes. Exemplo incorreto: Vaga 1: Auxiliar Administrativo/porteiro/Professora de Inglês. Exemplo correto: Vaga 1: Auxiliar Administrativo; Vaga 2: Porteiro; Vaga 3: Professora de Inglês. Se para uma mesma vaga de emprego há diversas posições disponíveis, pode indicá-los livremente no campo de "Número de Vagas" no formulário de inserção de vagas; </li>
      <li>Vagas veiculadas em uma área profissional diferente do cargo da vaga. A área profissional na qual a vaga é veiculada deve ser a mais adequada possível ao cargo a ser preenchido; </li>
      <li>As ofertas devem ter relação com a vaga anunciada e com as atividades da empresa. A vaga deve se referir à própria empresa, não sendo admitida a publicação de vagas para terceiros, e deve se referir à área de atuação (área e especialização). Vagas cadastradas fora da área de atuação ou para terceiros não serão aceitas, salvo pacotes específicos que permitam esse tipo de serviço; </li>
      <li>Não é permitido vender as vagas de trabalho do Portal a terceiros; </li>
    </ol>
    <br />
    <h2>7. Terminação de serviços </h2>
    <p>
      As Condições Legais permanecerão em vigor enquanto a Empresa usar o Portal e sua conta permanecer aberta. A empresa pode excluir seu perfil e conta, bem como suas ofertas de emprego a qualquer momento.
    </p>
    <p>
      A Inklua reserva o direito de cancelar o serviço e impedir ou limitar o acesso da Empresa, sem o direito ao reembolso do preço pago em caso de uso fraudulento ou não de acordo com estas Condições Legais e contra a finalidade do Portal.
    </p>
    <p>
      O não cumprimento das regras de registro pode significar o cancelamento imediato da conta da Companhia e a destruição dos conteúdos associados a ela, sem a possibilidade de recuperação.
    </p>
    <p>
      Em nenhum caso a suspensão do serviço ou a não ativação devido a violação das regras de registro ou de qualquer das Condições Legais resultará no reembolso do valor pago.
    </p>
    <br />
    <h2>8. Disposições legais exclusão de garantias e limitação de responsabilidade</h2>
    <p>
      As exclusões e limitações de responsabilidade nesta seção aplicam-se à extensão máxima permitida pela lei aplicável. Nada nesta seção destina-se a limitar qualquer direito de usuários que não podem ser legalmente limitados.
    </p>
    <p>
      A Inklua fornece os serviços e acesso ao Portal como está e de acordo com a disponibilidade. Renunciamos expressamente todas as garantias de qualquer espécie, expressas ou implícitas, incluindo garantias ou condições de comercialização, adequação a uma finalidade específica, título, usufruto tranquilo, exatidão ou não violação. A Inklua não garante que o Portal pode estar disponível de forma ininterrupta, seguro ou livre de erros, ou que os resultados que podem ser obtidos com a utilização do Site é precisa ou confiável. Devido a razões de manutenção, segurança ou capacidade e por causa de eventos sobre os quais a Inklua não pode influenciar (por exemplo, falhas de redes de comunicações públicas, falta de energia, etc.) breves falhas ou suspensão temporária pode ocorrer os serviços do Portal. Consequentemente, exclui a Inklua, até ao limite máximo permitido por lei, qualquer responsabilidade por danos de qualquer espécie que pode ser devido à falta de disponibilidade ou continuidade do Portal e serviços habilitados na mesma, bem como erros no acesso às diferentes páginas da web ou àqueles a partir dos quais, quando apropriado, tais serviços são fornecidos.
    </p>
    <p>
      A Inklua não pode ser responsabilizado por danos de qualquer espécie decorrentes da falta de exatidão, integridade, pontualidade, erros ou omissões que possam sofrer as informações e serviços contidos neste Site ou outro Conteúdo do Usuário e terceiros para aqueles que podem ser acessados através dele. No caso de um usuário considerar que há conteúdo no Portal que viola seus direitos, ele deve notificar imediatamente a Inklua por meio do formulário de contato do Portal, nem assumir qualquer obrigação ou compromisso de verificar ou monitorar seu conteúdo e informações adicionais. Além das verificações de sua equipe de moderação e após as reclamações enviadas pelos usuários através do formulário de contato facilmente acessível no Portal.
    </p>
    <p>
      O Portal inclui em seu conteúdo links para sites pertencentes ou gerenciados por terceiros, a fim de facilitar o acesso às informações disponíveis através da Internet. A Inklua não assume qualquer responsabilidade derivada da existência de links entre o conteúdo deste site e conteúdos localizados fora dele ou qualquer outra menção de conteúdo externo a este site. Tais links ou menções têm um propósito exclusivamente informativo e, em nenhum caso, implicam o apoio, aprovação, comercialização ou qualquer relacionamento entre a Inklua as pessoas ou entidades que são os autores ou gerentes de tais conteúdos ou proprietários dos sites onde estão localizados.
    </p>
    <p>
      O uso da senha é pessoal e intransferível, a atribuição, mesmo que temporária, a terceiros não é permitida. O Usuário deve adotar as medidas necessárias para a custódia da senha escolhida por ele, evitando o seu uso por terceiros. O usuário é o único responsável pelo uso de sua senha, com indenização completa para a Inklua. Caso o Usuário conheça ou suspeite do uso de sua senha por terceiros, ele deve informar a Inklua o mais rápido possível.
    </p>
    <br />
    <h2>9. Direitos de propriedade intelectual e industrial</h2>
    <p>
      Ficam reservados todos os direitos de exploração.
    </p>
    <p>
      Este Website é regido pelas leis brasileiras e se encontra protegido pela legislação nacional e internacional sobre propriedade intelectual e industrial.
    </p>
    <p>
      O Usuário reconhece que todos os elementos do Portal e de cada um dos Serviços prestados através do mesmo, a informação e materiais contidos no mesmo, a estrutura, seleção, ordenação e apresentação de seus conteúdos e os programas de computador utilizados em relação com o mesmo, estão protegidos pelos direitos de propriedade intelectual e industrial do Portal ou de terceiros.
    </p>
    <p>
      Salvo o que for autorizado pela Inklua ou, em seu caso, pelos terceiros titulares dos direitos correspondentes, ou a menos que isso seja legalmente permitido, o usuário não poderá reproduzir, transformar, modificar, desmontar, desunir, realizar engenharia inversa, distribuir, alugar, emprestar, colocar à disposição ou permitir o acesso ao público através de qualquer modalidade de comunicação pública de nenhum dos elementos referidos no parágrafo anterior.
    </p>
    <p>
      Em particular, fica terminantemente proibida a utilização dos textos, imagens, anúncios e qualquer outro elemento incluído no presente Portal para sua posterior inclusão, total ou parcial, em outros websites alheios ao Portal, sem contar com a autorização prévia e por escrito do Portal.
    </p>
    <p>
      O Usuário deverá abster-se de suprimir os sinais identificadores dos direitos (de propriedade intelectual, industrial ou qualquer outro) da Inklua, ou de terceiros que figurem no Portal e em cada um dos diversos Serviços oferecidos através dele. Igualmente, o Usuário deverá abster-se de eludir ou manipular, quaisquer dispositivos técnicos estabelecidos pela Inklua ou por terceiros, seja no Portal, em quaisquer dos Serviços ou em quaisquer dos materiais, elementos ou informação obtidos através do mesmo, para a proteção dos seus direitos.
    </p>
    <p>
      A legitimidade dos direitos de propriedade intelectual ou industrial correspondentes aos conteúdos inseridos pelos Candidatos e Empresas é de exclusiva responsabilidade dos mesmos. No caso de qualquer usuário ou terceiro considerar que houve uma violação de seus legítimos direitos de propriedade intelectual e/ou industrial pela introdução de certos conteúdos no Portal, eles devem notificar a referida circunstância por escrito à Inklua de acordo com os dados de contato indicado abaixo e indicando:
    </p>
    <ol>
      <li>Identificar dados da parte interessada detentora dos direitos alegadamente infringidos. Se o pedido for apresentado por um terceiro que não seja o interessado, deve indicar e credenciar a representação com a qual atua; </li>
      <li>Indicação do conteúdo protegido pelos direitos de propriedade intelectual e / ou industrial e sua localização no Portal; </li>
      <li>Acreditação da existência, posse e validade dos direitos de propriedade intelectual e / ou industrial acima mencionados;</li>
      <li>Declaração expressa em que o interessado é responsável pela veracidade dos dados e informações fornecidas na notificação referida neste ponto. </li>
    </ol>
    <br />
    <h2>10. Legislação aplicável e jurisdição </h2>
    <p>
      As presentes Condições Legais serão regidas pela Legislação Brasileira.
    </p>
    <p>
      Para todas as questões ou ações que forem suscitadas e/ou derivadas a partir da prestação dos serviços e conteúdos do Portal, e sobre a interpretação, aplicação, cumprimento ou descumprimento do aqui estabelecido, o Portal e o Usuário, com renúncia expressa a qualquer outro foro, por mais privilegiado que seja, se submetem à jurisdição dos Juizados e Tribunais da Cidade e Comarca de São Paulo/ SP.
    </p>
    <br />
    <h2>11. Informação de contato</h2>
    <p>
      Se tiver alguma dúvida, reclamação, sugestão ou comentário sobre estas Condições Legais, pode nos contatar através do formulário de contato na página do Portal Inklua Para Empresas.
    </p>
  </main>
);

export default PrivacyPolicyContent;
