import React from "react";
import { node, bool, string } from "prop-types";

import styles from "./styles.module.scss";

const SectionTitle = ({ children, centered, tabIndex }) => (
  <h2
    className={styles.title}
    data-testid="account-section-title"
    style={{
      textAlign: centered ? "center" : "left",
    }}
    tabIndex={tabIndex}
  >
    {children}
  </h2>
);

SectionTitle.propTypes = {
  children: node.isRequired,
  centered: bool,
  tabIndex: string,
};

SectionTitle.defaultProps = {
  centered: false,
  tabIndex: "0",
};

export { SectionTitle };
