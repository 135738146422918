import { string, oneOf } from "prop-types";

import { WarningTriangle, ForbiddenCircle } from "../../../../libs/icons";

import styles from "./styles.module.scss";

const CandidateRecruitmentStatus = ({ recruiterName, status }) => {
  if (status === "BLOCKED") {
    return (
      <div className={styles.container}>
        <div className={styles.textWrapper}>
          <span
            className={[styles.smallText, styles.redText].join(" ")}
            data-testid="primary"
          >
            <ForbiddenCircle /> Não considerar este candidato
          </span>
        </div>
      </div>
    );
  }

  if (status === "TAKEN") {
    return (
      <div className={styles.container}>
        <WarningTriangle color="#fc846e" />
        <div className={styles.textWrapper}>
          <span className={styles.smallText} data-testid="secondary">
            Candidato está em processo com
          </span>
          <span className={styles.bigText} data-testid="primary">
            {recruiterName}
          </span>
        </div>
      </div>
    );
  }

  return null;
};

CandidateRecruitmentStatus.propTypes = {
  status: oneOf(["TAKEN", "YOURS", "BLOCKED", "AVAILABLE"]).isRequired,
  recruiterName: string,
};

CandidateRecruitmentStatus.defaultProps = {
  recruiterName: null,
};

export { CandidateRecruitmentStatus };
