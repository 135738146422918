// TO-DO: change values and get correct urls
const illustrations = [
    { value: "1", image: "https://s3.sa-east-1.amazonaws.com/public.inklua/1.webp" },
    { value: "2", image: "https://s3.sa-east-1.amazonaws.com/public.inklua/2.webp" },
    { value: "3", image: "https://s3.sa-east-1.amazonaws.com/public.inklua/3.webp" },
    { value: "4", image: "https://s3.sa-east-1.amazonaws.com/public.inklua/4.webp" },
    { value: "5", image: "https://s3.sa-east-1.amazonaws.com/public.inklua/5.webp" },
    { value: "7", image: "https://s3.sa-east-1.amazonaws.com/public.inklua/7.webp" },
    { value: "8", image: "https://s3.sa-east-1.amazonaws.com/public.inklua/8.webp" },
    { value: "9", image: "https://s3.sa-east-1.amazonaws.com/public.inklua/9.webp" },
    { value: "10", image: "https://s3.sa-east-1.amazonaws.com/public.inklua/10.webp" },
    { value: "11", image: "https://s3.sa-east-1.amazonaws.com/public.inklua/11.webp" },
    { value: "12", image: "https://s3.sa-east-1.amazonaws.com/public.inklua/12.webp" },
    { value: "13", image: "https://s3.sa-east-1.amazonaws.com/public.inklua/13.webp" },
    { value: "14", image: "https://s3.sa-east-1.amazonaws.com/public.inklua/14.webp" },
    { value: "15", image: "https://s3.sa-east-1.amazonaws.com/public.inklua/15.webp" },
    { value: "16", image: "https://s3.sa-east-1.amazonaws.com/public.inklua/16.webp" },
];

const applicationLabel = {
    url: {
        label: "link",
        placeholder: "Digite o link"
    },
    email: {
        label: "email",
        placeholder: "Digite o e-mail"
    }
};

export { illustrations, applicationLabel }