import { string, node } from "prop-types";

import { SectionTitle } from "../SectionTitle";

import styles from "./styles.module.scss";

const InformationSummary = ({ title, children }) => {
  return (
    <div className={styles.wrapper}>
      <SectionTitle>{title}</SectionTitle>
      <div className={styles.innerWrapper}>{children}</div>
    </div>
  );
};

InformationSummary.propTypes = {
  title: string.isRequired,
  children: node.isRequired,
};

export default InformationSummary;
