const map = {
  api: {
    remoto: "remote",
    presencial: "local",
    hibrido: "hybrid",
  },
  url: {
    remote: "remoto",
    local: "presencial",
    hybrid: "hibrido",
  },
};

const parseSearchParameters = ({ urlParameters }) => {
  const { tipo, cidade, estado, termo } = urlParameters;

  const apiSearchParameters = [];
  const urlSearchParameters = [];

  if (tipo && (tipo === "remoto" || tipo === "presencial")) {
    urlSearchParameters.push(`tipo=${tipo}`);
    apiSearchParameters.push(`contract_type=${map.api[tipo]}`);
  }

  if (tipo && (tipo === "remote" || tipo === "local")) {
    urlSearchParameters.push(`tipo=${map.url[tipo]}`);
    apiSearchParameters.push(`contract_type=${tipo}`);
  }

  if (tipo && (tipo === "hibrido" || tipo === "hybrid")) {
    urlSearchParameters.push(`tipo=hibrido`);
    apiSearchParameters.push(`contract_type=hybrid`);
  }

  if (cidade) {
    urlSearchParameters.push(`cidade=${cidade}`);
    apiSearchParameters.push(`city=${cidade}`);
  }

  if (estado) {
    urlSearchParameters.push(`estado=${estado}`);
    apiSearchParameters.push(`state=${estado}`);
  }

  if (termo && termo.trim() !== "") {
    urlSearchParameters.push(`termo=${termo}`);
    apiSearchParameters.push(`q=${termo}`);
  }

  return {
    api: apiSearchParameters.join("&"),
    url: urlSearchParameters.join("&"),
  };
};

export { parseSearchParameters };
