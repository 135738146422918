import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "@unform/web";
import * as Yup from "yup";

import { InputLabel } from "../../../../components/InputLabel";
import SquareButton from "../../../../components/SquareButton";
import SquareLinkButton from "../../../../components/SquareLinkButton";
import { Radio, errorParser } from "../../../../components/NewInput";
import { LabelTooltip } from "../../../../components/LabelTooltip";
import useAxios from "../../../../hooks/useAxios";
import validationDictionary from "../../../../constants/validationDictionary";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { focusElement } from "../../../../helpers";

import { SectionTitle } from "../../../Account/components/SectionTitle";
import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";

import styles from "./styles.module.scss";

const availabilityTypes = [
  {
    name: "local",
    label: "Aceita trabalhar presencial?",
    tip: "O trabalho é feito presencialmente na empresa, sendo obrigatório o funcionário deslocar-se até a empresa.",
  },
  {
    name: "remote",
    label: "Aceita trabalhar remoto?",
    tip: "O trabalho é feito remotamente/home-office, sem a obrigatoriedade do funcionário deslocar-se até a empresa.",
  },
  {
    name: "hybrid",
    label: "Aceita trabalhar no modelo híbrido?",
    tip: "A empresa permite que alguns dias o trabalho seja feito presencial e alguns dias seja remoto.",
  },
  {
    name: "relocation",
    label: "Possui disponibilidade para mudança?",
  },
];

const CandidateEditAvailability = () => {
  const { exec, loading } = useAxios();
  const formRef = useRef();
  const history = useHistory();
  const [availabilityLocal, setAvailabilityLocal] = useState(null);
  const [availabilityRemote, setAvailabilityRemote] = useState(null);
  const [availabilityHybrid, setAvailabilityHybrid] = useState(null);
  const [availabilityRelocation, setAvailabilityRelocation] = useState(null);
  const [data, setData] = useState(null);

  const fetchData = async () => {
    const response = await exec({
      useCredentials: true,
      method: "GET",
      url: "/candidate/availability",
    });

    setData({
      local: response.data.local ? true : false,
      remote: response.data.remote ? true : false,
      hybrid: response.data.hybrid ? true : false,
      relocation: response.data.relocation ? true : false,
    });

    setAvailabilityLocal(response.data.local ? true : false);
    setAvailabilityRemote(response.data.remote ? true : false);
    setAvailabilityHybrid(response.data.hybrid ? true : false);
    setAvailabilityRelocation(response.data.relocation ? true : false);
  };

  const handleSubmit = async (data) => {
    data.local = availabilityLocal;
    data.remote = availabilityRemote;
    data.hybrid = availabilityHybrid;
    data.relocation = availabilityRelocation;

    try {
      const schema = Yup.object().shape({
        local: Yup.boolean().oneOf(
          [true, false],
          validationDictionary.GENERIC_REQUIRED_FIELD
        ),
        remote: Yup.boolean().oneOf(
          [true, false],
          validationDictionary.GENERIC_REQUIRED_FIELD
        ),
        hybrid: Yup.boolean().oneOf(
          [true, false],
          validationDictionary.GENERIC_REQUIRED_FIELD
        ),
        relocation: Yup.boolean().oneOf(
          [true, false],
          validationDictionary.GENERIC_REQUIRED_FIELD
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await exec({
        useCredentials: true,
        method: "PUT",
        url: "/candidate/availability",
        data,
      });

      if (response.success === false || response.error === true) return;

      history.push("/candidato/minha-conta");
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        const parsedErrors = errorParser(validationErrors);

        formRef.current.setErrors(parsedErrors);
      }
    }
  };

  useEffect(() => {
    fetchData();

    focusElement('[data-testid="section-title"]');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data) {
    return <LoadingSpinner />;
  }

  return (
    <AuthenticationWrapper>
      <SectionTitle>Disponibilidade</SectionTitle>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        className={styles.form}
        initialData={{
          local: data.local,
          remote: data.remote,
          hybrid: data.hybrid,
          relocation: data.relocation,
        }}
      >
        <div>
          <InputLabel
            label={availabilityTypes[0].label}
            name={availabilityTypes[0].name}
            icon={<LabelTooltip message={availabilityTypes[0].tip} />}
          />
          <Radio
            displayError
            name={availabilityTypes[0].name}
            options={[
              { id: true, label: "Sim" },
              { id: false, label: "Não" },
            ]}
            onChange={setAvailabilityLocal}
          />
        </div>
        <div>
          <InputLabel
            label={availabilityTypes[1].label}
            name={availabilityTypes[1].name}
            icon={<LabelTooltip message={availabilityTypes[1].tip} />}
          />
          <Radio
            displayError
            name={availabilityTypes[1].name}
            options={[
              { id: true, label: "Sim" },
              { id: false, label: "Não" },
            ]}
            onChange={setAvailabilityRemote}
          />
        </div>
        <div>
          <InputLabel
            label={availabilityTypes[2].label}
            name={availabilityTypes[2].name}
            icon={<LabelTooltip message={availabilityTypes[2].tip} />}
          />
          <Radio
            displayError
            name={availabilityTypes[2].name}
            options={[
              { id: true, label: "Sim" },
              { id: false, label: "Não" },
            ]}
            onChange={setAvailabilityHybrid}
          />
        </div>
        <div>
          <InputLabel
            label={availabilityTypes[3].label}
            name={availabilityTypes[3].name}
          />
          <Radio
            displayError
            name={availabilityTypes[3].name}
            options={[
              { id: true, label: "Sim" },
              { id: false, label: "Não" },
            ]}
            onChange={setAvailabilityRelocation}
          />
        </div>
        <SquareButton text="Concluir" type="submit" disabled={loading} />{" "}
      </Form>
      <SquareLinkButton
        testID="back-to-my-account-button"
        text="Voltar"
        url="/candidato/minha-conta"
        type="ghost"
      />
    </AuthenticationWrapper>
  );
};

export { CandidateEditAvailability };
