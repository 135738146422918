import { string, node, oneOf } from "prop-types";

import { SectionTitle } from "../SectionTitle";

import styles from "./styles.module.scss";

const NewInformationSummary = ({ title, children, columns }) => {
  return (
    <div className={styles.wrapper}>
      <SectionTitle>{title}</SectionTitle>
      <div
        className={[
          styles.innerWrapper,
          columns === 2 ? styles.twoColumns : styles.threeColumns,
        ].join(" ")}
      >
        {children}
      </div>
    </div>
  );
};

NewInformationSummary.propTypes = {
  title: string.isRequired,
  children: node,
  columns: oneOf([2, 3]),
};

NewInformationSummary.defaultProps = {
  columns: 3,
};

export { NewInformationSummary };
