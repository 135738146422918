import { bool, string, number } from "prop-types";
import useEnterPriseEnvCheck from "../../../../hooks/useEnterpriseEnvCheck";

import styles from "./styles.module.scss";

const DesktopStep = ({ active, stepName, stepIndex, currentStep }) => {
  const { isEnterpriseEnv } = useEnterPriseEnvCheck();

  return (
    <div className={[
      styles.wrapper,
      isEnterpriseEnv && styles.secondaryLayout,
      ].join(" ")}>
      <span
        className={[styles.index, active ? styles.active : ""].join(" ")}
        data-testid="step-index"
      >
        {currentStep > stepIndex ? "✓" : stepIndex}
      </span>
      <span
        className={[styles.name, active ? styles.active : ""].join(" ")}
        data-testid="step-name"
      >
        {stepName}
      </span>
    </div>
  );
};

DesktopStep.propTypes = {
  stepIndex: number.isRequired,
  stepName: string.isRequired,
  active: bool,
  currentStep: number,
};

DesktopStep.defaultProps = {
  active: false,
};

export { DesktopStep };
