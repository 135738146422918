import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import SocialMediaLink from "../SocialMediaLink";
import { Instagram, Facebook, LinkedIn } from "../../libs/icons";
import Config from "../../config";

import Logo from "./logo-inklua-color.svg";
import styles from "./styles.module.scss";

const InstitutionalHeader = () => {
  const [isFloating, setFloating] = useState(false);

  const handleScroll = () => {
    setFloating(window.scrollY > 0);
  };

  const renderSpacing = () => (
    <span className={styles.spacer} aria-hidden="true" />
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {renderSpacing()}
      <header
        className={[styles.wrapper, isFloating && styles.floating].join(" ")}
      >
        <div className={styles.container}>
          <Link to="/" aria-label="Início">
            <img src={Logo} alt="Inklua" />
          </Link>
          <div className={styles.socialMediaWrapper}>
            <SocialMediaLink url={Config.FACEBOOK_URL} color="#transparent">
              <Facebook color="#1b9b9e" />
            </SocialMediaLink>
            <SocialMediaLink url={Config.LINKEDIN_URL} color="#transparent">
              <LinkedIn color="#1b9b9e" />
            </SocialMediaLink>
            <SocialMediaLink url={Config.INSTAGRAM_URL} color="#transparent">
              <Instagram color="#1b9b9e" />
            </SocialMediaLink>
          </div>
        </div>
      </header>
    </>
  );
};

InstitutionalHeader.propTypes = {};

export default InstitutionalHeader;
