import React from "react";

const About = () => (
  <p>
    <b>About</b>
    <br />
    <i>(src/screens/About/index.js)</i>
  </p>
);

export default About;
