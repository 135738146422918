import { useRef, useState, useEffect } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";

import { errorParser, Input } from "../../../../components/NewInput";
import SquareButton from "../../../../components/SquareButton";
import validationDictionary from "../../../../constants/validationDictionary";
import { SectionTitle } from "../../../Account/components/SectionTitle";
import { useUserWorkExperience } from "../../../Account/hooks/useUserWorkExperience";
import { BooleanCheckbox } from "../../../../components/NewInput";
import { focusElement } from "../../../../helpers";


import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";

import styles from "./styles.module.scss";
import { GhostButton } from "../../../../components/GhostButton";
import { useHistory } from "react-router-dom";
import { isValidDateMMYYYY } from "../../utils/isValidDate";

Yup.addMethod(Yup.string, 'dateMMYYYY', function (errorMessage) {
  return this.test('dateMMYYYY', errorMessage, function (value) {
    const { path, createError } = this;
    if (!value || !isValidDateMMYYYY(value)) {
      return createError({ path, message: errorMessage });
    }

    return true;
  });
});

const CandidateAddExperience = () => {
  const { addWorkExperience, loading } = useUserWorkExperience();
  const [isOnGoing, setOnGoing] = useState(false);
  const formRef = useRef();
  const history = useHistory();

  const handleOngoing = ({ checked }) => {
    setOnGoing(checked);
  };

  const handleSubmit = async (data) => {
    try {
      const schema = Yup.object().shape({
        role: Yup.string()
          .min(3, validationDictionary.INVALID_ROLE)
          .required(validationDictionary.INVALID_ROLE),
        company: Yup.string()
          .min(3, validationDictionary.INVALID_COMPANY_NAME)
          .required(validationDictionary.INVALID_COMPANY_NAME),
        start_date: Yup.string()
          .dateMMYYYY(validationDictionary.INVALID_DATE)
          .min(3, validationDictionary.INVALID_DATE)
          .required(validationDictionary.INVALID_DATE),
        end_date:
          !isOnGoing &&
          Yup.string()
            .dateMMYYYY(validationDictionary.INVALID_DATE)
            .min(3, validationDictionary.INVALID_DATE)
            .required(validationDictionary.INVALID_DATE),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await addWorkExperience(data);

      if (!response) return false;

      window.location.href = "/autenticacao/cadastro/candidato/experiencia";

      return true;
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        const parsedErrors = errorParser(validationErrors);

        formRef.current.setErrors(parsedErrors);
      }
    }
  };

  useEffect(() => {
    focusElement('[data-testid="account-section-title"]');
  }, []);

  return (
    <>
      <AuthenticationWrapper>
        <SectionTitle>Experiência</SectionTitle>
        <Form ref={formRef} onSubmit={handleSubmit} className={styles.form}>
          <Input
            label="Cargo"
            name="role"
            placeholder="Insira o cargo"
            type="text"
          />
          <Input
            label="Empresa"
            name="company"
            placeholder="Insira o nome da empresa"
            type="text"
          />
          <Input
            label="Data de início"
            name="start_date"
            placeholder="Ex: 01/2010"
            type="text"
            mask="99/9999"
            ariaLabel="Preenchimento da data de início, no formato de dois digitos para o mês e quatro digitos para o ano. Só pode ser inserido números neste campo"
          />
          <BooleanCheckbox
            name="ongoing"
            onChange={handleOngoing}
            alternativeStyle
          >
            É o meu emprego atualmente
          </BooleanCheckbox>
          {!isOnGoing && (
            <Input
              label="Data de término"
              name="end_date"
              placeholder="Ex: 01/2010"
              type="text"
              mask="99/9999"
              ariaLabel="Preenchimento da data de término, no formato de dois digitos para o mês e quatro digitos para o ano. Só pode ser inserido números neste campo"
            />
          )}
          <Input
            label="Descrição"
            name="description"
            placeholder="Descreva brevemente as suas atividades, no que você atua/atuava nessa função."
            type="textarea"
            enableFlag
          />
          <SquareButton disabled={loading} text="Concluir" type="submit" />
          <GhostButton onClick={() => history.goBack()} text="Voltar" />
        </Form>
      </AuthenticationWrapper>
    </>
  );
};

export { CandidateAddExperience };
