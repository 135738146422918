import React, { useState } from "react";

import { useSearchContext } from "../../contexts/Search";
import { Magnifier, Plus } from "../../libs/icons";

import styles from "./styles.module.scss";

const SearchField = () => {
  const isMobile = window.innerWidth <= 480 ? true : false;
  const [open] = useState(!isMobile);
  const [term, setTerm] = useState("");
  const { openSearch } = useSearchContext();

  const toggleSearch = () => {
    if (!isMobile) {
      return;
    }

    openSearch();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (term === null || term.trim() === "") return;
    window.location.href = `/busca?termo=${term}`;
  };

  const handleChange = (event) => {
    setTerm(event.target.value);
    event.preventDefault();
  };

  return (
    <div className={[styles.wrapper, isMobile && styles.collapsed].join(" ")}>
      <form onSubmit={handleSubmit} aria-label="Busca" role="search">
        <button
          className={styles.button}
          type="button"
          onClick={toggleSearch}
          aria-label={isMobile && open ? "Buscar" : "Abrir busca"}
        >
          <Magnifier color="#1B9B9E" />
        </button>
        <input
          onChange={handleChange}
          className={[styles.field, isMobile && styles.collapsed].join(" ")}
          type="text"
          aria-label="Procure por cargo ou palavra-chave"
          placeholder="Procure por cargo ou palavra-chave"
          name="busca"
          aria-hidden={!isMobile && !open}
          aria-required="true"
        />
        <button
          className={[
            styles.button,
            styles.closeButton,
            isMobile && styles.closed,
          ].join(" ")}
          type="button"
          onClick={toggleSearch}
          aria-label="Fechar"
        >
          <Plus />
        </button>
      </form>
    </div>
  );
};

export default SearchField;
