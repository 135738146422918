import React from "react";
import { string } from "prop-types";

const Blocks = ({ color, label }) => {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      aria-label={label}
    >
      <g
        id="🖥️-03-home-inklua-(DesktopView)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="01-home-inklua-desktop"
          transform="translate(-1332.000000, -105.000000)"
          fill={color}
        >
          <g
            id="navigation-bar-top-icon-search"
            transform="translate(0.000000, 70.000000)"
          >
            <g id="27)-Icon/grid" transform="translate(1332.000000, 35.000000)">
              <path
                d="M12,16 L12,12 L16,12 L16.001,16 L12,16 Z M16,10 L12,10 C10.897,10 10,10.897 10,12 L10,16 C10,17.103 10.897,18 12,18 L16,18 C17.103,18 18,17.103 18,16 L18,12 C18,10.897 17.103,10 16,10 L16,10 Z M2,16 L2,12 L6,12 L6.001,16 L2,16 Z M6,10 L2,10 C0.897,10 0,10.897 0,12 L0,16 C0,17.103 0.897,18 2,18 L6,18 C7.103,18 8,17.103 8,16 L8,12 C8,10.897 7.103,10 6,10 L6,10 Z M12,6 L12,2 L16,2 L16.001,6 L12,6 Z M16,0 L12,0 C10.897,0 10,0.897 10,2 L10,6 C10,7.103 10.897,8 12,8 L16,8 C17.103,8 18,7.103 18,6 L18,2 C18,0.897 17.103,0 16,0 L16,0 Z M2,6 L2,2 L6,2 L6.001,6 L2,6 Z M6,0 L2,0 C0.897,0 0,0.897 0,2 L0,6 C0,7.103 0.897,8 2,8 L6,8 C7.103,8 8,7.103 8,6 L8,2 C8,0.897 7.103,0 6,0 L6,0 Z"
                id="🎨-Icon-Сolor"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Blocks.propTypes = {
  color: string,
  label: string,
};

Blocks.defaultProps = {
  color: "#1B9B9E",
  label: "Página inicial",
};

export default Blocks;
