import { useRef } from "react";
import { oneOfType, number, string } from "prop-types";
import { useHistory } from "react-router-dom";
import { Form } from "@unform/web";
import * as Yup from "yup";

import SquareButton from "../../../../components/SquareButton";
import { Radio, Input } from "../../../../components/NewInput";
import useAxios from "../../../../hooks/useAxios";
import validationDictionary from "../../../../constants/validationDictionary";

import styles from "./styles.module.scss";

const roleOptions = [
  { id: "1", label: "Sim" },
  { id: "0", label: "Eu só indiquei" },
];

const initialValues = {
  role: roleOptions[0].id,
  obs: "",
};

const CandidateReportAcceptanceForm = ({ candidateId, jobId }) => {
  const formRef = useRef(null);
  const history = useHistory();
  const { exec, loading } = useAxios();

  const handleGoBack = () => {
    history.goBack();
  };

  const handleSubmit = async (data) => {
    data.candidate_id = candidateId;
    data.job_id = jobId;

    try {
      const schema = Yup.object().shape({
        candidate_id: Yup.string()
          .min(1, validationDictionary.INVALID_CANDIDATE_ID)
          .required(validationDictionary.INVALID_CANDIDATE_ID),
        job_id: Yup.string()
          .min(1, validationDictionary.INVALID_JOB_LISTING_ID)
          .required(validationDictionary.INVALID_JOB_LISTING_ID),
        hired: Yup.string()
          .oneOf(
            roleOptions.map((reason) => reason.id),
            validationDictionary.INVALID_RECRUITMENT_ROLE
          )
          .required(validationDictionary.INVALID_RECRUITMENT_ROLE),
        obs: Yup.string()
          .min(6, validationDictionary.INVALID_NOTE)
          .required(validationDictionary.INVALID_NOTE),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await exec({
        useCredentials: true,
        method: "POST",
        url: "/admin/hunting/report/store",
        data,
      });

      if (response.status) {
        handleGoBack();
        return;
      }

      if (response.error) {
        alert(response.msg);
        return;
      }
    } catch (error) {
      console.error(error);

      const validationErrors = {};

      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={initialValues}
      className={styles.wrapper}
    >
      <span className={styles.radioButtonsFakeLabel}>
        O candidato foi contratado por você?
      </span>
      <div className={styles.radioWrapper}>
        <Radio name="hired" options={roleOptions} displayError />
      </div>
      <Input
        label="Observações"
        name="obs"
        placeholder="Informe se você indicou ou intermediou a contratação desse candidato e qual o nome do outro recrutador envolvido."
        type="textarea"
        disabled={loading}
      />
      <div className={styles.buttonsWrapper}>
        <SquareButton
          disabled={loading}
          text="Voltar"
          onClick={handleGoBack}
          simple
        />
        <SquareButton disabled={loading} text="Concluir" type="submit" />
      </div>
    </Form>
  );
};

CandidateReportAcceptanceForm.propTypes = {
  candidateId: oneOfType([number, string]).isRequired,
  jobId: oneOfType([number, string]).isRequired,
};

export { CandidateReportAcceptanceForm };
