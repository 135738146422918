import { string, bool, func } from "prop-types";
import useEnterPriseEnvCheck from "../../../../hooks/useEnterpriseEnvCheck";

import styles from "./styles.module.scss";

const IllustrationInput = ({ value, image, checked, setter }) => {
  const { isEnterpriseEnv } = useEnterPriseEnvCheck();

  return (
    <button
      key={`image_${value}`}
      type="button"
      onClick={() => setter(value)}
      className={[
        styles.button,
        checked ? styles.checked : styles.unchecked,
        isEnterpriseEnv && styles.secondaryLayout
      ].join(" ")}
      data-testid={`illustration-input-button-${value}`}
    >
      <img
        src={image}
        alt={`${value} illustration`}
        className={styles.image}
        data-testid={`illustration-input-image-${value}`}
      />
      {checked && (
        <span
          className={styles.checkmark}
          data-testid={`illustration-input-checkmark-${value}`}
        >
          ✓
        </span>
      )}
    </button>
  );
};

IllustrationInput.propTypes = {
  value: string.isRequired,
  image: string.isRequired,
  checked: bool.isRequired,
  setter: func.isRequired,
};

export { IllustrationInput };
