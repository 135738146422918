import React, { useRef } from "react";
import Slick from "react-slick";

import HeroBanner from "../../components/HeroBanner";
import SliderBannerA2x from "../../assets/images/home-slider-01@2x.webp";
import SliderBannerB2x from "../../assets/images/home-slider-02@2x.png";
import getUserData from "../../helpers/getUserData";

import styles from "./styles.module.css";
import "./styles.css";

const filterSliderData = (status, sliderData) => {
  if (status === "complete") {
    return sliderData.slice(1);
  }
  return sliderData;
};

const sliderData = [
  {
    id: 1,
    title: "Descubra um mundo de <b>Oportunidades<b>",
    description:
      "A Inklua pode ajudar pessoas com deficiência a se conectarem com o mercado de trabalho.",
    image: SliderBannerB2x,
    alt_image: `Ilustração de um grupo de quatro pessoas com deficiência, onde da esquerda 
    para a direita tem um rapaz em pé, ele é surdo/mudo, de cor preta da pele clara, ele faz 
    o sinal de positivo com a mão direita e com a mão esquerda deixa apoiado no ombro de um rapaz 
    cadeirante, de cor preta. Ao lado está uma mulher em pé, ela é deficiente visual, de pele 
    branca e ela está com a mão direita segurando uma bengala guia e com a mão esquerda nas costas 
    de outra mulher, que é deficiente física e da cor preta.`,
    link: {
      url: "/autenticacao/cadastro/candidato",
      text: "Cadastre-se aqui",
    },
  },
  {
    id: 2,
    title: "Se <b>#inklua</b> no mercado de trabalho",
    description:
      "Somos uma plataforma feita para que você explore vagas especificas para pessoas com deficiência, além de conteúdos e informações para que você se #inklua conosco.",
    image: SliderBannerA2x,
    alt_image: `Ilustração de uma mulher de cor preta, cabelo longo, ondulado e loiro.
    Ela é cadeirante, está sentada em uma cadeira de rodas de cor azul escuro, está na frente de um computador,
    que está sob uma mesa de cor cinza. Ela usa uma camiseta de manga curta de cor verde claro e uma calça de cor azul escuro.
    Ela olha para frente, em direção a tela do computador e ambas as mãos estão sobre o teclado.
    Ao fundo da ilustração tem uma textura verde clara e tem uma janela.`,
    link: null,
  },
];

const HeroSlider = () => {
  const sliderRef = useRef();
  const { status } = getUserData() || {};

  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    speed: 500,
    arrows: false,
    autoPlay: true,
    autoPlaySpeed: 6000,
    adaptiveHeight: true,
    onInit: () => handleDots()
  };

  const filteredData = filterSliderData(status, sliderData);

  function handleDots() {
    const dotsContainer = document.querySelectorAll(".slick-dots > li > button");
    dotsContainer.forEach(item => item.setAttribute("tabindex", "-1"));
  };

  function goToNext() {
    sliderRef.current.slickNext()
  };

  return (
    <div className={styles.wrapper}>
      <Slick {...settings} ref={sliderRef}>
        {filteredData.map((item) => (
          <HeroBanner
            id={item.id}
            key={`banner_${item.id}`}
            title={item.title}
            description={item.description}
            image={item.image}
            link={item.link}
            altImage={item.alt_image}
            nextBtn={goToNext}
          />
        ))}
      </Slick>
    </div>
  );
};

export default HeroSlider;
