export const parseFilename = (url) => {
  const fileUrlWithoutRelativeStringArray = url.split("/");
  const fileUrlWithoutRelativeString =
    fileUrlWithoutRelativeStringArray[
      fileUrlWithoutRelativeStringArray.length - 1
    ];

  if (fileUrlWithoutRelativeString.includes("?")) {
    return fileUrlWithoutRelativeString.split("?")[0];
  }

  return fileUrlWithoutRelativeString;
};
