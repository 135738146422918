import { useState } from "react"
import styles from './styles.module.scss';
import FirstStepImg from './assets/ilustracao1.svg'
import SecondStepImg from './assets/ilustracao2.svg'
import ThirdStepImg from './assets/ilustracao3.svg'
import SquareButton from "../../../../components/SquareButton";
import useEnterPriseEnvCheck from "../../../../hooks/useEnterpriseEnvCheck";

const ModalFirstAccess = ({ showModal }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const { isEnterpriseEnv } = useEnterPriseEnvCheck();

    if (currentStep === 1 && showModal) {
        return (
            <div className={[
                styles.wrapper,
                isEnterpriseEnv && styles.secondaryLayout,
            ].join(" ")}>
                <div className={styles.container}>
                    <img
                        className={styles.image}
                        src={FirstStepImg}
                        alt="first-step"
                    />
                    <div className={styles.description}>
                        <h1 className={styles.title}>Você sabia?</h1>
                        <span className={styles.text}>
                            A Inklua tem dois serviços, o de <strong>recrutamento e
                                seleção</strong> e o de <strong>anúncio de vagas. </strong>
                            Estes serviços  são muito distintos um do outro,
                            tanto no custo como nos resultados.
                        </span>
                        <button
                            type="button"
                            onClick={() => setCurrentStep(prev => prev + 1)}
                            className={styles.goNext}
                        >
                            Avançar
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    if (currentStep === 2 && showModal) {
        return (
            <div className={[
                styles.wrapper,
                isEnterpriseEnv && styles.secondaryLayout,
            ].join(" ")}>
                <div className={styles.container}>
                    <img
                        className={styles.image}
                        src={SecondStepImg}
                        alt="second-step"
                    />
                    <div className={styles.description}>
                        <h1 className={styles.title}>Você sabia?</h1>
                        <span className={styles.text}>
                            Anunciar vagas no site da Inklua <strong>não é garantia de ter o candidato certo para vaga certa!</strong>
                        </span>
                        <button
                            type="button"
                            onClick={() => setCurrentStep(prev => prev + 1)}
                            className={styles.goNext}
                        >
                            Avançar
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    if (currentStep === 3 && showModal) {
        return (
            <div className={[
                styles.wrapper,
                isEnterpriseEnv && styles.secondaryLayout,
            ].join(" ")}>
                <div className={styles.container}>
                    <img
                        className={styles.image}
                        src={ThirdStepImg}
                        alt="third-step"
                    />
                    <div className={styles.description}>
                        <span className={styles.text}>
                            Até mesmo a consultoria de recrutamento e seleção da
                            Inklua não se limita a anunciar suas vagas aqui para
                            conseguir acessar os profissionais que precisa.
                        </span>
                        <SquareButton
                            onClick={() => setCurrentStep(prev => prev + 1)}
                            text="Ok, estou ciente"
                            secondaryClass={isEnterpriseEnv ? styles.btnSecondaryLayout : ""}
                        />
                    </div>
                </div>
            </div>
        )
    }

    return null;

}

export { ModalFirstAccess }