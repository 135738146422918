import { useEffect, useState } from 'react';
import styles from './styles.module.scss'
import SquareButton from '../SquareButton';

const AcceptCookies = () => {
  const [showAcceptCookies, setShowAcceptCookies] = useState(false);
  const [renderComponent, setRenderComponent] = useState(true);

  const clickAgree = () => {
    localStorage.setItem('cookiesAcception', true);
    return setShowAcceptCookies(false);
  };

  useEffect(() => {
    if (!localStorage.getItem('cookiesAcception')) {
      return setShowAcceptCookies(true);
    } else {
      return setRenderComponent(false);
    }
  }, [])

  return renderComponent && (
    <div className={[
        styles.wrapper,
        styles[showAcceptCookies ? 'open' : 'close']
      ].join(' ')}
    >
      <p>
        Este website utiliza cookies analíticos de terceiros para melhorar a sua experiência de navegação. <br/>
        Ao continuar, você concorda com nossos <a href='/politica-de-privacidade'>termos e política de privacidade</a>.
      </p>
      <SquareButton
        onClick={clickAgree}
        text='Concordar e fechar'
        outlined
      />
    </div>
  )
}

export default AcceptCookies;