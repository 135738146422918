import React from "react";

import PasswordRecoveryForm from "../../authentication/PasswordRecoveryForm";
import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";

const ForgotPasswordEnterprise = () => {
  return (
    <AuthenticationWrapper>
      <PasswordRecoveryForm />
    </AuthenticationWrapper>
  );
};

export default ForgotPasswordEnterprise;
