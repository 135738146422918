import { useState } from "react";
import { string } from "prop-types";

import styles from "./styles.module.scss";

export const LabelTooltip = ({ name, message, ariaLabel }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.wrapper} data-testid="label-tooltip-wrapper">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        onMouseLeave={() => setIsOpen(false)}
        data-testid="label-tooltip-button"
        className={styles.button}
        aria-label={ariaLabel}
        aria-describedby={`${name}-tooltip-message`}
      >
        <svg
          width="14px"
          height="14px"
          viewBox="0 0 14 14"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="⬅️➡️📱01-fluxo:usuario-completando-cadastro(MobileView)"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="question-mark-circle"
              transform="translate(-1.000000, -1.000000)"
              fillRule="nonzero"
            >
              <rect
                id="Rectangle"
                fill="#000000"
                opacity="0"
                transform="translate(8.000000, 8.000000) rotate(180.000000) translate(-8.000000, -8.000000) "
                x="0"
                y="0"
                width="16"
                height="16"
              />
              <path
                d="M8,1.33333333 C4.31810167,1.33333333 1.33333333,4.31810167 1.33333333,8 C1.33333333,11.6818983 4.31810167,14.6666667 8,14.6666667 C11.6818983,14.6666667 14.6666667,11.6818983 14.6666667,8 C14.6666667,6.23189007 13.9642877,4.53619732 12.7140452,3.28595479 C11.4638027,2.03571227 9.76810993,1.33333333 8,1.33333333 L8,1.33333333 Z M8,12 C7.63181017,12 7.33333333,11.7015232 7.33333333,11.3333333 C7.33333333,10.9651435 7.63181017,10.6666667 8,10.6666667 C8.36818983,10.6666667 8.66666667,10.9651435 8.66666667,11.3333333 C8.66666667,11.7015232 8.36818983,12 8,12 Z M8.66666667,8.56 L8.66666667,9.33333333 C8.66666667,9.70152317 8.36818983,10 8,10 C7.63181017,10 7.33333333,9.70152317 7.33333333,9.33333333 L7.33333333,8 C7.33333333,7.63181017 7.63181017,7.33333333 8,7.33333333 C8.55228475,7.33333333 9,6.88561808 9,6.33333333 C9,5.78104858 8.55228475,5.33333333 8,5.33333333 C7.44771525,5.33333333 7,5.78104858 7,6.33333333 C7,6.70152317 6.70152317,7 6.33333333,7 C5.9651435,7 5.66664743,6.70152317 5.66664743,6.33333333 C5.66182371,5.13063612 6.5719429,4.12129029 7.76872853,4.00209212 C8.96551416,3.88289396 10.0568381,4.69289886 10.289311,5.87292446 C10.5217839,7.05295005 9.8192357,8.21635969 8.66666667,8.56 L8.66666667,8.56 Z"
                id="Shape"
                fill="#1B9B9E"
              />
            </g>
          </g>
        </svg>
      </button>
      <div
        className={[
          styles.tooltip,
          isOpen ? styles.isOpen : styles.isClosed,
        ].join(" ")}
      >
        <p id={`${name}-tooltip-message`}>{message}</p>
      </div>
    </div>
  );
};

LabelTooltip.propTypes = {
  message: string.isRequired,
  ariaLabel: string,
};

LabelTooltip.defaultProps = {
  ariaLabel: null,
};
