import React, { useState } from "react";
import { node } from "prop-types";

import SnackBarContext from "./SnackBarContext";

const SnackBarProvider = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  const [text, setText] = useState(null);
  const [failure, setFailure] = useState()

  const showSnackBar = ({ timeout, text, error }) => {
    setText(text);
    setOpen(true);
    setFailure(error);

    setTimeout(() => {
      setOpen(false);
    }, timeout);

    setTimeout(() => {
      setText("");
    }, timeout + 250);
  };

  const value = {
    isOpen,
    setOpen,
    text,
    setText,
    showSnackBar,
    failure,
  };

  return (
    <SnackBarContext.Provider value={value}>
      {children}
    </SnackBarContext.Provider>
  );
};

SnackBarProvider.propTypes = {
  children: node.isRequired,
};

export default SnackBarProvider;
