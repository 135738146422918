import React from "react";
import { string } from "prop-types";

const SettingsSlider = ({ color }) => {
  return (
    <svg
      width="20px"
      height="18px"
      viewBox="0 0 20 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M11,19 C10.448,19 10,18.552 10,18 C10,17.448 10.448,17 11,17 C11.552,17 12,17.448 12,18 C12,18.552 11.552,19 11,19 M21,17 L13.815,17 C13.401,15.839 12.302,15 11,15 C9.698,15 8.599,15.839 8.185,17 L3,17 C2.447,17 2,17.447 2,18 C2,18.553 2.447,19 3,19 L8.185,19 C8.599,20.161 9.698,21 11,21 C12.302,21 13.401,20.161 13.815,19 L21,19 C21.553,19 22,18.553 22,18 C22,17.447 21.553,17 21,17 M19,13 C18.448,13 18,12.552 18,12 C18,11.448 18.448,11 19,11 C19.552,11 20,11.448 20,12 C20,12.552 19.552,13 19,13 M19,9 C17.698,9 16.599,9.839 16.185,11 L3,11 C2.447,11 2,11.447 2,12 C2,12.553 2.447,13 3,13 L16.185,13 C16.599,14.161 17.698,15 19,15 C20.654,15 22,13.654 22,12 C22,10.346 20.654,9 19,9 M7,5 C7.552,5 8,5.448 8,6 C8,6.552 7.552,7 7,7 C6.448,7 6,6.552 6,6 C6,5.448 6.448,5 7,5 M3,7 L4.185,7 C4.599,8.161 5.698,9 7,9 C8.302,9 9.401,8.161 9.815,7 L21,7 C21.553,7 22,6.553 22,6 C22,5.447 21.553,5 21,5 L9.815,5 C9.401,3.839 8.302,3 7,3 C5.698,3 4.599,3.839 4.185,5 L3,5 C2.447,5 2,5.447 2,6 C2,6.553 2.447,7 3,7"
          id="path-1"
        ></path>
      </defs>
      <g
        id="🖥️-Telas-DesktopView"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="02-pagina-resultados-busca"
          transform="translate(-19.000000, -1643.000000)"
        >
          <rect fill="#FBFCFD" x="0" y="0" width="1440" height="3380"></rect>
          <g
            id="botao-filtrar-resultados"
            transform="translate(-48.000000, 1624.000000)"
          >
            <rect
              id="Button-Background"
              stroke={color}
              strokeWidth="2"
              x="1"
              y="1"
              width="300"
              height="54"
              rx="5"
            ></rect>
            <g id="content" transform="translate(65.000000, 16.000000)">
              <g id="options-2">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <use
                  id="🎨-Icon-color"
                  fill={color}
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                ></use>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

SettingsSlider.propTypes = {
  color: string,
};

SettingsSlider.defaultProps = {
  color: "#1B9B9E",
};

export default SettingsSlider;
