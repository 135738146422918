import { bool, node } from "prop-types";

import styles from "./styles.module.scss";

const Toggler = ({ visible, children }) => {
  return (
    <div
      className={[
        styles.wrapper,
        visible ? styles.visible : styles.hidden,
      ].join(" ")}
    >
      {children}
    </div>
  );
};

Toggler.propTypes = {
  children: node.isRequired,
  visible: bool.isRequired,
};

export { Toggler };
