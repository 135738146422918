import { arrayOf, shape, string, node, number } from "prop-types";

import { NewInformationSummary } from "../../modules/Account/components/NewInformationSummary";
import { WorkExperiences } from "../../modules/Recruiter/components/WorkExperiences";
import { toDate } from "../../helpers";

import styles from "./styles.module.scss";

const CandidateWorkExperienceSummary = ({ data, actionButton }) => {
  return (
    <>
      <NewInformationSummary title="Experiências" />
      {Boolean(data.length) ? (
        <WorkExperiences
          data={data.map((experience) => ({
            id: experience.id,
            role: experience.role,
            company: experience.company,
            startDate: toDate(experience.start_date, "MM/YYYY"),
            endDate:
              experience.end_date && toDate(experience.end_date, "MM/YYYY"),
            description: experience.description,
          }))}
        />
      ) : (
        <span>Sem experiência cadastrada.</span>
      )}
      {actionButton && (
        <div className={styles.actionButtonWrapper}>{actionButton}</div>
      )}
    </>
  );
};

CandidateWorkExperienceSummary.propTypes = {
  data: arrayOf(
    shape({
      id: number.isRequired,
      role: string.isRequired,
      company: string.isRequired,
      start_date: string.isRequired,
      end_date: string,
      description: string.isRequired,
    })
  ),
  actionButton: node,
};

CandidateWorkExperienceSummary.defaultProps = {
  data: []
};

export { CandidateWorkExperienceSummary };
