import styles from "./styles.module.scss";

const SearchSkeletonLoader = () => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.title} />
      <span className={styles.company} />
      <span className={styles.date} />
      <span className={styles.description} />
      <span className={styles.location} />
      <span className={styles.salary} />
      <span className={styles.button} />
      <span className={styles.share} />
    </div>
  );
};

export default SearchSkeletonLoader;
