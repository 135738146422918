import React from "react";
import { string } from "prop-types";

const CheckWithCircle = ({ color }) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="⬅️➡️🖥️-07-fluxo:recrutador-cadastrando-vagas(DesktopView)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="09-minhas-vagas-detalhes-tooltip"
          transform="translate(-472.000000, -738.000000)"
        >
          <g
            id="card-vaga-1-copy-3"
            transform="translate(174.000000, 696.000000)"
          >
            <g id="bg">
              <g id="bg-shadow">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                ></use>
                <use
                  fill="#FFFFFF"
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                ></use>
              </g>
              <rect
                id="bg-solid"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="348"
                height="314"
                rx="10"
              ></rect>
            </g>
            <g
              id="checkmark-circle-2-outline-(1)"
              transform="translate(296.000000, 40.000000)"
            >
              <rect
                id="Rectangle"
                fill="#000000"
                fillRule="nonzero"
                opacity="0"
                x="0"
                y="0"
                width="28"
                height="28"
              ></rect>
              <path
                d="M14,2.33333333 C7.55667792,2.33333333 2.33333333,7.55667792 2.33333333,14 C2.33333333,20.4433221 7.55667792,25.6666667 14,25.6666667 C20.4433221,25.6666667 25.6666667,20.4433221 25.6666667,14 C25.6666667,10.9058076 24.4375035,7.9383453 22.2495791,5.75042089 C20.0616547,3.56249647 17.0941924,2.33333333 14,2.33333333 Z M14,23.3333333 C8.84534233,23.3333333 4.66666667,19.1546577 4.66666667,14 C4.66666667,8.84534233 8.84534233,4.66666667 14,4.66666667 C19.1546577,4.66666667 23.3333333,8.84534233 23.3333333,14 C23.3333333,16.4753539 22.3500028,18.8493238 20.5996633,20.5996633 C18.8493238,22.3500028 16.4753539,23.3333333 14,23.3333333 Z"
                id="Shape"
                fill={color}
              ></path>
              <path
                d="M17.15,9.78833333 L12.74,15.6216667 L10.8383333,13.16 C10.442069,12.6509776 9.70818911,12.559569 9.19916667,12.9558333 C8.69014422,13.3520976 8.59873569,14.0859776 8.995,14.595 L11.83,18.2233333 C12.0526258,18.5050741 12.3925896,18.6686009 12.7516667,18.6666836 C13.1127461,18.6657988 13.4530753,18.4977881 13.6733333,18.2116667 L19.005,11.2116667 C19.3980426,10.6962009 19.2987991,9.95970931 18.7833333,9.56666667 C18.2678676,9.17362402 17.531376,9.27286757 17.1383333,9.78833333 L17.15,9.78833333 Z"
                id="Path"
                fill={color}
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

CheckWithCircle.propTypes = {
  color: string,
};

CheckWithCircle.defaultProps = {
  color: "#1B9B9E",
};

export default CheckWithCircle;
