import React from "react";
import { bool, string } from "prop-types";

const User = ({ color, hidden }) => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden={hidden}
    >
      <g
        id="person-icon-hover-state"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M32,33.3333333 C32,34.2533333 31.2337143,35 30.2857143,35 C29.3377143,35 28.5714286,34.2533333 28.5714286,33.3333333 C28.5714286,28.7383333 24.7262857,25 20,25 C15.2737143,25 11.4285714,28.7383333 11.4285714,33.3333333 C11.4285714,34.2533333 10.6622857,35 9.71428571,35 C8.76628571,35 8,34.2533333 8,33.3333333 C8,26.9 13.3845714,21.6666667 20,21.6666667 C26.6154286,21.6666667 32,26.9 32,33.3333333 M20,8.33333333 C21.8908571,8.33333333 23.4285714,9.82833333 23.4285714,11.6666667 C23.4285714,13.505 21.8908571,15 20,15 C18.1091429,15 16.5714286,13.505 16.5714286,11.6666667 C16.5714286,9.82833333 18.1091429,8.33333333 20,8.33333333 M26.8571429,11.6666667 C26.8571429,7.99 23.7817143,5 20,5 C16.2182857,5 13.1428571,7.99 13.1428571,11.6666667 C13.1428571,15.3433333 16.2182857,18.3333333 20,18.3333333 C23.7817143,18.3333333 26.8571429,15.3433333 26.8571429,11.6666667 Z"
          id="person-icon"
          fill={color}
        ></path>
      </g>
    </svg>
  );
};

User.propTypes = {
  color: string,
  hidden: bool,
};

User.defaultProps = {
  color: "#1B9B9E",
  hidden: true,
};

export default User;
