import { useRef, useState, useEffect } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";

import { errorParser, Input } from "../../../../components/NewInput";
import SelectInput from "../../../../components/SelectInput";
import SquareButton from "../../../../components/SquareButton";
import validationDictionary from "../../../../constants/validationDictionary";
import { focusElement } from "../../../../helpers";
import { SectionTitle } from "../../../Account/components/SectionTitle";
import { educationTypes } from "../../../../libs/data";
import { BooleanCheckbox } from "../../../../components/NewInput";
import { useUserEducation } from "../../../Account/hooks/useUserEducation";

import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";

import styles from "./styles.module.scss";
import { useHistory } from "react-router-dom";
import { GhostButton } from "../../../../components/GhostButton";
import { isValidDateMMYYYY } from "../../utils/isValidDate";

Yup.addMethod(Yup.string, 'dateMMYYYY', function (errorMessage) {
  return this.test('dateMMYYYY', errorMessage, function (value) {
    const { path, createError } = this;
    if (!value || !isValidDateMMYYYY(value)) {
      return createError({ path, message: errorMessage });
    }

    return true;
  });
});

const CandidateAddEducation = () => {
  const { addCourse, loading } = useUserEducation({
    includeLanguageCourses: false,
  });
  const [educationType, setEducationType] = useState(null);
  const [isOnGoing, setOnGoing] = useState(false);
  const formRef = useRef();
  const history = useHistory();

  const handleOngoing = ({ checked }) => {
    setOnGoing(checked);
  };

  const handleSubmit = async (data) => {
    try {
      data.type = educationType;

      const schema = Yup.object().shape({
        type: Yup.number()
          .oneOf(
            educationTypes.map((l) => l.value),
            validationDictionary.INVALID_EDUCATION_TYPE
          )
          .required(validationDictionary.INVALID_EDUCATION_TYPE)
          .nullable(),
        course: Yup.string()
          .min(3, validationDictionary.INVALID_COURSE)
          .required(validationDictionary.INVALID_COURSE),
        institution: Yup.string()
          .min(3, validationDictionary.INVALID_INSTITUTION)
          .required(validationDictionary.INVALID_INSTITUTION),
          start_date: Yup.string()
          .dateMMYYYY(validationDictionary.INVALID_DATE)
          .min(3, validationDictionary.INVALID_DATE)
          .required(validationDictionary.INVALID_DATE),
        end_date:
          !isOnGoing &&
          Yup.string()
            .dateMMYYYY(validationDictionary.INVALID_DATE)
            .min(3, validationDictionary.INVALID_DATE)
            .required(validationDictionary.INVALID_DATE),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await addCourse(data);

      if (!response) return false;

      window.location.href =
        "/autenticacao/cadastro/candidato/formacao-academica";

      return true;
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        const parsedErrors = errorParser(validationErrors);

        formRef.current.setErrors(parsedErrors);
      }
    }
  };

  useEffect(() => {
    focusElement('[data-testid="account-section-title"]');
  }, []);

  return (
    <>
      <AuthenticationWrapper>
        <SectionTitle>Formação acadêmica</SectionTitle>
        <Form ref={formRef} onSubmit={handleSubmit} className={styles.form}>
          <SelectInput
            name="type"
            label="Nível da formação"
            placeholder="Selecione o nível"
            setter={setEducationType}
            keys={{ label: "label", value: "value" }}
            options={educationTypes}
          />
          <Input
            label="Nome do curso"
            name="course"
            placeholder="Insira o nome do curso"
            type="text"
          />
          <Input
            label="Nome da instituição"
            name="institution"
            placeholder="Insira o nome da instituição"
            type="text"
          />
          <Input
            label="Data de início"
            name="start_date"
            placeholder="Ex: 01/2010"
            type="text"
            mask="99/9999"
            ariaLabel="Preenchimento da data de início, no formato de dois digitos para o mês e quatro digitos para o ano. Só pode ser inserido números neste campo"
          />
          <BooleanCheckbox
            name="ongoing"
            onChange={handleOngoing}
            alternativeStyle
          >
            Estou cursando atualmente
          </BooleanCheckbox>
          {!isOnGoing && (
            <Input
              label="Data de término"
              name="end_date"
              placeholder="Ex: 01/2010"
              type="text"
              mask="99/9999"
              ariaLabel="Preenchimento da data de término, no formato de dois digitos para o mês e quatro digitos para o ano. Só pode ser inserido números neste campo"
            />
          )}
          <SquareButton disabled={loading} text="Concluir" type="submit" />
          <GhostButton onClick={() => history.goBack()} text="Voltar" />
        </Form>
      </AuthenticationWrapper>
    </>
  );
};

export { CandidateAddEducation };
