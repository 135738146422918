import { node } from "prop-types";

import styles from "./styles.module.scss";

const MyPostingsList = ({ children }) => {
  return (
    <div className={styles.wrapper} data-testid="my-postings-list-wrapper">
      <div className={styles.container} data-testid="my-postings-list-container">
        {children}
      </div>
    </div>
  );
};

MyPostingsList.propTypes = {
  children: node.isRequired,
};

export { MyPostingsList };
