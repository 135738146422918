import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import SectionContactForm from "../../components/SectionContactForm";

import styles from "./styles.module.scss";

const MetaTags = () => (
  <Helmet>
    <title>Contato empresarial | Inklua</title>
    <meta name="description" content="O Gostaria de encontrar os melhores profissionais com deficiência para sua empresa, com agilidade e assertividade? Entre em contato e conheça as nossas soluções." />
    <meta name="keywords" content="R&S de pessoas com deficiência (PcDs), R&S pcd, recrutamento e seleção de pcd, consultoria pcd, consultoria focada em pcd, site para PcD, anúncio de vagas para pcd, palestras sobre pcd, oportunidade de emprego pcd, recrutamento e seleção, Inklua." />
    <meta property="og:title" content="Contato empresarial | inklua" />
    <meta property="og:type" content="website" />
    <meta property="og:description" content="Gostaria de encontrar os melhores profissionais com deficiência para sua empresa, com agilidade e assertividade? Entre em contato e conheça as nossas soluções." />
    <meta property="og:image" content="https://s3.sa-east-1.amazonaws.com/public.inklua/logo_mini.png" />
    <meta property="og:image:type" content="image/jpeg" />
    <meta property="og:image:width" content="200" />
    <meta property="og:image:height" content="200" />
    <meta property="og:url" content="https://inklua.com/para-empresas/contato" />
    <meta property="og:site_name" content="Inklua" />
    <link rel="canonical" href="https://inklua.com/para-empresas/contato" />
  </Helmet>
);

const Contact = ({ type }) => {
  const { pathname } = useLocation();
  const isEnterpriseVersion = pathname.includes('para-empresas')

  if (isEnterpriseVersion) {
    return (
      <>
        <MetaTags />
        <div className={styles.wrapper}>
          <SectionContactForm type="enterprise" />
        </div>
      </>
    )
  }

  return (
    <>
      <MetaTags />
      <div className={styles.wrapper}>
        <SectionContactForm />
      </div>
    </>
  )
};

export default Contact;
