const getScreenSize = () => {
  const { innerWidth } = window;

  if (innerWidth <= 480) {
    return "mobile";
  }

  if (innerWidth >= 641 && innerWidth <= 1279) {
    return "mobile";
  }

  return "desktop";
};

export default getScreenSize;
