import { Link } from "react-router-dom";

import Illustration from "./assets/group-illustration.png";
import styles from "./styles.module.scss";
import { defaultDescription, firstTimeDescription } from './constants';
import useEnterPriseEnvCheck from "../../../../hooks/useEnterpriseEnvCheck";

const JobPostingCTA = ({ firstTime }) => {
  const { isEnterpriseEnv } = useEnterPriseEnvCheck();

  return (
    <div className={[
      styles.wrapper,
      isEnterpriseEnv && styles.secondaryLayout
    ].join(" ")}>
      <img
        className={styles.illustration}
        src={Illustration}
        aria-hidden="true"
        alt=""
        data-testid="jpcta-image"
      />
      <p className={styles.text} data-testid="jpcta-text">
        {firstTime ? firstTimeDescription.text : defaultDescription.text}
      </p>
      {isEnterpriseEnv &&
        <Link
          to="/para-empresas/recrutador/criar-vaga"
          aria-label="Cadastrar vaga"
          className={styles.link}
        >
          {firstTime ? firstTimeDescription.buttonLabel : defaultDescription.buttonLabel}
        </Link>
      }
      {!isEnterpriseEnv &&
        <a
          href="/recrutador/criar-vaga"
          aria-label="Cadastrar vaga"
          className={styles.link}
          target="_blank"
          rel="noreferrer"
        >
          {firstTime ? firstTimeDescription.buttonLabel : defaultDescription.buttonLabel}
        </a>
      }
    </div>
  );
};

export { JobPostingCTA };
