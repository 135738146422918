import { useEffect } from "react";

import useDoubleAuthPrevention from "../../../hooks/useDoubleAuthPrevention";

import AuthenticationWrapper from "../../../../authentication/components/AuthenticationWrapper";

import { SectionTitle } from "../../components/SectionTitle";
import RecruiterInfoForm from "../../components/RecruiterInfoForm";

const EditInformation = () => {
  const { validate } = useDoubleAuthPrevention();

  useEffect(() => {
    validate({ redirect: false });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthenticationWrapper>
      <SectionTitle>Dados da conta</SectionTitle>
      <RecruiterInfoForm />
    </AuthenticationWrapper>
  );
};

export default EditInformation;
