import * as Yup from "yup";

const validateFileField = Yup.object().shape({
  url: Yup.mixed().optional(),
  extension: Yup.mixed().when("url", {
    is: (v) => v === null,
    then: (schema) => schema.required("INVALID_EXTENSION"),
    otherwise: (schema) => schema.optional(),
  }),
  base64: Yup.mixed().when("url", {
    is: (v) => v === null,
    then: (schema) => schema.required("INVALID_BASE64"),
    otherwise: (schema) => schema.optional(),
  }),
});

export { validateFileField };
