import { func, bool, string } from "prop-types";
import useEnterPriseEnvCheck from "../../hooks/useEnterpriseEnvCheck";

import styles from "./styles.module.scss";

const Radio = ({ label, value, onChange, checked }) => {
  const { isEnterpriseEnv } = useEnterPriseEnvCheck();

  return (
    <div className={[
      styles.wrapper,
      isEnterpriseEnv && styles.secondaryLayout
    ].join(" ")}>
      <button
        className={[
          styles.input,
          checked && styles.active,
        ].join(" ")}
        onClick={() => onChange({ value: value })}
        data-testid={`checkbox-button-${value}`}
        type="button"
      />
      <span
        className={styles.text}
        data-testid="checkbox-button-text"
      >{label}</span>
    </div>
  );
};

Radio.propTypes = {
  label: string.isRequired,
  value: string.isRequired,
  onChange: func.isRequired,
  checked: bool.isRequired,
};

export default Radio;