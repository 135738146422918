import React from "react";
import { string, number } from "prop-types";

import SquareLinkButton from "../../components/SquareLinkButton";
import ShareButton from "../../components/ShareButton";
import { toDate, parseUrl } from "../../helpers";

import styles from "./styles.module.scss";

const Article = ({
  id,
  image,
  title,
  source,
  date,
  description,
  url,
  category,
  altImage,
}) => {
  return (
    <article className={styles.wrapper}>
      <img
        src={image}
        className={styles.image}
        alt={altImage || `Imagem do artigo "${title}"`}
        tabIndex="0"
      />
      <span className={styles.category} tabIndex="0">{category}</span>
      <h1 className={styles.title} tabIndex="0">{title}</h1>
      <div className={styles.infoWrapper}>
        <span className={styles.source} tabIndex="0">{source}</span>
        <span className={styles.date} tabIndex="0">
          Publicada em: {toDate(date, "DD/MM/YYYY")}
        </span>
      </div>
      <div className={styles.descriptionWrapper}>
        <p className={styles.description} tabIndex="0">{description}</p>
      </div>
      <div className={styles.footer}>
        <SquareLinkButton
          type="outline"
          text="Saiba mais"
          rel="external"
          url={parseUrl(url)}
        />
        <ShareButton
          text={`Compartilhar artigo ${title}`}
          title={title}
          url={url}
          id={id}
        />
      </div>
    </article>
  );
};

Article.propTypes = {
  image: string.isRequired,
  title: string.isRequired,
  source: string.isRequired,
  date: string.isRequired,
  description: string.isRequired,
  url: string.isRequired,
  category: string.isRequired,
  id: number.isRequired,
  altImage: string,
};

export default Article;
