const useUserData = () => {
  const userData = localStorage.getItem("@user_data");

  const getUserType = () => {
    if (userData && JSON.parse(userData).type === "PJ") {
      return "RECRUITER";
    }

    return "CANDIDATE";
  };

  const isRegistrationComplete = () => {
    if (!userData || !JSON.parse(userData).email_verified_at) {
      return false;
    }

    return true;
  };

  const getRegistrationStatus = () => {
    if (!userData) {
      return "unauthenticated";
    }

    if (!JSON.parse(userData).email_verified_at) {
      return "pending_email_confirmation";
    }

    if (!JSON.parse(userData).accepted_terms) {
      return "pending_terms";
    }

    return "complete";
  };

  const registrationStatus = getRegistrationStatus();

  return { isRegistrationComplete, registrationStatus, getUserType };
};

export default useUserData;
