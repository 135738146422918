import { string, node } from "prop-types";

import useAxios from "../../hooks/useAxios";
import { SimpleInformation } from "../../modules/Recruiter/components/SimpleInformation";
import { NewInformationSummary } from "../../modules/Account/components/NewInformationSummary";

import styles from "./styles.module.scss";

const DisabilitySummary = ({ type, report, details, actionButton }) => {
  const { exec, loading } = useAxios();

  const handleOpenFile = async (url) => {
    try {
      const response = await exec({
        useCredentials: true,
        method: "GET",
        url: url,
      });

      window.open(response.url, '_blank')
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <NewInformationSummary title="Pessoa com Deficiência (PcD)" columns={2}>
      <ul data-testid="disability-summary">
        <li>
          <SimpleInformation
            title="Tipo de deficiência"
            value={type || "-"}
            testID="disability-type"
          />
        </li>
        <li data-testid="disability-report-item">
          {report ? (
            <SimpleInformation
              title="Laudo"
              onClick={() => handleOpenFile(report)}
              value="Visualizar"
              testID="laudo-info"
              loading={loading}
            />
          ) : (
            <SimpleInformation
              title="Laudo"
              value="-"
              testID="laudo-info"
            />
          )}
        </li>
        <li>
          <SimpleInformation
            title="Detalhes"
            value={details || "-"}
            testID="disability-details"
          />
        </li>
      </ul>
      {actionButton && (
        <div className={styles.actionButtonWrapper}>{actionButton}</div>
      )}
    </NewInformationSummary>
  );
};

DisabilitySummary.propTypes = {
  type: string,
  report: string,
  details: string,
  actionButton: node,
};

DisabilitySummary.defaultProps = {
  type: null,
  report: null,
  details: null,
  actionButton: null,
};

export { DisabilitySummary };
