import React from "react";
import { string } from "prop-types";

const Magnifier = ({ color }) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      aria-label="Buscar"
    >
      <g
        id="🖥️-03-home-inklua-(DesktopView)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="02-home-inklua-mobile-skeleton-loading"
          transform="translate(-330.000000, -121.000000)"
        >
          <g id="navigation-bar" transform="translate(0.000000, 94.000000)">
            <g id="bg" fill="transparent">
              <rect id="head-bg" x="0" y="0" width="415" height="72"></rect>
            </g>
            <g
              id="itens"
              transform="translate(287.000000, 24.000000)"
              fill={color}
            >
              <g
                id="27)-Icon/search"
                transform="translate(43.000000, 3.000000)"
              >
                <path
                  d="M2,8 C2,4.691 4.691,2 8,2 C11.309,2 14,4.691 14,8 C14,11.309 11.309,14 8,14 C4.691,14 2,11.309 2,8 M17.707,16.293 L14.312,12.897 C15.365,11.543 16,9.846 16,8 C16,3.589 12.411,0 8,0 C3.589,0 0,3.589 0,8 C0,12.411 3.589,16 8,16 C9.846,16 11.543,15.365 12.897,14.312 L16.293,17.707 C16.488,17.902 16.744,18 17,18 C17.256,18 17.512,17.902 17.707,17.707 C18.098,17.316 18.098,16.684 17.707,16.293"
                  id="🎨-Icon-Сolor"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Magnifier.propTypes = {
  color: string,
};

Magnifier.defaultProps = {
  color: "#1B9B9E",
};

export default Magnifier;
