import React from "react";
import { string } from "prop-types";

const Arrow = ({ color, strokeWidth, className }) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M21 7.5L12 16.5L3 7.5" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
};

Arrow.propTypes = {
    color: string,
    strokeWidth: string,
    className: string,

};

Arrow.defaultProps = {
    color: "#fff",
    strokeWidth: '1.5',
    className: ''
};

export default Arrow;

