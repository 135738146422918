import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { bool } from "prop-types";

import { User } from "../../../../libs/icons";
import { lockScroll } from "../../../../helpers";
import IconLink from "../../../../components/IconLink";
import Menu from "../Menu";
import MenuTrigger from "../MenuTrigger";
import SearchBlockWrapper from "../../../../components/SearchBlockWrapper";

import styles from "./styles.module.scss";

const Header = () => {
  const [isFloating, setFloating] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const userData = localStorage.getItem("@user_data")
    ? JSON.parse(localStorage.getItem("@user_data"))
    : undefined;

  const handleScroll = () => {
    setFloating(window.scrollY > 0);
  };

  const getMyAccountPath = () => {
    if (userData?.type === "PJ" && !userData.inkluer) {
      return "/para-empresas/minha-conta";
    }

    if (userData?.type === "PF") {
      return "/candidato/minha-conta";
    }
  };

  useEffect(() => {
    lockScroll(isMenuOpen);

    return () => {
      lockScroll(false);
    }
  }, [isMenuOpen]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <span className={styles.spacer} aria-hidden="true" />
      <header
        className={[styles.wrapper, isFloating && styles.floating].join(" ")}
      >
        <div className={styles.container}>
          <Link to="/para-empresas" aria-label="Início">
            <img
              src="https://s3.sa-east-1.amazonaws.com/public.inklua/para-empresas/logo_empresa.svg"
              alt="Logomarca da Inklua Empresarial, onde cada letra possui cor branca"
              width="164px"
            />
          </Link>
          <div className={styles.links}>
            {localStorage.getItem("@user_token") && (
              <>
                {userData?.name && (
                  <IconLink
                    to={getMyAccountPath()}
                    text={userData?.name}
                    type="link"
                    color="#FFFFFF"
                    href={
                      (userData?.type === "PJ" && userData?.inkluer)
                      && "/recrutador/minha-conta"
                    }
                  />
                )}
              </>
            )}
            {!localStorage.getItem("@user_token") && (
              <IconLink
                to="/para-empresas/autenticacao/login"
                text="Entrar"
                type="link"
                color="#FFFFFF"
              >
                <User color="#FFFFFF" hidden />
              </IconLink>
            )}
            <MenuTrigger onClick={() => setIsMenuOpen(true)} />
          </div>
        </div>
      </header>
      <Menu visible={isMenuOpen} handleClose={() => setIsMenuOpen(false)} />
      <SearchBlockWrapper type="mobile" />
    </>
  );
};

Header.propTypes = {
  hideSearch: bool,
};

export default Header;
