import { string } from "prop-types";

import { Spinner } from "../../libs/icons";

import styles from "./styles.module.scss";

const LoadingSpinner = ({ color }) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Spinner color={color} />
      </div>
    </div>
  );
};

LoadingSpinner.propTypes = {
  color: string,
};

LoadingSpinner.defaultProps = {
  color: "#1B9B9E",
};

export { LoadingSpinner };
