import { number } from "prop-types";

import { Magnifier } from "../../../../libs/icons";
import SquareButton from "../../../../components/SquareButton";

import Illustration from "./empty-results-illustration.svg";
import styles from "./styles.module.scss";

export const messages = [
  "Evite usar abreviações ao digitar o termo de busca. Digite o termo completo.",
  "Evite restringir os resultados da busca. Procure usar menos critérios.",
];

const EmptyResultsFeedback = ({ counter, onClick, enterpriseVersion, type, buttonLabel }) => {
  return (
    <div className={[
      styles.wrapper,
      enterpriseVersion && styles.secondaryLayout
    ].join(" ")}>
      <h1 className={styles.title}>
        <b>Busca</b> sem resultados
      </h1>
      <img
        src={Illustration}
        className={styles.illustration}
        alt="Ilustração de uma uma pessoa confusa ou perdida."
      />
      <h2 className={styles.subtitle}>
        <b>Busca</b> sem resultados
      </h2>
      <p className={styles.message} data-testid="empty-results-message">
        {counter <= 1 ? messages[0] : messages[1]}
      </p>
      <div className={styles.buttonWrapper}>
        <SquareButton
          text={type === "recruiter" ? buttonLabel : "Realizar nova busca"}
          type="button"
          onClick={onClick}
          data-testid="new-search-button"
          icon={<Magnifier color="#fff" />}
        />
      </div>
    </div>
  );
};

EmptyResultsFeedback.propTypes = {
  counter: number,
};

EmptyResultsFeedback.defaultProps = {
  counter: 1,
};

export default EmptyResultsFeedback;
