const getCounter = ({ counterList, key }) => {
  let result = 0;

  if (!counterList || !counterList.length) return result;

  counterList.forEach((counter) => {
    if (counter.name === key) result = counter.positions;
  });

  return result;
};

export { getCounter };
