import React from "react";
import { string } from "prop-types";

const Mail = ({ color }) => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M31.2,29.75 L8.8,29.75 C7.9184,29.75 7.2,29.022 7.2,28.125 L7.2,12.28125 L19.04,21.3 C19.3248,21.51775 19.6624,21.625 20,21.625 C20.3376,21.625 20.6752,21.51775 20.96,21.3 L32.8,12.28125 L32.8,28.125 C32.8,29.022 32.0816,29.75 31.2,29.75 L31.2,29.75 Z M30.1328,10.25 L20,17.96875 L9.8672,10.25 L30.1328,10.25 Z M31.2,7 L8.8,7 C6.1536,7 4,9.18725 4,11.875 L4,28.125 C4,30.81275 6.1536,33 8.8,33 L31.2,33 C33.8464,33 36,30.81275 36,28.125 L36,11.875 C36,9.18725 33.8464,7 31.2,7 L31.2,7 Z"
          id="path-mail-icon-1"
        ></path>
      </defs>
      <g
        id="mail-icon-hover-state"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-mail-icon-1"></use>
        </mask>
        <use id="mail-icon" fill={color} xlinkHref="#path-mail-icon-1"></use>
      </g>
    </svg>
  );
};

Mail.propTypes = {
  color: string,
};

Mail.defaultProps = {
  color: "#1B9B9E",
};

export default Mail;
