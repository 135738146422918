import config from "../../config";


export const getParaEmpresasUrl = (location) => {
  const { PARA_EMPRESAS_URL } = config;
  const subPaths = location.pathname.split("/para-empresas");

  if (subPaths[1] === "") return PARA_EMPRESAS_URL;

  if (subPaths[1].includes("/solucoes-rs")) {
    const hash = subPaths[1].split("/solucoes-rs")[1];

    if (hash === "") return PARA_EMPRESAS_URL + "/solucoes-rs";
    return PARA_EMPRESAS_URL + "/solucoes-rs" + hash.replace("/", "#");
  }

  if (subPaths[1].includes("/autenticacao/cadastro/recrutador")) {
    return PARA_EMPRESAS_URL + "/autenticacao/cadastro";
  }

  if (subPaths[1].includes("/minha-conta/editar")) {
    return PARA_EMPRESAS_URL + "/minha-conta/editar-dados";
  }

  if (subPaths[1].includes("/minha-conta/alterar-senha")) {
    return PARA_EMPRESAS_URL + "/minha-conta/editar-senha";
  }

  if (subPaths[1].includes("/recrutador/criar-vaga")) {
    return PARA_EMPRESAS_URL + "/minhas-vagas/criar-vaga";
  }

  if (subPaths[1].includes("/recrutador/minhas-vagas")) {
    return PARA_EMPRESAS_URL + "/minhas-vagas";
  }

  return PARA_EMPRESAS_URL + subPaths[1];

};

// autenticacao/reenviar-nova-senha
// autenicacao/definir-nova-senha
// autenticacao/aceite-os-termos-de-uso
