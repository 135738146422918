import React, { useEffect, useRef, useState } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";

import { Input } from "../../../../components/NewInput";
import SquareButton from "../../../../components/SquareButton";
import { GhostButton } from "../../../../components/GhostButton";
import useAxios from "../../../../hooks/useAxios";
import { Pencil, Lock } from "../../../../libs/icons";
import validationDictionary from "../../../../constants/validationDictionary";
import { useUserContext } from "../../../../contexts/User";
import { useSnackBarContext } from "../../../../contexts/SnackBar";

import FieldsGrid from "../../../authentication/components/FieldsGrid";

import PasswordButton from "../PasswordButton";

import styles from "./styles.module.scss";

const CandidateInfoForm = () => {
  const formRef = useRef(null);
  const [isLoading, setLoading] = useState(true);
  const { exec, loading } = useAxios();
  const { userData, setUserData } = useUserContext();
  const { showSnackBar } = useSnackBarContext();
  const currentStorage = localStorage.getItem("@user_data");
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  const handleSubmit = async (data) => {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string()
          .min(3, validationDictionary.INVALID_NAME)
          .required(validationDictionary.INVALID_NAME),
        lastname: Yup.string()
          .min(3, validationDictionary.INVALID_LASTNAME)
          .required(validationDictionary.INVALID_LASTNAME),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await exec({
        useCredentials: true,
        method: "POST",
        url: "/user/update",
        data: {
          name: data.name,
          lastname: data.lastname,
        },
      });

      const storage = JSON.parse(localStorage.getItem("@user_data"));
      const updatedStorage = {
        ...storage,
        name: response.data.name,
        lastname: response.data.lastname,
      };

      setUserData({ ...userData, ...updatedStorage });
      localStorage.setItem("@user_data", JSON.stringify(updatedStorage));

      showSnackBar({
        timeout: 2000,
        text: validationDictionary.MY_ACCOUNT_UPDATED_SUCCESFULLY,
      });
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  useEffect(() => {
    if (currentStorage) {
      const { name, lastname, email } = JSON.parse(currentStorage);

      setUserData({
        ...userData,
        name,
        lastname,
        email,
      });
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStorage]);

  if (isLoading) {
    return <span />;
  }

  return (
    <Form
      ref={formRef}
      initialData={
        userData && {
          name: userData.name,
          lastname: userData.lastname,
          email: userData.email,
        }
      }
      onSubmit={handleSubmit}
    >
      <FieldsGrid>
        <Input
          label="Nome"
          name="name"
          placeholder="Digite seu nome"
          type="text"
          disabled={loading}
          icon={<Pencil color="#8F9BB3" />}
        />
        <Input
          label="Sobrenome"
          name="lastname"
          placeholder="Digite seu sobrenome"
          type="text"
          disabled={loading}
          icon={<Pencil color="#8F9BB3" />}
        />
        <Input
          label="E-mail"
          name="email"
          placeholder={userData.email}
          type="text"
          disabled
          icon={<Lock color="#8F9BB3" />}
        />
      </FieldsGrid>
      <p
        className={styles.emailChangeMessage}
        data-testid="change-email-instruction"
      >
        <Link to="/contato">Contate a Inklua</Link> para editar seu e-mail
      </p>
      <PasswordButton />
      <SquareButton disabled={loading} text="Concluir" type="submit" />
      <GhostButton text="Voltar" name="back" onClick={handleGoBack} />
    </Form>
  );
};

export default CandidateInfoForm;
