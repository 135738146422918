import { string, arrayOf, shape } from "prop-types";

import { toDate } from "../../../../helpers";

import { SimpleInformation } from "../SimpleInformation";

import styles from "./styles.module.scss";

const EducationSummary = ({ data }) => {
  return (
    <>
      {data.map((course) => (
        <div
          className={styles.position}
          key={`course_${course.start_at}`}
          data-testid="education-summary-item"
        >
          <SimpleInformation
            title="Curso"
            value={course.course}
            testID="course"
          />
          <SimpleInformation
            title="Nível"
            value={course.level_education}
            name="level"
            testID="level"
          />
          {course.institute && (
            <SimpleInformation
              title="Instituição"
              value={course.institute}
              testID="institute"
            />
          )}
          {course.start_at && (
            <SimpleInformation
              title="Data de início"
              value={toDate(course.start_at, "MM/YYYY")}
              testID="start-date"
            />
          )}
          {course.end_at && (
            <SimpleInformation
              title="Data de conclusão"
              value={toDate(course.end_at, "MM/YYYY")}
              testID="end-date"
            />
          )}
        </div>
      ))}
    </>
  );
};

EducationSummary.propTypes = {
  data: arrayOf(
    shape({
      course: string.isRequired,
      level_education: string.isRequired,
      institute: string,
      start_at: string,
      end_at: string,
    })
  ).isRequired,
};

EducationSummary.defaultProps = {
  data: {
    institute: null,
    start_at: null,
    end_at: null,
  },
};

export { EducationSummary };
