import React from "react";

import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";
import AcceptTheTerms from "../AcceptTheTerms";

const AgreeWithTermsEnterprise = () => {
  return (
    <AuthenticationWrapper>
      <AcceptTheTerms />
    </AuthenticationWrapper>
  );
};

export default AgreeWithTermsEnterprise;
