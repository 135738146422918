import { node } from "prop-types";

import styles from "./styles.module.scss";

const TextButton = ({ onClick, children }) => {
  return (
    <button className={styles.button} onClick={onClick} data-testid="text-button">
      {children}
    </button>
  );
};

TextButton.propTypes = {
  children: node.isRequired,
};

export default TextButton;
