import React from "react";
import { node } from "prop-types";

import AccountCardContainer from "../../components/AccountCardContainer";

import styles from "./styles.module.scss";

const MyAccountHeader = ({ children }) => (
  <AccountCardContainer>
    <div className={styles.wrapper} data-testid="my-account-header">
      {children}
    </div>
  </AccountCardContainer>
);

MyAccountHeader.propTypes = {
  children: node.isRequired,
};

export { MyAccountHeader };
