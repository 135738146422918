const getUserData = () => {
  const storage = localStorage.getItem("@user_data");

  if (!storage) return null;

  const parsedUserData = JSON.parse(storage);

  return parsedUserData;
};

export default getUserData;
