import { Link } from "react-router-dom";

import getUserData from "../../../../../helpers/getUserData";
import { Pencil, Copy } from "../../../../../libs/icons";

import styles from "./styles.module.scss";

const PasswordButton = () => {
  const { has_password } = getUserData();
  const havePassword = has_password !== false;

  return (
    <Link to="/para-empresas/minha-conta/alterar-senha" className={styles.falseInput}>
      <span className={styles.falseLabel}>Senha</span>
      <span
        className={[
          styles.falseField,
          havePassword ? styles.withPassword : styles.withoutPassword,
        ].join(" ")}
      >
        {havePassword ? "●●●●●" : "Crie uma senha para a conta"}
      </span>
      {havePassword ? <Pencil color="#8F9BB3" /> : <Copy color="#8F9BB3" />}
    </Link>
  );
};

export default PasswordButton;
