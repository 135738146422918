export const sectionsTitle = ["Consultoria de R&S", "Mapeamento Tech", "Busca de Perfil", "Anúncio de vagas"];

export const sectionsContent = {
  "Consultoria de R&S": [
    {
      title: 'Como funciona o serviço de consultoria? ',
      description:
        `<p>A consultoria oferece um serviço completo de recrutamento e seleção, 
            tudo começa com o alinhamento do perfil profissional desejado por sua 
            empresa e as características da vaga.</p>
            <p>Após o alinhamento, o recrutador começa o processo de divulgação e 
            busca ativa por profissionais e, em até 5 dias úteis, lhe são apresentados 
            de 3 a 5 candidatos.</p>
            <p>Ao longo do processo sua empresa é informada sobre a evolução das 
            buscas e seleção e o recrutador lhe dará todo o suporte necessário 
            para as etapas seguintes dentro da empresa</p>`
    },
    {
      title: 'Quais as vantagens de contratar a consultoria? ',
      description:
        `
            <p>✓ Sua empresa não precisa se preocupar em encontrar os profissionais desejados, a consultoria faz isso por você;</p>
            <p>✓Pagamento só na contratação, ou seja, sua empresa só paga a consultoria se contratar um profissional indicado pela mesma; </p>
            <p>✓Garantia após a contratação: se o candidato desistir da oportunidade no período de garantia, a consultoria refaz o processo sem custos; </p>
            <p>✓A consultoria atua com busca ativa de candidatos (não se limita aos candidatos da plataforma da Inklua) para entregar o serviço em qualquer lugar do país de forma ágil e assertiva; </p>
            <p>✓Não importa seu volume de vagas, a consultoria consegue atendê-lo com um quadro de aproximadamente cem recrutadores; </p>
            <p>✓Validação prévia de laudos médicos para enquadramento na Lei de Cotas. </p>`
    },
    {
      title: 'A consultoria realiza a contratação ou terceirização dos candidatos? ',
      description:
        ` <p>Não, a Inklua não terceiriza profissionais para outras empresas.</p>  
            <p>A consultoria oferece serviço de recrutamento e seleção para empresas que queiram contratar profissionais para suas equipes.</p> `
    }
  ],
  "Mapeamento Tech": [
    {
      title: 'Como funciona o serviço de Mapeamento Tech?',
      description:
        `<p>A Inklua disponibiliza a base de candidatos da área de tecnologia já 
                mapeada pelo time da consultoria, para que você tenha liberdade e 
                flexibilidade para buscar o perfil desejado.</p>
                <p>Após encontrar um profissional que tenha interesse, é só entrar em contato com a Inklua 
                na mesma página do brienfig do candidato ou através do formulário de 
                contato. Nosso time entrará em contato para alinharmos os próximos passos. </p>`
    },
    {
      title: 'Quais as vantagens de acessar o mapeamento tech?',
      description:
        `
                <h3>✓É livre!</h3> 
                <p>✓Economiza tempo na etapa de recrutamento, podendo dedicar mais tempo e energia na etapa de seleção;</p>
                <p>✓Resumo simples e objetivo para tomada de decisão rápida.</p>
                <p>✓Filtros para facilitarem as buscas.</p>
                 `
    },
    {
      title: 'Qual a diferença entre o Mapeamento Tech e a Consultoria?',
      description:
        `<p><b>Mapeamento tech</b> um serviço oriundo da consultoria, onde nossos 
                consultores buscam, entrevistam, analisam e geram um briefing dos profissionais 
                da área de tecnologia. O briefing destes profissionais fica disponível no site 
                da Inklua com livre acesso, para que qualquer empresa possa consultar e filtrar 
                os profissionais que deseja. Tudo isso com atendimento e suporte ao longo do 
                processo de seleção igual ao que é oferecido na consultoria.</p>`
    }
  ],
  "Busca de Perfil": [
    {
      title: 'O que é a Busca de Perfis?',
      description:
        `<p>É um serviço que permite a você acesso ilimitado à base de candidatos da Inklua. 
                Você pode explorar e visualizar o perfil dos candidatos, abordá-los diretamente 
                e ofertar uma oportunidade de emprego.</p>`
    },
    {
      title: 'O que preciso fazer para usar a Busca de Perfis?',
      description:
        `<p>É necessário contratar o serviço através de contato com a área comercial da Inklua.
                Após a assinatura do contrato, a Inklua irá disponibilizar o acesso à plataforma.
                </p>`
    },
    {
      title: 'Para quais empresas a plataforma funciona melhor?',
      description:
        `<p>Este serviço é ideal para empresas que queiram buscar ativamente por candidatos e 
                precisam de acesso ilimitado a perfis profissionais de pessoas com deficiência.
        </p>`
    },
    {
      title: 'Quais são os planos disponíveis?',
      description:
        `<p>A Inklua tem apenas um plano: assinatura anual com acesso ilimitado.</p>`
    },
    {
      title: 'Como funciona o pagamento?',
      description:
        `<p>Após a assinatura de contrato é solicitado o pagamento via boleto/depósito bancário 
                em parcela única. Após confirmação de pagamento, os acessos são liberados.</p>`
    }
  ],
  "Anúncio de vagas": [
    {
      title: 'Como anunciar minha vaga no site da Inklua?',
      description:
        `
                <p>Para anunciar sua vaga no site da Inklua é bem simples. </p> 
                <p>1) Primeiro, é necessário se <a href="/para-empresas/autenticacao/cadastro/recrutador">cadastrar</a>. 
                    Basta se identificar como recrutador na plataforma e inserir suas informações e de sua empresa; </p>
                <p>2) Após validar seu cadastro através do e-mail, acesse no menu a opção 
                    "Minhas vagas", clique em “Cadastrar vaga” e preencha o formulário; </p>
                <p>3) Após criar o anúncio da vaga, o time da Inklua irá analisar se cumpre todos 
                    os <a href="/para-empresas/politica-de-privacidade">termos da Inklua</a> e o aprovará, seguindo para pagamento. 
                        Há, se for o primeiro anúncio da sua empresa, não tem custos! </p>
                <p>4) Você pode pagar usando cartão de crédito ou boleto e assim que for identificado o pagamento, 
                    sua vaga será automaticamente publicada! </p>
                `
    },
    {
      title: 'Posso anunciar várias vagas?',
      description:
        `Sim, pode anunciar quantas vagas quiser. Um perfil (descritivo) por anúncio. `
    },
    {
      title: 'Por quanto tempo meu anúncio fica ativo?',
      description:
        ` Seu anúncio fica ativo no site da Inklua por 20 dias após a data de publicação;  `
    },
    {
      title: 'Anunciar vagas na Inklua é gratuito?',
      description:
        `O primeiro anúncio para sua empresa é gratuito, a partir do segundo anúncio é cobrado R$ 250,00 (por anúncio). `
    },
    {
      title: 'Quantas pessoas visualizam o anúncio?',
      description:
        `Não conseguimos precisar quantas pessoas irão visualizar o seu anúncio, mas podemos compartilhar os números recentes que temos. Temos uma média mensal de 25 mil acessos na plataforma, sendo 16 mil usuários únicos.  `
    },
    {
      title: 'Posso editar meus anúncios?',
      description:
        `<p>Se o seu anúncio não respeitar os <a href="/para-empresas/politica-de-privacidade">termos da Inklua</a>, ele voltará para edição. Caso o anúncio já tenha sido publicado, você pode entrar em contato com nosso time e solicitar uma revisão, respeitando os termos.</p>  `
    }
  ],
}