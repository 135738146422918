import styles from './styles.module.scss';
import { string, shape, func } from "prop-types";
import '../../../../libs/newIcons/style.scss';

const Icons = [
    <i className="icon-bulb-outline" />,
    <i className="icon-pin-outline" />,
    <i className="icon-checkmark-circle-outline" />,
    <i className="icon-money" />,
    <i className="icon-corner-down-right-outline" />,
    <i className="icon-at-outline" />,
];

const IconsButton = {
    primary: <i className="icon-done-all-outline" />,
    secondary: <i className="icon-search-outline" />,
    third: <i className="icon-folder-outline" />,
    fourth: <i className="icon-done-all-outline" />,
};

const Comparation = ({
    color,
    header,
    fields,
    button,
    onClick,
    action
}) => {
    return (
        <div className={styles.wrapper}>
            <div
                className={[
                    styles.header,
                    color && styles[color]
                ].join(" ")}
            >
                <h2 className={styles.title}>{header.title}</h2>
                <p className={styles.paragraph}>{header.description}</p>
                <i className="icon-arrowhead-down-outline" />
            </div>
            <ul className={styles.body}>
                {fields.map((item, index) => {
                    return (
                        <li className={[
                            styles.card,
                            color && styles[color]
                        ].join(" ")}>
                            <div className={styles.icon}>
                                {Icons[index]}
                            </div>
                            <div className={styles.cardTitle}>
                                <span>{item.title}</span>
                                <p className={styles.paragraph}>{item.description}</p>
                            </div>
                        </li>
                    )
                })}
            </ul>
            <div className={styles.actions}>
                <button
                    type="button"
                    onClick={onClick}
                    className={[
                        styles.btnFirstAction,
                        color && styles[color]
                    ].join(" ")}
                >
                    {IconsButton[color]}
                    {button.label}
                </button>
                <button
                    onClick={action}
                    type="button"
                    className={[
                        styles.btnSecondAction,
                        color && styles[color]
                    ].join(" ")}
                >
                    Consulte o FAQ
                </button>
            </div>
        </div >
    )
}

Comparation.propTypes = {
    color: string,
    header: string,
    fields: string,
    button: shape({
        label: string,
        icon: string,
    }),
    onClick: func,
};

Comparation.defaultProps = {
    color: '',
    header: '',
    fields: '',
    button: shape({
        label: '',
        icon: '',
    })
};

export default Comparation;