import React, { useEffect } from "react";
import SquareLinkButton from "../../components/SquareLinkButton";
import { focusElement } from "../../../../helpers";
import AuthenticationParagraph from "../../../authentication/components/AuthenticationParagraph";
import SectionTitle from "../../components/SectionTitle";

const EmailValidatedSuccesfully = () => {
  useEffect(() => {
    focusElement('[data-testid="section-title"]');
  }, []);

  return (
    <>
      <SectionTitle
        small
        centered
        level="h2"
        children="Seu e-mail <b>já foi validado</b>"
        enterpriseVersion
      />
      <AuthenticationParagraph>
        Agora é só cadastrar o seu anúncio!
      </AuthenticationParagraph>
      <SquareLinkButton
        url="/para-empresas/recrutador/minhas-vagas"
        type="fill"
        text="Próxima etapa"
      />
    </>
  );
};

export default EmailValidatedSuccesfully;
