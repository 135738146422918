import React from "react";
import { string } from "prop-types";

const AlertTriangle = ({ color }) => {
  return (
    <svg
      width="66px"
      height="57px"
      viewBox="0 0 66 57"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M36,45 C37.656,45 39,46.344 39,48 C39,49.656 37.656,51 36,51 C34.344,51 33,49.656 33,48 C33,46.344 34.344,45 36,45 Z M36,24 C37.656,24 39,25.344 39,27 L39,27 L39,39 C39,40.656 37.656,42 36,42 C34.344,42 33,40.656 33,39 L33,39 L33,27 C33,25.344 34.344,24 36,24 Z M62.5941,55.059 C61.9071,56.274 60.5691,57 59.0151,57 L12.9831,57 C11.4291,57 10.0911,56.274 9.4071,55.059 C9.0831,54.489 8.6571,53.328 9.4581,52.008 L32.4711,13.854 C33.8721,11.532 38.1261,11.532 39.5271,13.854 L62.5431,52.008 C63.3411,53.328 62.9181,54.489 62.5941,55.059 M67.6821,48.909 L44.6661,10.752 C42.8691,7.776 39.6291,6 35.9991,6 C32.3691,6 29.1291,7.776 27.3351,10.752 L4.3191,48.909 C2.6121,51.738 2.5611,55.14 4.1811,58.008 C5.9181,61.089 9.2931,63 12.9831,63 L59.0151,63 C62.7081,63 66.0801,61.089 67.8201,58.008 C69.4401,55.14 69.3891,51.738 67.6821,48.909"
          id="path-1-triangle"
        ></path>
      </defs>
      <g
        id="🔀-💻-06-fluxo-de-excecao:clicando-em-um-token-invalido-ou-ja-validado(TabletView)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="01-token-invalido"
          transform="translate(-351.000000, -213.000000)"
        >
          <g
            id="content-token-invalido"
            transform="translate(205.000000, 207.000000)"
          >
            <g
              id="27)-Icon/alert-triangle"
              transform="translate(143.000000, 0.000000)"
            >
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1-triangle"></use>
              </mask>
              <use
                id="🎨-Icon-Сolor"
                fill={color}
                fillRule="evenodd"
                xlinkHref="#path-1-triangle"
              ></use>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

AlertTriangle.propTypes = {
  color: string,
};

AlertTriangle.defaultProps = {
  color: "#1B9B9E",
};

export default AlertTriangle;
