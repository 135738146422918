import React from "react";
import { node, string, func, bool } from "prop-types";

import styles from "./styles.module.scss";

const EducationList = ({ children }) => (
  <div className={styles.list}>{children}</div>
);

EducationList.propTypes = {
  children: node.isRequired,
};

const EducationListItem = ({
  course,
  level,
  institution,
  start_date,
  end_date,
  handleRemove,
  handleEdit,
  disableActions,
}) => (
  <article className={styles.item} data-testid="education-list-item">
    <div>
      <p className={styles.text}>
        <b>Curso:</b> {course}
        <br />
        <b>Nível:</b> {level}
        <br />
        {institution && (
          <>
            <b>Instituição:</b> {institution}
            <br />
          </>
        )}
        {start_date && (
          <>
            <b>Data de início:</b> {start_date}
            <br />
          </>
        )}
        {end_date && (
          <>
            <b>Data de conclusão:</b> {end_date}
          </>
        )}
      </p>
      <div className={styles.buttonWrapper}>
        <button
          className={styles.removeButton}
          onClick={handleRemove}
          disabled={course === "Inglês" ?? disableActions}
          data-testid="delete-course-button"
        >
          Deletar
        </button>
        <button
          className={styles.editButton}
          onClick={handleEdit}
          disabled={disableActions}
          data-testid="edit-course-button"
        >
          Editar
        </button>
      </div>
    </div>
  </article>
);

EducationListItem.propTypes = {
  course: string.isRequired,
  level: string.isRequired,
  institution: string,
  start_date: string,
  end_date: string,
  handleRemove: func.isRequired,
  handleEdit: func.isRequired,
  disableActions: bool,
};

EducationListItem.defaultProps = {
  disableActions: false,
  institution: null,
  start_date: null,
  end_date: null,
};

export { EducationList, EducationListItem };
