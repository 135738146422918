import ContentLoader from "react-content-loader";

import styles from "./styles.module.scss";

const Skeleton = (props) => {
  return (
    <div className={styles.wrapper}>
      <ContentLoader
        {...props}
      >
        <rect x="0" y="0" rx="3" ry="3" width={props.width} height="183" />
      </ContentLoader>
    </div>
  );
};

export default Skeleton;
