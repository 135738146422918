import React from "react";
import { string, bool, number } from "prop-types";

// import ButtonWithInkoinSupport from "../../components/ButtonWithInkoinSupport";
import useUserData from "../../hooks/useUserData";
import { parseUrl } from "../../helpers";

import styles from "./styles.module.css";

const Advertising = ({ image, title, description, url, hasInkoins, id }) => {
  const { registrationStatus } = useUserData();

  return (
    <article className={styles.wrapper}>
      <span className={styles.label}>Patrocinado</span>
      <img src={image} className={styles.image} alt={`Imagem de ${title}`} />
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.description}>{description}</p>
      {/* <div className={styles.footer}>
        <ButtonWithInkoinSupport
          text="Saiba mais"
          url={parseUrl(url)}
          hasInkoins={registrationStatus !== "unauthenticated" && hasInkoins}
          id={id}
        />
      </div> */}
    </article>
  );
};

Advertising.propTypes = {
  image: string.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  url: string.isRequired,
  hasInkoins: bool.isRequired,
  id: number.isRequired,
};

export default Advertising;
