import useEnterPriseEnvCheck from "../../../../hooks/useEnterpriseEnvCheck";
import styles from "./styles.module.scss";
import {
  V3_STATUSES,
  TABS_TEXT_JOBS,
  HUNTING_STATUSES,
  TABS_TEXT_HUNTING,
} from "./variables";

const PositionFilter = ({ changeTab, type, currentTab }) => {
  const { isEnterpriseEnv } = useEnterPriseEnvCheck();
  let status;
  let tabs;

  if (type === "minhas-vagas") {
    status = V3_STATUSES;
    tabs = TABS_TEXT_JOBS;
  } else {
    status = HUNTING_STATUSES;
    tabs = TABS_TEXT_HUNTING;
  }

  return (
    <div
      className={[
        styles.wrapper,
        isEnterpriseEnv && styles.secondaryLayout,
      ].join(" ")}
    >
      {status.map((item, index) => {
        const isCurrentTab = currentTab === tabs[item];
        return (
          <button
            onClick={() => changeTab(item)}
            type="button"
            key={`${item}_${index}`}
            data-testid={`position-filter-${tabs[item]}`}
            className={[styles.item, isCurrentTab && styles.currentTab].join(
              " "
            )}
          >
            {tabs[item]}
          </button>
        );
      })}
    </div>
  );
};

export { PositionFilter };
