/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import SquareButton from "../../../../components/SquareButton";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import useAxios from "../../../../hooks/useAxios";
import { focusElement, toDate } from "../../../../helpers";

import { SectionTitle } from "../../../Account/components/SectionTitle";
import { useUserEducation } from "../../../Account/hooks/useUserEducation";
import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";

import {
  EducationList,
  EducationListItem,
} from "../../../Account/components/EducationListItem";

import styles from "./styles.module.scss";
import { isUserFullySignedUp } from "../../../Account/hooks/isUserFullySignedUp";
import { useSnackBarContext } from "../../../../contexts/SnackBar";
import { Modal } from "../../../../components/Modal";

const CandidateDegree = () => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const { exec, loading: isAxiosLoading } = useAxios();
  const { courses, loading, removeCourse } = useUserEducation();
  const { checkUserStatusAndRedirect } = isUserFullySignedUp();
  const { showSnackBar } = useSnackBarContext();

  const handleAddDegree = () => {
    history.push(
      "/autenticacao/cadastro/candidato/formacao-academica/adicionar"
    );
  };

  const handleLogout = () => {
    localStorage.removeItem("@user_data");
    localStorage.removeItem("@user_token");
    localStorage.removeItem("@user_inkoins");
    localStorage.removeItem("@user_shares");
    localStorage.removeItem("@user_redirect");

    history.push("/");
  };

  const skipStepAndProceed = async () => {
    const response = await exec({
      useCredentials: true,
      method: "POST",
      url: "/candidate/education",
      data: {
        no_courses: true,
      },
    });

    if (response.error) {
      showSnackBar({
        timeout: 5000,
        text: response.error,
        error: true
      });
      handleLogout();
      return;
    }

    if (response.user.status) {
      localStorage.setItem("@user_data", JSON.stringify(response.user));
      checkUserStatusAndRedirect();
    }
  };

  const editItem = ({ id }) => {
    history.push(
      `/autenticacao/cadastro/candidato/formacao-academica/editar/${id}`
    );
  };

  useEffect(() => {
    checkUserStatusAndRedirect();
    focusElement('[data-testid="account-section-title"]');
  }, []);

  if (loading && !courses.length) {
    return <LoadingSpinner />;
  }

  return (
    <AuthenticationWrapper>
      <SectionTitle>Formação acadêmica</SectionTitle>
      {Boolean(courses.length) ? (
        <>
          <EducationList>
            {courses.map((item) => (
              <EducationListItem
                disableActions={loading}
                key={`education-item-${item.id}`}
                course={item.course}
                institution={item.institute}
                level={item.level_education}
                start_date={
                  item.start_at ? toDate(item.start_at, "MM/YYYY") : null
                }
                end_date={item.end_at ? toDate(item.end_at, "MM/YYYY") : null}
                handleRemove={() => removeCourse({ id: item.id })}
                handleEdit={() => editItem({ id: item.id, type: item.type })}
              />
            ))}
          </EducationList>
          <SquareButton
            outlined
            text={window.innerWidth <= 480 ? "Cadastrar mais formações" : "Cadastrar mais uma formação"}
            onClick={handleAddDegree}
          />
          <SquareButton
            disabled={loading || isAxiosLoading}
            text="Próxima etapa"
            onClick={skipStepAndProceed}
          />
        </>
      ) : (
        <div className={styles.form}>
          <p tabIndex="0">
            Adicione suas últimas formações acadêmicas, cursos extracurriculares
            e profissionalizantes, todo ensino conta!
          </p>
          <SquareButton
            text="Cadastrar Formação"
            onClick={handleAddDegree}
          />
          <SquareButton
            disabled={loading || isAxiosLoading}
            simple
            text="Preencher depois"
            onClick={() => setOpenModal(true)}
          />
        </div>
      )}
      <Modal
        handleClose={() => setOpenModal(false)}
        visible={openModal}
        showCloseButton={false}
        className={styles.modalWrapper}
      >
        <div className={styles.modal}>
          <p>
            Candidatos que cadastram suas formações são <strong>mais vistos</strong> e <strong>mais contactados</strong>.
            Tem certeza que não vai cadastrar?
          </p>
          <SquareButton
            text="Cadastrar minha formação"
            onClick={() => {
              setOpenModal(false);
              handleAddDegree();
            }}
            disabled={isAxiosLoading}
            outlined
            secondaryClass={styles.modalSubmitButton}
          />
          <SquareButton
            simple
            text="Vou pular e deixar incompleto"
            onClick={() => {
              setOpenModal(false);
              skipStepAndProceed();
            }}
            disabled={isAxiosLoading}
            secondaryClass={styles.modalSkipButton}
          />
        </div>
      </Modal>
    </AuthenticationWrapper>
  );
};

export { CandidateDegree };
