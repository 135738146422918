const escapeRegExp = (text) =>
  text.replace(/[-[\]{}()*+?.,\\^$|#\\s]/g, "\\$&");

export const capitalize = (string) => {
  const words = string.split(" ");

  words.forEach((word, index) => {
    const firstLetter = word.split("").shift();
    const rest = word.slice(1);

    words[index] = firstLetter.toUpperCase() + rest;
  });

  return words.join(" ");
};

export const matchOptions = (options, filter) => {
  const result = [];

  options.forEach((item) => {
    const lowerCaseItem = item.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(" ","_").toLowerCase();
    const lowerCaseFilter = filter.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(" ","_").toLowerCase();

    if (lowerCaseItem.includes(lowerCaseFilter)) {
      result.push(capitalize(item));
    }
  });

  return result;
};

export const parseHighlitedString = (string, filter) => {
  const regExString = escapeRegExp(filter);
  const regex = new RegExp(regExString, "gi");

  let output = string.replace(regex, "#");

  return output.split("#");
};
