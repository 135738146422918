import React from "react";
import { bool, string } from "prop-types";

const TrendingUp = ({ color, hidden }) => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 29 29"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path d="M20.9887751,1.12336782 C20.9864421,1.03937771 20.9619449,0.960053706 20.9397809,0.879563176 C20.9222829,0.811904471 20.9141172,0.743079235 20.884954,0.681253176 C20.8581238,0.620593647 20.8126292,0.571599412 20.7729672,0.515606 C20.7239729,0.444447706 20.6796448,0.373289412 20.6166522,0.314962941 C20.6061535,0.304464176 20.6014874,0.290465824 20.5898221,0.281133588 C20.547827,0.244971176 20.4964997,0.232139353 20.4510051,0.202976118 C20.3810133,0.157481471 20.3121881,0.110820294 20.2328641,0.0804905294 C20.1547066,0.0524938235 20.0777157,0.0454946471 19.9972251,0.0338293529 C19.9412317,0.0256636471 19.8910709,0 19.8315779,0 L13.9989309,0 C13.3538401,0 12.8324015,0.522605176 12.8324015,1.16652941 C12.8324015,1.81045365 13.3538401,2.33305882 13.9989309,2.33305882 L17.295543,2.33305882 L12.5874303,7.82507929 L7.59935053,4.83293135 C7.10590859,4.53429982 6.47131659,4.64395359 6.1026933,5.08606824 L0.27004624,12.0852447 C-0.141738643,12.5798532 -0.0752464662,13.3159332 0.419362004,13.7277181 C0.638669534,13.9096967 0.902305181,13.9983529 1.16594083,13.9983529 C1.50073477,13.9983529 1.83202912,13.8560364 2.06300195,13.5784024 L7.25639089,7.34680224 L12.2316388,10.3331175 C12.7204147,10.6259164 13.3480075,10.5232618 13.7177973,10.0916459 L18.6650485,4.31965841 L18.6650485,6.99917647 C18.6650485,7.64310071 19.1864872,8.16570588 19.8315779,8.16570588 C20.4766687,8.16570588 20.9981074,7.64310071 20.9981074,6.99917647 L20.9981074,1.16652941 C20.9981074,1.15136453 20.9899417,1.13853271 20.9887751,1.12336782" id="path-1"></path>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" transform="translate(-207.8333, -571.1667)">
          <g id="menu-itens" transform="translate(208.3333, 127.5)">
            <g id="como-funciona" transform="translate(0, 441.6667)">
              <g id="folder-outline" transform="translate(0, 2.5)">
                <rect id="Rectangle" stroke="#FFFFFF" fill="#FFFFFF" fillRule="nonzero" opacity="0" x="0" y="0" width="28" height="28"></rect>
                <g id="Icons/trending-up" transform="translate(3.1698, 7.3285)">
                  <mask id="mask-2" fill="white">
                    <use href="#path-1"></use>
                  </mask>
                  <path stroke="#FFFFFF" fill="#FFFFFF" strokeWidth="1" d="M20.9887751,1.12336782 C20.9864421,1.03937771 20.9619449,0.960053706 20.9397809,0.879563176 C20.9222829,0.811904471 20.9141172,0.743079235 20.884954,0.681253176 C20.8581238,0.620593647 20.8126292,0.571599412 20.7729672,0.515606 C20.7239729,0.444447706 20.6796448,0.373289412 20.6166522,0.314962941 C20.6061535,0.304464176 20.6014874,0.290465824 20.5898221,0.281133588 C20.547827,0.244971176 20.4964997,0.232139353 20.4510051,0.202976118 C20.3810133,0.157481471 20.3121881,0.110820294 20.2328641,0.0804905294 C20.1547066,0.0524938235 20.0777157,0.0454946471 19.9972251,0.0338293529 C19.9412317,0.0256636471 19.8910709,0 19.8315779,0 L13.9989309,0 C13.3538401,0 12.8324015,0.522605176 12.8324015,1.16652941 C12.8324015,1.81045365 13.3538401,2.33305882 13.9989309,2.33305882 L17.295543,2.33305882 L12.5874303,7.82507929 L7.59935053,4.83293135 C7.10590859,4.53429982 6.47131659,4.64395359 6.1026933,5.08606824 L0.27004624,12.0852447 C-0.141738643,12.5798532 -0.0752464662,13.3159332 0.419362004,13.7277181 C0.638669534,13.9096967 0.902305181,13.9983529 1.16594083,13.9983529 C1.50073477,13.9983529 1.83202912,13.8560364 2.06300195,13.5784024 L7.25639089,7.34680224 L12.2316388,10.3331175 C12.7204147,10.6259164 13.3480075,10.5232618 13.7177973,10.0916459 L18.6650485,4.31965841 L18.6650485,6.99917647 C18.6650485,7.64310071 19.1864872,8.16570588 19.8315779,8.16570588 C20.4766687,8.16570588 20.9981074,7.64310071 20.9981074,6.99917647 L20.9981074,1.16652941 C20.9981074,1.15136453 20.9899417,1.13853271 20.9887751,1.12336782"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

TrendingUp.propTypes = {
  color: string,
  hidden: bool,
};

TrendingUp.defaultProps = {
  color: "#1B9B9E",
  hidden: true,
};

export default TrendingUp;
