const educationTypes = [
  { value: 1, label: "Ensino Fundamental" },
  { value: 2, label: "Ensino Médio Incompleto" },
  { value: 3, label: "Ensino Médio Completo" },
  { value: 4, label: "Curso Técnico" },
  { value: 5, label: "Curso Profissionalizante" },
  { value: 6, label: "Ensino Superior" },
  { value: 7, label: "Pós-Graduação/MBA" },
  { value: 8, label: "Mestrado / Doutorado" },
];

export default educationTypes;
