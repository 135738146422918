import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { toDate } from "../../../../helpers";
import useAxios from "../../../../hooks/useAxios";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import SquareLinkButton from "../../../../components/SquareLinkButton";
import { focusElement } from "../../../../helpers";

import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";

import { SectionTitle } from "../../components/SectionTitle";
import {
  ExperienceList,
  ExperienceListItem,
} from "../../components/ExperienceListItem";

const CandidateListExperience = () => {
  const history = useHistory();
  const { exec, loading } = useAxios();
  const [data, setData] = useState(null);

  const fetchExperiences = async () => {
    try {
      const { user } = await exec({
        method: "GET",
        url: `/candidate/experience`,
        useCredentials: true,
      });

      setData(user.experience);
    } catch (error) {
      console.log({ error });
    }
  };

  const editItem = ({ id }) => {
    history.push(`/candidato/minha-conta/experiencia/editar/${id}`);
  };

  const removeItem = async ({ id }) => {
    try {
      const response = await exec({
        method: "DELETE",
        url: `/candidate/experience/${id}`,
        useCredentials: true,
      });

      if (!response.error) {
        await fetchExperiences();
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    fetchExperiences();

    focusElement('[data-testid="section-title"]');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading && !data) {
    return <LoadingSpinner />;
  }

  return (
    <AuthenticationWrapper>
      <SectionTitle>Experiência</SectionTitle>
      {data?.length ? (
        <ExperienceList>
          {data.map((item) => (
            <ExperienceListItem
              disableActions={loading}
              key={`experience-item-${item.id}`}
              company={item.company}
              role={item.role}
              start_date={
                item.start_date ? toDate(item.start_date, "MM/YYYY") : null
              }
              end_date={item.end_date ? toDate(item.end_date, "MM/YYYY") : null}
              handleRemove={() => removeItem({ id: item.id })}
              handleEdit={() => editItem({ id: item.id, type: item.type })}
            />
          ))}
        </ExperienceList>
      ) : (
        <p>Sem experiências cadastradas</p>
      )}
      <div style={{ display: "grid", rowGap: "16px", marginTop: "24px" }}>
        <SquareLinkButton
          testID="add-another-experience-button"
          text="Cadastrar outra experiência"
          url="/candidato/minha-conta/experiencia/adicionar"
        />
        <SquareLinkButton
          testID="back-to-my-account-button"
          text="Voltar"
          url="/candidato/minha-conta"
          type="ghost"
        />
      </div>
    </AuthenticationWrapper>
  );
};

export { CandidateListExperience };
