const validationDictionary = {
  CONFIRM_YOUR_PASSWORD: "Confirme sua senha",
  GENERIC_INVALID_FIELD: "Campo inválido",
  GENERIC_REQUIRED_FIELD: "Campo obrigatório",
  INVALID_BIRTHDATE: "Digite a data de nascimento",
  INVALID_BIRTHDATE_AGE: "Insira uma data válida",
  INVALID_RECRUITER_NAME: "Nome inválido, por favor revise.",
  INVALID_CANDIDATE_ID: "ID de candidato inválido",
  INVALID_CITY: "Digite o nome da cidade",
  INVALID_CITY_SELECT: "Selecione uma cidade válida",
  INVALID_CNPJ: "CNPJ inválido",
  INVALID_COMPANY_NAME: "Digite o nome da empresa",
  INVALID_CONCLUSION_DATE: "Digite a data de término",
  INVALID_COURSE: "Digite o nome do curso",
  INVALID_DATE: "Data inválida",
  INVALID_DETAILS: "Detalhes inválidos",
  INVALID_EDUCATION_TYPE: "Selecione o tipo",
  INVALID_EMAIL: "Digite um e-mail válido",
  INVALID_END_DATE: "Digite a data",
  INVALID_FIELD: "Campo inválido",
  INVALID_GENDER: "Selecione um gênero",
  INVALID_INSTITUTION: "Digite o nome da instituição",
  INVALID_JOB_LISTING_ID: "ID de vaga inválida",
  INVALID_LASTNAME: "Digite um sobrenome válido",
  INVALID_LINKEDIN_URL: "Digite uma URL válida",
  INVALID_LOGIN: "E-mail ou senha inválidos",
  INVALID_MESSAGE: "Mensagem inválida",
  INVALID_NAME: "Digite um nome válido",
  INVALID_NEIGHBORHOOD: "Digite um bairro válido",
  INVALID_NOTE: "Observações inválidas",
  INVALID_OPTION: "Escolha uma opção",
  INVALID_PASSWORD: "Digite uma senha válida",
  INVALID_PHONE: "Digite o número de celular",
  INVALID_PORTFOLIO_URL: "Digite uma URL válida",
  INVALID_RACE: "Selecione uma cor/raça",
  INVALID_REASON: "Motivos inválidos",
  INVALID_RECRUITMENT_ROLE: "Participação na contratação inválida",
  INVALID_ROLE: "Selecione o cargo",
  INVALID_SALARY: "Digite um salário válido",
  INVALID_SALARY_RANGE: "Digite um valor maior",
  INVALID_START_DATE: "Digite a data de início",
  INVALID_STATE: "Digite um estado válido",
  INVALID_STATE_SELECT: "Selecione um estado",
  INVALID_URL: "Digite uma URL válida",
  INVALID_DISABILITY: "Selecione sua deficiência",
  MY_ACCOUNT_UPDATED_SUCCESFULLY: "Suas informações foram atualizadas!",
  PASSWORD_MIN_CHARACTERS: "Mínimo de 8 caracteres",
  CURRENT_PASSWORD_MIN_CHARACTERS: "Mínimo de 6 caracteres",
  PASSWORDS_DO_NOT_MATCH: "Suas senhas não são iguais",
  TERMS_ARE_REQUIRED: "Aceite os termos antes de continuar",
  USER_NOT_FOUND: "Não existem cadastros com esse e-mail",
  INVALID_CONTRACT_TYPE_AT_LEAST_ONE: "Selecione pelo menos uma modalidade",
  EMAILS_DO_NOT_MATCH: "Seus e-mails não são iguais",
};

export default validationDictionary;
