import React from "react";
import { string } from "prop-types";

const Spinner = ({ color }) => {
  return (
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="icon-spinner-group-a"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="icon-spinner-group-b"
          transform="translate(-191.000000, -394.000000)"
        >
          <g
            id="loading-filters"
            transform="translate(62.000000, 394.000000)"
            fill={color}
          >
            <path
              d="M139.653784,0.756845971 L141.763891,0 L143.278098,4.21878106 L141.16799,4.97562703 C136.597005,6.61513172 133.483642,10.9661191 133.483642,15.9084909 C133.483642,22.3207967 138.676112,27.5178825 145.079971,27.5178825 C150.045441,27.5178825 154.412256,24.3644437 156.027592,19.7484226 L156.767843,17.6330632 L161,19.113062 L160.259749,21.2284214 C158.020152,27.6283468 151.966379,32 145.079971,32 C136.198631,32 129,24.7949701 129,15.9084909 C129,9.06022502 133.314527,3.030588 139.653784,0.756845971 Z"
              id="spinner"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

Spinner.propTypes = {
  color: string,
};

Spinner.defaultProps = {
  color: "#1B9B9E",
};

export default Spinner;
