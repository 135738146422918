import React from "react";
import { string, bool } from "prop-types";

const Info = ({ color, hidden }) => {
  return (
    <svg
      aria-hidden={hidden}
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
    <title>Icons/info</title>
    <defs>
        <path d="M10,0 C15.522,0 20,4.477 20,10 C20,15.523 15.522,20 10,20 C4.477,20 -8.58490721e-16,15.523 -8.58490721e-16,10 C-8.58490721e-16,4.477 4.477,0 10,0 Z M10,2 C5.589,2 2,5.589 2,10 C2,14.411 5.589,18 10,18 C14.411,18 18,14.411 18,10 C18,5.589 14.411,2 10,2 Z M10,8 C10.552,8 11,8.448 11,9 L11,14 C11,14.552 10.552,15 10,15 C9.448,15 9,14.552 9,14 L9,9 C9,8.448 9.448,8 10,8 Z M10,5 C10.552,5 11,5.448 11,6 C11,6.552 10.552,7 10,7 C9.448,7 9,6.552 9,6 C9,5.448 9.448,5 10,5 Z" id="path-1"></path>
    </defs>
    <g id="🖥01-cadastro-relampago(Desktop)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="05-perfil-candidato-faltando-infromacoes" transform="translate(-624, -210)">
            <g id="Group" transform="translate(176, 182)">
                <g id="Group-3" transform="translate(448, 28)">
                    <g id="Icons/info" transform="translate(12, 12) rotate(180) translate(-12, -12)translate(2, 2)">
                        <mask id="mask-2" fill="white">
                            <use href="#path-1"></use>
                        </mask>
                        <use id="🎨-Icon-Сolor" fill={color} href="#path-1"></use>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
  );
};

Info.propTypes = {
  color: string,
  hidden: bool,
};

Info.defaultProps = {
  color: "#1B9B9E",
  hidden: true,
};

export default Info;
