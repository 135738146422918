import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { bool } from "prop-types";

import { LeftArrow } from "../../libs/icons";

import Logo from "./logo-inklua-color.svg";
import styles from "./styles.module.scss";

const Header = () => {
  const [isFloating, setFloating] = useState(false);
  const history = useHistory();

  const handleScroll = () => {
    setFloating(window.scrollY > 0);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <span className={styles.spacer} aria-hidden="true" />
      <header
        className={[
          styles.wrapper,
          isFloating && styles.floating,
        ].join(" ")}
      >
        <div className={styles.container}>
          <div className={styles.leftActions}>
            <button onClick={handleGoBack} className={styles.backButton}>
              <LeftArrow />
            </button>
          </div>
          <Link to="/" aria-label="Início" className={styles.link}>
            <img
              src={Logo}
              alt="Inklua"
              width="100%"
            />
          </Link>
          <div className={styles.rightActions}></div>
        </div>
      </header>
    </>
  );
};

Header.propTypes = {
  secondaryLayout: bool,
};

Header.defaultProps = {
  secondaryLayout: false,
};

export default Header;
