import React from 'react';
import styles from "./styles.module.scss";

const Footer = ({ id }) => {

    const newId = id.replace("#", "%23")
    const linkWhatsapp = `https://api.whatsapp.com/send?phone=5511993271509&text=Ol%C3%A1%2C%20tenho%20interesse%20em%20saber%20mais%20sobre%20o%20profissional%20${newId}`;
    const messageMail = `Olá, tenho interesse em saber mais sobre o profissional ${id}`;

    return (
        <div className={styles.wrapper}>
            <span className={styles.title}>Quer conhecer esse profissional?</span>
            <span className={styles.call}>Fale Conosco:</span>
            <span className={styles.description}>
                <a href={linkWhatsapp} target="_blank" rel="noreferrer">(11) 9 9327-1509 </a>
                ou
                <a href={`mailto:contato@inklua.com?subject=${messageMail}`} target="_blank" rel="noreferrer"> contato@inklua.com</a>
            </span>
            <span className={styles.id}>Profissional: <b>{id}</b></span>
        </div>
    )
};

export default Footer;