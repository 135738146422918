import React, { useState, useRef, useEffect } from "react";
import { useField } from "@unform/core";
import { node, bool, func, string, oneOf } from "prop-types";
import InputMask from "react-input-mask";

import { CrossedEye, OpenEye } from "../../libs/icons";

import warningIcon from "./assets/warning-icon.svg";
import styles from "./styles.module.scss";

const Input = ({
  label,
  name,
  placeholder,
  defaultValue: CustomDefaultValue,
  type,
  blurCallback,
  mask,
  icon,
  disabled,
  enableFlag,
  ariaLabel,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error, clearError } = useField(name);

  const handleType = () => {
    if (type === "password" && showPassword) {
      return "text";
    }

    return type;
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <fieldset className={styles.wrapper}>
      <label htmlFor={`field-${name}`} className={styles.label}>
        {label}{" "}
        {enableFlag && <span className={styles.optionalFlag}>(Opcional)</span>}
      </label>
      {type === "text" && mask && (
        <InputMask
          className={[styles.field, error && styles.invalid].join(" ")}
          ref={inputRef}
          id={`field-${name}`}
          defaultValue={defaultValue}
          placeholder={placeholder}
          type="text"
          onFocus={clearError}
          autoComplete="off"
          aria-label={ariaLabel}
          aria-invalid={Boolean(error)}
          aria-describedby={`error_${name}`}
          mask={mask}
          maskPlaceholder=""
          // beforeMaskedStateChange={maskToCurrency}
          {...rest}
        />
      )}
      {type === "textarea" && (
        <textarea
          className={[
            styles.field,
            error && styles.invalid,
            disabled ? styles.disabled : "",
          ].join(" ")}
          style={error && { backgroundImage: `url(${warningIcon})` }}
          ref={inputRef}
          id={`field-${name}`}
          data-testid={`field-${name}`}
          placeholder={placeholder}
          onFocus={clearError}
          onBlur={blurCallback}
          aria-label={ariaLabel}
          aria-invalid={Boolean(error)}
          aria-describedby={`error_${name}`}
          disabled={disabled}
          defaultValue={CustomDefaultValue || defaultValue}
          {...rest}
        />
      )}
      {type !== "textarea" && !mask && (
        <input
          className={[
            styles.field,
            error && styles.invalid,
            disabled ? styles.disabled : "",
          ].join(" ")}
          style={error && { backgroundImage: `url(${warningIcon})` }}
          ref={inputRef}
          id={`field-${name}`}
          defaultValue={CustomDefaultValue || defaultValue}
          placeholder={placeholder}
          type={handleType()}
          onFocus={clearError}
          onBlur={blurCallback}
          aria-label={ariaLabel}
          aria-invalid={Boolean(error)}
          aria-describedby={`error_${name}`}
          disabled={disabled}
          data-testid={`field-${name}`}
          autoComplete="off"
          {...rest}
        />
      )}
      {!error && icon && type !== "password" && (
        <span className={styles.iconWrapper} aria-hidden="true">
          {icon}
        </span>
      )}
      {type === "password" && !error && (
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          className={styles.iconWrapper}
          aria-label={showPassword ? "Hide password" : "Show password"}
          aria-hidden="true"
          tabIndex="-1"
        >
          {showPassword ? (
            <CrossedEye color="#8F9BB3" />
          ) : (
            <OpenEye color="#8F9BB3" />
          )}
        </button>
      )}
      {error && (
        <span
          className={styles.error}
          id={`error_${name}`}
          data-testid={`error-message-${name}`}
        >
          {error}
        </span>
      )}
    </fieldset>
  );
};

Input.propTypes = {
  label: string.isRequired,
  name: string.isRequired,
  placeholder: string,
  type: oneOf(["textarea", "email", "text", "password"]).isRequired,
  blurCallback: func,
  icon: node,
  mask: string,
  enableFlag: bool,
  ariaLabel: string,
};

Input.defaultProps = {
  placeholder: null,
  blurCallback: () => { },
  icon: null,
  mask: null,
  enableFlag: false,
  ariaLabel: "",
};

export default Input;
