import { string } from "prop-types";

import { Plus } from "../../../../libs/icons";

import styles from "./styles.module.scss";

const SearchActiveFilter = ({ text, onClick }) => {
  return (
    <button
      className={styles.button}
      onClick={onClick}
      data-testid="search-active-filter-button"
    >
      <Plus color="#137784" /> {text}
    </button>
  );
};

SearchActiveFilter.propTypes = {
  text: string.isRequired,
};

export default SearchActiveFilter;
