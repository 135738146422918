import React from "react";
import { oneOf } from "prop-types";
import SocialLogin from "react-social-login";

import styles from "./styles.module.scss";
class SocialButton extends React.Component {
  render() {
    const { triggerLogin, serviceName, ...props } = this.props;

    return (
      <button
        type="button"
        className={[
          styles.button,
          styles[`${serviceName.toLowerCase()}Button`],
        ].join(" ")}
        onClick={triggerLogin}
        {...props}
      >
        Entrar com {serviceName}
      </button>
    );
  }
}

SocialButton.propTypes = {
  serviceName: oneOf(["Facebook", "Google"]).isRequired,
};

export default SocialLogin(SocialButton);
