import { number, array } from 'prop-types';
import React from 'react';
import Separator from "../../../../components/Separator";
import AccountCardContainer from "../../../Account/components/AccountCardContainer";
import { DesktopStep } from "../DesktopStep";
import { MobileStep } from "../MobileStep";
import { Steps } from "../Steps";

const StepsGroup = ({ currentStep, steps }) => {
    const isMobile = window.innerWidth <= 650;

    if (isMobile) {
        return (
            <AccountCardContainer>
                <MobileStep
                    currentStepIndex={currentStep}
                    maxSteps={steps.length}
                    currentStepName={steps[currentStep - 1].name}
                    nextStepName={
                        steps[currentStep]?.name ? steps[currentStep].name : ""
                    }
                />
            </AccountCardContainer>
        )
    }

    return (
        <AccountCardContainer>
            <Steps>
                {steps.map((step, index) => {
                    return (
                        <React.Fragment key={`step_${step.name}`}>
                            {index > 0 && <Separator style={{ margin: 0 }} />}
                            <DesktopStep
                                active={currentStep >= index + 1}
                                currentStep={currentStep}
                                stepIndex={index + 1}
                                stepName={step.name}
                            />
                        </React.Fragment>
                    )
                })}
            </Steps>
        </AccountCardContainer>
    )
};

StepsGroup.propTypes = {
    currentStep: number.isRequired,
    steps: array.isRequired,
};

export { StepsGroup };