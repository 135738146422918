
const Star = ({ color, width, height }) => {
    return (
        <svg
            version="1.1"
            width={width}
            height={height}
            viewBox="0 0 34.0 34.0"
            xmlns="http://www.w3.org/2000/svg"
        >
            <defs>
                <clipPath id="i0">
                    <path d="M1440,0 L1440,1024 L0,1024 L0,0 L1440,0 Z"></path>
                </clipPath>
                <clipPath id="i1">
                    <path d="M14.1151786,0 C14.658219,0 15.1534728,0.310419683 15.3901786,0.799155983 L18.988512,8.05248932 L27.063512,9.22832265 C27.5984128,9.30435067 28.0434994,9.67800363 28.211012,10.191656 C28.3641757,10.6930338 28.2279323,11.2380075 27.8568453,11.6083226 L22.0201786,17.2749893 L23.4368453,25.2508226 C23.5401103,25.7931186 23.3189539,26.3460095 22.8701786,26.6674893 C22.6136456,26.8472596 22.3047636,26.936935 21.9918453,26.9224893 C21.7652873,26.9234009 21.541815,26.8699619 21.3401786,26.766656 L14.1151786,22.984156 L6.89017863,26.766656 C6.41153427,27.0183453 5.83135544,26.9755818 5.39478596,26.6564345 C4.95821648,26.3372871 4.7414247,25.7974376 4.83601197,25.2649893 L6.25267863,17.289156 L0.416011966,11.6224893 C0.0449249577,11.2521742 -0.0913184644,10.7072005 0.0618452997,10.2058226 C0.229357827,9.6921703 0.674444446,9.31851734 1.2093453,9.24248932 L9.2843453,8.06665598 L12.8401786,0.799155983 C13.0768844,0.310419683 13.5721383,0 14.1151786,0 Z M14.1151786,4.60998932 L11.4376786,9.92248932 C11.2296532,10.3356669 10.8330031,10.6212549 10.3751786,10.6874893 L4.42517863,11.5658226 L8.67517863,15.716656 C9.01251371,16.0429385 9.16636598,16.5151058 9.08601197,16.9774893 L8.06601197,22.8708226 L13.4068453,20.0374893 C13.6331501,19.953357 13.8783765,19.9337389 14.1151786,19.9808226 C14.3422694,19.9750887 14.5669153,20.0288084 14.7668453,20.136656 L20.1076786,22.9699893 L19.0876786,17.0058226 C19.0073246,16.5434392 19.1611769,16.0712719 19.498512,15.7449893 L23.748512,11.594156 L17.798512,10.7158226 C17.3559894,10.6267285 16.982416,10.3320791 16.7926786,9.92248932 L14.1151786,4.60998932 Z"></path>
                </clipPath>
            </defs>
            <g transform="translate(-254.0 -315.0)">
                <g clipPath="url(#i0)">
                    <g transform="translate(250.0 223.0)">
                        <g transform="translate(4.0 89.0)">
                            <g transform="translate(0.0 3.0)">
                                <g transform="translate(2.8848213669689375 2.8275106836681516)">
                                    <g clipPath="url(#i1)">
                                        <polygon
                                            points="5.55111512e-17,0 28.2728573,0 28.2728573,26.9294473 5.55111512e-17,26.9294473 5.55111512e-17,0"
                                            stroke="none"
                                            fill={color}
                                        ></polygon>
                                    </g>
                                    <path
                                        d="M21.9918453,26.9224893 C21.7652873,26.9234009 21.541815,26.8699619 21.3401786,26.766656 L14.1151786,22.984156 L6.89017863,26.766656 C6.41153427,27.0183453 5.83135544,26.9755818 5.39478596,26.6564345 C4.95821648,26.3372871 4.7414247,25.7974376 4.83601197,25.2649893 L6.25267863,17.289156 L0.416011966,11.6224893 C0.0449249577,11.2521742 -0.0913184644,10.7072005 0.0618452997,10.2058226 C0.229357827,9.6921703 0.674444446,9.31851734 1.2093453,9.24248932 L9.2843453,8.06665598 L12.8401786,0.799155983 C13.0768844,0.310419683 13.5721383,0 14.1151786,0 C14.658219,0 15.1534728,0.310419683 15.3901786,0.799155983 L18.988512,8.05248932 L27.063512,9.22832265 C27.5984128,9.30435067 28.0434994,9.67800363 28.211012,10.191656 C28.3641757,10.6930338 28.2279323,11.2380075 27.8568453,11.6083226 L22.0201786,17.2749893 L23.4368453,25.2508226 C23.5401103,25.7931186 23.3189539,26.3460095 22.8701786,26.6674893 C22.6136456,26.8472596 22.3047636,26.936935 21.9918453,26.9224893 L21.9918453,26.9224893 Z M14.1151786,19.9808226 C14.3422694,19.9750887 14.5669153,20.0288084 14.7668453,20.136656 L20.1076786,22.9699893 L19.0876786,17.0058226 C19.0073246,16.5434392 19.1611769,16.0712719 19.498512,15.7449893 L23.748512,11.594156 L17.798512,10.7158226 C17.3559894,10.6267285 16.982416,10.3320791 16.7926786,9.92248932 L14.1151786,4.60998932 L11.4376786,9.92248932 C11.2296532,10.3356669 10.8330031,10.6212549 10.3751786,10.6874893 L4.42517863,11.5658226 L8.67517863,15.716656 C9.01251371,16.0429385 9.16636598,16.5151058 9.08601197,16.9774893 L8.06601197,22.8708226 L13.4068453,20.0374893 C13.6331501,19.953357 13.8783765,19.9337389 14.1151786,19.9808226 L14.1151786,19.9808226 Z"
                                        stroke={color}
                                        strokeWidth="0.3"
                                        fill="none"
                                        strokeMiterlimit="10"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

Star.defaultProps = {
    width: '0px',
    height: '0px',
    color: '#fff',
};

export default Star;
