import { oneOf, node, string, func } from "prop-types";

import SquareButton from "../../../../components/SquareButton";

import ClockImage from "./assets/clock-illustration.svg";
import ArchiveImage from "./assets/archive-illustration.svg";
import styles from "./styles.module.scss";
import useEnterPriseEnvCheck from "../../../../hooks/useEnterpriseEnvCheck";

const illustrationMap = {
  clock: ClockImage,
  archive: ArchiveImage,
};

const JobPostingStatus = ({
  title,
  image,
  children,
  handleOnClick,
  actionText,
  banner,
}) => {
  const { isEnterpriseEnv } = useEnterPriseEnvCheck();

  return (
    <article className={[
      styles.wrapper,
      isEnterpriseEnv && styles.secondaryLayout,
      banner && styles.wrapperBanner,
    ].join(" ")}>
      <img
        className={[styles.illustration, banner && styles.illustrationBanner].join(" ")}
        src={illustrationMap[image]}
        alt=""
        aria-hidden="true"
        data-testid="jps-image"
      />
      <h1 className={[styles.title, banner && styles.titleBanner].join(" ")} data-testid="jps-title">
        {title}
      </h1>
      {children}
      {Boolean(handleOnClick && actionText) && (
        <SquareButton
          type="button"
          text={actionText}
          simple
          onClick={handleOnClick}
        />
      )}
    </article>
  );
};

JobPostingStatus.propTypes = {
  image: oneOf(["clock", "archive"]),
  children: node.isRequired,
  title: string.isRequired,
  handleOnClick: func,
  actionText: string,
};

JobPostingStatus.defaultProps = {
  image: "clock",
  handleOnClick: null,
  actionText: "",
};

export { JobPostingStatus };
