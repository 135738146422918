import styles from "./styles.module.scss";
import SquareLinkButton from "../../SquareLinkButton";
import { formatCurrency } from "../../../helpers";
import { useEffect, useState } from "react";

const Job = (props) => {
    const { title, payment, city, gid, enterpriseVersion } = props;
    const [isMobile, setIsMobile] = useState(false);
    const url = enterpriseVersion ?
        `/para-empresas/mapeamento-tech/${gid}`
        :
        `/mapeamento-tech/${gid}`

    useEffect(() => {
        if (window.innerWidth <= 480) setIsMobile(true);
    }, []);

    return (
        <div
            className={[
                styles.wrapper,
                enterpriseVersion && styles.secondaryLayout
            ].join(" ")}>
            <span className={styles.title}>{title}</span>
            <div className={styles.description}>
                <div className={styles.details}>
                    <span className={styles.pretension}>Pretensão Salarial: <b>{formatCurrency(payment)}</b></span>
                    <span className={styles.pretension}>Local: <b>{city}</b></span>
                </div>
                <SquareLinkButton
                    url={url}
                    text="Ver detalhes"
                    size={isMobile ? 'small' : 'default'}
                />
            </div>
        </div>
    )
}

export default Job;