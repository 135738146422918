import { number, string } from "prop-types";

import styles from "./styles.module.scss";

const MobileStep = ({
  currentStepIndex,
  maxSteps,
  currentStepName,
  nextStepName,
}) => {
  return (
    <div className={styles.wrapper}>
      <svg height="68" width="68">
        <circle
          className={styles.circle}
          cx="34"
          cy="34"
          r="30"
          stroke="#d8d8d8"
          strokeWidth="4"
          fillOpacity="0"
          style={{
            strokeDasharray: 1000,
            strokeDashoffset: 0,
          }}
        />
        <circle
          className={styles.circle}
          cx="34"
          cy="34"
          r="30"
          stroke="#1b9b9e"
          strokeWidth="4"
          fillOpacity="0"
          style={{
            strokeDasharray: 1000,
            strokeDashoffset:
              1000 - ((100 * currentStepIndex) / maxSteps) * 1.88,
            transform: "rotate(-90deg)",
            transformOrigin: "center center",
          }}
        />
      </svg>
      <div className={styles.textWrapper}>
        <span className={styles.steps} data-testid="steps">
          {currentStepIndex}/{maxSteps}
        </span>
        <h1 className={styles.currentStep} data-testid="current-step-name">
          {currentStepName}
        </h1>
        {nextStepName && (
          <span className={styles.nextStep} data-testid="next-step-name">
            <b>Próximo:</b> {nextStepName}
          </span>
        )}
      </div>
    </div>
  );
};

MobileStep.propTypes = {
  currentStepIndex: number.isRequired,
  maxSteps: number.isRequired,
  currentStepName: string.isRequired,
  nextStepName: string.isRequired,
};

export { MobileStep };
