import React from "react";
import { string } from "prop-types";

const Pencil = ({ color }) => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M16.0186,10.6787 L13.3236,7.9837 L15.2716,6.0347 L17.9656,8.7287 L16.0186,10.6787 Z M9.0796,17.6247 L6.1026,17.8957 L6.3666,14.9397 L11.9836,9.3227 L14.6796,12.0187 L9.0796,17.6247 Z M19.4036,7.3377 L19.4026,7.3367 L16.6646,4.5987 C15.9236,3.8597 14.6506,3.8247 13.9486,4.5297 L4.9526,13.5257 C4.6266,13.8507 4.4246,14.2827 4.3826,14.7397 L4.0036,18.9097 C3.9776,19.2047 4.0826,19.4967 4.2926,19.7067 C4.4816,19.8957 4.7366,19.9997 4.9996,19.9997 C5.0306,19.9997 5.0606,19.9987 5.0906,19.9957 L9.2606,19.6167 C9.7186,19.5747 10.1496,19.3737 10.4746,19.0487 L19.4716,10.0517 C20.1996,9.3217 20.1686,8.1037 19.4036,7.3377 L19.4036,7.3377 Z"
          id="path-1-pencil"
        ></path>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-4.000000, -4.000000)">
          <use fill={color} xlinkHref="#path-1-pencil"></use>
        </g>
      </g>
    </svg>
  );
};

Pencil.propTypes = {
  color: string,
};

Pencil.defaultProps = {
  color: "#1B9B9E",
};

export default Pencil;
