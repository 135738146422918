import { useState } from "react";

import useAxios from "../../../../hooks/useAxios";

export const useUserWorkExperience = () => {
  const { exec, loading } = useAxios();
  const [experiences, setExperiences] = useState([]);

  const fetchExperiences = async () => {
    try {
      const result = await exec({
        method: "GET",
        url: `/candidate/experience`,
        useCredentials: true,
      });

      setExperiences(result.user.experience ?? []);
    } catch (error) {
      console.error("[INKLUA]", { error });
    }
  };

  const addWorkExperience = async (data) => {
    try {
      const response = await exec({
        useCredentials: true,
        method: "POST",
        url: "/candidate/experience",
        data,
      });

      if (response.error) {
        return false;
      }

      fetchExperiences();

      return response;
    } catch (error) {
      console.log({ error });
    }
  };

  const removeItem = async ({ id }) => {
    try {
      const response = await exec({
        method: "DELETE",
        url: `/candidate/experience/${id}`,
        useCredentials: true,
      });

      if (!response.error) {
        await fetchExperiences();
      }
    } catch (error) {
      console.error("[INKLUA_API_ERROR]", { error });
    }
  };

  return {
    loading,
    addWorkExperience,
    fetchExperiences,
    removeItem,
    experiences,
  };
};
