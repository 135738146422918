import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import styles from "./styles.module.scss";

import ModuleOne from "../../../../components/ModuleOne";
import SectionTitle from "../../../../components/SectionTitle";
import SquareButton from "../../../../components/SquareButton";
import SectionIntro from "../../../../components/SectionIntro";
import SectionContactForm from "../../components/SectionContactForm";
import ButtonsGroup from "../../components/ButtonsGroup";
import FirstModuleImage from "./assets/second-section.webp";
import SecondModuleImage from "./assets/third-section.webp";
import ThirdModuleImage from "./assets/fourth-section.webp";

const MetaTags = () => (
  <Helmet>
    <title>Soluções para R&S | Inklua</title>
    <meta name="description" content="Somos uma consultoria focada em inclusão de pessoas com deficiência no mercado de trabalho. Atendemos empresas de todo Brasil! Atuamos com recrutamento e seleção de pessoas com deficiência (PcD), anúncio de vagas para PcD, mapeamento de profissionais de tecnologia e palestras sobre capacitismo e empregabilidade de pessoas com deficiência." />
    <meta name="keywords" content="Consultoria Especializada em PcD, consultoria PcD, Recrutamento e Seleção de mão de obra PcD, PcD, R&S de pessoas com deficiência, R&S pcd, recrutamento e seleção de pcd, Recrutamento de PcD, consultoria pcd, consultoria focada em pcd, site para PcD, anúncio de vagas para pcd, palestras sobre pcd, oportunidade de emprego pcd, recrutamento e seleção, Inklua, deficiente empregos, contratação de deficientes, vagas exclusiva para pcd, vaga pne, deficiente empregos, projetos para empregabilidade de pessoas com deficiência, acessibilidade, vaga de emprego pcd, emprego para pcd, vaga para pcd, vaga pcd Brasil, oportunidade de emprego pcd, oportunidade, trabalho, recrutamento e seleção, cadastrar currículo, curriculo, Inklua, vagas na Inklua, inklua, empresa inklua, PcD." />
    <meta property="og:title" content="Soluções para R&S | Inklua" />
    <meta property="og:type" content="website" />
    <meta property="og:description" content="Atuamos com recrutamento e seleção de pessoas com deficiência (PcD), anúncio de vagas, mapeamento de profissionais de tecnologia e palestras sobre capacitismo." />
    <meta property="og:image" content="https://s3.sa-east-1.amazonaws.com/public.inklua/logo_mini.png" />
    <meta property="og:image:type" content="image/jpeg" />
    <meta property="og:image:width" content="200" />
    <meta property="og:image:height" content="200" />
    <meta property="og:url" content=" https://inklua.com/para-empresas OU https://inklua.com/paraempresas/solucoes-rs " />
    <meta property="og:site_name" content=" Inklua" />
    <link rel="canonical" href=" https://inklua.com/para-empresas OU https://inklua.com/paraempresas/solucoes-rs " />
  </Helmet>
);

const EnterpriseHome = () => {
  const secondBlockRef = useRef();
  const formBlockRef = useRef();
  const { search } = useLocation();
  const userData = localStorage.getItem("@user_data");

  const handlePanelScroll = () => {
    secondBlockRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const handleFormScroll = () => {
    formBlockRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const redirectLogin = () => {
    const data = userData ? JSON.parse(userData) : {};

    if (data.type === "PJ") {
      if (data.inkluer) {
        window.location = window.location.origin + "/recrutador/minhas-vagas";
        return;
      }
      window.location.href = "/para-empresas/recrutador/minhas-vagas";
      return;
    }
    window.location.href = "/para-empresas/autenticacao/login";
  };

  useEffect(() => {
    const oldId = localStorage.getItem("@user_id");
    const id = search ? search.replace("?", "") : "-";

    if (oldId && oldId !== "-") return;
    localStorage.setItem("@user_id", id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MetaTags />
      <main className={styles.wrapper}>
        <section className={styles.firstSection}>
          <div>
            <SectionTitle color="#438FCC" level="h2">
              Sua empresa já pratica a inclusão de pessoas com deficiência?
            </SectionTitle>
            <p className={styles.firstSectionDescription}>
              Tenha acesso a diferentes soluções para fazer parte deste movimento de
              inclusão e diversidade.
            </p>
            <SquareButton
              text="Conheça nossos serviços!"
              onClick={handlePanelScroll}
            />
          </div>
        </section>
        <section className={styles.title} >
          <SectionIntro
            level="span"
            text="nossos serviços"
          />
          <SectionTitle
            level="h2"
            children="O que a Inklua oferece <b>para sua empresa</b>"
            centered
          />
        </section>
        <section className={styles.secondSection} ref={secondBlockRef}>
          <ModuleOne
            title="INKLUA R&S"
            subtitle="Recrutamento <b>e seleção</b>"
            description="
                    <p>
                    Entregamos o serviço de recrutamento e seleção de candidatos em qualquer lugar do país, 
                    de forma ágil e assertiva. Conte com uma equipe especializada e dedicada única e exclusivamente 
                    à atividade de encontrar o profissional certo para a oportunidade que você possui. 
                    </p>
                    "
            image={[FirstModuleImage]}
            action={
              <ButtonsGroup
                firstButton={{
                  text: "Contate a Inklua",
                  action: handleFormScroll,
                }}
                secondButton={{
                  text: "Saiba mais",
                  action: () => {
                    window.location.href =
                      "/para-empresas/solucoes-rs/consultoria";
                  },
                }}
              />
            }
          />
        </section>
        <section className={styles.thirdSection}>
          <ModuleOne
            title="Tecnologia"
            subtitle="Mapeamento <b>Tech</b>"
            inverse
            description="
                    <p>
                    Precisa de profissionais PcDs que atuam na área de tecnologia?
                     A Inklua oferece uma lista de profissionais que já foram 
                     mapeados pelo nosso time de consultoria, basta ler o 
                     briefing de cada candidato e entrar em contato 
                     conosco para conhecer o profissional. 
                    </p>
                    "
            image={[SecondModuleImage]}
            action={
              <ButtonsGroup
                firstButton={{
                  text: "Visualize os candidatos",
                  action: () => {
                    window.location.href = "/para-empresas/mapeamento-tech";
                  },
                }}
                secondButton={{
                  text: "Saiba mais",
                  action: () => {
                    window.location.href =
                      "/para-empresas/solucoes-rs/mapeamento";
                  },
                }}
              />
            }
          />
        </section>
        <section className={styles.fourthSection}>
          <ModuleOne
            title="Inclusão"
            subtitle="Palestras de <b>sensibilização</b>"
            description="
                    <p>
                    A Inklua oferece uma palestra gratuita para sua empresa, 
                    onde nosso palestrante e sensibilizador, Marcelo Zig, 
                    faz um convite a reflexão sobre o conceito sobre o capacitismo 
                    e a experiência da pessoa com deficiência em sociedade.
                    </p>
                    "
            image={[ThirdModuleImage]}
            action={
              <ButtonsGroup
                firstButton={{
                  text: "Agende uma conversa",
                  action: () => {
                    window.location.href = "/para-empresas/palestras";
                  },
                }}
              />
            }
          />
        </section>
        <section className={styles.fifthSection}>
          <ModuleOne
            title="+ Candidatos"
            subtitle="Plataforma para </br> <b>busca de perfis</b>"
            description="
                    <p>
                    Que tal ter acesso à base da <strong>maior empresa de inclusão de pessoas com deficiência </strong> no mercado de trabalho?
                    Agora você pode ter acesso ilimitado aos perfis cadastrados na Inklua e fazer recrutamento ativo!
                    </p>
                    "
            image={["https://s3.sa-east-1.amazonaws.com/public.inklua/para-empresas/home/busca-perfil.webp"]}
            inverse
            action={
              <ButtonsGroup
                firstButton={{
                  text: "Contate a Inklua",
                  action: handleFormScroll,
                }}
                secondButton={{
                  text: "Saiba mais",
                  action: () => {
                    window.location.href = "/para-empresas/solucoes-rs/busca-de-perfis";
                  },
                }}
              />
            }
          />
        </section>
        <section className={styles.fifthSection}>
          <ModuleOne
            title="+ Visibilidade"
            subtitle="Anúncio de <b>vagas</b>"
            description="
                    <p>
                    Quer apenas anunciar uma vaga na plataforma que é referência 
                    no mercado de trabalho para PcDs e não tem interesse em um serviço 
                    completo de recrutamento e seleção? Basta se cadastrar na plataforma 
                    e anunciar sua vaga!<br /><br />
                    A primeira vaga é gratuita!
                    </p>
                    "
            image={["https://s3.sa-east-1.amazonaws.com/public.inklua/para-empresas/home/anuncios-vagas.webp"]}
            action={
              <ButtonsGroup
                firstButton={{
                  text: "Anuncie sua vaga",
                  action: () => redirectLogin(),
                }}
                secondButton={{
                  text: "Saiba mais",
                  action: () => {
                    window.location.href = "/para-empresas/solucoes-rs/anuncio";
                  },
                }}
              />
            }
          />
        </section>
        <section className={styles.sixtySection}>
          <SectionIntro text="Dúvidas" />
          <SectionTitle
            level="span"
            children="
                    Ainda tem dúvidas sobre qual serviço 
                    é a melhor solução para sua empresa?
                    "
            centered
          />
          <SquareButton
            text="Confira o comparativo"
            onClick={() => {
              window.location.href = "/para-empresas/solucoes-rs/comparativo";
            }}
          />
        </section>
        <div className={styles.contactSection} ref={formBlockRef}>
          <SectionContactForm />
        </div>
      </main>
    </>
  );
};

export default EnterpriseHome;
