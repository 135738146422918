import { node } from "prop-types";

import styles from "./styles.module.scss";

const AuthenticationWrapper = ({ children }) => {
  return (
    <div className={styles.wrapper} data-testid="auth-wrapper">
      {children}
    </div>
  );
};

AuthenticationWrapper.propTypes = {
  children: node.isRequired,
};

export default AuthenticationWrapper;
