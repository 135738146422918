import React, { useRef } from "react";
import { Form } from "@unform/web";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import { Input } from "../../../../components/NewInput";
import SquareButton from "../../components/SquareButton";
import SectionTitle from "../../../../components/SectionTitle";
import useAxios from "../../../../hooks/useAxios";
import validationDictionary from "../../../../constants/validationDictionary";

import AuthenticationParagraph from "../../../authentication/components/AuthenticationParagraph";
import FieldsGrid from "../../../authentication/components/FieldsGrid";

import styles from './styles.module.scss';
import { parseEmail } from "../../../../helpers";

const PasswordRecoveryForm = () => {
  const formRef = useRef(null);
  const { exec, loading } = useAxios();
  const history = useHistory();

  const handleSubmit = async (data) => {
    try {
      const schema = Yup.object().shape({
        email: Yup.string()
          .transform(parseEmail)
          .email(validationDictionary.INVALID_EMAIL)
          .required(validationDictionary.INVALID_EMAIL),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await exec({
        method: "POST",
        url: "/user/password/email",
        data: { ...data, email: parseEmail(data.email) }
      });

      if (response.success) {
        localStorage.setItem("@temp_user_email", JSON.stringify(data.email));

        history.push("/para-empresas/autenticacao/reenviar-nova-senha");
        return;

      }

      if (response.status === 400 && response.error) {
        formRef.current.setErrors({
          email: validationDictionary.USER_NOT_FOUND,
        });
      }
    } catch (error) {
      const validationErrors = {};

      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      className={styles.wrapper}
    >
      <SectionTitle
        small
        centered
        level="h2"
        children="Confirme o e-mail <b>cadastrado na Inklua</b>"
      />
      <AuthenticationParagraph>
        Esqueceu a senha? Não se preocupe, vamos recuperá-la! Primeiro, confirme
        seu e-mail.
      </AuthenticationParagraph>
      <FieldsGrid>
        <Input
          label="E-mail"
          name="email"
          placeholder="Digite seu e-mail"
          type="text"
        />
      </FieldsGrid>
      <FieldsGrid size="small">
        <SquareButton disabled={loading} text="Enviar" type="submit" />
      </FieldsGrid>
    </Form>
  );
};

export default PasswordRecoveryForm;
