import React, { useEffect, useState } from "react";
import { oneOf, number, string, bool } from "prop-types";

import useAxios from "../../hooks/useAxios";
import { useUserContext } from "../../contexts/User";
import { useShareContext } from "../../contexts/Share";
import { Inkoin } from "../../libs/icons";
import Config from "../../config";

import WhatsAppLogo from "./assets/whatsapp-logo@2x.png";
import FacebookLogo from "./assets/facebook-logo@2x.png";
import TwitterLogo from "./assets/twitter-logo@2x.png";
import LinkedinLogo from "./assets/linkedin-logo@2x.png";

import styles from "./styles.module.scss";

const SocialMediaShareButton = ({ id, service, url, hasInkoins }) => {
  const [shareStatusByService, setShareStatusByService] = useState(null);
  const [clicked, setClicked] = useState(
    !hasInkoins || !localStorage.getItem("@user_token")
  );
  const { exec, loading } = useAxios();
  const { addInkoins } = useUserContext();
  const { updateShareOnItem } = useShareContext();

  useEffect(() => {
    if (localStorage.getItem("@user_shares")) {
      const shares = JSON.parse(localStorage.getItem("@user_shares"));
      setShareStatusByService(shares[id]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = async (service) => {
    if (clicked) return;

    const response = await exec({
      useCredentials: true,
      method: "POST",
      url: "/actions/share",
      data: {
        content_id: id,
        action: "share",
        media: service,
      },
    });

    if (response?.data?.coins) {
      addInkoins(response.data.coins);
      updateShareOnItem(id, service);
    }

    setClicked(true);
  };

  const InnerContent = ({ service, children }) => {
    if (loading) {
      return (
        <span
          className={[styles.loadingMessage, loading && styles.active].join(
            " "
          )}
        >
          <Inkoin />
          Resgatando...
        </span>
      );
    }

    if (clicked || (shareStatusByService && shareStatusByService[service])) {
      return children;
    }

    return (
      <>
        {children}
        <span className={styles.loadingMessage}>
          <Inkoin />
          {Config.INKOIN_SHARE_VALUE}
        </span>
      </>
    );
  };

  if (service === "whatsapp") {
    return (
      <a
        href={`https://wa.me/?text=${url}`}
        rel="noreferrer"
        target="_blank"
        className={styles.link}
        onClick={() => handleClick("whatsapp")}
        style={{ backgroundColor: "#55BA63" }}
      >
        <InnerContent service="whatsapp">
          <img
            className={styles.icon}
            src={WhatsAppLogo}
            aria-hidden="true"
            alt=""
          />
          <span className={styles.text}>WhatsApp</span>
        </InnerContent>
      </a>
    );
  }

  if (service === "facebook") {
    return (
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
        rel="noreferrer"
        target="_blank"
        className={styles.link}
        onClick={() => handleClick("facebook")}
        style={{ backgroundColor: "#3A589B" }}
      >
        <InnerContent service="facebook">
          <img
            className={styles.icon}
            src={FacebookLogo}
            aria-hidden="true"
            alt=""
          />
          <span className={styles.text}>Facebook</span>
        </InnerContent>
      </a>
    );
  }

  if (service === "linkedin") {
    return (
      <a
        href={`https://www.linkedin.com/shareArticle?url=${url}`}
        rel="noreferrer"
        target="_blank"
        className={styles.link}
        onClick={() => handleClick("linkedin")}
        style={{ backgroundColor: "#0077B7" }}
      >
        <InnerContent service="linkedin">
          <img
            className={styles.icon}
            src={LinkedinLogo}
            aria-hidden="true"
            alt=""
          />
          <span className={styles.text}>Linkedin</span>
        </InnerContent>
      </a>
    );
  }

  return (
    <a
      href={`https://twitter.com/intent/tweet?text=${url}`}
      rel="noreferrer"
      target="_blank"
      className={styles.link}
      onClick={() => handleClick("twitter")}
      style={{ backgroundColor: "#1da1f2" }}
    >
      <InnerContent service="twitter">
        <img
          className={styles.icon}
          src={TwitterLogo}
          aria-hidden="true"
          alt="Twitter"
        />
        <span className={styles.text}>Twitter</span>
      </InnerContent>
    </a>
  );
};

SocialMediaShareButton.propTypes = {
  service: oneOf(["whatsapp", "facebook", "linkedin", "twitter"]).isRequired,
  id: number.isRequired,
  hasInkoins: bool.isRequired,
  url: string,
};

SocialMediaShareButton.defaultPropTypes = {
  url: "",
};

export default SocialMediaShareButton;
