import { bool, func } from "prop-types";
import SquareLinkButton from "../../SquareLinkButton";

import { LikeButton } from "../LikeButton";

import styles from "./styles.module.scss";

export const DesktopFooter = ({ 
  handleLike, 
  disableButton, 
  alreadyLiked, 
  externalJob, 
  handleModal, 
  compleoJob, 
  url,
  loading
}) => {

  const handleText = () => {

    if (compleoJob) {
      return <p>Ao clicar no botão abaixo você concorda com os
        <a href="https://ats.compleo.com.br/TermosServicoCandidato" target="_blank" rel="noreferrer"> termos de uso</a> e a
        <a href="https://ats.compleo.com.br/PoliticaPrivacidade" target="_blank" rel="noreferrer"> política de privacidade</a> da COMPLEO.</p>
    }

    if (externalJob) {
      if (alreadyLiked) return <p>ATENÇÃO: esta vaga não é da consultoria da Inklua, o processo seletivo será direto com a empresa anunciante da vaga. Para rever o link ou e-mail <button type="button" onClick={handleModal} className={styles.button}>clique aqui.</button></p>
      return <p>ATENÇÃO: esta vaga não é da consultoria da Inklua, o processo seletivo será direto com a empresa anunciante da vaga.</p>
    }

    if (alreadyLiked) return <p>Você já deu um "Gostei" nessa vaga! O recrutador já sabe que você tem interesse por essa vaga e vai avaliar sua candidatura.</p>

    return <p>Gostou dessa vaga, clique no botão abaixo para o recrutador ficar sabendo!</p>
  };

  if (compleoJob) {
    return (
      <div className={styles.footer}>
        <p tabIndex="0">
          {handleText()}
        </p>
        <div className={styles.buttonContainer}>
          <SquareLinkButton
            text="Candidadar-me agora"
            url={url}
            rel="external"
          />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.footer}>
      <p tabIndex="0">
        {handleText()}
      </p>
      <LikeButton
        onClick={handleLike}
        disabled={disableButton || loading}
        liked={alreadyLiked}
      />
    </div>
  );
};

DesktopFooter.propTypes = {
  handleLike: func.isRequired,
  disableButton: bool,
  alreadyLiked: bool,
};

DesktopFooter.defaultProps = {
  disableButton: false,
  alreadyLiked: false,
};
