import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import SquareButton from "../../../../components/SquareButton";
import SectionTitle from "../../../../components/SectionTitle";
import useCounter from "../../../../hooks/useCounter";
import useAxios from "../../../../hooks/useAxios";
import { useSnackBarContext } from "../../../../contexts/SnackBar";
import { focusElement } from "../../../../helpers";

import AuthenticationParagraph from "../../components/AuthenticationParagraph";

import styles from "./styles.module.scss";
import { GhostButton } from "../../../../components/GhostButton";

const ResendEmail = () => {
  const [userEmail, setUserEmail] = useState(null);
  const { exec, loading } = useAxios();
  const { active, startCounter, counter } = useCounter();
  const { showSnackBar } = useSnackBarContext();
  const history = useHistory();

  const sendNewEmail = async () => {
    try {
      const response = await exec({
        method: "POST",
        url: "/email/verification",
        useCredentials: true,
      });

      if (response.status === 'success') {
        const userData = JSON.parse(localStorage.getItem("@user_data"));
        localStorage.setItem(
          "@user_data",
          JSON.stringify({
            ...userData,
            status: "pending-personal-data",
            email_verified_at: true,
          })
        );

        history.push("/");
        return;
      }

      return response;
    } catch (error) {
      console.log({ error });
    }
  };

  const handleSubmit = async () => {
    try {
      startCounter(60);

      const newEmailResponse = await sendNewEmail();

      if (newEmailResponse.msg || newEmailResponse.message) {
        showSnackBar({
          timeout: 5000,
          text: "E-mail reenviado!",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("@user_data");
    localStorage.removeItem("@user_token");
    localStorage.removeItem("@user_inkoins");
    localStorage.removeItem("@user_shares");
    localStorage.removeItem("@user_redirect");
    window.location.href = "/"
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("@user_data"));

    if (!userData) return history.push("/autenticacao/login");

    if (userData?.email) {
      setUserEmail(userData.email);
    }

    focusElement('[data-testid="section-title"]');
  }, []);

  return (
    <>
      <SectionTitle
        small
        centered
        level="h2"
        children="Pode confirmar se <b>você é você mesmo?</b>"
      />
      {userEmail && (
        <AuthenticationParagraph>
          <b>{userEmail}</b>
        </AuthenticationParagraph>
      )}
      <AuthenticationParagraph>
        Enviamos um e-mail para você no endereço acima. Selecione o link dentro
        do e-mail para confirmar seu cadastro na Inklua.
      </AuthenticationParagraph>
      <button className={styles.confirmButton} onClick={sendNewEmail}>
        Já confirmou seu e-mail? <span>Clique aqui</span>.
      </button>
      <SquareButton
        onClick={handleSubmit}
        type="button"
        text={`Reenviar e-mail ${active ? "(" + counter + ")" : ""}`}
        disabled={active || loading}
      />
      <GhostButton
        name="Sair"
        onClick={handleLogout}
        text="Sair"
      />
    </>
  );
};

export default ResendEmail;
