import React, { useState } from "react";
import { node } from "prop-types";

import SearchContext from "./SearchContext";

const SearchProvider = ({ children }) => {
  const [open, setOpen] = useState(() => {
    const hash = window.location.hash;
    return hash === "#vagas" ? true : false;
  });

  const closeSearch = () => {
    setOpen(false);
  };

  const openSearch = () => {
    setOpen(true);
  };

  const value = {
    open,
    openSearch,
    closeSearch,
  };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

SearchProvider.propTypes = {
  children: node.isRequired,
};

export default SearchProvider;
