import { useEffect, useRef, useState } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";

import {
  errorParser,
  validateFileField,
  Input,
} from "../../../../components/NewInput";
import SquareButton from "../../../../components/SquareButton";
import SquareLinkButton from "../../../../components/SquareLinkButton";
import validationDictionary from "../../../../constants/validationDictionary";
import { SectionTitle } from "../../../Account/components/SectionTitle";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import useAxios from "../../../../hooks/useAxios";
import { BooleanCheckbox } from "../../../../components/NewInput";
import { toDate, focusElement } from "../../../../helpers";

import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";

import styles from "./styles.module.scss";
import { isValidDateMMYYYY } from "../../../Candidate/utils/isValidDate";

Yup.addMethod(Yup.string, 'dateMMYYYY', function (errorMessage) {
  return this.test('dateMMYYYY', errorMessage, function (value) {
    const { path, createError } = this;
    if (!value || !isValidDateMMYYYY(value)) {
      return createError({ path, message: errorMessage });
    }

    return true;
  });
});

const CandidateEditExperience = () => {
  const { id } = useParams();
  const { exec, loading } = useAxios();
  const [isOnGoing, setOnGoing] = useState(false);
  const history = useHistory();
  const formRef = useRef();
  const [formData, setFormData] = useState(null);

  const fetchCourseInformation = async () => {
    try {
      const result = await exec({
        method: "GET",
        url: `/candidate/experience/${id}`,
        useCredentials: true,
      });

      const shouldDisplayAsOngoing = result?.end_date ? false : true;
      setOnGoing(shouldDisplayAsOngoing);

      setFormData(result);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleOngoing = ({ checked }) => {
    setOnGoing(checked);
  };

  const handleSubmit = async (data) => {
    try {
      const schema = Yup.object().shape({
        resume: validateFileField,
        role: Yup.string()
          .min(3, validationDictionary.INVALID_ROLE)
          .required(validationDictionary.INVALID_ROLE),
        company: Yup.string()
          .min(3, validationDictionary.INVALID_COMPANY_NAME)
          .required(validationDictionary.INVALID_COMPANY_NAME),
        start_date: Yup.string()
          .dateMMYYYY(validationDictionary.INVALID_DATE)
          .min(3, validationDictionary.INVALID_DATE)
          .required(validationDictionary.INVALID_DATE),
        end_date:
          !isOnGoing &&
          Yup.string()
            .dateMMYYYY(validationDictionary.INVALID_DATE)
            .min(3, validationDictionary.INVALID_DATE)
            .required(validationDictionary.INVALID_DATE),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await exec({
        useCredentials: true,
        method: "PUT",
        url: `/candidate/experience/${id}`,
        data,
      });

      if (response.error) {
        return;
      }

      history.goBack();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        const parsedErrors = errorParser(validationErrors);

        formRef.current.setErrors(parsedErrors);
      }
    }
  };

  useEffect(() => {
    fetchCourseInformation();

    focusElement('[data-testid="section-title"]');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!formData) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <AuthenticationWrapper>
        <SectionTitle>Experiência</SectionTitle>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          className={styles.form}
          initialData={{
            role: formData.role,
            company: formData.company,
            description: formData.description,
            start_date: toDate(formData.start_date, "MM/YYYY"),
            end_date: formData.end_date
              ? toDate(formData.end_date, "MM/YYYY")
              : null,
          }}
        >
          <Input
            label="Cargo"
            name="role"
            placeholder="Insira o cargo"
            type="text"
          />
          <Input
            label="Empresa"
            name="company"
            placeholder="Insira o nome da empresa"
            type="text"
          />
          <Input
            label="Data de início"
            name="start_date"
            placeholder="Ex: 01/2010"
            type="text"
            mask="99/9999"
            ariaLabel="Preenchimento da data de início, no formato de dois digitos para o mês e quatro digitos para o ano. Só pode ser inserido números neste campo"
          />
          <BooleanCheckbox
            name="ongoing"
            onChange={handleOngoing}
            alternativeStyle
            checked={isOnGoing}
          >
            É o meu emprego atualmente
          </BooleanCheckbox>
          {!isOnGoing && (
            <Input
              label="Data de término"
              name="end_date"
              placeholder="Ex: 01/2010"
              type="text"
              mask="99/9999"
              ariaLabel="Preenchimento da data de término, no formato de dois digitos para o mês e quatro digitos para o ano. Só pode ser inserido números neste campo"
            />
          )}
          <Input
            label="Descrição"
            name="description"
            placeholder="Descreva brevemente as suas atividades, no que você atua/atuava nessa função."
            type="textarea"
            enableFlag
          />
          <SquareButton text="Concluir" type="submit" disabled={loading} />
        </Form>
        <SquareLinkButton
          testID="back-to-experiences-list"
          text="Voltar"
          url="/candidato/minha-conta/experiencia"
          type="ghost"
        />
      </AuthenticationWrapper>
    </>
  );
};

export { CandidateEditExperience };
