import React, { forwardRef } from "react";
import { oneOf } from "prop-types";

import ContactForm from "../../../../components/ContactForm";
import SectionTitle from "../../../../components/SectionTitle";

import styles from "./styles.module.scss";
import { Facebook, Instagram } from "../../../../libs/icons";
import Linkedin from "../../../../libs/icons/Linkedin";

import SocialMediaLink from '../../../../components/SocialMediaLink';
import Config from "../../../../config";


const SectionContactForm = forwardRef(({ type }, ref) => {
  const isMobile = window.innerWidth <= 480;

  return (
    <section
      className={styles.wrapper}
      ref={ref}
    >
      <div className={styles.form}>
        <SectionTitle
          level="h2"
          children="Fale com a <b>Inklua</b>"
          linebreak={false}
        />
        <div className={styles.formWrapper}>
          <ContactForm
            type="simple"
            pallete="business"
          />
        </div>
      </div>
      <div className={styles.activity}>
        <SectionTitle
          level="h2"
          children="Onde <b>estamos</b>"
          linebreak={false}
          centered={isMobile}
        />
        <p className={styles.description}>
          Atuamos <b>em todo o Brasil</b> com um time trabalhando
          remotamente. Desta forma conseguimos ampliar
          nossa alcance e encontrar os <b>melhores profissionais </b>
          para sua empresa, com <b>agilidade</b> e <b>assertividade.</b>
        </p>
        <div className={styles.socialMediaWrapper}>
          <span>Siga-nos</span>
          <div className={styles.socialMediaButtons}>
            <SocialMediaLink url={Config.INSTAGRAM_URL} color="#438FCC">
              <Instagram color="#fff" />
            </SocialMediaLink>
            <SocialMediaLink url={Config.FACEBOOK_URL} color="#438FCC">
              <Facebook color="#fff" />
            </SocialMediaLink>
            <SocialMediaLink url={Config.LINKEDIN_URL} color="#438FCC">
              <Linkedin color="#fff" />
            </SocialMediaLink>
          </div>
        </div>
      </div>
    </section>
  );
});

ContactForm.propTypes = {
  type: oneOf(["default", "simple"]),
};

ContactForm.defaultProps = {
  type: "default",
};

export default SectionContactForm;
