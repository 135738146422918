import React from "react";
import { string, oneOfType, number } from "prop-types";
import { Link } from "react-router-dom";

import LocationChip from "../../../../components/LocationChip";
import SalaryChip from "../../../../components/SalaryChip";
import ShareButton from "../../../../components/ShareButton";
import { toSlug, toDate, parseLocation, parseUrl, parseContractType } from "../../../../helpers";

import parseDescription from "../../helpers/parseDescription";

import styles from "./styles.module.scss";

const SearchResultItem = ({
  title,
  id,
  location,
  company,
  date,
  description,
  salary,
  contractTypes,
}) => {
  return (
    <article
      className={styles.wrapper}
      data-testid="search-result-item-wrapper"
    >
      <h1 className={styles.title} data-testid="search-result-item-title" tabIndex="0">
        {title}
      </h1>
      <div className={styles.infoWrapper}>
        <span
          className={styles.company}
          data-testid="search-result-item-company"
          tabIndex="0"
        >
          {company}
        </span>
        <span className={styles.date} data-testid="search-result-item-date" tabIndex="0">
          Publicada em: {toDate(date, "DD/MM/YYYY")}
        </span>
      </div>
      <p className={styles.description} tabIndex="0">
        {parseDescription({ string: description, size: 170, ellipsis: true })}
      </p>
      <div className={styles.footer}>
        <div className={styles.tagsWrapper}>
          <LocationChip
            location={parseContractType({ ...contractTypes, presential: contractTypes.local, location })}
            tabIndex="0"
          />
          {Boolean(salary) && <SalaryChip value={salary} />}
        </div>
        <div>
          <Link
            className={styles.link}
            to={`/vaga/${toSlug(title)}/${id}`}
            data-testid="search-result-item-link"
          >
            Ver detalhes
          </Link>
        </div>
        <div className={styles.shareWrapper}>
          <ShareButton
            text="Compartilhar"
            title={title}
            url={parseUrl(`/vaga/${toSlug(title)}/${id}`)}
            id={id}
            shares={{
              whatsapp: true,
              twitter: true,
              facebook: true,
              linkedin: true,
            }}
          />
        </div>
      </div>
    </article>
  );
};

SearchResultItem.propTypes = {
  location: string,
  title: string.isRequired,
  company: string.isRequired,
  date: string.isRequired,
  description: string.isRequired,
  salary: number,
  id: oneOfType([string, number]),
};

SearchResultItem.defaultProps = {
  location: "Remoto",
  salary: 0,
};

export default SearchResultItem;
