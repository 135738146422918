import { string, func } from "prop-types";
import AccountCardContainer from "../../../Account/components/AccountCardContainer";
import styles from './styles.module.scss';
import { PageTitle } from "../PageTitle"
import { PositionFilter } from "../PositionFilter";
import Exit from "../../../../libs/icons/Exit";
import ButtonWithIcon from "../../../../components/ButtonWithIcon";
import { PositionFilterMobile } from "../PositionFilterMobile";
import useEnterpriseEnvCheck from "../../../../hooks/useEnterpriseEnvCheck";

const PageHeader = ({ title, logout, changeTab, type, currentTab }) => {
    const isTablet = window.innerWidth <= 850;
    const { isEnterpriseEnv } = useEnterpriseEnvCheck();

    return (
        <AccountCardContainer>
            <div className={styles.wrapper}>
                {title &&
                    <PageTitle>{title}</PageTitle>
                }
                {logout &&
                    <ButtonWithIcon onClick={logout} text="Sair">
                        <Exit color={isEnterpriseEnv ? '#438FCC' : '#1B9B9E'}/>
                    </ButtonWithIcon>
                }
                {isTablet ?
                    <PositionFilterMobile
                        currentValue={currentTab}
                        onChange={changeTab}
                        highlight
                        env={type}
                    />
                    :
                    <PositionFilter
                        changeTab={changeTab}
                        type={type}
                        currentTab={currentTab}
                    />
                }
            </div>
        </AccountCardContainer>
    )
}

PageHeader.propTypes = {
    title: string,
    logout: func,
    changeTab: func.isRequired,
    type: string.isRequired,
    currentTab: string.isRequired,
  };

export { PageHeader };