import { bool } from "prop-types";

import { Spinner } from "../../../../libs/icons";

import styles from "./styles.module.scss";

const MobileLoadingFeedback = ({ active }) => {
  return (
    <div
      className={[styles.wrapper, active && styles.active].join(" ")}
      data-testid="mobile-loading-feedback-wrapper"
    >
      <div className={styles.iconWrapper}>
        <Spinner color="#fff" />
      </div>
      <h1 className={styles.title}>Refinando sua busca</h1>
      <p className={styles.message}>Estamos filtrando seus resultados</p>
    </div>
  );
};

MobileLoadingFeedback.propTypes = {
  active: bool,
};

MobileLoadingFeedback.defaultProps = {
  active: false,
};

export default MobileLoadingFeedback;
