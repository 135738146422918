const Peek = ({ color, width, height }) => {
    return (
        <svg
            version="1.1"
            width={width}
            height={height}
            viewBox="0 0 34.0 34.0"
            xmlns="http://www.w3.org/2000/svg"
        >
            <defs>
                <clipPath id="i0">
                    <path d="M1440,0 L1440,1024 L0,1024 L0,0 L1440,0 Z"></path>
                </clipPath>
                <clipPath id="i1">
                    <path d="M1.41666667,0 C2.19907006,0 2.83333333,0.634263271 2.83333333,1.41666667 C2.83333333,2.19907006 2.19907006,2.83333333 1.41666667,2.83333333 C0.634263271,2.83333333 0,2.19907006 0,1.41666667 C0,0.634263271 0.634263271,0 1.41666667,0 Z"></path>
                </clipPath>
                <clipPath id="i2">
                    <path d="M1.41666667,0 C2.19907006,0 2.83333333,0.634263271 2.83333333,1.41666667 C2.83333333,2.19907006 2.19907006,2.83333333 1.41666667,2.83333333 C0.634263271,2.83333333 0,2.19907006 0,1.41666667 C0,0.634263271 0.634263271,0 1.41666667,0 Z"></path>
                </clipPath>
                <clipPath id="i3">
                    <path d="M1.41666667,0 C2.19907006,0 2.83333333,0.634263271 2.83333333,1.41666667 C2.83333333,2.19907006 2.19907006,2.83333333 1.41666667,2.83333333 C0.634263271,2.83333333 0,2.19907006 0,1.41666667 C0,0.634263271 0.634263271,0 1.41666667,0 Z"></path>
                </clipPath>
                <clipPath id="i4">
                    <path d="M6.23679965,2.42856191 C11.8740495,-1.3803907 19.4229292,-0.644173212 24.2180031,4.18220973 L24.2888365,4.21054306 C29.1152194,9.00561697 29.8514369,16.5544967 26.0424843,22.1917465 C22.2335317,27.8289964 14.9551098,29.9625792 8.70550313,27.2738764 C8.41950632,27.1532709 8.10700374,27.1093252 7.79883646,27.1463764 L1.73550313,28.3647097 L1.45216979,28.3647097 C1.06584127,28.3745532 0.692260013,28.2261442 0.418003126,27.9538764 C0.0816836865,27.6159027 -0.0620642203,27.131419 0.0355031259,26.6647097 L1.28216979,20.6722097 C1.33508696,20.3640837 1.29051455,20.0471244 1.15466979,19.7655431 C-1.53403305,13.5159364 0.599549781,6.23751453 6.23679965,2.42856191 Z M22.223994,6.17621884 C19.6429189,3.59514372 15.9766742,2.41522337 12.3746698,3.0063764 C8.94763145,3.5675581 5.96703301,5.66893523 4.2871224,8.70824717 C2.6072118,11.7475591 2.41343776,15.3892901 3.76133646,18.5897097 C4.11598819,19.3950983 4.21966351,20.2886809 4.05883646,21.1538764 L3.25133646,25.1488764 L7.24633646,24.3413764 C7.50839697,24.2960954 7.77373255,24.2724047 8.03966979,24.2705431 C8.64845521,24.275402 9.25027269,24.40058 9.81050313,24.6388764 C13.0109228,25.9867751 16.6526537,25.7930011 19.6919657,24.1130905 C22.7312776,22.4331799 24.8326548,19.4525814 25.3938365,16.0255431 C25.9849895,12.4235387 24.8050691,8.75729397 22.223994,6.17621884 Z"></path>
                </clipPath>
            </defs>
            <g transform="translate(-259.0 -555.0)">
                <g clipPath="url(#i0)">
                    <g transform="translate(250.0 223.0)">
                        <g transform="translate(9.0 329.0)">
                            <g transform="translate(0.0 3.0)">
                                <g transform="translate(15.58333333333334 15.58333333333334)">
                                    <g clipPath="url(#i1)">
                                        <polygon
                                            points="0,0 2.83333333,0 2.83333333,2.83333333 0,2.83333333 0,0"
                                            stroke="none"
                                            fill={color}
                                        ></polygon>
                                    </g>
                                </g>
                                <g transform="translate(21.25 15.58333333333334)">
                                    <g clipPath="url(#i2)">
                                        <polygon
                                            points="0,0 2.83333333,0 2.83333333,2.83333333 0,2.83333333 0,0"
                                            stroke="none"
                                            fill={color}
                                        ></polygon>
                                    </g>
                                </g>
                                <g transform="translate(9.916666666666664 15.58333333333334)">
                                    <g clipPath="url(#i3)">
                                        <polygon
                                            points="0,0 2.83333333,0 2.83333333,2.83333333 0,2.83333333 0,0"
                                            stroke="none"
                                            fill={color}
                                        ></polygon>
                                    </g>
                                </g>
                                <g transform="translate(2.7978302074709145 2.8019569366322106)">
                                    <g clipPath="url(#i4)">
                                        <polygon
                                            points="0,0 28.4710462,0 28.4710462,28.4285462 0,28.4285462 0,0"
                                            stroke="none"
                                            fill={color}
                                        ></polygon>
                                    </g>
                                    <path
                                        d="M24.2180031,4.18220973 C19.4229292,-0.644173212 11.8740495,-1.3803907 6.23679965,2.42856191 C0.599549781,6.23751453 -1.53403305,13.5159364 1.15466979,19.7655431 C1.29051455,20.0471244 1.33508696,20.3640837 1.28216979,20.6722097 L0.0355031259,26.6647097 C-0.0620642203,27.131419 0.0816836865,27.6159027 0.418003126,27.9538764 C0.692260013,28.2261442 1.06584127,28.3745532 1.45216979,28.3647097 L1.73550313,28.3647097 L7.79883646,27.1463764 C8.10700374,27.1093252 8.41950632,27.1532709 8.70550313,27.2738764 C14.9551098,29.9625792 22.2335317,27.8289964 26.0424843,22.1917465 C29.8514369,16.5544967 29.1152194,9.00561697 24.2888365,4.21054306 L24.2180031,4.18220973 Z M25.3938365,16.0255431 C24.8326548,19.4525814 22.7312776,22.4331799 19.6919657,24.1130905 C16.6526537,25.7930011 13.0109228,25.9867751 9.81050313,24.6388764 C9.25027269,24.40058 8.64845521,24.275402 8.03966979,24.2705431 C7.77373255,24.2724047 7.50839697,24.2960954 7.24633646,24.3413764 L3.25133646,25.1488764 L4.05883646,21.1538764 C4.21966351,20.2886809 4.11598819,19.3950983 3.76133646,18.5897097 C2.41343776,15.3892901 2.6072118,11.7475591 4.2871224,8.70824717 C5.96703301,5.66893523 8.94763145,3.5675581 12.3746698,3.0063764 C15.9766742,2.41522337 19.6429189,3.59514372 22.223994,6.17621884 C24.8050691,8.75729397 25.9849895,12.4235387 25.3938365,16.0255431 L25.3938365,16.0255431 Z"
                                        stroke={color}
                                        strokeWidth="0.8"
                                        fill="none"
                                        strokeMiterlimit="10"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

Peek.defaultProps = {
    width: '0px',
    height: '0px',
    color: '#fff',
};

export default Peek;
