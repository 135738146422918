import { node, oneOf } from "prop-types";

import styles from "./styles.module.scss";

const FieldsGrid = ({ children, size }) => {
  return (
    <div
      className={[styles.wrapper, size === "small" && styles.small].join(" ")}
    >
      {children}
    </div>
  );
};

FieldsGrid.propTypes = {
  size: oneOf([null, "small"]),
  children: node.isRequired,
};

FieldsGrid.defaultProps = {
  size: null,
};

export default FieldsGrid;
