export const HUNTING_BUTTON_TEXT = {
  expirada: "Expirada",
  cancelada: "Vaga cancelada",
  fechada: "Vaga fechada",
  reprovada: "Editar",
  aguardando_aprovacao: "Aguardando análise",
  aguardando_pagamento: "Aguardando pagamento",
  publicada: "Detalhes",
  reposicao: "Detalhes"
};

export const V3_BUTTON_TEXT = {
  publicada: "Visualizar",
  reposicao: "Visualizar",
  aguardando_aprovacao: "Em análise",
  aguardando_pagamento: "Pagar",
  pagamento_em_progresso: "Pagamento em progresso",
  reprovada: "Editar",
  expirada: "Expirada",
  cancelada: "Vaga cancelada",
  fechada: "Vaga fechada",
};

export const V3_BUTTON_LINK = {
  publicada: "/vaga",
  reposicao: "/vaga",
  aguardando_pagamento: "/recrutador/criar-vaga/pagamento",
  pagamento_em_progresso: "",
  reprovada: "/recrutador/criar-vaga/editar",
  aguardando_aprovacao: "",
  expirada: "",
  cancelada: "",
  fechada: "",
};

export const HUNTING_BUTTON_LINK = {
  reprovada: "/recrutador/editar-vaga",
  publicada: "/recrutador/visualizar-vaga"
}

export const V3_BUTTON_LINK_ENTERPRISE = {
  publicada: "/vaga",
  reposicao: "/vaga",
  aguardando_pagamento: "/para-empresas/recrutador/criar-vaga/pagamento",
  pagamento_em_progresso: "",
  reprovada: "/para-empresas/recrutador/criar-vaga/editar",
  aguardando_aprovacao: "",
  expirada: "",
  cancelada: "",
  fechada: "",
};

export const HUNTING_STATUSES = Object.keys(HUNTING_BUTTON_TEXT);

export const V3_STATUSES = Object.keys(V3_BUTTON_TEXT);
