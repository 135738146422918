import { useHistory } from "react-router-dom";

export const useRedirect = () => {
  const history = useHistory();
  const storagePath = "@user_redirect";

  const setRedirect = ({ path }) => {
    localStorage.setItem(storagePath, path);
  };

  const loginAndRedirect = ({ path }) => {
    setRedirect({ path });
    history.push("/autenticacao/login");
  };

  const getRedirect = () => {
    const redirectPath = localStorage.getItem(storagePath);

    if (!redirectPath) {
      return null;
    }

    return redirectPath;
  };

  const clearRedirect = () => {
    localStorage.removeItem(storagePath);
  };

  return {
    setRedirect,
    loginAndRedirect,
    getRedirect,
    clearRedirect,
  };
};
