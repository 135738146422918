import React from "react";
import { string } from "prop-types";

const Payment = ({ color }) => {
    return (
        <svg width="30px" height="16px" viewBox="0 0 30 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="⬅️➡️🖥️-04-fluxo:cadastro-vagas(Desktop)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="11-Minhas-vagas-cadastro-pagamento-preenchido-Copy" transform="translate(-288.000000, -624.000000)" fill={color}>
                    <g id="content-miolo" transform="translate(175.000000, 344.000000)">
                        <g id="fields" transform="translate(40.000000, 40.000000)">
                            <g id="cadastrar-cta" transform="translate(0.000000, 220.000000)">
                                <g id="content" transform="translate(73.000000, 18.000000)">
                                    <g id="Group-3" transform="translate(0.000000, 2.000000)">
                                        <rect id="Rectangle" x="0" y="0" width="3" height="16"></rect>
                                        <rect id="Rectangle-Copy" x="5" y="0" width="2" height="16"></rect>
                                        <rect id="Rectangle-Copy-2" x="12" y="0" width="3" height="16"></rect>
                                        <rect id="Rectangle-Copy-3" x="22" y="0" width="4" height="16"></rect>
                                        <rect id="Rectangle-Copy-4" x="8" y="0" width="2" height="16"></rect>
                                        <rect id="Rectangle-Copy-6" x="18" y="0" width="2" height="16"></rect>
                                        <rect id="Rectangle-Copy-8" x="28" y="0" width="2" height="16"></rect>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

Payment.propTypes = {
    color: string,
};

Payment.defaultProps = {
    color: "#1B9B9E",
};

export default Payment;
