import React from "react";
import { node, string, func, bool } from "prop-types";
import { Link, useLocation } from "react-router-dom";

import styles from "./styles.module.scss";

const MenuLink = ({ to, href, handleClose, children, blank, testid }) => {
  const { pathname } = useLocation();

  if (href) {
    return (
      <a
        onClick={handleClose}
        className={styles.link}
        href={href}
        target="_blank"
        rel="noreferrer"
        data-testid={testid}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      onClick={handleClose}
      className={[styles.link, pathname === to && styles.active].join(" ")}
      to={to}
      target={blank ? "_blank" : "_self"}
      data-testid={testid}
    >
      {children}
    </Link>
  );
};

MenuLink.propTypes = {
  blank: bool,
  children: node.isRequired,
  handleClose: func.isRequired,
  href: string,
  to: string,
  testid: string,
};

MenuLink.defaultProps = {
  blank: false,
  href: null,
  to: null,
  testid: "menu-link",
};

export default MenuLink;
