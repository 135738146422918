import { node } from "prop-types";

import styles from "./styles.module.scss";

const FormSectionTitle = ({ children }) => {
  return <h2 className={styles.title} data-testid="fst-title">{children}</h2>;
};

FormSectionTitle.propTypes = {
  children: node.isRequired,
};

export { FormSectionTitle };
