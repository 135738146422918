import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_INKLUA_API_URL,
  timeout: 20000,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    if (!config.useCredentials) {
      const apiKey = process.env.REACT_APP_INKLUA_API_KEY

      return {
        ...config,
        headers: { ...config.headers, "api-key": apiKey }
      };
    }

    const userToken = localStorage.getItem("@user_token");

    return {
      ...config,
      headers: { ...config.headers, Authorization: `Bearer ${userToken}` },
    };
  },
  (error) => {
    console.log("### error", error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.code === "ECONNABORTED") {
      return Promise.reject({
        error: true,
        message: "Serviço indisponível",
        code: 503,
      });
    }

    const { status, statusText, data } = error.response;

    if (error.response.status === 401 || error.response.status === 498) {
      console.log("AXIOS INSTANCE ERROR RESPONSE STATUS 401");
      localStorage.removeItem("@user_inkoins");
      localStorage.removeItem("@user_token");
      localStorage.removeItem("@user_data");
      localStorage.removeItem("@user_shares");

      if (window.location.pathname !== "/autenticacao/login") {
        window.location.href = "/autenticacao/login";
      }
      return Promise.reject({ error: true, status, statusText, data });;
    }

    return Promise.reject({ error: true, status, statusText, data });
  }
);

export default axiosInstance;
