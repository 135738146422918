export const formatPhone = (value) => {
  const onlyNumbers = value.match(/[0-9]/g).join("");
  const extraDigit = onlyNumbers.length > 10 ? 7 : 6;
  const locationCode = onlyNumbers.slice(0, 2);

  return `(${locationCode}) ${onlyNumbers.slice(
    2,
    extraDigit
  )}-${onlyNumbers.slice(extraDigit, 11)}`;
};

export const onlyNumbers = (value) => {
  const defaultResult = "";

  if (!value || value === "") {
    return defaultResult;
  }

  if (!value.match(/[0-9]/g) || !value.match(/[0-9]/g).length) {
    return defaultResult;
  }

  return value.match(/[0-9]/g).join("");
};
