import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import SquareButton from "../../../../components/SquareButton";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import useAxios from "../../../../hooks/useAxios";
import { focusElement, toDate } from "../../../../helpers";

import { SectionTitle } from "../../../Account/components/SectionTitle";
import { useUserWorkExperience } from "../../../Account/hooks/useUserWorkExperience";
import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";
import {
  ExperienceList,
  ExperienceListItem,
} from "../../../Account/components/ExperienceListItem";

import styles from "./styles.module.scss";
import { isUserFullySignedUp } from "../../../Account/hooks/isUserFullySignedUp";
import { useSnackBarContext } from "../../../../contexts/SnackBar";
import { Modal } from "../../../../components/Modal";

const CandidateExperiences = () => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const { exec, loading: isAxiosLoading } = useAxios();
  const { experiences, loading, removeItem, fetchExperiences } = useUserWorkExperience();
  const { handleUserAccessPage, checkUserStatusAndRedirect } = isUserFullySignedUp();
  const { showSnackBar } = useSnackBarContext();

  const handleAddExperience = () => {
    history.push("/autenticacao/cadastro/candidato/experiencia/adicionar");
  };

  const handleLogout = () => {
    localStorage.removeItem("@user_data");
    localStorage.removeItem("@user_token");
    localStorage.removeItem("@user_inkoins");
    localStorage.removeItem("@user_shares");
    localStorage.removeItem("@user_redirect");

    history.push("/");
  };

  const doRedirect = async () => {
    try {
      const response = await exec({
        useCredentials: true,
        method: "GET",
        url: "/candidate/experience",
      });

      if (response.error) {
        showSnackBar({
          timeout: 5000,
          text: response.error,
          error: true
        });
        handleLogout();
        return;
      }

      if (response.user.status) {
        localStorage.setItem("@user_data", JSON.stringify(response.user));
        handleUserAccessPage();
      }

    } catch (error) {
      console.log({ error });
    }
  };

  const skipStepAndProceed = async () => {
    try {
      const response = await exec({
        useCredentials: true,
        method: "POST",
        url: "/candidate/experience",
        data: {
          no_work_registered: true,
        },
      });

      if (response.error) {
        showSnackBar({
          timeout: 5000,
          text: response.error,
          error: true
        });
        handleLogout();
        return;
      }

      if (response.user.status) {
        localStorage.setItem("@user_data", JSON.stringify(response.user));
        handleUserAccessPage();
      }

    } catch (error) {
      console.log({ error });
    }
  };

  const goToNextStep = async () => {
    try {
      const response = await exec({
        useCredentials: true,
        method: "PUT",
        url: "/candidate/experience",
        data: {
          first_job: true,
        },
      });

      if (response.error) return;

      if (response.user.status === "pending-degrees") {
        localStorage.setItem("@user_data", JSON.stringify(response.user));
        doRedirect();
      }

    } catch (error) {
      console.log({ error });
    }
  };

  const editItem = ({ id }) => {
    history.push(`/autenticacao/cadastro/candidato/experiencia/editar/${id}`);
  };

  useEffect(() => {
    checkUserStatusAndRedirect()
    focusElement('[data-testid="account-section-title"]');
    fetchExperiences();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <AuthenticationWrapper>
      <SectionTitle>Experiência</SectionTitle>
      {Boolean(experiences.length) ? (
        <>
          <ExperienceList>
            {experiences.map((item) => (
              <ExperienceListItem
                disableActions={loading}
                key={`experience-item-${item.id}`}
                company={item.company}
                role={item.role}
                description={item.description}
                start_date={
                  item.start_date ? toDate(item.start_date, "MM/YYYY") : null
                }
                end_date={
                  item.end_date ? toDate(item.end_date, "MM/YYYY") : null
                }
                handleRemove={() => removeItem({ id: item.id })}
                handleEdit={() => editItem({ id: item.id, type: item.type })}
              />
            ))}
          </ExperienceList>
          <SquareButton
            text={window.innerWidth <= 480 ? `Cadastrar mais experiências` : `Cadastrar mais uma experiência`}
            onClick={handleAddExperience}
            disabled={isAxiosLoading}
            outlined
          />
          <SquareButton
            text="Próxima etapa"
            onClick={doRedirect}
            disabled={isAxiosLoading}
          />
        </>
      ) : (
        <div className={styles.form}>
          <p tabIndex="0">
            Adicione as suas últimas experiências ou aquelas que você acreditar
            serem mais relevantes.
          </p>
          <SquareButton
            text="Será meu primeiro emprego"
            onClick={goToNextStep}
            outlined
            disabled={isAxiosLoading}
          />
          <SquareButton
            text="Cadastrar experiência"
            onClick={handleAddExperience}
            disabled={isAxiosLoading}
          />
          <SquareButton
            simple
            text="Preencher depois"
            onClick={() => setOpenModal(true)}
            disabled={isAxiosLoading}
          />
        </div>
      )}
      <Modal
        handleClose={() => setOpenModal(false)}
        visible={openModal}
        showCloseButton={false}
        className={styles.modalWrapper}
      >
        <div className={styles.modal}>
          <p>
            Candidatos que cadastram suas experiências são <strong>mais vistos</strong> e <strong>mais contactados</strong>.
            Tem certeza que não vai cadastrar?
          </p>
          <SquareButton
            text="Cadastrar minha experiência"
            onClick={() => {
              setOpenModal(false);
              handleAddExperience();
            }}
            disabled={isAxiosLoading}
            outlined
            secondaryClass={styles.modalSubmitButton}
          />
          <SquareButton
            simple
            text="Vou pular e deixar incompleto"
            onClick={() => {
              setOpenModal(false);
              skipStepAndProceed();
            }}
            disabled={isAxiosLoading}
            secondaryClass={styles.modalSkipButton}
          />
        </div>
      </Modal>
    </AuthenticationWrapper>
  );
};

export { CandidateExperiences };
