import { useEffect, useState } from "react";

import useAxios from "../../../../hooks/useAxios";

export const useUserEducation = () => {
  const { exec, loading } = useAxios();
  const [courses, setCourses] = useState([]);

  const fetchCourses = async () => {
    try {
      const result = await exec({
        method: "GET",
        url: `/candidate/education`,
        useCredentials: true,
      });

      setCourses(result.filter((c) => c.type !== "language" || !c.type));
    } catch (error) {
      console.error("[INKLUA_API_ERROR]", { error });
    }
  };

  const removeCourse = async ({ id }) => {
    try {
      const response = await exec({
        method: "DELETE",
        url: `/candidate/education/${id}`,
        useCredentials: true,
      });

      if (!response.error) {
        await fetchCourses();
      }
    } catch (error) {
      console.error("[INKLUA_API_ERROR]", { error });
    }
  };

  const addCourse = async (data) => {
    try {
      const response = await exec({
        useCredentials: true,
        method: "POST",
        url: "/candidate/education",
        data,
      });

      if (response.error) {
        return false;
      }

      fetchCourses();

      return response;
    } catch (error) {
      console.error("[INKLUA_API_ERROR]", { error });
    }
  };

  useEffect(() => {
    fetchCourses();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    addCourse,
    removeCourse,
    courses,
  };
};
