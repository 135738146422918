export const englishLevels = [
  {
    name: "Não requer inglês",
    constant: "NOT_REQUIRED",
    value: 1,
  },
  {
    name: "Técnico",
    constant: "TECHNICAL",
    value: 2,
  },
  {
    name: "Básico",
    constant: "BASIC",
    value: 3,
  },
  {
    name: "Intermediário",
    constant: "INTERMEDIATE",
    value: 4,
  },
  {
    name: "Avançado",
    constant: "ADVANCED",
    value: 5,
  },
  {
    name: "Fluente",
    constant: "FLUENT",
    value: 6,
  },
  {
    name: "Nativo",
    constant: "NATIVE",
    value: 7,
  },
];

export const candidateEnglishLevels = [
  {
    name: "Desconhece",
    value: 1,
    slug: "none",
  },
  {
    name: "Técnico",
    value: 2,
    slug: "technical",
  },
  {
    name: "Básico",
    value: 3,
    slug: "basic",
  },
  {
    name: "Intermediário",
    value: 4,
    slug: "intermediate",
  },
  {
    name: "Avançado",
    value: 5,
    slug: "advanced",
  },
  {
    name: "Fluente",
    value: 6,
    slug: "fluent",
  },
  {
    name: "Nativo",
    value: 7,
    slug: "native",
  },
];
