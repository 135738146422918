import styles from "./styles.module.scss";
import SectionTitle from "../../../components/SectionTitle";
import ContentLoader from "react-content-loader";
import useEnterPriseEnvCheck from "../../../hooks/useEnterpriseEnvCheck";

const Skeleton = (props) => {
    return (
        <div className={styles.skeletonWrapper}>
            <ContentLoader
                {...props}
            >
                <rect x="0" y="0" rx="3" ry="3" width={props.width} height="183" />
            </ContentLoader>
        </div>
    );
};

const SearchBlockLoader = ({ isEnterpriseEnv }) => {
    return (
        <div className={styles.searchBlock}>
            <SectionTitle
                children="Encontre <b>o profissional certo</b> para você"
                extraStyle={[
                    styles.name,
                    isEnterpriseEnv && styles.secondaryLayout
                ].join(" ")}
                linebreak={false}
                level="p"
            />
            <div className={styles.searchInputs}>
                <Skeleton
                    speed={1}
                    width={288}
                    height={80}
                    viewBox="0 0 288 80"
                    backgroundColor="#EDF1F7"
                    foregroundColor="#f7f9fc"
                />
                <Skeleton
                    speed={1}
                    width={235}
                    height={80}
                    viewBox="0 0 235 80"
                    backgroundColor="#EDF1F7"
                    foregroundColor="#f7f9fc"
                />
                <Skeleton
                    speed={1}
                    width={235}
                    height={80}
                    viewBox="0 0 235 80"
                    backgroundColor="#EDF1F7"
                    foregroundColor="#f7f9fc"
                />
                <Skeleton
                    speed={1}
                    width={288}
                    height={56}
                    viewBox="0 0 288 56"
                    backgroundColor="#EDF1F7"
                    foregroundColor="#f7f9fc"
                />
            </div>
        </div>
    )
};

const SearchBlockLoaderMob = () => {
    return (
        <div className={styles.searchBlockMob}>
            <Skeleton
                speed={1}
                width={288}
                height={56}
                viewBox="0 0 288 56"
                backgroundColor="#EDF1F7"
                foregroundColor="#f7f9fc"
            />
        </div>
    )
};

const FiltersBlockWrapper = () => {
    let items = [];
    for (let count = 0; count < 5; count++) {
        items.push(
            <div className={styles.singleFilterWrapper} key={count}>
                <Skeleton
                    speed={1}
                    width={164}
                    height={34}
                    viewBox="0 0 164 18"
                    backgroundColor="#EDF1F7"
                    foregroundColor="#f7f9fc"
                />
                <Skeleton
                    speed={1}
                    width={120}
                    height={34}
                    viewBox="0 0 120 22"
                    backgroundColor="#EDF1F7"
                    foregroundColor="#f7f9fc"
                />
                <Skeleton
                    speed={1}
                    width={120}
                    height={34}
                    viewBox="0 0 120 22"
                    backgroundColor="#EDF1F7"
                    foregroundColor="#f7f9fc"
                />
            </div>
        )
    };
    return (
        <div className={styles.filtersWrapper}>
            <Skeleton
                speed={1}
                width={150}
                height={24}
                viewBox="0 0 150 24"
                backgroundColor="#EDF1F7"
                foregroundColor="#f7f9fc"
            />
            {items}
        </div>
    )
};

const JobListWrapper = ({ isMobile }) => {
    let items = [];
    for (let count = 0; count < 5; count++) {
        if (isMobile) {
            items.push(
                <div className={styles.singleJobWrapper} key={count}>
                    <Skeleton
                        speed={1}
                        width={300}
                        height={42}
                        viewBox="0 0 300 18"
                        backgroundColor="#EDF1F7"
                        foregroundColor="#f7f9fc"
                    />
                    <div className={styles.filterDescription}>
                        <div className={styles.filterDetails}>
                            <Skeleton
                                speed={1}
                                width={190}
                                height={34}
                                viewBox="0 0 190 22"
                                backgroundColor="#EDF1F7"
                                foregroundColor="#f7f9fc"
                            />
                            <Skeleton
                                speed={1}
                                width={150}
                                height={34}
                                viewBox="0 0 150 22"
                                backgroundColor="#EDF1F7"
                                foregroundColor="#f7f9fc"
                            />
                        </div>
                    </div>
                </div>
            )
        } else {
            items.push(
                <div className={styles.singleJobWrapper} key={count}>
                    <Skeleton
                        speed={1}
                        width={300}
                        height={42}
                        viewBox="0 0 300 18"
                        backgroundColor="#EDF1F7"
                        foregroundColor="#f7f9fc"
                    />
                    <div className={styles.filterDescription}>
                        <div className={styles.filterDetails}>
                            <Skeleton
                                speed={1}
                                width={190}
                                height={34}
                                viewBox="0 0 190 22"
                                backgroundColor="#EDF1F7"
                                foregroundColor="#f7f9fc"
                            />
                            <Skeleton
                                speed={1}
                                width={150}
                                height={34}
                                viewBox="0 0 150 22"
                                backgroundColor="#EDF1F7"
                                foregroundColor="#f7f9fc"
                            />
                        </div>
                        <Skeleton
                            speed={1}
                            width={167}
                            height={56}
                            viewBox="0 0 167 56"
                            backgroundColor="#EDF1F7"
                            foregroundColor="#f7f9fc"
                        />
                    </div>
                </div>
            )
        }
    }
    return (
        <div className={styles.jobListWrapper}>
            {items}
        </div>
    )
};

const JobListWrapperMob = ({ isMobile }) => {
    let items = [];
    for (let count = 0; count < 5; count++) {
        items.push(
            <div className={styles.singleJobWrapper} key={count}>
                <Skeleton
                    speed={1}
                    width={300}
                    height={42}
                    viewBox="0 0 300 18"
                    backgroundColor="#EDF1F7"
                    foregroundColor="#f7f9fc"
                />
                <div className={styles.filterDescription}>
                    <div className={styles.filterDetails}>
                        <Skeleton
                            speed={1}
                            width={190}
                            height={34}
                            viewBox="0 0 190 22"
                            backgroundColor="#EDF1F7"
                            foregroundColor="#f7f9fc"
                        />
                        <Skeleton
                            speed={1}
                            width={150}
                            height={34}
                            viewBox="0 0 150 22"
                            backgroundColor="#EDF1F7"
                            foregroundColor="#f7f9fc"
                        />
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className={styles.jobListWrapper}>
            {items}
        </div>
    )
};

const Loader = ({ isMobile }) => {
    const { isEnterpriseEnv } = useEnterPriseEnvCheck();

    return (
        <div className={styles.wrapper}>
            <SectionTitle
                children="<b>Mapeamento</b> Tech"
                extraStyle={[
                    styles.title,
                    isEnterpriseEnv && styles.secondaryLayout
                ].join(" ")}
                linebreak={false}
                level="h2"
            />
            <p className={styles.description}>
                Profissionais da área de tecnologia
            </p>
            {isMobile ? (
                <>
                    <SearchBlockLoaderMob />
                    <JobListWrapperMob />
                </>
            ) : (
                <div className={styles.filters}>
                    <SearchBlockLoader isEnterpriseEnv={isEnterpriseEnv} />
                    <div className={styles.content}>
                        <FiltersBlockWrapper />
                        <JobListWrapper />
                    </div>
                </div>
            )}
        </div>
    )
}

export default Loader;