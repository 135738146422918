import { useRef, useState, useEffect } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

import {
  FileInput,
  errorParser,
  Input,
  Radio,
} from "../../../../components/NewInput";
import { InputLabel } from "../../../../components/InputLabel";
import SquareButton from "../../../../components/SquareButton";
import SelectInput from "../../../../components/SelectInput";
import { disability } from "../../../../libs/data";
import validationDictionary from "../../../../constants/validationDictionary";
import { SectionTitle } from "../../../Account/components/SectionTitle";
import useAxios from "../../../../hooks/useAxios";
import { focusElement } from "../../../../helpers";

import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";

import styles from "./styles.module.scss";
import { isUserFullySignedUp } from "../../../Account/hooks/isUserFullySignedUp";
import { useSnackBarContext } from "../../../../contexts/SnackBar";

const disabilityTypes = ["with-disability", "without-disability"];

const CandidateDisability = () => {
  const { exec, loading } = useAxios();
  const history = useHistory();
  const [type, handleTypeChange] = useState(disabilityTypes[1]);
  const [selectedDisability, setDisability] = useState(null);
  const formRef = useRef();
  const { handleUserAccessPage } = isUserFullySignedUp();
  const { showSnackBar } = useSnackBarContext();
  const [candidateDisabilityDescription, setCandidateDisabilityDescription] = useState("");
  const remainingCharacters = 500 - candidateDisabilityDescription.length;

  const handleLogout = () => {
    localStorage.removeItem("@user_data");
    localStorage.removeItem("@user_token");
    localStorage.removeItem("@user_inkoins");
    localStorage.removeItem("@user_shares");
    localStorage.removeItem("@user_redirect");

    history.push("/");
  };

  const handleSubmit = async (data) => {
    if (type === disabilityTypes[1] && data.type !== null) {
      const response = await exec({
        useCredentials: true,
        method: "PUT",
        url: "/candidate/disability",
        data: {
          disability: false,
        },
      });

      if (response.error) {
        showSnackBar({
          timeout: 5000,
          text: response.error,
          error: true
        });
        handleLogout();
        return;
      }

      if (response.user.status) {
        localStorage.setItem("@user_data", JSON.stringify(response.user));
        handleUserAccessPage();
      }

      return;
    }

    try {
      data.disability = true;
      data.disability_type = selectedDisability;

      const schema = Yup.object().shape({
        disability_type: Yup.number()
          .oneOf(
            disability.map((dis) => dis.value),
            validationDictionary.INVALID_DISABILITY
          )
          .required(validationDictionary.INVALID_DISABILITY),
        type: Yup
          .string()
          .required(validationDictionary.GENERIC_REQUIRED_FIELD)
          .nullable(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await exec({
        useCredentials: true,
        method: "PUT",
        url: "/candidate/disability",
        data,
      });

      if (response.error) {
        showSnackBar({
          timeout: 5000,
          text: response.error,
          error: true
        });
        handleLogout();
        return;
      }

      if (response.user.status) {
        localStorage.setItem("@user_data", JSON.stringify(response.user));
        handleUserAccessPage();
      }

    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        const parsedErrors = errorParser(validationErrors);

        formRef.current.setErrors(parsedErrors);
      }
    }
  };

  useEffect(() => {
    focusElement('[data-testid="account-section-title"]');
    // handleUserAccessPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthenticationWrapper>
      <SectionTitle>Deficiência</SectionTitle>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        className={styles.form}
      >
        <span>
          <InputLabel label="Você possui alguma deficiência?" name="type" />
          <Radio
            name="type"
            options={[
              { id: disabilityTypes[1], label: "Não", checked: false },
              { id: disabilityTypes[0], label: "Sim", checked: false },
            ]}
            onChange={handleTypeChange}
            displayError
          />
        </span>
        {type === disabilityTypes[0] && (
          <>
            <SelectInput
              name="disability_type"
              label="Qual o tipo de deficiência?"
              placeholder="Selecione sua deficiência"
              setter={setDisability}
              keys={{ label: "label", value: "value" }}
              options={disability}
            />
            <div>
              <Input
                label="Detalhes"
                name="details"
                placeholder="Conte para nós sobre sua deficiência, do que você precisa para realizar suas atividades, se precisa de alguma tecnologia ou de algum acessório no seu dia a dia."
                type="textarea"
                disabled={loading}
                enableFlag
                value={candidateDisabilityDescription}
                onChange={(e) => {
                  if (e.target.value.length > 500) return;

                  setCandidateDisabilityDescription(e.target.value);
                }}
              />
              <span
                style={{
                  color: remainingCharacters <= 50 ? "#ff0000" : "#000000",
                }}
              >
                {remainingCharacters} caracteres restantes
              </span>
            </div>
            <FileInput
              name="report"
              label="Anexar laudo"
              placeholder="Clique aqui para anexar o laudo"
              suffix="Formatos: DOC, DOCX, PDF, JPG, JPEG e PNG."
              validExtensions={["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "pdf", "jpeg", "jpg", "png"]}
              enableFlag
            />
          </>
        )}
        <SquareButton
          disabled={loading}
          text="Próxima etapa"
          type="submit"
        />
      </Form>
    </AuthenticationWrapper>
  );
};

export { CandidateDisability };
