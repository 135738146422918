const disability = [
  { value: 3, label: "Auditiva" },
  { value: 4, label: "Intelectual" },
  { value: 1, label: "Física" },
  { value: 5, label: "Psicossocial" },
  { value: 6, label: "Reabilitado" },
  { value: 2, label: "Visual" },
];

export default disability;
