import React from "react";
import { Link } from "react-router-dom";

import Config from "../../../../config";
import SocialMediaLink from "../../../../components/SocialMediaLink";
import { Instagram, Facebook, LinkedIn } from "../../../../libs/icons";

import Logo from "./logo-inklua-white.svg";
import styles from "./styles.module.scss";

const EnterpriseFooter = () => {
  const userData = localStorage.getItem("@user_data")
    ? JSON.parse(localStorage.getItem("@user_data"))
    : undefined;
  const hasUserToken = localStorage.getItem("@user_token");

  const getMyAccountPath = () => {
    if (!hasUserToken) {
      return "/autenticacao/login";
    }

    if (userData?.type === "PJ" && !userData.inkluer) {
      return "/para-empresas/minha-conta";
    }

    if (userData?.type === "PF") {
      return "/candidato/minha-conta";
    }
  };

  const renderMyPositionsButton = () => {
    if (userData?.type === "PJ") {
      if (userData.inkluer) {
        return (
          <li>
            <a
              href="/recrutador/minhas-vagas"
              target="_blank"
              rel="noreferrer"
            >
              Minhas vagas
            </a>
          </li>
        )
      }

      if (userData.profile === 'leader') {
        return (
          <li>
            <a
              href="/processos/recrutando"
              target="_blank"
              rel="noreferrer"
            >
              Meu escritório
            </a>
          </li>
        )
      }

      return (
        <li>
          <Link to="/para-empresas/recrutador/minhas-vagas">Minhas vagas</Link>
        </li>
      )
    }
    return null;
  };

  const renderMyAccountButton = () => {
    if (userData?.type === "PJ" && userData?.inkluer) {
      return (
        <a
          href="/recrutador/minha-conta"
          target="_blank"
          rel="noreferrer"
        >
          Minha Conta
        </a>
      )
    }

    return (
      <Link to={getMyAccountPath()}>Minha Conta</Link>
    )
  }

  return (
    <footer className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.firstColumn}>
          <Link to="/para-empresas">
            <img
              src={Logo}
              alt="Inklua"
              className={styles.logo}
            />
          </Link>
          <p className={styles.credits}>
            inklua.com © 2022
            <br />
            Todos os direitos reservados
          </p>
        </div>
        <div className={styles.secondColumn}>
          <ul className={styles.menu}>
            <li>
              <Link to="/para-empresas">Pagina inicial</Link>
            </li>
            <li>
              <Link to="/para-empresas/mapeamento-tech">Mapeamento Tech</Link>
            </li>
            <li>
              {renderMyAccountButton()}
            </li>
            {renderMyPositionsButton()}
            <li>
              <Link to="/para-empresas/contato">Contato</Link>
            </li>
            <li>
              <Link to="/para-empresas/palestras">
                Palestras
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <div className={styles.sociaMediaWrapper}>
            <SocialMediaLink url={Config.INSTAGRAM_URL} color='#326B99'>
              <Instagram color="#fff" />
            </SocialMediaLink>
            <SocialMediaLink url={Config.FACEBOOK_URL} color='#326B99'>
              <Facebook color="#fff" />
            </SocialMediaLink>
            <SocialMediaLink url={Config.LINKEDIN_URL} color='#326B99'>
              <LinkedIn color="#fff" />
            </SocialMediaLink>
          </div>
          <p className={styles.links}>
            <Link to="/para-empresas/politica-de-privacidade">
              Termos e condições de uso
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default EnterpriseFooter;
