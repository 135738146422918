import React from "react";
import { string } from "prop-types";

const CrossedEye = ({ color }) => {
  return (
    <svg
      width="20px"
      height="18px"
      viewBox="0 0 20 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <rect
          id="path-crossed-eye-1"
          x="24"
          y="24"
          width="302"
          height="490"
          rx="20"
        ></rect>
        <filter
          x="-25.2%"
          y="-15.1%"
          width="150.3%"
          height="131.0%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="25"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0.593891531   0 0 0 0 0.593891531   0 0 0 0 0.593891531  0 0 0 0.249480988 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter2"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="25"
            in="shadowOffsetOuter2"
            result="shadowBlurOuter2"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0.105882353   0 0 0 0 0.607843137   0 0 0 0 0.619607843  0 0 0 0.249535621 0"
            type="matrix"
            in="shadowBlurOuter2"
            result="shadowMatrixOuter2"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
          </feMerge>
        </filter>
      </defs>
      <g
        id="⬅️➡️📱02-fluxo:alterando-senha(MobileView)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="05-altere-sua-senha"
          transform="translate(-320.000000, -475.000000)"
        >
          <g id="bg" transform="translate(32.000000, 190.000000)">
            <rect
              id="bg-solid"
              fill="transparent"
              x="0"
              y="0"
              width="350"
              height="530"
              rx="20"
            ></rect>
          </g>
          <g id="content" transform="translate(56.000000, 222.000000)">
            <g
              id="form-state:default-nova-senha"
              transform="translate(0.000000, 210.000000)"
            >
              <g id="form-body" transform="translate(0.000000, 24.000000)">
                <g id="bg-form">
                  <rect
                    id="bg"
                    fill="transparent"
                    x="0"
                    y="0"
                    width="302"
                    height="56"
                    rx="10"
                  ></rect>
                  <rect
                    id="stroke"
                    stroke={color}
                    x="0.5"
                    y="0.5"
                    width="301"
                    height="55"
                    rx="10"
                  ></rect>
                </g>
                <g
                  id="27)-Icon/alert-circle"
                  transform="translate(264.000000, 19.000000)"
                  fill={color}
                >
                  <path
                    d="M19.8676,9.4976 C19.4526,10.2206 18.4766,11.7386 16.9546,13.1266 L15.5436,11.7156 C16.6036,10.7646 17.3706,9.7186 17.8266,9.0046 C16.8946,7.5856 14.0716,3.8936 9.7806,4.0026 C9.1806,4.0176 8.6176,4.1116 8.0816,4.2536 L6.5016,2.6736 C7.4736,2.2896 8.5446,2.0336 9.7296,2.0036 C15.7046,1.8166 19.2296,7.3906 19.8676,8.5026 C20.0436,8.8106 20.0436,9.1896 19.8676,9.4976 L19.8676,9.4976 Z M11.9176,13.7466 L13.4976,15.3266 C12.5266,15.7106 11.4546,15.9666 10.2706,15.9966 C10.1886,15.9986 10.1066,15.9996 10.0246,15.9996 C4.1616,15.9996 0.7616,10.5946 0.1326,9.4976 C-0.0444,9.1896 -0.0444,8.8106 0.1326,8.5026 C0.5466,7.7796 1.5226,6.2616 3.0446,4.8726 L4.4556,6.2846 C3.3956,7.2356 2.6296,8.2816 2.1726,8.9956 C3.1046,10.4146 5.9146,14.0976 10.2196,13.9976 C10.8196,13.9826 11.3826,13.8886 11.9176,13.7466 L11.9176,13.7466 Z M9.9996,10.4996 C9.1726,10.4996 8.4996,9.8276 8.4996,8.9996 C8.4996,8.9746 8.5106,8.9516 8.5116,8.9256 L10.0746,10.4886 C10.0486,10.4896 10.0256,10.4996 9.9996,10.4996 L9.9996,10.4996 Z M2.7066,0.2926 C2.3166,-0.0974 1.6836,-0.0974 1.2926,0.2926 C0.9026,0.6836 0.9026,1.3166 1.2926,1.7066 L6.9236,7.3376 C6.6466,7.8456 6.4996,8.4106 6.4996,8.9996 C6.4996,10.9296 8.0706,12.4996 9.9996,12.4996 C10.5896,12.4996 11.1546,12.3526 11.6626,12.0766 L17.2926,17.7066 C17.4886,17.9026 17.7436,17.9996 17.9996,17.9996 C18.2556,17.9996 18.5116,17.9026 18.7066,17.7066 C19.0976,17.3166 19.0976,16.6836 18.7066,16.2926 L2.7066,0.2926 Z"
                    id="🎨-Icon-Сolor"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

CrossedEye.propTypes = {
  color: string,
};

CrossedEye.defaultProps = {
  color: "#1B9B9E",
};

export default CrossedEye;
