export function isValidDate(dateString) {
  const datePattern = /^(\d{2})\/(\d{2})\/(\d{4})$/;
  if (!datePattern.test(dateString)) {
    return false;
  }

  const [, day, month, year] = dateString.match(datePattern);

  const dayInt = parseInt(day, 10);
  const monthInt = parseInt(month, 10);
  const yearInt = parseInt(year, 10);

  const currentYear = new Date().getFullYear();
  if (yearInt < 1900 || yearInt > currentYear) {
    return false;
  }

  if (monthInt < 1 || monthInt > 12) {
    return false;
  }

  const daysInMonth = new Date(yearInt, monthInt, 0).getDate();
  if (dayInt < 1 || dayInt > daysInMonth) {
    return false;
  }

  return true;
}

export function isValidDateMMYYYY(dateString) {
  const datePattern = /^(0[1-9]|1[0-2])\/(19|20)\d{2}$/;

  return datePattern.test(dateString) && isValidDate(`01/${dateString}`);
}