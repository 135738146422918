import React, { useState, useEffect } from 'react';
import styles from "./styles.module.scss";
import CheckboxFilter from '../../../modules/Search/components/CheckboxFilter';
import {
    handleRemoteFilter,
    handleStateFilter,
    handleCityFilter,
    handlePaymentsFilter,
    handleLevelsFilter,
} from '../../../modules/Search/helpers/handleFilters';
import SearchSquareButton from '../../../modules/Search/components/SearchSquareButton';

const Filter = ({
    field,
    options,
    handleFilter,
    removeFilter,
    filtered,
    enterpriseVersion,
}) => {

    const [newFilter, setnewFilter] = useState(null);
    const [filterLenght, setFilterLenght] = useState(6);

    useEffect(() => {
        if (!field || !options) return;

        switch (field) {
            case "remote":
                const remote = handleRemoteFilter(options, filtered);
                setnewFilter(remote);
                break;
            case "states":
                const states = handleStateFilter(options, filtered);
                setnewFilter(states);
                break;
            case "city":
                const city = handleCityFilter(options, filtered);
                setnewFilter(city);
                break;
            case "payments":
                const payments = handlePaymentsFilter(options, filtered);
                setnewFilter(payments);
                break;
            case "levels":
                const levels = handleLevelsFilter(options, filtered);
                setnewFilter(levels);
                break;
            default:
                return;
        }
    }, [field, options, filtered]);

    if (!newFilter) return null;

    return (
        <div className={[
            styles.wrapper,
            enterpriseVersion && styles.secondaryLayout
        ].join(" ")}>
            <p className={styles.title}>
                {newFilter.name}
            </p>
            {
                newFilter.values.map((option, index) => {
                    if (index > filterLenght) return null;

                    if (index === filterLenght) {
                        return (
                            <SearchSquareButton
                                text="Mostrar mais"
                                onClick={() => setFilterLenght(prev => prev + 6)}
                                size="small"
                                name="cities-show-more"
                                key={`${field}-${option.value}`}
                            />
                        )
                    };
                    return (
                        <div className={styles.filterGroup} key={`${field}-${option.value}`}>
                            <CheckboxFilter
                                type="radio"
                                key={`${field}-${option.value}`}
                                label={option.name}
                                value={option.value}
                                field={option.field}
                                counter={option.counter}
                                onChange={option.isChecked ? removeFilter : handleFilter}
                                checked={option.isChecked}
                            />
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Filter;