import React from "react";

import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";
import ResendNewPassword from "../ResendNewPassword";

const ConfirmYourEmail = () => (
  <AuthenticationWrapper>
    <ResendNewPassword />
  </AuthenticationWrapper>
);
export default ConfirmYourEmail;
