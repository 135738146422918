import { Form } from "@unform/web";
import * as Yup from "yup";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { string, func } from 'prop-types';

import styles from './styles.module.scss';
import AccountCardContainer from "../../../Account/components/AccountCardContainer";
import Radio from "../../../../components/Radio";
import { NumberCC, ExpDateCC, CodeCC, Input, CustomerDocument, CEPCustomer } from "../../../../components/NewInput";
import { Toggler } from "../../../../components/Toggler";
import SquareButton from "../../../../components/SquareButton";
import useAxios from "../../../../hooks/useAxios";
import { ValidateCPF, ValidateCNPJ } from "../../../../helpers";
import { useSnackBarContext } from "../../../../contexts/SnackBar";
import { Payment } from "../../../../libs/icons";
import { FormSectionTitle } from "../FormSectionTitle";
import SelectInput from "../../../../components/SelectInput";
import states from "../../../../libs/data/states";
import useEnterPriseEnvCheck from "../../../../hooks/useEnterpriseEnvCheck";

const JobPayment = ({ goToNextStep, id }) => {
    const [paymentMethod, setPaymentMethod] = useState("boleto");
    const [boletoOptions, setBoletoOptions] = useState({ url: '', showBoleto: false });
    const [selectedState, setSelectedState] = useState(states.brazil[0].acronym);
    const { showSnackBar } = useSnackBarContext();
    const { exec, loading } = useAxios();
    const history = useHistory();
    const formRef = useRef();
    const { isEnterpriseEnv } = useEnterPriseEnvCheck();

    const createCCPayload = async (data) => {
        try {
            const schema = Yup.object().shape({
                card_number: Yup.string().required("Campo Obrigatório"),
                card_exp: Yup.string().required("Campo Obrigatório"),
                card_cvv: Yup.string().required("Campo Obrigatório"),
                card_holder_name: Yup.string().required("Campo Obrigatório"),
                customer_address_street: Yup.string().required("Campo Obrigatório"),
                customer_address_zip_code: Yup.string().required("Campo obrigatório"),
                customer_address_city: Yup.string().required("Campo Obrigatório"),
                customer_address_number: Yup.string().required("Campo Obrigatório"),
                customer_address_nh: Yup.string().required("Campo Obrigatório"),
                customer_document: Yup.string()
                    .test('test-cpf-and-cnpj', 'Digite um CPF/CNPJ válido', function () {
                        return data.customer_document.length > 11 ?
                            ValidateCNPJ(data.customer_document) : ValidateCPF(data.customer_document);
                    }).required()
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            let phone = JSON.parse(localStorage.getItem("@user_data")).phone;
            phone = phone.match(/[0-9]/g).join("");
            const area_code = phone.slice(0, 2);
            phone = phone.slice(2);

            let newData = {
                ...data,
                content_id: id,
                payment_method: paymentMethod,
                card_exp_month: (data.card_exp && data.card_exp.split("/")[0]) || '',
                card_exp_year: (data.card_exp && data.card_exp.split("/")[1]) || '',
                customer_address_line_1: data.customer_address_street
                    + ',' + data.customer_address_number,
                customer_address_line_2: data.customer_address_nh
                    + ',' + data.customer_address_city + ',' + selectedState,
                customer_address_state: selectedState,
                customer_address_country: 'BR',
                customer_document_type: data.customer_document.length > 11 ? 'CNPJ' : 'CPF',
                customer_phone_area_code: area_code,
                customer_phone_number: phone,
            };

            delete newData.card_holder_name_boleto;
            delete newData.customer_document_boleto;
            delete newData.card_exp;
            delete newData.customer_address_street;
            delete newData.customer_address_number;
            delete newData.customer_address_nh;

            return newData;

        } catch (err) {
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
        }
    };

    const createBoletoPayload = async (data) => {
        try {
            const schema = Yup.object().shape({
                card_holder_name_boleto: Yup.string().required("Campo Obrigatório"),
                customer_document_boleto: Yup.number()
                    .test('test-cpf-and-cnpj', 'Digite um CPF/CNPJ válido', function () {
                        return data.customer_document_boleto.length > 11 ?
                            ValidateCNPJ(data.customer_document_boleto) : ValidateCPF(data.customer_document_boleto);
                    }).required()
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            const { card_holder_name_boleto, customer_document_boleto } = data;

            const newPayload = {
                customer_document: customer_document_boleto,
                card_holder_name: card_holder_name_boleto,
                content_id: id,
                payment_method: paymentMethod,
            };

            return newPayload;

        } catch (err) {
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
        }
    };

    const handleSubmit = async (data) => {
        let payload;

        if (paymentMethod === 'credit_card') payload = await createCCPayload(data);
        else payload = await createBoletoPayload(data);

        const res = await exec({
            useCredentials: true,
            method: "POST",
            url: "/transaction",
            data: payload,
        });

        if (res.error) {
            const secondRes = await exec({
                useCredentials: true,
                method: "GET",
                url: `/transaction/order/paid?content_id=${id}`,
            });

            if (secondRes.paid === true) {
                return goToNextStep();
            }
            return showSnackBar({
                timeout: 5000,
                text: "Ocorreu um erro, revise os dados de pagamento e tente novamente.",
                error: true,
            });
        }

        if (res.data?.pagarme.status !== 'paid') {
            if (res.pagarme?.status === 'pending' && paymentMethod === 'boleto') {
                return setBoletoOptions({ url: res.url, showBoleto: true })
            } else {
                return showSnackBar({
                    timeout: 5000,
                    text: "Ocorreu um erro, revise os dados de pagamento e tente novamente.",
                    error: true,
                });
            }
        }
        return goToNextStep();
    };

    return (
        <AccountCardContainer>
            <h1>Pagamento</h1>
            <Form
                ref={formRef}
                onSubmit={handleSubmit}
                className={styles.wrapper}
            >
                <div className={styles.firstSection}>
                    <span>Forma de pagamento:</span>
                    <div className={styles.paymentMethod}>
                        <Radio
                            label="Cartão de crédito"
                            value="credit_card"
                            onChange={({ value }) => setPaymentMethod(value)}
                            checked={paymentMethod === 'credit_card'}
                        />
                        <Radio
                            label="Boleto"
                            value="boleto"
                            onChange={({ value }) => setPaymentMethod(value)}
                            checked={paymentMethod === 'boleto'}
                        />
                    </div>
                </div>
                <Toggler visible={paymentMethod === 'credit_card'}>
                    <div className={styles.secondSection}>
                        <div className={styles.creditCardDetails}>
                            <NumberCC
                                label="Número do cartão"
                                name="card_number"
                                placeholder="Digite o número do cartão"
                                type="text"
                            />
                            <ExpDateCC
                                label="Validade"
                                name="card_exp"
                                placeholder="MM/AA"
                                type="text"
                            />
                            <CodeCC
                                label="Código de segurança"
                                name="card_cvv"
                                placeholder="Digite o CVV"
                                type="text"
                            />
                        </div>
                        <div className={styles.customerDetails}>
                            <Input
                                label="Nome do titular do cartão"
                                name="card_holder_name"
                                placeholder="Digite o nome gravado no cartão"
                                type="text"
                            />
                            <CustomerDocument
                                label="CPF ou CNPJ do titular"
                                name="customer_document"
                                placeholder="Digite o CPF ou CNPJ do titular"
                                type="text"
                            />
                        </div>
                        <div className={styles.customerAddress}>
                            <FormSectionTitle>Dados de cobrança</FormSectionTitle>
                            <span>
                                Para concluir o pagamento com cartão de crédito
                                é necessário inserir os dados de cobrança
                                do titular do cartão.
                            </span>
                            <div className={styles.mainAddress}>
                                <Input
                                    label="Endereço"
                                    name="customer_address_street"
                                    placeholder="Digite o endereço"
                                    type="text"
                                />
                                <Input
                                    label="Número"
                                    name="customer_address_number"
                                    placeholder="Número"
                                    type="text"
                                />
                                <CEPCustomer
                                    label="CEP"
                                    name="customer_address_zip_code"
                                    placeholder="00000-000"
                                    type="text"
                                />
                            </div>
                            <div className={styles.addressDetails}>
                                <Input
                                    label="Bairro"
                                    name="customer_address_nh"
                                    placeholder="Digite o nome do bairro"
                                    type="text"
                                />
                                <Input
                                    label="Cidade"
                                    name="customer_address_city"
                                    placeholder="Digite o nome da cidade"
                                    type="text"
                                />
                                <SelectInput
                                    name="state"
                                    label="Estado"
                                    placeholder="Selecione o estado"
                                    setter={setSelectedState}
                                    keys={{ label: "name", value: "acronym" }}
                                    options={states.brazil}
                                />
                            </div>
                        </div>
                        <div className={styles.amount}>
                            <span><strong>Atenção: </strong>a Inklua não armazena os seus dados de pagamento.</span>
                            <div>
                                <label>Total: R$250,00 </label><span>em 1x no cartão</span>
                            </div>
                        </div>
                    </div>
                </Toggler>
                <Toggler visible={paymentMethod === 'boleto' && !boletoOptions.showBoleto}>
                    <div className={styles.thirdSection}>
                        <div className={styles.boletoDetails}>
                            <Input
                                label="Nome do responsável ou empresa"
                                name="card_holder_name_boleto"
                                placeholder="Digite o nome do responsável"
                                type="text"
                            />
                            <CustomerDocument
                                label="CPF do responsável ou CNPJ"
                                name="customer_document_boleto"
                                placeholder="Digite o CPF ou CNPJ"
                                type="text"
                            />
                        </div>
                        <div className={styles.amount}>
                            <span><strong>Atenção: </strong>a Inklua não armazena os seus dados de pagamento.</span>
                            <div>
                                <label>Total: R$250,00 </label><span>em 1x no cartão</span>
                            </div>
                        </div>
                    </div>
                </Toggler>
                <Toggler visible={boletoOptions.showBoleto}>
                    <div className={styles.fourthSection}>
                        <span>
                            <strong>Boleto gerado!</strong>
                            <br />
                            Agora é só clicar no botão abaixo e e realizar o pagamento.
                        </span>
                        <a
                            className={styles.boletoDownload}
                            href={boletoOptions.url}
                            target="_blank" rel="noreferrer"
                        >
                            <Payment />
                            <span>Visualizar boleto</span>
                        </a>
                    </div>
                </Toggler>
                <div className={styles.fifthSection}>
                    <SquareButton
                        simple
                        type="button"
                        text="Cancelar"
                        onClick={() => history.goBack()}
                    />
                    <SquareButton
                        type={boletoOptions.url ? "button" : "submit"}
                        text={boletoOptions.url ? "Continuar" : "Pagar"}
                        disabled={loading}
                        secondaryClass={isEnterpriseEnv ? styles.btnSecondaryLayout : ""}
                        onClick={() => {
                            if (boletoOptions.url) goToNextStep();
                        }}
                    />
                </div>
            </Form >
        </AccountCardContainer >
    )
}

JobPayment.propTypes = {
    goToNextStep: func.isRequired,
    id: string.isRequired,
};

export { JobPayment };