import { arrayOf, shape, string, node } from "prop-types";

import { NewInformationSummary } from "../../modules/Account/components/NewInformationSummary";
import { EducationSummary } from "../../modules/Recruiter/components/EducationSummary";

import styles from "./styles.module.scss";

const UserEducationSummary = ({ data, actionButton }) => {
  return (
    <NewInformationSummary title="Formação acadêmica">
      {Boolean(data.length) ? (
        <EducationSummary data={data} />
      ) : (
        <span>Sem formação cadastrada.</span>
      )}
      {actionButton && (
        <div className={styles.actionButtonWrapper}>{actionButton}</div>
      )}
    </NewInformationSummary>
  );
};

UserEducationSummary.propTypes = {
  data: arrayOf(
    shape({
      level_education: string.isRequired,
      course: string.isRequired,
      institute: string.isRequired,
      start_at: string.isRequired,
      end_at: string,
    })
  ),
  actionButton: node,
};

UserEducationSummary.defaultProps = {
  data: [],
  report: null,
};

export { UserEducationSummary };
