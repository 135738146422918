import { useState, useEffect } from "react";

const UserInfo = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  const handleLogout = () => {
    localStorage.removeItem("@user_inkoins");
    localStorage.removeItem("@user_token");
    localStorage.removeItem("@user_data");
    localStorage.removeItem("@user_shares");

    window.location.href = "/";
  };

  useEffect(() => {
    const userData = localStorage.getItem("@user_data");
    const userToken = localStorage.getItem("@user_token");

    if (userData || userToken) {
      const retrievedData = {};

      if (userData) {
        retrievedData.userData = JSON.parse(userData);
      }

      if (userToken) {
        retrievedData.userToken = userToken;
      }

      setData(retrievedData);
    }

    setIsLoading(false);
  }, []);

  if (!data && isLoading) {
    return (
      <p
        style={{
          margin: "50px auto 75px",
          maxWidth: "500px",
          textAlign: "center",
        }}
      >
        Carregando...
      </p>
    );
  }

  if (!data && !isLoading) {
    return (
      <div
        style={{
          margin: "50px auto 75px",
          maxWidth: "500px",
        }}
      >
        <p
          style={{
            textAlign: "center",
          }}
        >
          Nada para exibir. Por favor, se autentique e retorne para esta página
        </p>
        <button onClick={handleLogout}>Limpar Informações</button>
      </div>
    );
  }

  return (
    <div
      style={{
        margin: "50px auto 75px",
        maxWidth: "500px",
      }}
    >
      <h2>Suas informações de usuário</h2>
      <p>
        <b>Nome:</b> {data.userData.name}
        <br />
        <b>Sobrenome:</b> {data.userData.lastname}
        <br />
        <b>E-mail:</b> {data.userData.email}
      </p>
      <p>
        <b>O email foi verificado?</b>{" "}
        {data.userData.email_verified_at
          ? `Sim (${data.userData.email_verified_at})`
          : "Não"}
        <br />
      </p>
      <p>
        <b>Inkoins disponíveis:</b> {data.userData.wallet.coins}
        <br />
      </p>
      <pre
        style={{
          overflowX: "scroll",
          background: "rgba(0, 0, 0, 0.05)",
          border: "1px solid rgba(0, 0, 0, 0.1)",
          padding: "15px",
        }}
      >
        {JSON.stringify(data)}
      </pre>
      <button onClick={handleLogout}>Desconectar</button>
    </div>
  );
};

export default UserInfo;
