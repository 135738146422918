import React from 'react';
import styles from './styles.module.scss';
import { string } from "prop-types";

const Mention = ({ text, author }) => {
    return (
        <section className={styles.wrapper}>
            <p className={styles.text}>{text}</p>
            <span className={styles.author}>{author}</span>
        </section>
    )
}

Mention.propTypes = {
    author: string,
    text: string
};

Mention.defaultProps = {
    author: '',
    text: ''
};

export default Mention;