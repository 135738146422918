import React from "react";
import { string } from "prop-types";

const Plus = ({ color }) => {
  return (
    <svg
      width="22px"
      height="22px"
      viewBox="0 0 22 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      id="inklua-icon-plus"
    >
      <g
        id="🖥️-03-inklua-empresas-desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="01-inklua-empresas-desktop"
          transform="translate(-641.000000, -2288.000000)"
        >
          <g id="modulo-2" transform="translate(0.000000, 1824.000000)">
            <rect id="grid-944px" x="0" y="0" width="1440" height="800"></rect>
            <g
              id="linha-sensibilizacao-collapsed"
              transform="translate(174.000000, 455.000000)"
              fill={color}
            >
              <g
                id="27)-Icon/plus-circle"
                transform="translate(467.000000, 9.000000)"
              >
                <path
                  d="M12.3333333,9.66666667 L12.3333333,1.66666667 C12.3333333,0.929333333 11.736,0.333333333 11,0.333333333 C10.264,0.333333333 9.66666667,0.929333333 9.66666667,1.66666667 L9.66666667,9.66666667 L1.66666667,9.66666667 C0.930666667,9.66666667 0.333333333,10.2626667 0.333333333,11 C0.333333333,11.7373333 0.930666667,12.3333333 1.66666667,12.3333333 L9.66666667,12.3333333 L9.66666667,20.3333333 C9.66666667,21.0706667 10.264,21.6666667 11,21.6666667 C11.736,21.6666667 12.3333333,21.0706667 12.3333333,20.3333333 L12.3333333,12.3333333 L20.3333333,12.3333333 C21.0693333,12.3333333 21.6666667,11.7373333 21.6666667,11 C21.6666667,10.2626667 21.0693333,9.66666667 20.3333333,9.66666667 L12.3333333,9.66666667 Z"
                  id="🎨-Icon-Сolor"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Plus.propTypes = {
  color: string,
};

Plus.defaultProps = {
  color: "#1B9B9E",
};

export default Plus;
