import React from "react";
import { string, oneOf, bool } from "prop-types";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";

const SquareLinkButton = ({ text, url, size, type, inline, rel, dataTest }) => {
  if (rel === "external" || rel === "noreferrer") {
    return (
      <a
        href={url}
        className={[
          styles.url,
          size === "small" ? styles.small : styles.medium,
          type === "fill" ? styles.fill : "",
          type === "outline" ? styles.outline : "",
          type === "ghost" ? styles.ghost : "",
          inline && styles.inline,
        ].join(" ")}
        rel="noreferrer"
        target="_blank"
        data-test={dataTest}
      >
        {text}
      </a>
    );
  }

  return (
    <Link
      to={url}
      className={[
        styles.url,
        size === "small" ? styles.small : styles.medium,
        type === "fill" ? styles.fill : "",
        type === "outline" ? styles.outline : "",
        type === "ghost" ? styles.ghost : "",
        inline && styles.inline,
      ].join(" ")}
      rel={rel}
      data-test={dataTest}
    >
      {text}
    </Link>
  );
};

SquareLinkButton.propTypes = {
  text: string.isRequired,
  url: string.isRequired,
  size: oneOf(["small", "medium"]),
  type: oneOf(["fill", "outline", "ghost"]),
  rel: oneOf(["external", "next", "noreferrer"]),
  inline: bool,
  dataTest: string,
};

SquareLinkButton.defaultProps = {
  size: "medium",
  type: "fill",
  inline: false,
  rel: "next",
  dataTest: "square-link-button",
};

export default SquareLinkButton;
