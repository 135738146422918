import React, { forwardRef } from "react";
import { oneOf } from "prop-types";

import ContactForm from "../../components/ContactForm";
import SectionTitle from "../../components/SectionTitle";

import Background from "./background.svg";
import styles from "./styles.module.scss";


const SectionContactForm = forwardRef(({ type }, ref) => {
  return (
    <section
      style={{ backgroundImage: `url(${Background})` }}
      className={styles.wrapper}
      ref={ref}
    >
      <SectionTitle
        level="h2"
        children="Fale com a <b>Inklua</b>"
        linebreak={false}
      />
      <div className={styles.formWrapper}>
        <ContactForm type={type} />
      </div>
    </section>
  );
});

ContactForm.propTypes = {
  type: oneOf(["default", "simple"]),
};

ContactForm.defaultProps = {
  type: "default",
};

export default SectionContactForm;
