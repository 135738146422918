export const isExtensionValid = (fileNameWithExtension, validExtensions) => {
  if (!fileNameWithExtension || !validExtensions) {
    return false;
  }

  const normalizedFileName = fileNameWithExtension.toLowerCase();

  const matches = validExtensions.filter((extension) =>
    normalizedFileName.includes(extension.toLowerCase())
  );

  return Boolean(matches.length);
};
