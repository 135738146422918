import React from "react";
import { string, node } from "prop-types";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";

const IconLink = ({ children, text, to, color, href, dataTest }) => {

  if (href) {
    return (
      <a
        href={href}
        className={styles.button}
        id={`IconLink-${text}`}
        style={{ color }}
        target="_blank"
        rel="noreferrer"
        data-test={dataTest}
      >
        {children}
        <span className={styles.linkText}>{text}</span>
      </a>
    )
  }

  return (
    <Link
      to={to}
      className={styles.button}
      id={`IconLink-${text}`}
      data-test={dataTest}
      style={{ color }}
    >
      {children}
      <span className={styles.linkText}>{text}</span>
    </Link>
  );
};

IconLink.propTypes = {
  children: node.isRequired,
  text: string.isRequired,
  to: string,
  color: string,
  dataTest: string,
};

IconLink.defaultProps = {
  to: "/",
  color: "#1b9b9e",
  dataTest: "",
};

export default IconLink;
