import React from "react";
import styles from "./styles.module.scss";
import NewSkeleton from "../../../../../components/NewSkeleton";

const JobDetailsModal = () => (
  <div className={styles.wrapper}>
    <div className={styles.header}>
      <NewSkeleton
        speed={1}
        width={300}
        height={38}
        viewBox="0 0 300 38"
        backgroundColor="#edf1f7"
        foregroundColor="#f7f9fc"
      />
    </div>
    <div className={styles.pretension}>
      <NewSkeleton
        speed={1}
        width={196}
        height={30}
        viewBox="0 0 196 21"
        backgroundColor="#edf1f7"
        foregroundColor="#f7f9fc"
      />
      <NewSkeleton
        speed={1}
        width={196}
        height={30}
        viewBox="0 0 196 21"
        backgroundColor="#edf1f7"
        foregroundColor="#f7f9fc"
      />
    </div>
    <div className={styles.resume}>
      <NewSkeleton
        speed={1}
        width={300}
        height={30}
        viewBox="0 0 300 21"
        backgroundColor="#edf1f7"
        foregroundColor="#f7f9fc"
      />
      <NewSkeleton
        speed={1}
        width={300}
        height={30}
        viewBox="0 0 300 21"
        backgroundColor="#edf1f7"
        foregroundColor="#f7f9fc"
      />
      <NewSkeleton
        speed={1}
        width={300}
        height={30}
        viewBox="0 0 300 21"
        backgroundColor="#edf1f7"
        foregroundColor="#f7f9fc"
      />
      <NewSkeleton
        speed={1}
        width={300}
        height={30}
        viewBox="0 0 300 21"
        backgroundColor="#edf1f7"
        foregroundColor="#f7f9fc"
      />
      <NewSkeleton
        speed={1}
        width={300}
        height={30}
        viewBox="0 0 300 21"
        backgroundColor="#edf1f7"
        foregroundColor="#f7f9fc"
      />
      <NewSkeleton
        speed={1}
        width={300}
        height={30}
        viewBox="0 0 300 21"
        backgroundColor="#edf1f7"
        foregroundColor="#f7f9fc"
      />
    </div>
  </div>
);

export default JobDetailsModal;
