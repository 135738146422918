import React, { useState } from "react";
import { node } from "prop-types";

import ModalContext from "./ModalContext";

const ModalProvider = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  const [url, setUrl] = useState(null);
  const [title, setTitle] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [hasInkoins, setHasInkoins] = useState(false);

  const value = {
    isOpen,
    setOpen,
    url,
    setUrl,
    title,
    setTitle,
    itemId,
    setItemId,
    hasInkoins,
    setHasInkoins,
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: node.isRequired,
};

export default ModalProvider;
