import React from 'react';
import styles from "./styles.module.scss";
import Check from '../../libs/icons/Check';
import SectionTitle from '../SectionTitle';
import Footer from './Footer';
import SquareButton from '../../components/SquareButton';
import Close from '../../libs/icons/Close';
import NewSkeleton from '../NewSkeleton';

const JobDetailsModal = ({ details, setOpenDetails, loading = true }) => {

    if (loading) {
        return (
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <NewSkeleton
                        speed={1}
                        width={300}
                        height={38}
                        viewBox="0 0 300 38"
                        backgroundColor="#edf1f7"
                        foregroundColor="#f7f9fc"
                    />
                </div>
                <div className={styles.pretension}>
                    <NewSkeleton
                        speed={1}
                        width={196}
                        height={30}
                        viewBox="0 0 196 21"
                        backgroundColor="#edf1f7"
                        foregroundColor="#f7f9fc"
                    />
                    <NewSkeleton
                        speed={1}
                        width={196}
                        height={30}
                        viewBox="0 0 196 21"
                        backgroundColor="#edf1f7"
                        foregroundColor="#f7f9fc"
                    />
                </div>
                <div className={styles.resume}>
                    <NewSkeleton
                        speed={1}
                        width={300}
                        height={30}
                        viewBox="0 0 300 21"
                        backgroundColor="#edf1f7"
                        foregroundColor="#f7f9fc"
                    />
                    <NewSkeleton
                        speed={1}
                        width={300}
                        height={30}
                        viewBox="0 0 300 21"
                        backgroundColor="#edf1f7"
                        foregroundColor="#f7f9fc"
                    />
                    <NewSkeleton
                        speed={1}
                        width={300}
                        height={30}
                        viewBox="0 0 300 21"
                        backgroundColor="#edf1f7"
                        foregroundColor="#f7f9fc"
                    />
                    <NewSkeleton
                        speed={1}
                        width={300}
                        height={30}
                        viewBox="0 0 300 21"
                        backgroundColor="#edf1f7"
                        foregroundColor="#f7f9fc"
                    />
                    <NewSkeleton
                        speed={1}
                        width={300}
                        height={30}
                        viewBox="0 0 300 21"
                        backgroundColor="#edf1f7"
                        foregroundColor="#f7f9fc"
                    />
                    <NewSkeleton
                        speed={1}
                        width={300}
                        height={30}
                        viewBox="0 0 300 21"
                        backgroundColor="#edf1f7"
                        foregroundColor="#f7f9fc"
                    />
                </div>
            </div >
        )
    }

    const {
        title,
        payment,
        city,
        description,
        updated_at,
        gid,
    } = details;

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <SectionTitle
                    children={title}
                    level="span"
                    secondaryClass={styles.title}
                />
                <button
                    type="button"
                    onClick={setOpenDetails}
                    className={styles.closeButton}>
                    <Close
                        width="40px"
                        height="40px"
                        color="#1B9B9E" />
                </button>
            </div>
            <div className={styles.pretension}>
                <span>Pretensão Salarial: <b>R$ {payment}</b></span>
                <span>Local: <b>{city}</b></span>
            </div>
            <div className={styles.resume}>
                <span>{description}</span>
            </div>
            <ul className={styles.skills}>
                {details.doctor_degree && <li><Check color="#2E3A59" height="9.7px" /><span>Doutorado:<b>{details.doctor_degree}</b></span></li>}
                {details.master_degree && <li><Check color="#2E3A59" height="9.7px" /><span>Mestrado:<b>{details.master_degree}</b></span></li>}
                {details.mba_degree && <li><Check color="#2E3A59" height="9.7px" /><span>MBA:<b>{details.mba_degree}</b></span></li>}
                {details.superior_degree && <li><Check color="#2E3A59" height="9.7px" /><span>Ensino Superior:<b>{details.superior_degree}</b></span></li>}
                {details.tecnical_degree && <li><Check color="#2E3A59" height="9.7px" /><span>Ensino Técnico:<b>{details.tecnical_degree}</b></span></li>}
                {details.english_level && <li><Check color="#2E3A59" height="9.7px" /><span>Inglês:<b>{details.english_level}</b></span></li>}
                {details.payment && <li><Check color="#2E3A59" height="9.7px" /><span>Pretensão salarial:<b>R$ {payment}</b></span></li>}
                {details.pcd_type_id && <li><Check color="#2E3A59" height="9.7px" /><span>Característica da deficiência:<b>{details.pcd_type_id}</b></span></li>}
                {details.CID && <li><Check color="#2E3A59" height="9.7px" /><span>CID:<b>{details.CID}</b></span></li>}
                {details.move_out && <li><Check color="#2E3A59" height="9.7px" /><span>{details.move_out}</span></li>}
            </ul>
            <div className={styles.updated}>
                <span>Atualizado em {updated_at.replace(" ", " às ")}</span>
            </div>
            <Footer id={gid} />
            <SquareButton
                text="Voltar"
                type="button"
                secondaryClass={styles.backButton}
                onClick={() => setOpenDetails(prev => !prev)}
            />
        </div >
    )
}

export default JobDetailsModal;