const config = {
  INSTAGRAM_URL: "https://www.instagram.com/inkluaoficial",
  FACEBOOK_URL: "https://www.facebook.com/Inklua",
  LINKEDIN_URL: "https://www.linkedin.com/company/inklua",
  PARA_EMPRESAS_URL: "https://para-empresas.inklua.com",
  INKOIN_AD_CLICK_VALUE: 300,
  INKOIN_SHARE_VALUE: 40,
  INKOIN_ANIMATION_INTERVAL: 500,
  FACEBOOK_APP_ID: "520550749274277",
  GOOGLE_APP_ID:
    "207959078161-emjm42sqcku2f7m6iufu4gvgnifue1ll.apps.googleusercontent.com",
};

export default config;
