import React from "react";

import DefineNewPasswordForm from "../../components/DefineNewPasswordForm";
import AuthenticationWrapper from "../../components/AuthenticationWrapper";

const DefineNewPassword = () => {
  return (
    <AuthenticationWrapper>
      <DefineNewPasswordForm />
    </AuthenticationWrapper>
  );
};

export default DefineNewPassword;
