import React from "react";
import { node, func, bool } from "prop-types";

import isMobile from "../../../../helpers/isMobile";
import { Plus } from "../../../../libs/icons";
import "../../../../libs/newIcons/style.scss";
import SearchSquareButton from "../SearchSquareButton";

import styles from "./styles.module.scss";

const SearchGrid = ({
  sidebar,
  content,
  activeFilters,
  setOpenState,
  open,
}) => {
  return (
    <section className={styles.wrapper} data-testid="search-grid-wrapper">
      <aside className={styles.sidebar} data-testid="search-grid-sidebar">
        {!open && isMobile() ? (
          <>
            <SearchSquareButton
              icon={<i className="icon-options-2-outline" />}
              text="Filtrar Resultados"
              onClick={() => setOpenState(true)}
              centered
              name="collapse-sidebar"
            />
            {activeFilters}
          </>
        ) : (
          <>
            <h2
              className={styles.sidebarTitle}
              data-testid="search-grid-sidebar-title"
              tabIndex="0"
            >
              {isMobile() ? "Selecione as opções" : "Filtrar"}
            </h2>
            {isMobile() && (
              <button
                data-testid="search-grid-close-button"
                className={styles.closeButton}
                onClick={() => setOpenState(false)}
              >
                <Plus />
                Fechar
              </button>
            )}
            {sidebar}
          </>
        )}
      </aside>
      <main className={styles.content} data-testid="search-grid-content">
        {content}
      </main>
    </section>
  );
};

SearchGrid.propTypes = {
  sidebar: node.isRequired,
  content: node.isRequired,
  activeFilters: node,
  setOpenState: func.isRequired,
  open: bool.isRequired,
};

export default SearchGrid;
