import React from "react";

import AuthenticationWrapper from "../../components/AuthenticationWrapper";
import ResendNewPassword from "../../components/ResendNewPassword";

const ConfirmYourEmail = () => (
  <AuthenticationWrapper>
    <ResendNewPassword />
  </AuthenticationWrapper>
);
export default ConfirmYourEmail;
