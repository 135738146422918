import React from "react";

import Position from "../../components/Position";
import Article from "../../components/Article";
import Advertising from "../../components/Advertising";
import ImageAdvertising from "../../components/ImageAdvertising";
import MultiplePositions from "../../components/MultiplePositions";

const ItemParser = ({ item }) => {
  if (item?.type === "position") {
    const parsedDescription = item?.description.slice(0, 200);

    return (
      <Position
        id={item?.id}
        image={item?.image}
        title={item?.title}
        company={item?.company}
        date={item?.date}
        description={parsedDescription}
        location={item?.location}
        contractType={{
          hybrid: Boolean(item?.hybrid),
          presential: Boolean(item?.presential),
          remote: Boolean(item?.remote),
        }}
        shares={item?.shares}
        altImage={item?.alt_image}
      />
    );
  }

  if (item?.type === "article") {
    return (
      <Article
        image={item?.image}
        title={item?.title}
        source={item?.source}
        date={item?.date}
        description={item?.description}
        url={item?.url}
        category={item?.category}
        id={item?.id}
        altImage={item?.alt_image}
      />
    );
  }

  if (item?.type === "advertising") {
    return (
      <Advertising
        image={item?.image}
        title={item?.title}
        description={item?.description}
        url={item?.url}
        hasInkoins={!item?.clicked}
        id={item?.id}
      />
    );
  }

  if (item?.type === "image") {
    return (
      <ImageAdvertising
        id={item?.id}
        description={item?.description}
        image={item?.image}
        url={item?.url}
        hasInkoins={!item?.clicked}
      />
    );
  }

  if (item?.type === "positions" || item?.type === "group") {
    return (
      <MultiplePositions
        title={item?.title}
        positions={item?.positions}
      />
    );
  }

  return null;
};

export default ItemParser;
