// TO-DO: Tabs to use on hunting mobile
export const TABS_TEXT_HUNTING = {
  ativas: "Ativas",
  em_espera: "Em espera",
  nao_ativas: "Não ativas",
};

export const TABS_TEXT_JOBS = {
  ativas: "Ativas",
  em_espera: "Em espera",
  nao_ativas: "Não ativas",
};

export const V3_STATUSES = Object.keys(TABS_TEXT_JOBS);

export const HUNTING_STATUSES = Object.keys(TABS_TEXT_HUNTING);
export const STATUSES_HUNTING = Object.keys(TABS_TEXT_HUNTING);

export const tabText = {
  ativas: "Ativas",
  em_espera: "Em espera",
  nao_ativas: "Não ativas",
};

export const statuses = Object.keys(tabText);
