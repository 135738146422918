import { func } from "prop-types";

import SquareButton from "../../../../components/SquareButton";

import styles from "./styles.module.scss";

const SearchPagination = ({ onClick }) => {
  return (
    <div className={styles.wrapper}>
      <SquareButton
        onClick={onClick}
        aria-disabled="false"
        text="Carregar mais"
      />
    </div>
  );
};

SearchPagination.propTypes = {
  onClick: func.isRequired,
};

export default SearchPagination;
