import React from "react";
import { string } from "prop-types";

const Linkedin = ({ color }) => {
  return (
    <svg
      width="15px"
      height="17px"
      viewBox="0 0 15 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      aria-label="Linkedin"
    >
      <g
        id="🖥️-03-buscando-uma-vaga-inklua-(DesktopView)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="01-home-inklua-desktop"
          transform="translate(-1283.000000, -2772.000000)"
        >
          <g id="footer" transform="translate(0.000000, 2694.000000)">
            <rect id="bg-footer" x="0" y="0" width="1440" height="224"></rect>
            <g id="btn-linkedin" transform="translate(1270.000000, 67.000000)">
              <g>
                <circle id="Oval" cx="20" cy="20" r="20"></circle>
                <g
                  id="27)-Icon/activity"
                  transform="translate(12.625000, 11.375000)"
                  fill={color}
                >
                  <path
                    d="M10.2307917,5.21909375 C12.8383438,5.21909375 14.9534479,7.45690625 14.9534479,10.2155 L14.9534479,10.2155 L14.9534479,15.2729219 C14.9534479,15.7000312 14.6273021,16.0472187 14.2260781,16.0472187 L14.2260781,16.0472187 L12.5315729,16.0472187 C12.130349,16.0472187 11.8042031,15.7000312 11.8042031,15.2729219 L11.8042031,15.2729219 L11.8042031,10.318625 C11.8042031,9.44292187 11.2124583,8.64628125 10.3938646,8.55948437 C9.45094792,8.4589375 8.65657292,9.23925 8.65657292,10.2155 L8.65657292,10.2155 L8.65657292,15.2729219 C8.65657292,15.7000312 8.33042708,16.0472187 7.92920313,16.0472187 L7.92920313,16.0472187 L6.23469792,16.0472187 C5.83347396,16.0472187 5.50732813,15.7000312 5.50732813,15.2729219 L5.50732813,15.2729219 L5.50732813,10.2155 C5.50732813,7.45690625 7.62243229,5.21909375 10.2307917,5.21909375 Z M3.32780208,5.99201563 C3.72902604,5.99201563 4.05436458,6.33834375 4.05436458,6.76545313 L4.05436458,6.76545313 L4.05436458,15.2732656 C4.05436458,15.700375 3.72902604,16.0467031 3.32780208,16.0467031 L3.32780208,16.0467031 L1.14892188,16.0467031 C0.747697917,16.0467031 0.421552083,15.700375 0.421552083,15.2732656 L0.421552083,15.2732656 L0.421552083,6.76545313 C0.421552083,6.33834375 0.747697917,5.99201563 1.14892188,5.99201563 L1.14892188,5.99201563 Z M2.23828125,0.578125 C3.2409375,0.578125 4.0546875,1.444375 4.0546875,2.51171875 C4.0546875,3.5790625 3.2409375,4.4453125 2.23828125,4.4453125 C1.235625,4.4453125 0.421875,3.5790625 0.421875,2.51171875 C0.421875,1.444375 1.235625,0.578125 2.23828125,0.578125 Z"
                    id="🎨-Icon-Сolor"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Linkedin.propTypes = {
  color: string,
};

Linkedin.defaultProps = {
  color: "#1B9B9E",
};

export default Linkedin;
