import { useEffect, useState } from "react";

const useCounter = () => {
  const [active, setActive] = useState(false);
  const [counter, setCount] = useState(0);

  const startCounter = (initialCounter) => {
    setActive(true);
    setCount(initialCounter);
  };

  useEffect(() => {
    if (counter <= 0) {
      setActive(false);
      return;
    }

    if (!active) {
      setCount(0);
      return;
    }

    const interval = setInterval(() => {
      setCount((counter) => counter - 1);
    }, [1000]);

    return () => clearInterval(interval);
  }, [active, counter]);

  return { active, counter, startCounter };
};

export default useCounter;
