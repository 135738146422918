import { string, oneOf } from "prop-types";

import {
  WarningTriangle,
  CheckWithCircle,
  ForbiddenCircle,
} from "../../../../libs/icons";

import styles from "./styles.module.scss";

export const statuses = {
  TAKEN: null,
  YOURS: "Candidato abordado por você",
  BLOCKED: "Não considerar este candidato",
  AVAILABLE: "",
};

const CandidateStatusToolTip = ({ status, recruiterName }) => {
  if (status === "AVAILABLE") {
    return null;
  }

  const renderIcon = ({ status }) => {
    if (status === "TAKEN") {
      return <WarningTriangle color="#fc846e" />;
    }

    if (status === "YOURS") {
      return <CheckWithCircle />;
    }

    if (status === "BLOCKED") {
      return <ForbiddenCircle />;
    }
  };

  const getStatus = ({ status, recruiterName }) => {
    if (status === "TAKEN") {
      return `Em processo com ${recruiterName}`;
    }

    return statuses[status];
  };

  return (
    <div className={styles.wrapper} data-testid="candidatestatus-wrapper">
      <span
        className={styles.status}
        data-testid="candidatestatus-icon-wrapper"
      >
        {renderIcon({ status })}
      </span>
      {status !== "available" && (
        <span className={styles.tooltip} data-testid="candidatestatus-tooltip">
          {getStatus({ status, recruiterName })}
        </span>
      )}
    </div>
  );
};

CandidateStatusToolTip.propTypes = {
  status: oneOf(["TAKEN", "YOURS", "BLOCKED", "AVAILABLE"]).isRequired,
  recruiterName: string,
};
CandidateStatusToolTip.defaultProps = {
  recruiterName: "",
};

export { CandidateStatusToolTip };
