import { formatCurrency } from "../../../../helpers";

export const handleRemoteFilter = (options, filtered) => {
  const name = "Modelo de trabalho";
  const isChecked = filtered.remote[0] || Object.keys(filtered.remote)[0];
  let values;

  if (!Array.isArray(options)) {
    values = Object.entries(options).map((value) => ({
      name: value[0] === 0 ? "Presencial" : "Remoto",
      field: `remote_${value[0]}`,
      value: value[0],
      counter: value[1],
      isChecked: isChecked === value[0],
    }));
    return { name, values };
  }

  values = options.map((value, index) => ({
    name: index === 0 ? "Presencial" : "Remoto",
    field: `remote_${index}`,
    value: index,
    counter: value,
    isChecked: Number(isChecked) === index,
  }));

  return { name, values };
};

export const handleStateFilter = (options, filtered) => {
  const name = "Estado";
  const isChecked = Object.keys(filtered.state_id)[0];

  const values = options.map((value) => {
    return {
      name: value.name,
      field: `state_id_${value.state_id}`,
      value: value.state_id,
      counter: value.total,
      isChecked: Number(isChecked) === value.state_id,
    };
  });
  return { name, values };
};

export const handleCityFilter = (options, filtered) => {
  const name = "Cidade";
  const isChecked = Object.keys(filtered.city)[0];

  const values = Object.entries(options).map((value) => {
    const formattedValue = value[0]
      .replaceAll(" ", "_")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    return {
      name: value[0],
      field: `city_${formattedValue}`,
      value: formattedValue,
      counter: value[1],
      isChecked: isChecked === formattedValue,
    };
  });
  return { name, values };
};

export const handlePaymentsFilter = (options, filtered) => {
  const name = "Salário";
  const isChecked = filtered.payment_max;
  const valuesReduced = Object.entries(options).filter((value) => value[1] > 0);

  const values = valuesReduced.map((value) => {
    return {
      name: `Até ${formatCurrency(value[0])}`,
      field: "payment_max",
      value: value[0],
      counter: value[1],
      isChecked: isChecked === value[0],
    };
  });
  return { name, values };
};

export const handleLevelsFilter = (options, filtered) => {
  const name = "Nível de inglês";
  const isChecked = Object.keys(filtered.english_level)[0];

  const values = options.map((value) => {
    return {
      name: value.level,
      field: `english_level_${value.english_level}`,
      value: value.english_level,
      counter: value.total,
      isChecked: Number(isChecked) === value.english_level,
    };
  });
  return { name, values };
};
