import React from "react";
import { string } from "prop-types";

const File = ({ color }) => {
  return (
    <svg
      width="28px"
      height="28px"
      viewBox="0 0 28 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="08-menu-candidato-logado"
          transform="translate(-48.000000, -371.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <g id="Group-3" transform="translate(48.000000, 244.000000)">
            <g id="menu-itens" transform="translate(0.000000, 126.000000)">
              <g
                id="file-add-outline"
                transform="translate(0.000000, 1.000000)"
              >
                <rect
                  id="Rectangle"
                  opacity="0"
                  x="0"
                  y="0"
                  width="28"
                  height="28"
                />
                <path
                  d="M23.03,9.71833333 L16.6833333,2.71833333 C16.4627643,2.47394065 16.1492079,2.33411146 15.82,2.3331231 L7.65333333,2.3331231 C6.87048644,2.32399552 6.11599922,2.62605335 5.5558785,3.17304625 C4.99575777,3.72003914 4.67589441,4.4671518 4.66666667,5.25 L4.66666667,22.75 C4.67589441,23.5328482 4.99575777,24.2799609 5.5558785,24.8269538 C6.11599922,25.3739466 6.87048644,25.6760045 7.65333333,25.6668769 L20.3466667,25.6668769 C21.1295136,25.6760045 21.8840008,25.3739466 22.4441215,24.8269538 C23.0042422,24.2799609 23.3241056,23.5328482 23.3333333,22.75 L23.3333333,10.5 C23.3325785,10.2108582 23.2244805,9.93229801 23.03,9.71833333 Z M16.3333333,5.83333333 L19.53,9.33333333 L17.1966667,9.33333333 C16.9498717,9.31843372 16.7194114,9.2050021 16.5570663,9.01852464 C16.3947212,8.83204718 16.3141059,8.58816226 16.3333333,8.34166667 L16.3333333,5.83333333 Z M20.3466667,23.3333333 L7.65333333,23.3333333 C7.48927558,23.3428833 7.3281646,23.2867331 7.20558058,23.1772831 C7.08299656,23.0678331 7.00902429,22.9140875 7,22.75 L7,5.25 C7.00902429,5.08591249 7.08299656,4.93216693 7.20558058,4.82271691 C7.3281646,4.7132669 7.48927558,4.65711669 7.65333333,4.66666667 L14,4.66666667 L14,8.34166667 C13.9607949,10.1306338 15.3729935,11.6157873 17.1616667,11.6666667 L21,11.6666667 L21,22.75 C20.9909757,22.9140875 20.9170034,23.0678331 20.7944194,23.1772831 C20.6718354,23.2867331 20.5107244,23.3428833 20.3466667,23.3333333 L20.3466667,23.3333333 Z"
                  id="Shape"
                  fill={color}
                />
                <path
                  d="M16.3333333,15.1666667 L15.1666667,15.1666667 L15.1666667,14 C15.1666667,13.3556678 14.6443322,12.8333333 14,12.8333333 C13.3556678,12.8333333 12.8333333,13.3556678 12.8333333,14 L12.8333333,15.1666667 L11.6666667,15.1666667 C11.0223345,15.1666667 10.5,15.6890011 10.5,16.3333333 C10.5,16.9776655 11.0223345,17.5 11.6666667,17.5 L12.8333333,17.5 L12.8333333,18.6666667 C12.8333333,19.3109989 13.3556678,19.8333333 14,19.8333333 C14.6443322,19.8333333 15.1666667,19.3109989 15.1666667,18.6666667 L15.1666667,17.5 L16.3333333,17.5 C16.9776655,17.5 17.5,16.9776655 17.5,16.3333333 C17.5,15.6890011 16.9776655,15.1666667 16.3333333,15.1666667 Z"
                  id="Path"
                  fill={color}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

File.propTypes = {
  color: string,
};

File.defaultProps = {
  color: "fff",
};

export default File;
