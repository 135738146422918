export const steps = [
    {
        name: "Cadastro de vaga",
    },
    {
        name: "Validação da Inklua",
    },
    {
        name: "Pagamento",
    },
    {
        name: "Publicação",
    },
];

export const stepsFirstAccess = [
    {
        name: "Cadastro de vaga",
    },
    {
        name: "Validação da Inklua",
    },
    {
        name: "Publicação",
    },
];

export const defaultValidation = "Em breve você receberá um e-mail sobre o próximo passo."

export const firstAccessValidation = "Em breve você receberá um e-mail sobre o próximo passo."