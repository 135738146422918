import React from "react";
import { string } from "prop-types";

import Image from "./assets/inkoin2x.png";

const Inkoin = ({ size }) => {
  return (
    <img
      src={Image}
      style={{ height: size, width: size }}
      aria-hidden="true"
      alt="Inkoin"
      data-testid="inkoin-icon"
    />
  );
};

Inkoin.propTypes = {
  size: string,
};

Inkoin.defaultProps = {
  size: "26px",
};

export default Inkoin;
