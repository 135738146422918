import React, { useEffect, useState } from "react";
import { string, number, shape, bool, oneOfType } from "prop-types";

import { useShareContext } from "../../../../contexts/Share";
import { useModalContext } from "../../../../contexts/Modal";
// import { Share, Inkoin } from "../../../../libs/icons";
import { Share } from "../../../../libs/icons";
import { getScreenSize, canRedeem } from "../../../../helpers";

import styles from "./styles.module.scss";

const ShareButton = ({ text, url, title, id, shares }) => {
  const isHandHeld = getScreenSize() !== "desktop";
  const { setOpen, setUrl, setTitle, setItemId, setHasInkoins } =
    useModalContext();
  const { items, registerShare } = useShareContext();
  const [isHovered, setHovered] = useState(false);
  const [showInkoins, setShowInkoins] = useState(false);

  const openModal = async () => {
    setUrl(url);
    setTitle(title);
    setOpen(true);
    setItemId(id);
    setHasInkoins(showInkoins);
  };

  useEffect(() => {
    if (shares) {
      registerShare(id, shares);
    }

    return () => {
      setUrl(null);
      setTitle(null);
      setItemId(null);
      setHasInkoins(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shares && canRedeem(items[id])) {
      setShowInkoins(true);
      return;
    }

    setShowInkoins(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <button
      className={[styles.button].join(" ")}
      onClick={openModal}
      type="button"
      aria-label={text}
      data-testid="share-button"
      onMouseEnter={() => !isHandHeld && setHovered(true)}
      onMouseLeave={() => !isHandHeld && setHovered(false)}
    >
      {text}
      <Share color={isHovered ? "#fff" : "#438FCC"} />
    </button>
  );
};

ShareButton.propTypes = {
  text: string,
  url: string.isRequired,
  title: string.isRequired,
  id: oneOfType([number, string]).isRequired,
  shares: shape({
    whatsapp: bool.isRequired,
    twitter: bool.isRequired,
    facebook: bool.isRequired,
    linkedin: bool.isRequired,
  }),
};

ShareButton.defaultProps = {
  text: "Compartilhar",
  shares: null,
};

export default ShareButton;
