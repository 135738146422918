import React from "react";
import { string } from "prop-types";

const Lock = ({ color }) => {
  return (
    <svg
      width="16px"
      height="20px"
      viewBox="0 0 16 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <rect
          id="path-lock-1"
          x="24"
          y="24"
          width="302"
          height="689"
          rx="20"
        ></rect>
        <filter
          x="-25.2%"
          y="-10.7%"
          width="150.3%"
          height="122.1%"
          filterUnits="objectBoundingBox"
          id="filter-2-lock"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="25"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0.593891531   0 0 0 0 0.593891531   0 0 0 0 0.593891531  0 0 0 0.249480988 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter2"
          ></feOffset>
          <feGaussianBlur
            stdDeviation="25"
            in="shadowOffsetOuter2"
            result="shadowBlurOuter2"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0.105882353   0 0 0 0 0.607843137   0 0 0 0 0.619607843  0 0 0 0.249535621 0"
            type="matrix"
            in="shadowBlurOuter2"
            result="shadowMatrixOuter2"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
          </feMerge>
        </filter>
        <path
          d="M8,14 C7.448,14 7,13.552 7,13 C7,12.448 7.448,12 8,12 C8.552,12 9,12.448 9,13 C9,13.552 8.552,14 8,14 M8,10 C6.346,10 5,11.346 5,13 C5,14.654 6.346,16 8,16 C9.654,16 11,14.654 11,13 C11,11.346 9.654,10 8,10 M14,17 C14,17.552 13.552,18 13,18 L3,18 C2.448,18 2,17.552 2,17 L2,9 C2,8.448 2.448,8 3,8 L4,8 L6,8 L10,8 L12,8 L13,8 C13.552,8 14,8.448 14,9 L14,17 Z M6,4.111 C6,2.947 6.897,2 8,2 C9.103,2 10,2.947 10,4.111 L10,6 L6,6 L6,4.111 Z M13,6 L12,6 L12,4.111 C12,1.845 10.206,0 8,0 C5.794,0 4,1.845 4,4.111 L4,6 L3,6 C1.346,6 0,7.346 0,9 L0,17 C0,18.654 1.346,20 3,20 L13,20 C14.654,20 16,18.654 16,17 L16,9 C16,7.346 14.654,6 13,6 L13,6 Z"
          id="path-lock-3-lock"
        ></path>
      </defs>
      <g
        // id="⬅️➡️📱01-fluxo:alterando-nome-e-sobrenome(MobileView)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          //   id="06-minha-conta-default-deleted-name"
          transform="translate(-322.000000, -576.000000)"
        >
          <g id="bg" transform="translate(32.000000, 190.000000)">
            <g id="bg-shadow">
              <use
                fill="black"
                fillOpacity="1"
                filter="url(#filter-2-lock)"
                xlinkHref="#path-lock-1"
              ></use>
              <use
                fill="#FFFFFF"
                fillRule="evenodd"
                xlinkHref="#path-lock-1"
              ></use>
            </g>
            <rect
              id="bg-solid"
              fill="#FFFFFF"
              x="0"
              y="0"
              width="350"
              height="729"
              rx="20"
            ></rect>
          </g>
          <g id="content" transform="translate(56.000000, 222.000000)">
            <g id="fields" transform="translate(0.000000, 104.000000)">
              <g id="form-email" transform="translate(0.000000, 208.000000)">
                <g id="form-body" transform="translate(0.000000, 24.000000)">
                  <g id="bg-form">
                    <rect
                      id="bg"
                      fill="#F7F9FC"
                      x="0"
                      y="0"
                      width="302"
                      height="56"
                      rx="10"
                    ></rect>
                    <rect
                      id="stroke"
                      stroke="#E4E9F2"
                      x="0.5"
                      y="0.5"
                      width="301"
                      height="55"
                      rx="10"
                    ></rect>
                  </g>
                  <g transform="translate(266.000000, 18.000000)">
                    <mask fill="white">
                      <use xlinkHref="#path-lock-3-lock"></use>
                    </mask>
                    <use fill={color} xlinkHref="#path-lock-3-lock"></use>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Lock.propTypes = {
  color: string,
};

Lock.defaultProps = {
  color: "#1B9B9E",
};

export default Lock;
