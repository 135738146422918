import React, { useRef, useEffect, useState } from "react";
import { useField } from "@unform/core";
import { string } from "prop-types";

import styles from "./styles.module.scss";

const ImageInput = ({ name, label, defaultValue, suffix, placeholder }) => {
  const inputRef = useRef(null);
  const [previewImage, setPreviewImage] = useState(null);
  const { fieldName, registerField, error } = useField(name);

  const handleChange = (event) => {
    const file = event.target.files[0];

    if (!file || !file.type.includes("image")) return;

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onloadend = function () {
      inputRef.current = {
        value: {
          base64: reader.result,
          extension: file.type.split("/")[1],
          url: defaultValue,
        },
      };

      setPreviewImage(reader.result);
    };
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return (
          ref?.current?.value || {
            base64: null,
            extension: null,
            url: defaultValue,
          }
        );
      },
    });
  }, [fieldName, registerField, defaultValue]);

  return (
    <fieldset className={styles.wrapper}>
      <label htmlFor={`image-field-${name}`} className={styles.label}>
        {label}
      </label>
      <div
        style={{ backgroundImage: `url(${previewImage || defaultValue})` }}
        className={styles.preview}
      >
        {Boolean(!previewImage && !defaultValue) && (
          <span className={styles.placeholder}>{placeholder}</span>
        )}
        <input
          type="file"
          name={name}
          encType="multipart/form-data"
          onChange={handleChange}
          className={styles.input}
          autoComplete="off"
        />
      </div>
      {suffix && <span className={styles.suffix}>{suffix}</span>}
      {error && (
        <span
          className={styles.errorMessage}
          id={`error_${name}`}
          data-testid={`error-message-${name}`}
        >
          Campo obrigatório
        </span>
      )}
    </fieldset>
  );
};

ImageInput.propTypes = {
  label: string.isRequired,
  name: string.isRequired,
  defaultValue: string,
  suffix: string,
  placeholder: string,
};

ImageInput.defaultProps = {
  defaultValue: null,
  suffix: null,
  placeholder: null,
};

export default ImageInput;
