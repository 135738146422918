import styles from "./styles.module.scss";
import Filter from "./Filter";
import TextButton from "../../modules/Search/components/TextButton";

const FiltersBlock = ({
    filters,
    onSubmit,
    filtered,
    setLoading,
    enterpriseVersion,
    clearPage
}) => {

    const handleFilter = ({ value, field }) => {
        setLoading(true);
        clearPage(1);
        onSubmit(prev => ({ ...prev, [field]: value }));
    };

    const removeFilter = ({ value, field }) => {
        setLoading(true);
        clearPage(1);
        onSubmit(prev => {
            delete prev[field]
            return { ...prev }
        });
    };

    return (
        <div className={[
            styles.wrapper,
            enterpriseVersion && styles.secondaryLayout
        ].join(" ")}>
            <h2 className={styles.title}>Filtrar</h2>
            {Object.entries(filters).map((filterGroup, index) => (
                    <Filter
                        key={`${filterGroup[0]}_${index}`}
                        field={filterGroup[0]}
                        options={filterGroup[1]}
                        handleFilter={handleFilter}
                        removeFilter={removeFilter}
                        filtered={filtered}
                        enterpriseVersion={enterpriseVersion}
                    />
                ))
            }
            <TextButton onClick={() => onSubmit({})}>
                Desfazer todos os filtros
            </TextButton>
        </div>
    )
}

export default FiltersBlock;