import React from "react";
import { node } from "prop-types";

import styles from "./styles.module.scss";

const SearchResultList = ({ children }) => {
  return (
    <div
      className={styles.wrapper}
      data-testid="search-result-list-wrapper"
    >
      {children}
    </div>
  );
};

SearchResultList.propTypes = {
  children: node.isRequired,
};

export default SearchResultList;
