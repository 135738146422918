import { useState } from "react";
import styles from "./styles.module.scss";
// import Filter from "./Filter";
// import TextButton from "../../modules/Search/components/TextButton";
import SquareButton from "../SquareButton";
import { Magnifier } from "../../libs/icons";

const FiltersBlockMobile = ({
  //   filters,
  //   onSubmit,
  //   filtered,
  //   setLoading,
  handleFiltereds,
  isMobile,
}) => {
  const [openFilters, setOpenFilters] = useState(false);

  // const handleFilter = ({ value, field }) => {
  //     setLoading(true);
  //     onSubmit(prev => ({ ...prev, [field]: value }));
  // };

  // const removeFilter = ({ value, field }) => {
  //     setLoading(true);
  //     onSubmit(prev => {
  //         delete prev[field]
  //         return { ...prev }
  //     });
  // };

  if (!isMobile) return null;

  return (
    !openFilters &&
    handleFiltereds() && (
      <div className={styles.wrapper}>
        <SquareButton
          text="Filtrar resultados"
          icon={<Magnifier color="#137784" />}
          type="button"
          outlined
          onClick={() => setOpenFilters((prev) => !prev)}
        />
      </div>
    )
  );

  // return (
  //     <div className={styles.wrapper}>
  //         <h2 className={styles.title}>Filtrar</h2>
  //         {
  //             Object.entries(filters).map((filterGroup, index) => (
  //                 <Filter
  //                     key={`${filterGroup[0]}_${index}`}
  //                     field={filterGroup[0]}
  //                     options={filterGroup[1]}
  //                     handleFilter={handleFilter}
  //                     removeFilter={removeFilter}
  //                     filtered={filtered}
  //                 />
  //             ))
  //         }
  //         <TextButton onClick={() => onSubmit({})}>
  //             Desfazer todos os filtros
  //         </TextButton>
  //     </div>
  // )
};

export default FiltersBlockMobile;
