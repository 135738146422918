import React from "react";
import { Link } from "react-router-dom";

import Config from "../../config";
import SocialMediaLink from "../SocialMediaLink";
import { Instagram, Facebook, LinkedIn } from "../../libs/icons";

import Logo from "./logo-inklua-white.svg";
import styles from "./styles.module.scss";

const InstitutionalFooter = () => (
  <footer className={styles.wrapper}>
    <div className={styles.container}>
      <div className={styles.firstColumn}>
        <Link to="/">
          <img src={Logo} alt="Inklua" className={styles.logo} />
        </Link>
      </div>
      <div className={styles.secondColumn}>
        <p className={styles.credits}>
          inklua.com © 2021
          <br />
          Todos os direitos reservados
        </p>
      </div>
      <div className={styles.sociaMediaWrapper}>
        <SocialMediaLink url={Config.INSTAGRAM_URL}>
          <Instagram color="#fff" />
        </SocialMediaLink>
        <SocialMediaLink url={Config.FACEBOOK_URL}>
          <Facebook color="#fff" />
        </SocialMediaLink>
        <SocialMediaLink url={Config.LINKEDIN_URL}>
          <LinkedIn color="#fff" />
        </SocialMediaLink>
      </div>
    </div>
  </footer>
);

export default InstitutionalFooter;
