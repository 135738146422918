import React, { forwardRef } from "react";
import { string, arrayOf, node, bool } from "prop-types";

import SectionTitle from "../../components/SectionTitle";
import SectionIntro from "../../components/SectionIntro";
import ResponsiveImage from "../../components/ResponsiveImage";

import styles from "./styles.module.scss";

const ModuleOne = forwardRef(
  ({ title, subtitle, description, image, inverse, action}, ref) => {
    const generateDescription = () => ({
      __html: description,
    });

    return (
      <section
        className={[styles.wrapper, inverse && styles.inverse].join(" ")}
        ref={ref}
      >
        <div>
          {title && <SectionIntro level="span" text={title} />}
          <SectionTitle level="h2" children={subtitle} linebreak={false} />
          <div
            className={styles.descriptionWrapper}
            dangerouslySetInnerHTML={generateDescription()}
          />
          <div className={styles.actionWrapper}>{action}</div>
        </div>
        <div>
          <ResponsiveImage
            className={styles.image}
            alt=""
            images={[image[0], image[1], image[2]]}
          />
        </div>
      </section>
    );
  }
);

ModuleOne.propTypes = {
  title: string,
  subtitle: string.isRequired,
  description: string.isRequired,
  image: arrayOf(node).isRequired,
  inverse: bool.isRequired,
  action: node,
};

ModuleOne.defaultProps = {
  title: null,
  inverse: false,
  action: null,
};

export default ModuleOne;
