import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";

import { Grid, Row, Column } from "../../components/Grid";
import ItemParser from "../../components/ItemParser";
import HeroSlider from "../../components/HeroSlider";
import PaginationWrapper from "../../components/PaginationWrapper";
import SkeletonList from "../../components/SkeletonList";
import SearchBlockWrapper from "../../components/SearchBlockWrapper";
import Separator from "../../components/Separator";
import useAxios from "../../hooks/useAxios";
import { isUserFullySignedUp } from "../../modules/Account/hooks/isUserFullySignedUp";

const MetaTags = () => (
  <Helmet>
    <title>Inklua</title>
    <meta property="og:type" content="website" />
    <meta property="og:description" content="Plataforma com conteúdo e vagas de emprego em todo o Brasil para pessoas com deficiência (PcD)." />
    <meta property="og:title" content="Inklua" />
    <meta property="og:locale" content="pt_BR" />
    <meta property="og:site_name" content="Inklua" />
    <meta property="og:image" content="https://s3.sa-east-1.amazonaws.com/public.inklua/logo_mini.png" />
    <meta property="og:image:width" content="1200" />
    <meta property="og:url" content="https://inklua.com/" />
    <meta name="robots" content="max-image-preview:large"></meta>
    <meta name="description" content="Plataforma com conteúdo e vagas de emprego em todo o Brasil para pessoas com deficiência (PcD)" />
    <meta name="keywords" content="vaga de emprego pcd, emprego para pcd, vaga para pcd, vaga pcd Brasil, oportunidade de emprego pcd, oportunidade, trabalho, recrutamento e seleção, cadastrar currículo, curriculo, Inklua, vagas na Inklua, inklua, empresa inklua, PcD." />
  </Helmet>
);

const Home = () => {
  const [contentData, setContentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { exec, loading } = useAxios();
  const { checkUserStatusAndRedirect } = isUserFullySignedUp();
  const searchBlockRef = useRef(null);

  const fetchData = async () => {
    try {
      const response = await exec({
        useCredentials: false, //Boolean(localStorage.getItem("@user_token")),
        method: "get",
        url: `home?page=${currentPage}`,
      });

      if (response.error) {
        return;
      }

      setContentData([...contentData, ...response.data]);
    } catch (e) {
      console.error(`INKLUA Home Page Catch Error: ${ e }`);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    checkUserStatusAndRedirect();

    const hash = window.location.hash;
    if (hash === '#vagas' && searchBlockRef.current) {
      searchBlockRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePagination = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <>
      <MetaTags />
      <HeroSlider />
      <SearchBlockWrapper
        type="home"
        ref={searchBlockRef}
      />
      <Separator />
      {!contentData.length && currentPage === 1 && (
        <Grid>
          <SkeletonList quantity={12} />
        </Grid>
      )}
      {Boolean(contentData.length) && (
        <Grid>
          <Row>
            {contentData.map((item) => (
              <Column key={`column_${item.id}`}>
                <ItemParser key={`item_${item.id}`} item={item} />
              </Column>
            ))}
          </Row>
          {loading && <SkeletonList quantity={6} />}
          <PaginationWrapper onPress={handlePagination} hidden={loading} />
        </Grid>
      )}
    </>
  );
};

export default Home;
