import React, { useRef } from "react";
import { Form } from "@unform/web";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";

import { Input } from "../../../../components/NewInput";
import { useUserContext } from "../../../../contexts/User";
import SquareButton from "../../components/SquareButton";
import SectionTitle from "../../components/SectionTitle";
import useAxios from "../../../../hooks/useAxios";
import validationDictionary from "../../../../constants/validationDictionary";

import FieldsGrid from "../../../authentication/components/FieldsGrid";

const DefineNewPasswordForm = () => {
  const formRef = useRef(null);
  const { exec, loading } = useAxios();
  const history = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const { updateInkoins } = useUserContext();

  const handleSubmit = async (data) => {
    try {
      const schema = Yup.object().shape({
        password: Yup.string()
          .min(8, validationDictionary.PASSWORD_MIN_CHARACTERS)
          .required(validationDictionary.INVALID_PASSWORD),
        password_confirmation: Yup.string().oneOf(
          [Yup.ref("password"), null],
          validationDictionary.PASSWORDS_DO_NOT_MATCH
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await exec({
        method: "POST",
        url: "/user/password/reset",
        data: {
          ...data,
          token: params.get("token"),
          email: params.get("email"),
        },
      });

      if (response.token && response.user && response.user.wallet.coins >= 0) {
        localStorage.removeItem("@temp_user_email");
        localStorage.setItem("@user_token", response.token);
        localStorage.setItem("@user_data", JSON.stringify(response.user));
        localStorage.setItem(
          "@user_inkoins",
          JSON.stringify(response.user.wallet.coins)
        );
        updateInkoins(response.user.wallet.coins);
        formRef.current.reset();
        history.push("/");
      }
    } catch (error) {
      const validationErrors = {};

      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit} id="contact-form">
      <SectionTitle
        small
        centered
        level="h2"
        children="Digite uma nova <b>senha para a conta</b>"
      />
      <FieldsGrid>
        <Input
          label="Senha"
          name="password"
          placeholder="Digite sua senha"
          type="password"
        />
        <Input
          label="Confirme a senha"
          name="password_confirmation"
          placeholder="Confirme sua senha"
          type="password"
        />
      </FieldsGrid>
      <FieldsGrid size="small">
        <SquareButton disabled={loading} text="Continuar" type="submit" />
      </FieldsGrid>
    </Form>
  );
};

export default DefineNewPasswordForm;
