import React from "react";
import { func, bool } from "prop-types";

import styles from "./styles.module.scss";

const MenuTrigger = ({ onClick, changeWidth }) => {
  return (
    <button
      type="button"
      className={styles.button}
      onClick={onClick}
      style={changeWidth ? { width: "38px" } : {}}
      data-testid="menu-button"
    >
      <i aria-hidden="true"></i>
      <i aria-hidden="true"></i>
      <i aria-hidden="true"></i>
      Menu
    </button>
  );
};

MenuTrigger.propTypes = {
  onClick: func.isRequired,
  changeWidth: bool,
};

export default MenuTrigger;
