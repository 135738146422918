import { func, string, bool } from "prop-types";
import useEnterPriseEnvCheck from "../../../../hooks/useEnterpriseEnvCheck";

import { Chevron } from "../../../../libs/icons";

import {
  TABS_TEXT_HUNTING,
  TABS_TEXT_JOBS,
  HUNTING_STATUSES,
  V3_STATUSES
} from "../PositionFilter/variables";

import styles from "./styles.module.scss";

const PositionFilterMobile = ({ currentValue, onChange, highlight, env }) => {
  const { isEnterpriseEnv } = useEnterPriseEnvCheck();

  const handleOnChange = (event) => {
    onChange(event.target.value);
  };

  const handleOptions = () => {
    if (env === 'minhas-vagas') {
      return V3_STATUSES.map((type) => (
        <option key={`option-${type}`} value={type}>
          {TABS_TEXT_JOBS[type]}
        </option>
      ))
    }
    return HUNTING_STATUSES.map((type) => (
      <option key={`option-${type}`} value={type}>
        {TABS_TEXT_HUNTING[type]}
      </option>
    ))
  };

  return (
    <div
      className={[
        styles.wrapper,
        highlight ? styles.highlighted : styles.notHighlighted,
        isEnterpriseEnv && styles.secondaryLayout,
      ].join(" ")}
    >
      <span className={styles.value} data-testid="current-position">{currentValue}</span>
      <Chevron color="#000" />
      <select onChange={handleOnChange} className={styles.input}>
        {handleOptions()}
      </select>
    </div>
  );
};

PositionFilterMobile.propTypes = {
  onChange: func.isRequired,
  currentValue: string.isRequired,
  highlight: bool,
  env: string,
};

PositionFilterMobile.defaultProps = {
  highlight: false,
  env: "",
};

export { PositionFilterMobile };
