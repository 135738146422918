import styles from "./styles.module.scss";
import Job from "./Job";
import SearchSquareButton from "../../modules/Search/components/SearchSquareButton";

const JobsList = ({ candidates, seeMore, enterpriseVersion, showButton }) => {
    if (!candidates.length) return <pre>Carregando...</pre>;

    return (
        <div
            id="joblist-container"
            className={[
                styles.wrapper,
                enterpriseVersion && styles.secondaryLayout].join(" ")}
        >
            {candidates.map((job, index) => (
                <Job
                    {...job}
                    key={index + job.gid}
                    enterpriseVersion={enterpriseVersion}
                />))}
            {candidates.length >= 10 && showButton &&
                <SearchSquareButton
                    text="Ver mais"
                    type="button"
                    onClick={seeMore}
                />
            }
        </div>
    )
}

export default JobsList;