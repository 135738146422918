import { oneOf, number, string, oneOfType } from "prop-types";
import { Link } from "react-router-dom";

import SalaryChip from "../../../../components/SalaryChip";
import LocationChip from "../../../../components/LocationChip";
import { toSlug } from "../../../../helpers";

import styles from "./styles.module.scss";
import {
  V3_BUTTON_TEXT,
  V3_STATUSES,
  V3_BUTTON_LINK,
  V3_BUTTON_LINK_ENTERPRISE,
  HUNTING_BUTTON_TEXT,
  HUNTING_STATUSES,
  HUNTING_BUTTON_LINK,
} from "./variables";

import useEnterPriseEnvCheck from "../../../../hooks/useEnterpriseEnvCheck";

const PositionItem = ({
  status,
  id,
  title,
  date,
  location,
  salary,
  subscribers,
  likes,
  type,
  link
}) => {
  const isPositionInvalid = status === "aguardando_aprovacao" || status === "fechada" || status === "cancelada" || status === "pagamento_em_progresso"

  const { isEnterpriseEnv } = useEnterPriseEnvCheck();

  const handleLinkButton = (currentStatus) => {
    let text = "";
    let basePath = "";

    if (type === "minhas-vagas") {
      if (isEnterpriseEnv) {
        basePath = V3_BUTTON_LINK_ENTERPRISE[currentStatus];
      } else {
        basePath = V3_BUTTON_LINK[currentStatus];
      }
      text = V3_BUTTON_TEXT[currentStatus];
    } else {
      text = HUNTING_BUTTON_TEXT[currentStatus];
      basePath = HUNTING_BUTTON_LINK[currentStatus];
    }

    return (
      <Link
        to={`${basePath}${link}`}
        aria-label={text}
        className={[
          styles.link,
          isPositionInvalid ? styles.invalid : styles.valid,
          isEnterpriseEnv && styles.secondaryLayout,
        ].join(" ")}
      >
        {text}
      </Link>
    );
  };

  return (
    <article className={styles.wrapper}>
      <div className={styles.details}>
        <h1 className={styles.title} data-testid="position-item-title">
          {title}
        </h1>
        <div className={styles.dateAndSubscribers}>
          {Boolean(date) && (
            <span className={styles.date}>Publicada em: {date}</span>
          )}
          {Boolean(subscribers) && (
            <span
              className={styles.subscribers}
              data-testid="position-item-subscribers"
            >
              Interessados: {subscribers}
            </span>
          )}
          {Boolean(likes) && (
            <span
              className={[
                styles.subscribers,
                isEnterpriseEnv && styles.secondaryLayout,
              ].join(" ")}
              data-testid="position-item-likes"
            >
              {isEnterpriseEnv ? 'Cliques:' : 'Interessados:'} {likes}
            </span>
          )}
        </div>
        <div className={styles.chipsWrapper}>
          {location && <LocationChip location={location} />}
          <SalaryChip value={salary} />
        </div>
      </div>
      {handleLinkButton(status)}
    </article>
  );
};

PositionItem.propTypes = {
  status: oneOf(HUNTING_STATUSES, V3_STATUSES),
  subscribers: number,
  likes: number,
  id: oneOfType([number, string]),
  title: string.isRequired,
  date: string.isRequired,
  location: string.isRequired,
  salary: number.isRequired,
};

PositionItem.defaultProps = {
  subscribers: 0,
  likes: 0,
};

export { PositionItem };
