import React, { useEffect, useState } from "react";
import { node } from "prop-types";

import ShareContext from "./ShareContext";

const ShareProvider = ({ children }) => {
  const [items, setItems] = useState({});

  useEffect(() => {
    if (
      Object.keys(items).length === 0 &&
      localStorage.getItem("@user_shares")
    ) {
      const storedShares = JSON.parse(localStorage.getItem("@user_shares"));
      setItems(storedShares);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registerShare = (id, shares) => {
    if (!items[id]) {
      const currentItems = items;
      currentItems[id] = shares;
      setItems(currentItems);
    }
  };

  const updateShareOnItem = (id, service) => {
    const currentItems = items;
    currentItems[id][service] = true;
    setItems(() => ({ ...currentItems }));
    localStorage.setItem("@user_shares", JSON.stringify({ ...currentItems }));
  };

  const value = {
    items,
    setItems,
    registerShare,
    updateShareOnItem,
  };

  return (
    <ShareContext.Provider value={value}>{children}</ShareContext.Provider>
  );
};

ShareProvider.propTypes = {
  children: node.isRequired,
};

export default ShareProvider;
