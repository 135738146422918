import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import useAxios from "../../../../hooks/useAxios";
import { isUserFullySignedUp } from "../../../Account/hooks/isUserFullySignedUp";
import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";

import InvalidConfirmation from "../InvalidConfirmation";
import EmailValidatedSuccesfully from "../EmailValidatedSuccesfully";

const ConfirmYourEmailEnterprise = () => {
  const [validationStatus, setValidationStatus] = useState(null);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const { exec, loading } = useAxios();
  const { updateUserStatus } = isUserFullySignedUp();

  useEffect(() => {
    const values = {
      key: params.get("key"),
      hash: params.get("hash"),
      expires: params.get("expires"),
      signature: params.get("signature"),
    };

    const validateEmail = async () => {
      try {
        const response = await exec({
          method: "GET",
          url: `/email/verify/${values.key}/${values.hash}?expires=${values.expires}&signature=${values.signature}`,
        });

        if (response.user.email_verified_at && response.token) {
          localStorage.setItem("@user_data", JSON.stringify(response.user));
          localStorage.setItem("@user_token", response.token);
          setValidationStatus("valid");

          return; 
        }

        setValidationStatus("invalid");
      } catch (error) {
        setValidationStatus("invalid");
        console.error("[INKLUA]", { error });
      }
    };

    validateEmail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!validationStatus || loading) {
    return <LoadingSpinner color="#438fcc"/>;
  }

  return (
    <AuthenticationWrapper>
      {validationStatus === "invalid" ? (
        <InvalidConfirmation />
      ) : (
        <EmailValidatedSuccesfully />
      )}
    </AuthenticationWrapper>
  );
};

export default ConfirmYourEmailEnterprise;
