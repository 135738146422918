import React from "react";
import { Helmet } from "react-helmet";

import SectionContactForm from "../../components/SectionContactForm";

import styles from "./styles.module.scss";

const MetaTags = () => (
  <Helmet>
    <title>Contato | Inklua</title>
    <meta name="description" content="Tem alguma dúvida sobre alguma vaga? Ou tem algum feedback para nos contar? Fale conosco." />
    <meta name="keywords" content="vaga de emprego pcd, emprego para pcd, vaga para pcd, vaga pcd Brasil, oportunidade de emprego pcd, oportunidade, trabalho, recrutamento e seleção, cadastrar currículo, curriculo, Inklua, vagas na Inklua, inkua, empresa inklua, PcD." />
    <meta property="og:title" content="Contato | Inklua" />
    <meta property="og:type" content="website" />
    <meta property="og:description" content="Tem alguma dúvida sobre alguma vaga? Ou tem algum feedback para nos contar? Fale conosco." />
    <meta property="og:image" content="https://s3.sa-east-1.amazonaws.com/public.inklua/logo_mini.png" />
    <meta property="og:image:type" content="image/jpeg" />
    <meta property="og:image:width" content="200" />
    <meta property="og:image:height" content="200" />
    <meta property="og:url" content="https://inklua.com/contato" />
    <meta property="og:site_name" content="inklua" />
    <link rel="canonical" href=" https://inklua.com/contato/" />
  </Helmet>
);

const Contact = () => {
  return (
    <>
      <MetaTags />
      <div className={styles.wrapper}>
        <SectionContactForm />
      </div>
    </>
  )
};

export default Contact;
