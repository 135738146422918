import { useEffect } from "react";

import useDoubleAuthPrevention from "../../../../hooks/useDoubleAuthPrevention";

import AuthenticationWrapper from "../../components/AuthenticationWrapper";
import SignUpForm from "../../components/SignUpForm";
import { isUserFullySignedUp } from "../../../Account/hooks/isUserFullySignedUp";

const CandidateSignUp = () => {
  const { validate } = useDoubleAuthPrevention();
  const { handleUserAccessPage } = isUserFullySignedUp();

  useEffect(() => {
    validate({ redirect: true });
    handleUserAccessPage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthenticationWrapper>
      <SignUpForm />
    </AuthenticationWrapper>
  );
};

export { CandidateSignUp };
