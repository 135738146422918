import React from "react";
import { number, string, bool } from "prop-types";

import ButtonWithInkoinSupport from "../../components/ButtonWithInkoinSupport";
import useUserData from "../../hooks/useUserData";
import { parseUrl } from "../../helpers";

import styles from "./styles.module.scss";

const ImageAdvertising = ({ id, image, url, description, hasInkoins }) => {
  const { registrationStatus } = useUserData();

  return (
    <article className={styles.wrapper}>
      <span className={styles.label}>Patrocinado</span>
      <a
        href={parseUrl(url)}
        rel="noreferrer"
        target="_blank"
        className={styles.link}
      >
        <img
          src={image.replace("http://", "https://")}
          alt={description}
          className={styles.image}
        />
      </a>
      {/* <ButtonWithInkoinSupport
        text="Saiba mais"
        url={parseUrl(url)}
        hasInkoins={registrationStatus !== "unauthenticated" && hasInkoins}
        id={id}
      /> */}
    </article>
  );
};

ImageAdvertising.propTypes = {
  image: string.isRequired,
  description: string.isRequired,
  url: string.isRequired,
  hasInkoins: bool.isRequired,
  id: number.isRequired,
};

export default ImageAdvertising;
