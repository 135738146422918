import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { cnpj } from "cpf-cnpj-validator";

import useDoubleAuthPrevention from "../../../hooks/useDoubleAuthPrevention";
import SquareLinkButton from "../../../components/SquareLinkButton";
import ButtonWithIcon from "../../../../../components/ButtonWithIcon";
import { Exit } from "../../../../../libs/icons";
import { formatPhone } from "../../../../../helpers/format";

import AccountCardContainer from "../../components/AccountCardContainer";

import { MyAccountHeader } from "../../components/MyAccountHeader";
import WelcomeMessage from "../../components/WelcomeMessage";
import AccountExclusion from "../../components/AccountExclusion";
import InformationSummary from "../../components/InformationSummary";

const MyAccountEnterprise = () => {
  const history = useHistory();
  const { validate } = useDoubleAuthPrevention();
  const userLocalStorage = localStorage.getItem("@user_data");
  const userData = userLocalStorage ? JSON.parse(userLocalStorage) : null;

  const handleLogout = () => {
    localStorage.removeItem("@user_data");
    localStorage.removeItem("@user_token");
    localStorage.removeItem("@user_inkoins");
    localStorage.removeItem("@user_shares");

    history.push("/para-empresas");
  };

  useEffect(() => {
    validate({ redirect: false });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MyAccountHeader>
        {Boolean(userData) && (
          <WelcomeMessage
            email={userData.email}
            name={`${userData.name} ${userData.lastname}`}
          />
        )}
        <ButtonWithIcon onClick={handleLogout} text="Sair">
          <Exit color="#438FCC" />
        </ButtonWithIcon>
      </MyAccountHeader>
      <AccountCardContainer>
        <InformationSummary title="Dados da conta">
          <ul>
            {userData.fantasy_name && (
              <li>
                <b>Empresa:</b> {userData.fantasy_name}
              </li>
            )}
            {userData.cnpj && (
              <li>
                <b>CNPJ:</b> {cnpj.format(userData.cnpj)}
              </li>
            )}
            {userData.corporate_name && (
              <li>
                <b>Razão Social:</b> {userData.corporate_name}
              </li>
            )}
            <li>
              <b>Nome:</b> {userData.name} {userData.lastname}
            </li>
            {userData.phone && (
              <li>
                <b>Telefone:</b> {formatPhone(userData.phone)}
              </li>
            )}
            <li>
              <b>E-mail:</b> {userData.email}
            </li>
          </ul>
          <SquareLinkButton
            inline
            text="Editar dados"
            url="/para-empresas/minha-conta/editar"
            type="outline"
          />
        </InformationSummary>
      </AccountCardContainer>
    </>
  );
};

export default MyAccountEnterprise;
