import { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "@unform/web";
import * as Yup from "yup";

import SquareButton from "../../../../components/SquareButton";
import SelectInput from "../../../../components/SelectInput";
import SquareLinkButton from "../../../../components/SquareLinkButton";
import { candidateEnglishLevels } from "../../../../libs/data";
import validationDictionary from "../../../../constants/validationDictionary";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { focusElement } from "../../../../helpers";

import { SectionTitle } from "../../../Account/components/SectionTitle";
import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";
import { useUserLanguage } from "../../hooks/useUserLanguage";

import styles from "./styles.module.scss";

const CandidateEditLanguage = () => {
  const formRef = useRef();
  const history = useHistory();
  const [selectedEnglishLevel, setSelectedEnglishLevel] = useState(
    candidateEnglishLevels[0].value
  );
  const {
    getUserLanguage,
    updateUserLanguage,
    userLanguage,
    loading: isUserLanguageLoading,
  } = useUserLanguage();

  const fetchCourseInformation = async () => {
    try {
      await getUserLanguage();
    } catch (error) {
      console.log({ error });
    }
  };

  const goToNextStep = () => {
    history.push("/candidato/minha-conta/formacao-academica");
  };

  const handleSubmit = async (data) => {
    try {
      data.english_level = Number(selectedEnglishLevel);

      const schema = Yup.object().shape({
        english_level: Yup.number().oneOf(
          candidateEnglishLevels.map((l) => l.value),
          validationDictionary.GENERIC_INVALID_FIELD
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const result = await updateUserLanguage({ levelId: data.english_level });

      if (!result) {
        return;
      }

      goToNextStep();
    } catch (err) {
      console.log({ err });
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  useEffect(() => {
    fetchCourseInformation();

    focusElement('[data-testid="section-title"]');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isUserLanguageLoading) {
      setSelectedEnglishLevel(userLanguage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLanguage]);

  if (userLanguage === null && isUserLanguageLoading) {
    return <LoadingSpinner />;
  }

  return (
    <AuthenticationWrapper>
      <SectionTitle>Nível de inglês</SectionTitle>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        className={styles.form}
        initialData={{
          english_level: userLanguage,
        }}
      >
        {userLanguage && (
          <>
            <SelectInput
              name="english_level"
              label="Nível de inglês"
              placeholder="Selecione o nível"
              setter={setSelectedEnglishLevel}
              keys={{ label: "name", value: "value" }}
              options={candidateEnglishLevels}
              defaultValue={userLanguage}
              disabled={isUserLanguageLoading}
            />
          </>
        )}
        <SquareButton
          text="Concluir"
          type="submit"
          disabled={isUserLanguageLoading && userLanguage}
        />
      </Form>
      <SquareLinkButton
        testID="back-to-education-list"
        text="Voltar"
        url="/candidato/minha-conta/formacao-academica"
        type="ghost"
      />
    </AuthenticationWrapper>
  );
};

export { CandidateEditLanguage };
