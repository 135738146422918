import React from 'react';

const Search = ({ color }) => {
    return (
<svg viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
        <path d="M5,11 C5,7.691 7.691,5 11,5 C14.309,5 17,7.691 17,11 C17,14.309 14.309,17 11,17 C7.691,17 5,14.309 5,11 M20.707,19.293 L17.312,15.897 C18.365,14.543 19,12.846 19,11 C19,6.589 15.411,3 11,3 C6.589,3 3,6.589 3,11 C3,15.411 6.589,19 11,19 C12.846,19 14.543,18.365 15.897,17.312 L19.293,20.707 C19.488,20.902 19.744,21 20,21 C20.256,21 20.512,20.902 20.707,20.707 C21.098,20.316 21.098,19.684 20.707,19.293" id="path-1"></path>
    </defs>
    <g id="⬅️➡️📱01-realizando-a-busca-inklua(MobileView)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="02-search:focus-entering" transform="translate(-139.000000, -585.000000)">
            <g id="content" transform="translate(56.000000, 146.000000)">
                <g id="btn-procurar-vagas" transform="translate(0.000000, 420.000000)">
                    <g id="27)-Icon/search" transform="translate(80.000000, 16.000000)">
                        <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1"></use>
                        </mask>
                        <use id="🎨-Icon-Сolor" fill={color} xlinkHref="#path-1"></use>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
    )
};

export default Search;