import { useModalContext } from "../../contexts/Modal";
import SquareButton from "../../components/SquareButton";
import SocialMediaShareButton from "../../components/SocialMediaShareButton";
import { Plus } from "../../libs/icons";
import { useSnackBarContext } from "../../contexts/SnackBar";

import styles from "./styles.module.scss";
import useEnterPriseEnvCheck from "../../hooks/useEnterpriseEnvCheck";

const ShareModal = () => {
  const { setOpen, isOpen, url, itemId, hasInkoins } = useModalContext();
  const { showSnackBar } = useSnackBarContext();
  const { isEnterpriseEnv } = useEnterPriseEnvCheck();

  const copyUrlToClipboard = () => {
    const ta = document.createElement("textarea");
    ta.innerText = url.startsWith("http") ? url : window.location.origin + url;
    document.body.appendChild(ta);
    ta.select();
    document.execCommand("copy");
    ta.remove();

    showSnackBar({
      timeout: 5000,
      text: "Link copiado para área de transferência",
    });
  };

  if (!url || !itemId) return null;

  return (
    <>
      <div
        aria-hidden={isOpen}
        className={[styles.wrapper, isOpen && styles.isOpen].join(" ")}
        onClick={() => setOpen(false)}
      />
      <div className={[styles.container, isOpen && styles.isOpen, isEnterpriseEnv && styles.secondaryLayout].join(" ")}>
        <h1 className={styles.title} tabIndex={0}>Compartilhe o conteúdo</h1>
        <button
          aria-label="Fechar"
          className={styles.closeButton}
          onClick={() => setOpen(false)}
        >
          Close <Plus color={isEnterpriseEnv ? "#438FCC" : "#1B9B9E"} />
        </button>
        {url && (
          <p>
            <a className={styles.url} href={url} onClick={() => setOpen(false)}>
              {url.startsWith("http") ? url : window.location.origin + url}
            </a>
          </p>
        )}
        <SquareButton text="Copiar link" onClick={copyUrlToClipboard} />
        <h2 className={styles.socialShareText}>Compartilhe por:</h2>
        <SocialMediaShareButton
          url={url}
          id={itemId}
          hasInkoins={hasInkoins}
          service="whatsapp"
        />
        <SocialMediaShareButton
          url={url}
          id={itemId}
          hasInkoins={hasInkoins}
          service="facebook"
        />
        <SocialMediaShareButton
          url={url}
          id={itemId}
          hasInkoins={hasInkoins}
          service="linkedin"
        />
        <SocialMediaShareButton
          url={url}
          id={itemId}
          hasInkoins={hasInkoins}
          service="twitter"
        />
      </div>
    </>
  );
};

export default ShareModal;
