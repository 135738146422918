import React from "react";
import { string, node, func } from "prop-types";

import styles from "./styles.module.scss";

const ButtonWithIcon = ({ text, onClick, children }) => {
  return (
    <button className={styles.button} type="button" onClick={onClick}>
      {children}
      {text}
    </button>
  );
};

ButtonWithIcon.propTypes = {
  text: string.isRequired,
  children: node.isRequired,
  onClick: func.isRequired,
};

export default ButtonWithIcon;
