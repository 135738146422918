const parseUrl = (url) => {
  if (!url) {
    return null;
  }

  if (!url.includes("http")) {
    return `${window.location.origin}${url}`;
  }

  return url;
};

export default parseUrl;
