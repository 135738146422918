import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useParams, useHistory } from "react-router-dom";
import JobDetailsModal from "../../components/JobDetailsModal";
import ShareButton from "../../components/ShareButton";
import { LeftArrow } from "../../libs/icons";
import axios from "../../libs/axios";

const Details = () => {
  let { id } = useParams();
  let history = useHistory();
  const [candidate, setCandidate] = useState(null);

  // Get initial data
  const fechData = async () => {
    try {
      const response = await axios({
        method: "POST",
        url: `detail/${id}`,
        header: [],
      });
      setCandidate(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fechData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section
      className={styles.wrapper}
      style={{ paddingTop: "20px", maxWidth: "1075px", margin: "auto" }}
    >
      <div className={styles.modalContainer}>
        {candidate && (
          <div className={styles.headerDetails}>
            <button onClick={history.goBack}>
              <LeftArrow />
            </button>
            <span>Detalhes do candidato</span>
            <ShareButton
              text={`Compartilhar artigo ${candidate.title}`}
              title={candidate.title}
              url={window.location.href}
              id={candidate.gid}
            />
          </div>
        )}
        <JobDetailsModal
          details={candidate}
          setOpenDetails={history.goBack}
          loading={!candidate}
        />
      </div>
    </section>
  );
};

export default Details;
