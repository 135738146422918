import { node } from "prop-types";

import styles from "./styles.module.scss";

const AccountCardContainer = ({ children }) => {
  return (
    <div className={styles.wrapper} data-testid="account-card-container">
      {children}
    </div>
  );
};

AccountCardContainer.propTypes = {
  children: node.isRequired,
};

export default AccountCardContainer;
