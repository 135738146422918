import { bool, func } from "prop-types";

import styles from "./styles.module.scss";

const FillIcon = () => (
  <svg
    className={styles.icon}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 20"
  >
    <path
      fill="#FFF"
      d="M22 9a2 2 0 0 0-2-2h-6.32l.96-4.57c.02-.1.03-.21.03-.32 0-.41-.17-.79-.44-1.06L13.17 0 6.59 6.58C6.22 6.95 6 7.45 6 8v10a2 2 0 0 0 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73V9M0 20h4V8H0v12Z"
    />
  </svg>
);

const OutlineIcon = () => (
  <svg
    className={styles.icon}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 20"
  >
    <path
      fill="#1B9B9E"
      d="M4 8v12H0V8h4m4 12a2 2 0 0 1-2-2V8c0-.55.22-1.05.59-1.41L13.17 0l1.06 1.06c.27.27.44.64.44 1.05l-.03.32L13.69 7H20a2 2 0 0 1 2 2v2c0 .26-.05.5-.14.73l-3.02 7.05C18.54 19.5 17.83 20 17 20H8m0-2h9.03L20 11V9h-8.79l1.13-5.32L8 8.03V18Z"
    />
  </svg>
);

export const LikeButton = ({ disabled, liked, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={[
        styles.button,
        liked ? styles.liked : null,
        disabled ? styles.disabled : null,
      ].join(" ")}
      disabled={disabled || liked}
      data-testid="like-button"
    >
      {liked ? <FillIcon /> : <OutlineIcon />}
      <span className={styles.text}>
        {liked ? "Você já deu um Gostei aqui" : "Gostei"}
      </span>
    </button>
  );
};

LikeButton.propTypes = {
  onClick: func,
  disabled: bool,
  liked: bool,
};

LikeButton.defaultProps = {
  onClick: () => { },
  disabled: false,
  liked: false,
};
