const lockScroll = (shouldLock) => {
  const body = document.querySelector("body");
  const html = document.querySelector("html");
  const rootContainer = document.querySelector("#root");

  if (shouldLock) {
    rootContainer.classList.add("is-locked");
    html.classList.add("is-locked");
    body.classList.add("is-locked");
    return;
  }

  rootContainer.classList.remove("is-locked");
  html.classList.remove("is-locked");
  body.classList.remove("is-locked");
};

export default lockScroll;
