import VMasker from "vanilla-masker";

export const formatCurrency = (value) => {
    const newValue = VMasker.toMoney(value, {
        unit: 'R$',
        precision: 3,
        delimiter: '.',
        separator: '.',
    })
    return newValue;
}