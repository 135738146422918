import React, { useRef, useState } from "react";
import { Form } from "@unform/web";
import { useHistory } from "react-router-dom";
import { func } from "prop-types";

import { Input } from "../NewInput";
import SelectInput from "../SelectInput";
import SquareButton from "../SquareButton";
import { Magnifier } from "../../libs/icons";
import { states } from "../../libs/data";

import styles from "./styles.module.scss";

const SearchBlock = ({ successCallback }) => {
  const history = useHistory();
  const formRef = useRef();
  const [stateFilter, setStateFilter] = useState(null);

  const handleSubmit = (data) => {
    data.location = stateFilter;

    const params = [];

    if (!data.location && !data.position) {
      return;
    }

    if (data.location) {
      params.push(`estado=${data.location}`);
    }

    if (data.position) {
      params.push(`termo=${data.position}`);
    }

    successCallback();
    history.push(`/busca?${params.join("&")}`);
  };

  return (
    <section className={styles.wrapper}>
      <h2 className={styles.title} tabIndex="0">
        Encontre a vaga <b>certa para você</b>
      </h2>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        id="contact-form"
        className={styles.form}
      >
        <Input
          placeholder="Exemplo: Analista Contábil"
          name="position"
          label="Pesquise um cargo ou palavra-chave"
          type="text"
        />
        <SelectInput
          name="location"
          label="Em qual estado?"
          placeholder="Selecione um estado"
          setter={setStateFilter}
          keys={{ label: "name", value: "id" }}
          options={states.brazil}
        />
        <div className={styles.buttonWrapper}>
          <SquareButton
            text="Procurar vaga"
            type="submit"
            icon={<Magnifier color="#fff" />}
          />
        </div>
      </Form>
    </section>
  );
};

SearchBlock.propTypes = {
  successCallback: func.isRequired,
};

export default SearchBlock;
