import React, { useRef, forwardRef, useState } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { oneOf } from "prop-types";

import { Input, Radio } from "../NewInput";
import SquareButton from "../SquareButton";
import useAxios from "../../hooks/useAxios";
import { useSnackBarContext } from "../../contexts/SnackBar";
import validationDictionary from "../../constants/validationDictionary";

import styles from "./styles.module.scss";
import { parseEmail } from "../../helpers";

const initialValues = { type: "b2c" };

const ContactForm = forwardRef(({ type, pallete }, ref) => {
  const formRef = useRef(null);
  const { exec, loading } = useAxios();
  const { showSnackBar } = useSnackBarContext();

  const [typeValue, setTypeValue] = useState(initialValues.type);

  const handleSubmit = async (data) => {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string()
          .min(3, validationDictionary.INVALID_NAME)
          .required(validationDictionary.INVALID_NAME),
        phone: Yup.string()
          .min(10, validationDictionary.INVALID_PHONE)
          .required(validationDictionary.INVALID_PHONE),
        email: Yup.string()
          .transform(parseEmail)
          .email(validationDictionary.INVALID_EMAIL)
          .required(validationDictionary.INVALID_EMAIL),
        company:
          (type === "simple" || typeValue === "b2b") &&
          Yup.string()
            .min(6, validationDictionary.INVALID_COMPANY_NAME)
            .required(validationDictionary.INVALID_COMPANY_NAME),
        message: Yup.string()
          .min(20, validationDictionary.INVALID_MESSAGE)
          .required(validationDictionary.INVALID_MESSAGE),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await exec({
        method: "post",
        url: "/contato",
        data: {
          nome: data.name,
          empresa: data.company,
          tipo: data.type === "b2c" ? "Candidato" : "Empresa",
          email: parseEmail(data.email),
          mensagem: data.message,
          phone: data.phone,
        },
      });

      showSnackBar({
        timeout: 5000,
        text: "Mensagem enviada com sucesso",
      });

      formRef.current.reset();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const handleTypeChange = (value) => {
    setTypeValue(value);
  };

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={initialValues}
      id="contact-form"
    >
      {type === "default" && (
        <div className={styles.itemWrapper}>
          <Radio
            name="type"
            options={[
              { id: "b2c", label: "Sou um candidato" },
              { id: "b2b", label: "Represento uma empresa" },
            ]}
            onChange={handleTypeChange}
          />
        </div>
      )}
      <div className={styles.itemWrapper}>
        <Input
          label="Nome "
          name="name"
          placeholder="Digite seu nome"
          type="text"
          disabled={loading}
        />
      </div>
      {(type === "simple" || typeValue === "b2b") && (
        <div className={styles.itemWrapper}>
          <Input
            label="Empresa"
            name="company"
            placeholder="Digite o nome da sua empresa"
            type="text"
            disabled={loading}
          />
        </div>
      )}
      <div className={styles.itemWrapper}>
        <Input
          label="E-mail"
          name="email"
          placeholder="Digite seu e-mail"
          type="text"
          disabled={loading}
        />
      </div>
      <div className={styles.itemWrapper}>
        <Input
          label="Celular ou telefone"
          name="phone"
          placeholder="Digite seu telefone"
          type="text"
          disabled={loading}
          mask="(99) 9999-99999"
        />
      </div>
      <div className={styles.itemWrapper}>
        <Input
          label="Mensagem"
          name="message"
          placeholder="Como podemos te ajudar?"
          type="textarea"
          disabled={loading}
        />
      </div>
      <div className={styles.buttonWrapper}>
        <SquareButton disabled={loading} text="Enviar" type="submit" />
      </div>
    </Form>
  );
});

ContactForm.propTypes = {
  type: oneOf(["default", "simple"]),
};

ContactForm.defaultProps = {
  type: "default",
};

export default ContactForm;
