import { useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';

import styles from './styles.module.scss';

import SectionTitle from '../../../../components/SectionTitle';
import AccordionList from '../../../../components/AccordionList';
import SectionIntro from '../../../../components/SectionIntro';
import SectionContactForm from '../../components/SectionContactForm';

import Comparation from '../../components/Comparation';
import Slider from '../../components/Slider';
import Card from '../../components/Card';
import ButtonsGroup from '../../components/ButtonsGroup';

import { consultoria, mapTech, anuncio, perfil } from './texts';

const MetaTags = () => (
  <Helmet>
    <title>Soluções para R&S | Inklua</title>
    <meta name="description" content="Atuamos com recrutamento e seleção de pessoas com deficiência (PcD), anúncio de vagas, mapeamento de profissionais de tecnologia e palestras sobre capacitismo." />
    <meta name="keywords" content="R&S de pessoas com deficiência (PcDs), R&S pcd, recrutamento e seleção de pcd, consultoria pcd, consultoria focada em pcd, site para PcD, anúncio de vagas para pcd, palestras sobre pcd, oportunidade de emprego pcd, recrutamento e seleção, Inklua." />
    <meta property="og:title" content="Soluções para R&S | Inklua" />
    <meta property="og:type" content="website" />
    <meta property="og:description" content="Atuamos com recrutamento e seleção de pessoas com deficiência (PcD), anúncio de vagas, mapeamento de profissionais de tecnologia e palestras sobre capacitismo." />
    <meta property="og:image" content="https://s3.sa-east-1.amazonaws.com/public.inklua/logo_mini.png" />
    <meta property="og:image:type" content="image/jpeg" />
    <meta property="og:image:width" content="200" />
    <meta property="og:image:height" content="200" />
    <meta property="og:url" content=" https://inklua.com/para-empresas OU https://inklua.com/paraempresas/solucoes-rs " />
    <meta property="og:site_name" content=" Inklua" />
    <link rel="canonical" href=" https://inklua.com/para-empresas OU https://inklua.com/paraempresas/solucoes-rs " />
  </Helmet>
);

const Recruitment = () => {
  const isMobile = window.innerWidth <= 768;
  const showSlides = window.innerWidth <= 900;
  const userData = localStorage.getItem("@user_data");
  const formSection = useRef();
  const comparationSection = useRef();
  const faqSection = useRef();
  const mapSection = useRef();
  const recruitmentSection = useRef();
  const anuncioSection = useRef();
  const perfilSection = useRef();
  const { id } = useParams();

  const handleSectionScroll = (section, position) => {
    section.scrollIntoView({ behavior: "smooth", block: position });
  };

  useEffect(() => {
    if (!id) return;

    if (id === 'mapeamento' && !isMobile) return handleSectionScroll(mapSection.current, 'start');
    if (id === 'consultoria' && !isMobile) return handleSectionScroll(recruitmentSection.current, 'start');
    if (id === 'anuncio' && !isMobile) return handleSectionScroll(anuncioSection.current, 'start');
    if (id === 'busca-de-perfis' && !isMobile) return handleSectionScroll(perfilSection.current, 'start');
    if (id === 'comparativo' && !isMobile) return handleSectionScroll(comparationSection.current, 'start');

  }, [id, isMobile])

  return (
    <>
      <MetaTags />
      <main>
        <section className={styles.firstSection} >
          <SectionIntro
            level="span"
            text="nossos serviços"
          />
          <SectionTitle
            level="h2"
            children="Soluções para <b>recrutamento e seleção</b>"
            centered
          />
        </section>
        <section className={styles.commomSection} ref={recruitmentSection}>
          <div className={styles.leftSide}>
            <SectionTitle
              level="h2"
              children="Consultoria <b>de R&S</b>"
              centered={isMobile}
            />
            <p className={styles.paragraph}>
              Nossa consultoria oferece um serviço
              completo de R&S de pessoas com deficiência
              (PcDs). Tudo começa com o alinhamento do
              perfil para a vaga a ser preenchida e,
              em 5 dias úteis, apresentamos de 3 a 5
              candidatos para a posição.
            </p>
            <p className={styles.paragraph}>
              Esse serviço é ideal para empresas que precisam
              de uma solução completa de recrutamento e seleção,
              de forma ágil e assertiva, além de apoio quanto
              à Lei de Cotas.
            </p>
            <div className={styles.btnsDesktop}>
              <ButtonsGroup
                firstButton={{
                  text: "Converse com a consultoria",
                  action: () => handleSectionScroll(formSection.current, 'center'),
                }}
                secondButton={{
                  text: "Confira o comparativo",
                  action: () => handleSectionScroll(comparationSection.current, 'start'),
                }}
              />
            </div>
          </div>
          <div className={styles.rightSide}>
            <Card
              icon="checkmark"
              title="Serviço completo"
              text="
                        Consultoria completa e especializada em recrutamento 
                        e seleção de pessoas com deficiência.
                        "
            />
            <Card
              icon="star"
              title="Remuneração no sucesso"
              text="
                        Sua empresa só remunera a Inklua quando contratar 
                        o profissional.
                        "
            />
            <Card
              icon="pin"
              title="Presença em todo o Brasil"
              text="
                        Não importa o estado ou a cidade, 
                        a Inklua está presente em todo lugar!
                        "
            />
          </div>
          <div className={styles.btnsMobile}>
            <ButtonsGroup
              firstButton={{
                text: "Converse com a consultoria",
                action: () => handleSectionScroll(formSection.current, 'center'),
              }}
              secondButton={{
                text: "Confira o comparativo",
                action: () => handleSectionScroll(comparationSection.current, 'start'),
              }}
            />
          </div>
        </section>
        <section
          className={[styles.commomSection, styles.secondaryLayout].join(" ")}
          ref={mapSection}
        >
          <div className={styles.leftSide}>
            <SectionTitle
              level="span"
              children="Mapeamento <b>tech</b>"
              centered={isMobile}
              linebreak
            />
            <p className={styles.paragraph}>
              Disponibilizamos um briefing dos profissionais
              de tecnologia já mapeados pela nossa consultoria.
              Você pode consultar esta lista completa agora mesmo!
              Ao encontrar um profissional que gostaria de conhecer,
              é só entrar em contato! Nós iremos conectar vocês.
            </p>
            <p className={styles.paragraph}>
              Este serviço é ideal para empresas que têm urgência
              na contratação de profissionais da área de tecnologia.
            </p>
            <div className={styles.btnsDesktop}>
              <ButtonsGroup
                firstButton={{
                  text: "Visualize os candidatos",
                  action: () => window.location.href = "/para-empresas/mapeamento-tech",
                }}
                secondButton={{
                  text: "Confira o comparativo",
                  action: () => handleSectionScroll(comparationSection.current, 'start'),
                }}
                whiteVersion
              />
            </div>
          </div>
          <div className={styles.rightSide}>
            <Card
              icon="flash"
              title="Agilidade ao contrar profissionais de TI"
              text="
                        Economize o seu tempo e encontre o profissional de TI 
                        que precisa em poucos cliques através do nosso mapeamento. 
                        "
            />
            <Card
              icon="file"
              title="Briefing do profissional"
              text="
                        Tenha um resumo de cada profissional com 
                        informações como: formação, experiências, 
                        habilidade técnicas, pretensão salarial, 
                        disponibilidade, entre outros.
                        "
            />
            <Card
              icon="map"
              title="Presença em todo o Brasil"
              text="
                        Não importa o estado ou a cidade, 
                        a Inklua está presente em todo lugar!
                        "
            />
          </div>
          <div className={styles.btnsMobile}>
            <ButtonsGroup
              whiteVersion
              firstButton={{
                text: "Confira os profissionais",
                action: () => window.location.href = "/para-empresas/mapeamento-tech",
              }}
              secondButton={{
                text: "Confira o comparativo",
                action: () => handleSectionScroll(comparationSection.current, 'start'),
              }}
            />
          </div>
        </section>
        <section className={styles.commomSection} ref={perfilSection}>
          <div className={styles.leftSide}>
            <SectionTitle
              level="h2"
              children="Plataforma para <b>busca de perfis</b>"
              centered={isMobile}
            />
            <p className={styles.paragraph}>
              Disponibilizamos a nossa base de candidatos de forma ilimitada.
              Você pode explorar diversas opções com filtros personalizados,
              acessar as informações de cada candidato e iniciar conversas
              diretamente, simplificando o processo de recrutamento.
            </p>
            <p className={styles.paragraph}>
              Conte com a base da maior empresa de inclusão de pessoa com
              deficiência do Brasil em seus processos de recrutamento e
              seleção.
            </p>
            <div className={styles.btnsDesktop}>
              <ButtonsGroup
                firstButton={{
                  text: "Contate a Inklua",
                  action: () => handleSectionScroll(formSection.current, 'center'),
                }}
                secondButton={{
                  text: "Confira o comparativo",
                  action: () => handleSectionScroll(comparationSection.current, 'start'),
                }}
              />
            </div>
          </div>
          <div className={styles.rightSide}>
            <Card
              icon="people"
              title="Acesso a milhares de perfis"
              text="Acesso ilimitado a toda a base de candidatos da Inklua, que está em constante crescimento."
            />
            <Card
              icon="clipboard"
              title="+ 80% dos perfis possuem laudo médico"
              text="O laudo médico do candidato à disposição agiliza o seu processo de contratação."
            />
            <Card
              icon="pin"
              title="Candidatos de todo o Brasil"
              text="Nossa base de candidatos abrange todo o Brasil, das grandes capitais às cidades do interior."
            />
          </div>
          <div className={styles.btnsMobile}>
            <ButtonsGroup
              firstButton={{
                text: "Converse com a consultoria",
                action: () => handleSectionScroll(formSection.current, 'center'),
              }}
              secondButton={{
                text: "Confira o comparativo",
                action: () => handleSectionScroll(comparationSection.current, 'start'),
              }}
            />
          </div>
        </section>
        <section
          className={[styles.commomSection, styles.secondaryLayout].join(" ")}
          ref={anuncioSection}
        >
          <div className={styles.leftSide}>
            <SectionTitle
              level="span"
              children="Anúncio de<b>vagas</b>"
              centered={isMobile}
            />
            <p className={styles.paragraph}>
              Anuncie sua vaga no site da Inklua e atraia
              candidatos para seu processo seletivo.
            </p>
            <p className={styles.paragraph}>
              Este serviço é ideal para empresas que
              precisam apenas divulgar vagas e dispõem
              de recursos internos capazes de conduzir
              todo o processo de forma ágil e assertiva.
            </p>
            <div className={styles.btnsDesktop}>
              <ButtonsGroup
                whiteVersion
                firstButton={{
                  text: "Anuncie sua vaga",
                  action: () => {
                    const data = userData ? JSON.parse(userData) : {};

                    if (data.type === "PJ") {
                      if (data.inkluer) {
                        window.location = window.location.origin + "/recrutador/minhas-vagas"
                        return;
                      }
                      window.location.href = "/para-empresas/recrutador/minhas-vagas";
                      return;
                    }
                    window.location.href = "/para-empresas/autenticacao/login"
                  },
                }}
                secondButton={{
                  text: "Confira o comparativo",
                  action: () => handleSectionScroll(comparationSection.current, 'start'),
                }}
                whiteVersion
              />
            </div>
          </div>
          <div className={styles.rightSide}>
            <Card
              icon="swap"
              title="Contato direto com o candidato"
              text="
                        Os candidatos que se interessarem por 
                        sua vaga serão direcionados para o endereço 
                        de candidatura da sua empresa (e-mail, 
                        plataforma ou formulário). 
                        "
            />
            <Card
              icon="volUp"
              title="Anuncie para todo o Brasil"
              text="
                        Candidatos de todo o Brasil acessam 
                        a nossa plataforma diariamente em busca de vagas.
                        "
            />
            <Card
              icon="edit"
              title="Simplicidade na contratação"
              text="
                        O cadastro da vaga e o pagamento 
                        são feitos diretamente em nossa plataforma. 
                        E o primeiro anúncio é gratuito!
                        "
            />
          </div>
          <div className={styles.btnsMobile}>
            <ButtonsGroup
              whiteVersion
              firstButton={{
                text: "Anuncie sua vaga",
                action: () => {
                  const data = userData ? JSON.parse(userData) : {};

                  if (data.type === "PJ") {
                    if (data.inkluer) {
                      window.location = window.location.origin + "/recrutador/minhas-vagas";
                      return;
                    }
                    window.location.href = "/para-empresas/recrutador/minhas-vagas";
                    return;
                  }

                  window.location.href = "/para-empresas/autenticacao/login"
                }
              }}
              secondButton={{
                text: "Confira o comparativo",
                action: () => handleSectionScroll(comparationSection.current, 'start'),
              }}
            />
          </div>
        </section>
        <section className={styles.fourthSection} ref={comparationSection}>
          <SectionIntro
            level="span"
            text="Comparativo"
          />
          <SectionTitle
            level="span"
            children="Compare os recursos que cada <b>serviço oferece</b>"
            centered
          />
          <Slider>
            <Comparation
              header={consultoria.header}
              fields={consultoria.fields}
              button={consultoria.button}
              onClick={() => handleSectionScroll(formSection.current, 'center')}
              action={() => handleSectionScroll(faqSection.current, 'start')}
              color="primary"
            />
            <Comparation
              header={mapTech.header}
              fields={mapTech.fields}
              button={mapTech.button}
              onClick={() => {
                window.location.href = "/para-empresas/mapeamento-tech"
              }}
              action={() => handleSectionScroll(faqSection.current, 'start')}
              color="secondary"
            />
            <Comparation
              header={perfil.header}
              fields={perfil.fields}
              button={perfil.button}
              onClick={() => handleSectionScroll(formSection.current, 'center')}
              color="fourth"
              action={() => handleSectionScroll(faqSection.current, 'start')}
            />
            <Comparation
              header={anuncio.header}
              fields={anuncio.fields}
              button={anuncio.button}
              onClick={() => {
                const data = userData ? JSON.parse(userData) : {};

                if (data.type === "PJ") {
                  if (data.inkluer) {
                    window.location = window.location.origin + "/recrutador/minhas-vagas";
                    return;
                  }
                  window.location.href = "/para-empresas/recrutador/minhas-vagas";
                  return;
                }
                window.location.href = "/para-empresas/autenticacao/login"
              }}
              action={() => handleSectionScroll(faqSection.current, 'start')}
              color="third"
            />
          </Slider>
          {/* <div className={styles.comparationSection}>
            <Comparation
              header={consultoria.header}
              fields={consultoria.fields}
              button={consultoria.button}
              action={() => handleSectionScroll(faqSection.current, 'start')}
              onClick={() => handleSectionScroll(formSection.current, 'center')}
              color="primary"
            />
            <Comparation
              header={mapTech.header}
              fields={mapTech.fields}
              button={mapTech.button}
              onClick={() => {
                window.location.href = "/para-empresas/mapeamento-tech"
              }}
              color="secondary"
              action={() => handleSectionScroll(faqSection.current, 'start')}
            />
            <Comparation
              header={perfil.header}
              fields={perfil.fields}
              button={perfil.button}
              onClick={() => handleSectionScroll(formSection.current, 'center')}
              color="fourth"
              action={() => handleSectionScroll(faqSection.current, 'start')}
            />
            <Comparation
              header={anuncio.header}
              fields={anuncio.fields}
              button={anuncio.button}
              onClick={() => {
                const data = userData ? JSON.parse(userData) : {};

                if (data.type === "PJ") {
                  if (data.inkluer) {
                    // window.location.href = "/recrutador/minhas-vagas";
                    return;
                  }
                  window.location.href = "/para-empresas/recrutador/minhas-vagas";
                  return;
                }
                window.location.href = "/para-empresas/autenticacao/login"
              }}
              color="third"
              action={() => handleSectionScroll(faqSection.current, 'start')}
            />
          </div> */}
        </section>
        <section className={styles.FaqSection} ref={faqSection}>
          <AccordionList />
        </section>
        <div className={styles.contactSection} ref={formSection}>
          <SectionContactForm type="enterprise" />
        </div>
      </main>
    </>
  )
}

export default Recruitment;