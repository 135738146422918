import { string, node } from "prop-types";

import { NewInformationSummary } from "../../modules/Account/components/NewInformationSummary";
import { SimpleInformation } from "../../modules/Recruiter/components/SimpleInformation";

import styles from "./styles.module.scss";

const UserAvailabilitySummary = ({
  local,
  remote,
  hybrid,
  relocation,
  actionButton,
}) => {
  return (
    <NewInformationSummary title="Disponibilidade">
      <ul>
        <li>
          <SimpleInformation
            title="Trabalhar presencial"
            value={typeof local === 'string'
              ? local
              : (local ? "Aceita" : "Não aceita")
            }
            testID="availability-local"
          />
        </li>
        <li>
          <SimpleInformation
            title="Trabalhar remoto"
            value={typeof remote === 'string'
              ? remote
              : (remote ? "Aceita" : "Não aceita")
            }
            testID="availability-remote"
          />
        </li>
        <li>
          <SimpleInformation
            title="Trabalhar híbrido"
            value={typeof hybrid === 'string'
              ? hybrid
              : (hybrid ? "Aceita" : "Não aceita")
            }
            testID="availability-hybrid"
          />
        </li>
        <li>
          <SimpleInformation
            title="Disponibilidade para mudança"
            value={typeof relocation === 'string'
              ? relocation
              : (relocation ? "Aceita" : "Não aceita")
            }
            testID="availability-relocation"
          />
        </li>
      </ul>
      {actionButton && (
        <div className={styles.actionButtonWrapper}>{actionButton}</div>
      )}
    </NewInformationSummary>
  );
};

UserAvailabilitySummary.propTypes = {
  local: string,
  remote: string,
  hybrid: string,
  relocation: string,
  actionButton: node,
};

UserAvailabilitySummary.defaultProps = {
  local: false,
  remote: false,
  hybrid: false,
  relocation: false,
  actionButton: null,
};

export { UserAvailabilitySummary };
