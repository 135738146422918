import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { toDate } from "../../../../helpers";
import useAxios from "../../../../hooks/useAxios";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import SquareLinkButton from "../../../../components/SquareLinkButton";
import { focusElement } from "../../../../helpers";

import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";

import { SectionTitle } from "../../components/SectionTitle";
import {
  EducationList,
  EducationListItem,
} from "../../components/EducationListItem";

const CandidateListEducation = () => {
  const history = useHistory();
  const { exec, loading } = useAxios();
  const [data, setData] = useState(null);

  const fetchEducationItems = async () => {
    try {
      const result = await exec({
        method: "GET",
        url: `/candidate/education`,
        useCredentials: true,
      });

      // TODO: remove mock when language API is done
      // result.forEach((item) => {
      //   if (item.course === "Inglês" && !item.type) {
      //     item.type = "language";
      //   }
      // });

      setData(result);
    } catch (error) {
      console.log({ error });
    }
  };

  const editItem = ({ id, type }) => {
    if (type === "language") {
      return history.push(`/candidato/minha-conta/idioma/${id}`);
    }

    history.push(`/candidato/minha-conta/formacao-academica/editar/${id}`);
  };

  const removeItem = async ({ id }) => {
    try {
      const response = await exec({
        method: "DELETE",
        url: `/candidate/education/${id}`,
        useCredentials: true,
      });

      if (!response.error) {
        await fetchEducationItems();
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    fetchEducationItems();

    focusElement('[data-testid="section-title"]');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading && !data) {
    return <LoadingSpinner />;
  }

  return (
    <AuthenticationWrapper>
      <SectionTitle>Formação Acadêmica</SectionTitle>
      {data?.length ? (
        <EducationList>
          {data.map((item) => (
            <EducationListItem
              disableActions={loading}
              key={`education-item-${item.id}`}
              course={item.course}
              institution={item.institute}
              level={item.level_education}
              start_date={
                item.start_at ? toDate(item.start_at, "MM/YYYY") : null
              }
              end_date={item.end_at ? toDate(item.end_at, "MM/YYYY") : null}
              handleRemove={() => removeItem({ id: item.id })}
              handleEdit={() => editItem({ id: item.id, type: item.type })}
            />
          ))}
        </EducationList>
      ) : (
        <p>Sem formações cadastradas</p>
      )}
      <div style={{ display: "grid", rowGap: "16px", marginTop: "24px" }}>
        <SquareLinkButton
          testID="add-new-education"
          text={
            data?.length ? `Cadastrar outra formação` : `Cadastrar formação`
          }
          url="/candidato/minha-conta/formacao-academica/adicionar"
        />
        <SquareLinkButton
          testID="back-to-my-account-button"
          text="Voltar"
          url="/candidato/minha-conta"
          type="ghost"
        />
      </div>
    </AuthenticationWrapper>
  );
};

export { CandidateListEducation };
