import React from "react";
import { string, bool } from "prop-types";

const Clock = ({ color, hidden }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 15"
      aria-hidden={hidden}
    >
      <g fill="none" strokeWidth=".5">
        <path
          fill={color}
          stroke={color}
          d="M7.95 1a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5Zm0 12.15a5.4 5.4 0 1 1 0-10.8 5.4 5.4 0 0 1 0 10.8Z"
        />
        <path
          fill={color}
          stroke={color}
          d="M10.65 7.075H8.625V5.05a.675.675 0 1 0-1.35 0v2.7c0 .373.302.675.675.675h2.7a.675.675 0 1 0 0-1.35Z"
        />
      </g>
    </svg>
  );
};

Clock.propTypes = {
  color: string,
  hidden: bool,
};

Clock.defaultProps = {
  color: "#1B9B9E",
  hidden: true,
};

export default Clock;
