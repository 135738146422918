/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";

import {
  FileInput,
  errorParser,
  Input,
  CurrencyInput,
} from "../../../../components/NewInput";
import SquareButton from "../../../../components/SquareButton";
import SelectInput from "../../../../components/SelectInput";
import { states, genders, races } from "../../../../libs/data";
import validationDictionary from "../../../../constants/validationDictionary";
import AutoCompleteInput from "../../../../components/AutoCompleteInput";
import useAxios from "../../../../hooks/useAxios";
import { useStatesAndCities } from "../../../../hooks/useStatesAndCities";
import { onlyNumbers } from "../../../../helpers/format";
import { focusElement } from "../../../../helpers";

import { SectionTitle } from "../../../Account/components/SectionTitle";
import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";

import styles from "./styles.module.scss";
import { useSnackBarContext } from "../../../../contexts/SnackBar";
import { isUserFullySignedUp } from "../../../../modules/Account/hooks/isUserFullySignedUp";
import { isValidDate } from "../../utils/isValidDate";

const CandidatePersonalData = () => {
  const { exec, loading } = useAxios();
  const { loading: isLocationLoading, getCities: fetchCityList } = useStatesAndCities();
  const [city, setSelectedCity] = useState(null);
  const [state, setState] = useState(null);
  const [gender, setGender] = useState(null);
  const [race, setRace] = useState(null);
  const formRef = useRef();
  const [availableCities, setAvailableCities] = useState([]);
  const { showSnackBar } = useSnackBarContext();
  const { handleUserAccessPage } = isUserFullySignedUp();

  const getCities = async () => {
    try {
      const response = await fetchCityList(Number(state));

      setAvailableCities(response);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleSelectCity = (value) => {
    if (value === '') setSelectedCity('');

    const filteredCities = availableCities.filter((city) => {
      const cityFromData = city.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(" ", "_").toLowerCase();
      const cityValue = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(" ", "_").toLowerCase();

      return cityFromData === cityValue;
    });

    if (!filteredCities.length) return "";

    return filteredCities[0].id;

  };

  const handleSubmit = async (data) => {
    try {
      data.state = state;
      data.gender = gender;
      data.race = race;
      data.city = handleSelectCity(data.city);
      data.phone = onlyNumbers(data.phone);

      const schema = Yup.object().shape({
        birthdate: Yup.string()
          .test(
            "birthdate",
            validationDictionary.INVALID_BIRTHDATE_AGE,
            (value) => {
              const date = value.split('/');
              const today = new Date();
              const age = today.getFullYear() - parseInt(date[2], 10);

              return age >= 15 && age <= 80 && isValidDate (value);
            }
          )
          .required(validationDictionary.INVALID_BIRTHDATE),
        state: Yup.number()
          .oneOf(
            states.brazil.map((s) => s.id),
            validationDictionary.INVALID_STATE_SELECT
          )
          .required(validationDictionary.INVALID_STATE_SELECT)
          .nullable(),
        city: Yup.number()
          .typeError('Selecione uma cidade válida')
          .required(validationDictionary.INVALID_CITY_SELECT)
        ,
        race: Yup.number()
          .oneOf(
            races.map((raceOrColor) => raceOrColor.value),
            validationDictionary.INVALID_RACE
          )
          .required(validationDictionary.INVALID_RACE)
          .nullable(),
        gender: Yup.number()
          .oneOf(
            genders.map((genderListItem) => genderListItem.value),
            validationDictionary.INVALID_GENDER
          )
          .required(validationDictionary.INVALID_GENDER)
          .nullable(),
        phone: Yup.string()
          .min(10, validationDictionary.INVALID_PHONE)
          .max(13, validationDictionary.INVALID_PHONE)
          .required(validationDictionary.INVALID_PHONE),
        salary: Yup.number()
          .min(1, validationDictionary.INVALID_SALARY)
          .required(validationDictionary.INVALID_SALARY),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await exec({
        useCredentials: true,
        method: "PUT",
        url: "/candidate/personal-data",
        data,
      });

      if (response.error) {
        showSnackBar({
          timeout: 5000,
          text: response.data.message,
          error: true
        });
        return;
      }

      if (response.user.status) {
        localStorage.setItem("@user_data", JSON.stringify(response.user));
        handleUserAccessPage();
      }

    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        const parsedErrors = errorParser(validationErrors);

        formRef.current.setErrors(parsedErrors);
      }
    }
  };

  useEffect(() => {
    getCities();

  }, [state]);

  useEffect(() => {
    focusElement('[data-testid="account-section-title"]');
    handleUserAccessPage();
  }, []);

  return (
    <AuthenticationWrapper>
      <SectionTitle>Dados pessoais</SectionTitle>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        className={styles.form}
      >
        <Input
          label="Data de nascimento"
          name="birthdate"
          placeholder="Insira a sua data de nascimento"
          type="text"
          mask="99/99/9999"
          ariaLabel="preenchimento da data de nascimento no formato de dois digitos para o dia, dois digitos para o mes e quatro digitos para o ano. Só pode ser inserido números neste campo"
        />
        <SelectInput
          name="gender"
          label="Gênero"
          placeholder="Selecione o seu gênero"
          setter={setGender}
          keys={{ label: "label", value: "value" }}
          options={genders}
        />
        <SelectInput
          name="race"
          label="Cor/Raça"
          placeholder="Selecione a sua cor/raça"
          setter={setRace}
          keys={{ label: "label", value: "value" }}
          options={races}
        />
        <SelectInput
          name="state"
          label="Estado"
          placeholder="Selecione um estado"
          setter={setState}
          keys={{ label: "name", value: "id" }}
          options={states.brazil}
          disabled={loading}
        />
        <AutoCompleteInput
          disabled={isLocationLoading || !state}
          placeholder="Digite o nome da cidade"
          name="city"
          label="Cidade"
          options={loading ? [] : availableCities.map((city) => city.name)}
          fetch={() => { }}
          setter={handleSelectCity}
        />
        <Input
          label="Bairro"
          name="neighborhood"
          placeholder="Digite o nome do bairro"
          type="text"
          enableFlag
        />
        <Input
          label="Celular ou telefone"
          name="phone"
          placeholder="Insira um celular ou telefone"
          type="text"
          mask="(99) 9999-99999"
        />
        <CurrencyInput
          label="Pretensão salarial"
          name="salary"
          placeholder="Insira sua pretensão salarial"
          type="text"
        />
        <FileInput
          name="resume"
          label="Anexar currículo"
          placeholder="Clique aqui para anexar o currículo"
          suffix="Formatos: DOC, DOCX, PDF, JPG, JPEG e PNG."
          validExtensions={["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "pdf", "jpeg", "jpg", "png"]}
          enableFlag
        />
        <Input
          label="Linkedin"
          name="linkedin"
          placeholder="Insira o link do seu perfil LinkedIn"
          type="text"
          enableFlag
        />
        <Input
          label="Portfolio"
          name="portfolio"
          placeholder="Insira o link do seu portfólio"
          type="text"
          enableFlag
        />
        <SquareButton disabled={loading} text="Próxima etapa" type="submit" />
      </Form>
    </AuthenticationWrapper>
  );
};

export { CandidatePersonalData };
