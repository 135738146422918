import { string, number } from "prop-types";

import styles from "./styles.module.scss";

const SearchSummary = ({ quantity, term, loading }) => {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title} tabIndex="0">
        <b>Vagas</b> encontradas
      </h1>
      {term && !loading &&
        <p className={styles.description} tabIndex="0">
          {quantity === 1
            ? `${quantity} vaga encontrada`
            : `${quantity} vagas encontradas`}{" "}
          com "{term}"
        </p>
      }
    </div>
  );
};

SearchSummary.propTypes = {
  term: string.isRequired,
  quantity: number.isRequired,
};

export default SearchSummary;
