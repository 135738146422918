import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "@unform/web";
import * as Yup from "yup";

import { InputLabel } from "../../../../components/InputLabel";
import SquareButton from "../../../../components/SquareButton";
import { Radio, errorParser } from "../../../../components/NewInput";
import { LabelTooltip } from "../../../../components/LabelTooltip";
import useAxios from "../../../../hooks/useAxios";
import validationDictionary from "../../../../constants/validationDictionary";
import { focusElement } from "../../../../helpers";

import { SectionTitle } from "../../../Account/components/SectionTitle";
import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";

import styles from "./styles.module.scss";
import { isUserFullySignedUp } from "../../../Account/hooks/isUserFullySignedUp";
import { useSnackBarContext } from "../../../../contexts/SnackBar";

const availabilityTypes = [
  {
    name: "local",
    label: "Aceita trabalhar presencial?",
    tip: "O trabalho é feito presencialmente na empresa, sendo obrigatório o funcionário deslocar-se até a empresa.",
    ariaLabel: "O que significa trabalhar presencial?",
  },
  {
    name: "remote",
    label: "Aceita trabalhar remoto?",
    tip: "O trabalho é feito remotamente/home-office, sem a obrigatoriedade do funcionário deslocar-se até a empresa.",
    ariaLabel: "O que significa trabalhar remoto?",
  },
  {
    name: "hybrid",
    label: "Aceita trabalhar no modelo híbrido?",
    tip: "A empresa permite que alguns dias o trabalho seja feito presencial e alguns dias seja remoto.",
    ariaLabel: "O que significa trabalhar no modelo híbrido?",
  },
  {
    name: "relocation",
    label: "Possui disponibilidade para mudança?",
    ariaLabel: null,
    tip: null,
  },
];

const CandidateAvailability = () => {
  const { exec, loading } = useAxios();
  const formRef = useRef();
  const history = useHistory();
  const [availabilityLocal, setAvailabilityLocal] = useState(null);
  const [availabilityRemote, setAvailabilityRemote] = useState(null);
  const [availabilityHybrid, setAvailabilityHybrid] = useState(null);
  const [availabilityRelocation, setAvailabilityRelocation] = useState(null);
  const { handleUserAccessPage } = isUserFullySignedUp();
  const { showSnackBar } = useSnackBarContext();

  const handleLogout = () => {
    localStorage.removeItem("@user_data");
    localStorage.removeItem("@user_token");
    localStorage.removeItem("@user_inkoins");
    localStorage.removeItem("@user_shares");
    localStorage.removeItem("@user_redirect");

    history.push("/");
  };

  const handleSubmit = async (data) => {
    data.local = availabilityLocal;
    data.remote = availabilityRemote;
    data.hybrid = availabilityHybrid;
    data.relocation = availabilityRelocation;

    try {
      const schema = Yup.object().shape({
        local: Yup.boolean()
          .oneOf([true, false], validationDictionary.GENERIC_REQUIRED_FIELD)
          .nullable(),
        remote: Yup.boolean()
          .oneOf([true, false], validationDictionary.GENERIC_REQUIRED_FIELD)
          .nullable(),
        hybrid: Yup.boolean()
          .oneOf([true, false], validationDictionary.GENERIC_REQUIRED_FIELD)
          .nullable(),
        relocation: Yup.boolean()
          .oneOf([true, false], validationDictionary.GENERIC_REQUIRED_FIELD)
          .nullable(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await exec({
        useCredentials: true,
        method: "PUT",
        url: "/candidate/availability",
        data,
      });

      if (response.error) {
        showSnackBar({
          timeout: 5000,
          text: response.error,
          error: true
        });
        handleLogout();
        return;
      }

      if (response.user.status) {
        localStorage.setItem("@user_data", JSON.stringify(response.user));
        handleUserAccessPage();
      }

    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        const parsedErrors = errorParser(validationErrors);

        formRef.current.setErrors(parsedErrors);
      }
    }
  };

  useEffect(() => {
    focusElement('[data-testid="account-section-title"]');
    handleUserAccessPage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthenticationWrapper>
      <SectionTitle>Disponibilidade</SectionTitle>
      <Form ref={formRef} onSubmit={handleSubmit} className={styles.form}>
        <div>
          <InputLabel
            label={availabilityTypes[0].label}
            name={availabilityTypes[0].name}
            icon={
              <LabelTooltip
                message={availabilityTypes[0].tip}
                ariaLabel={availabilityTypes[0].ariaLabel}
                name="local"
              />
            }
            tabIndex="0"
          />
          <Radio
            displayError
            name={availabilityTypes[0].name}
            options={[
              { id: true, label: "Sim" },
              { id: false, label: "Não" },
            ]}
            onChange={setAvailabilityLocal}
          />
        </div>
        <div>
          <InputLabel
            label={availabilityTypes[1].label}
            name={availabilityTypes[1].name}
            icon={
              <LabelTooltip
                message={availabilityTypes[1].tip}
                ariaLabel={availabilityTypes[1].ariaLabel}
                name="remote"
              />
            }
            tabIndex="0"
          />
          <Radio
            displayError
            name={availabilityTypes[1].name}
            options={[
              { id: true, label: "Sim" },
              { id: false, label: "Não" },
            ]}
            onChange={setAvailabilityRemote}
          />
        </div>
        <div>
          <InputLabel
            label={availabilityTypes[2].label}
            name={availabilityTypes[2].name}
            icon={
              <LabelTooltip
                message={availabilityTypes[2].tip}
                ariaLabel={availabilityTypes[2].ariaLabel}
                name="hybrid"
              />
            }
            tabIndex="0"
          />
          <Radio
            displayError
            name={availabilityTypes[2].name}
            options={[
              { id: true, label: "Sim" },
              { id: false, label: "Não" },
            ]}
            onChange={setAvailabilityHybrid}
          />
        </div>
        <div>
          <InputLabel
            label={availabilityTypes[3].label}
            name={availabilityTypes[3].name}
            tabIndex="0"
          />
          <Radio
            displayError
            name={availabilityTypes[3].name}
            options={[
              { id: true, label: "Sim" },
              { id: false, label: "Não" },
            ]}
            onChange={setAvailabilityRelocation}
          />
        </div>
        <SquareButton text="Próxima etapa" type="submit" disabled={loading} />
      </Form>
    </AuthenticationWrapper>
  );
};

export { CandidateAvailability };
