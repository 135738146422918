import React from "react";
import { node } from "prop-types";

import styles from "./styles.module.scss";

const SectionTitle = ({ children }) => (
  <h2 className={styles.title} data-testid="account-section-title">{children}</h2>
);

SectionTitle.propTypes = {
  children: node.isRequired,
};

export { SectionTitle };
