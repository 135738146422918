import { useRef, useState, useEffect } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

import {
  FileInput,
  errorParser,
  Input,
  Radio,
} from "../../../../components/NewInput";
import { InputLabel } from "../../../../components/InputLabel";
import SquareButton from "../../../../components/SquareButton";
import SquareLinkButton from "../../../../components/SquareLinkButton";
import SelectInput from "../../../../components/SelectInput";
import { disability } from "../../../../libs/data";
import validationDictionary from "../../../../constants/validationDictionary";
import { SectionTitle } from "../../../Account/components/SectionTitle";
import useAxios from "../../../../hooks/useAxios";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { focusElement } from "../../../../helpers";

import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";

import styles from "./styles.module.scss";

const disabilityTypes = ["with-disability", "without-disability"];

const CandidateEditDisability = () => {
  const { exec, loading } = useAxios();
  const history = useHistory();
  const [type, handleTypeChange] = useState(null);
  const [selectedDisability, setDisability] = useState(null);
  const formRef = useRef();
  const [data, setData] = useState(null);

  const fetchData = async () => {
    try {
      const result = await exec({
        method: "GET",
        url: `/candidate/disability`,
        useCredentials: true,
      });

      if (!result.details && !result.disability && !result.report) {
        handleTypeChange(disabilityTypes[1]);
      } else {
        setDisability(result.disability);
        handleTypeChange(disabilityTypes[0]);
      }

      setData(result);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleSubmit = async (data) => {
    try {
      if (type === disabilityTypes[1]) {
        delete data.report;
        delete data.details;
        delete data.disability_type;
        delete data.type;
        data.disability = false;
      } else {
        data.disability = true;
        data.disability_type = selectedDisability;

        const schema = Yup.object().shape({
          disability_type: Yup.number()
            .oneOf(
              disability.map((dis) => dis.value),
              validationDictionary.GENERIC_INVALID_FIELD
            )
            .required(validationDictionary.GENERIC_INVALID_FIELD)
            .nullable(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
      }

      const response = await exec({
        useCredentials: true,
        method: "PUT",
        url: "/candidate/disability",
        data,
      });

      if (response.error) return;

      localStorage.setItem("@user_data", JSON.stringify(response.user));

      history.push("/candidato/minha-conta");
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        const parsedErrors = errorParser(validationErrors);

        formRef.current.setErrors(parsedErrors);
      }
    }
  };

  useEffect(() => {
    fetchData();

    focusElement('[data-testid="account-section-title"]');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data) {
    return <LoadingSpinner />;
  }

  return (
    <AuthenticationWrapper>
      <SectionTitle>Pessoa com Deficiência (PcD)</SectionTitle>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        className={styles.form}
        initialData={{
          type: type,
          details: data.details,
          report: data.report,
          disability: data.disability,
        }}
      >
        <span>
          <InputLabel label="Você possui alguma deficiência?" name="type" />
          <Radio
            name="type"
            options={[
              { id: disabilityTypes[1], label: "Não", checked: true },
              { id: disabilityTypes[0], label: "Sim", checked: false },
            ]}
            onChange={handleTypeChange}
          />
        </span>
        {type === disabilityTypes[0] && (
          <>
            <SelectInput
              name="disability_type"
              label="Qual o tipo de deficiência?"
              placeholder="Selecione sua deficiência"
              setter={setDisability}
              keys={{ label: "label", value: "value" }}
              options={disability}
              defaultValue={data.disability}
            />
            <Input
              label="Detalhes"
              name="details"
              placeholder="Conte para nós sobre sua deficiência, do que você precisa para realizar suas atividades, se precisa de alguma tecnologia ou de algum acessório no seu dia a dia."
              type="textarea"
              disabled={loading}
              enableFlag
            />
            <FileInput
              name="report"
              label="Anexar laudo"
              placeholder="Clique aqui para anexar o laudo"
              suffix="Formatos: DOC, DOCX, PDF, JPG, JPEG e PNG."
              validExtensions={["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "pdf", "jpeg", "jpg", "png"]}
              defaultValue={data.report}
              enableFlag
            />
          </>
        )}
        <SquareButton
          testID="submit-availability-form-button"
          disabled={loading}
          text="Concluir"
          type="submit"
        />
      </Form>
      <SquareLinkButton
        testID="back-to-my-account-button"
        text="Voltar"
        url="/candidato/minha-conta"
        type="ghost"
      />
    </AuthenticationWrapper>
  );
};

export { CandidateEditDisability };
