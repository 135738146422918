import { string } from "prop-types";

import { UserInformationWithAvatar } from "../../../../../components/UserInformationWithAvatar";

const WelcomeMessage = ({ name }) => (
  <UserInformationWithAvatar primaryText={name} secondaryText="Bem vindo(a)" />
);

WelcomeMessage.propTypes = {
  name: string.isRequired,
};

export default WelcomeMessage;
