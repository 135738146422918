import React from "react";
import { string } from "prop-types";

const CrossedEye = ({ color }) => {
  return (
    <svg
      width="20px"
      height="14px"
      viewBox="0 0 20 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M12,13.5 C11.173,13.5 10.5,12.827 10.5,12 C10.5,11.173 11.173,10.5 12,10.5 C12.827,10.5 13.5,11.173 13.5,12 C13.5,12.827 12.827,13.5 12,13.5 M12,8.5 C10.07,8.5 8.5,10.07 8.5,12 C8.5,13.93 10.07,15.5 12,15.5 C13.93,15.5 15.5,13.93 15.5,12 C15.5,10.07 13.93,8.5 12,8.5 M12.2197,16.9976 C7.9137,17.0976 5.1047,13.4146 4.1727,11.9956 C5.1987,10.3906 7.7827,7.1046 11.7807,7.0026 C16.0697,6.8936 18.8947,10.5856 19.8267,12.0046 C18.8017,13.6096 16.2167,16.8956 12.2197,16.9976 M21.8677,11.5026 C21.2297,10.3906 17.7057,4.8166 11.7297,5.0036 C6.2017,5.1436 2.9867,10.0136 2.1327,11.5026 C1.9557,11.8106 1.9557,12.1896 2.1327,12.4976 C2.7617,13.5946 6.1617,18.9996 12.0247,18.9996 C12.1067,18.9996 12.1887,18.9986 12.2707,18.9966 C17.7977,18.8556 21.0137,13.9866 21.8677,12.4976 C22.0437,12.1896 22.0437,11.8106 21.8677,11.5026"
          id="open-eye-path-1"
        ></path>
      </defs>
      <g
        id="🧩📱componentes:mobile"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="terms-error-state"
          transform="translate(-315.000000, -83.000000)"
        >
          <g
            id="field-pass-confirmation-view-on"
            transform="translate(35.000000, 38.000000)"
          >
            <g id="form-body" transform="translate(0.000000, 24.000000)">
              <g id="bg-form">
                <rect
                  id="bg"
                  fill="transparent"
                  x="0"
                  y="0"
                  width="318"
                  height="56"
                  rx="10"
                ></rect>
                <rect
                  id="stroke"
                  stroke="#E4E9F2"
                  x="0.5"
                  y="0.5"
                  width="317"
                  height="55"
                  rx="10"
                ></rect>
              </g>
              <g id="27)-Icon/eye" transform="translate(278.000000, 16.000000)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#open-eye-path-1"></use>
                </mask>
                <use
                  id="🎨-Icon-Сolor"
                  fill={color}
                  fillRule="evenodd"
                  xlinkHref="#open-eye-path-1"
                ></use>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

CrossedEye.propTypes = {
  color: string,
};

CrossedEye.defaultProps = {
  color: "#1B9B9E",
};

export default CrossedEye;
