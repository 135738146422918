import React from "react";
import { string } from "prop-types";
import "../../libs/newIcons/style.scss";
import useEnterPriseEnvCheck from "../../hooks/useEnterpriseEnvCheck";
import styles from "./styles.module.scss";

const LocationChip = ({ location, ...rest }) => {
  const { isEnterpriseEnv } = useEnterPriseEnvCheck();

  return (
    <span
      className={[
        styles.location,
        isEnterpriseEnv ? styles.enterprise : styles.default,
      ].join(' ')}
      {...rest}
    >
      <i
        className="icon-pin"
        aria-hidden="true"
        aria-label={`Ícone de ${location}`}
      />
      {location}
    </span>
  );
};

LocationChip.propTypes = {
  location: string.isRequired,
};

export default LocationChip;
