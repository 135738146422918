import React, { useRef, useEffect, useState } from "react";
import { useField } from "@unform/core";
import { string, oneOfType } from "prop-types";
import InputMask from "react-input-mask";

import styles from "./styles.module.scss";

// TO-DO: create a custom input with custom mask address
const NumberCC = ({
  label,
  name,
  placeholder,
  defaultValue: inputDefaultValue,
  ...rest
}) => {
  const inputRef = useRef(null);
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      getValue(refs) {
        if (!refs?.value || refs.value === "") {
          return '';
        }
        const onlyNumbers = refs.value.match(/[0-9]/g).join("");
        return onlyNumbers;
      },
    });
  }, [fieldName, registerField]);

  return (
    <fieldset className={styles.wrapper}>
      <label htmlFor={`field-${name}`} className={styles.label}>
        {label}
      </label>
      <InputMask
        className={[styles.field, error && styles.invalid].join(" ")}
        ref={inputRef}
        id={`field-${name}`}
        defaultValue={defaultValue}
        placeholder={placeholder}
        type="text"
        onFocus={clearError}
        aria-invalid={Boolean(error)}
        aria-describedby={`error_${name}`}
        mask="9999 9999 9999 9999"
        maskPlaceholder=""
        {...rest}
      />
      {error && (
        <span
          className={styles.error}
          id={`error_${name}`}
          data-testid={`error-message-${name}`}
        >
          {error}
        </span>
      )}
    </fieldset>
  );
};

const CodeCC = ({
  label,
  name,
  placeholder,
  defaultValue: inputDefaultValue,
  ...rest
}) => {
  const inputRef = useRef(null);
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      getValue(refs) {
        if (!refs?.value || refs.value === "") {
          return '';
        }

        const onlyNumbers = refs.value.match(/[0-9]/g).join("");
        return onlyNumbers;
      },
    });
  }, [fieldName, registerField]);

  return (
    <fieldset className={styles.wrapper}>
      <label htmlFor={`field-${name}`} className={styles.label}>
        {label}
      </label>
      <InputMask
        className={[styles.field, error && styles.invalid].join(" ")}
        ref={inputRef}
        id={`field-${name}`}
        defaultValue={defaultValue}
        placeholder={placeholder}
        type="text"
        onFocus={clearError}
        aria-invalid={Boolean(error)}
        aria-describedby={`error_${name}`}
        mask="999"
        maskPlaceholder=""
        {...rest}
      />
      {error && (
        <span
          className={styles.error}
          id={`error_${name}`}
          data-testid={`error-message-${name}`}
        >
          {error}
        </span>
      )}
    </fieldset>
  );
};

const ExpDateCC = ({
  label,
  name,
  placeholder,
  defaultValue: inputDefaultValue,
  ...rest
}) => {
  const inputRef = useRef(null);
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      getValue(refs) {
        if (!refs?.value || refs.value === "") {
          return '';
        }

        return refs.value;
      },
    });
  }, [fieldName, registerField]);

  return (
    <fieldset className={styles.wrapper}>
      <label htmlFor={`field-${name}`} className={styles.label}>
        {label}
      </label>
      <InputMask
        className={[styles.field, error && styles.invalid].join(" ")}
        ref={inputRef}
        id={`field-${name}`}
        defaultValue={defaultValue}
        placeholder={placeholder}
        type="text"
        onFocus={clearError}
        aria-invalid={Boolean(error)}
        aria-describedby={`error_${name}`}
        mask="99/99"
        maskPlaceholder=""
        {...rest}
      />
      {error && (
        <span
          className={styles.error}
          id={`error_${name}`}
          data-testid={`error-message-${name}`}
        >
          {error}
        </span>
      )}
    </fieldset>
  );
};

const CustomerDocument = ({
  label,
  name,
  placeholder,
  defaultValue: inputDefaultValue,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [mask, setMask] = useState("999.999.999-999");
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      getValue(refs) {
        if (!refs?.value || refs.value === "") {
          return '';
        }
        return refs.value.replace(/[^0-9]/g, "");
      },
    });
  }, [fieldName, registerField]);

  const handleInputValue = (event) => {
    const { target } = event;
    let nums = target.value.replace(/[^0-9]/g, "");

    if (nums.length > 11) {
      setMask("99.999.999/9999-99");
    } else {
      setMask("999.999.999-999");
    }
    setInputValue(target.value);
  }

  return (
    <fieldset className={styles.wrapper}>
      <label htmlFor={`field-${name}`} className={styles.label}>
        {label}
      </label>
      <InputMask
        className={[styles.field, error && styles.invalid].join(" ")}
        ref={inputRef}
        id={`field-${name}`}
        defaultValue={defaultValue}
        placeholder={placeholder}
        type="text"
        onFocus={clearError}
        aria-invalid={Boolean(error)}
        aria-describedby={`error_${name}`}
        mask={mask}
        maskPlaceholder=""
        onChange={handleInputValue}
        value={inputValue}
        {...rest}
      />
      {error && (
        <span
          className={styles.error}
          id={`error_${name}`}
          data-testid={`error-message-${name}`}
        >
          {error}
        </span>
      )}
    </fieldset>
  );
};

const CEPCustomer = ({
  label,
  name,
  placeholder,
  defaultValue: inputDefaultValue,
  ...rest
}) => {
  const inputRef = useRef(null);
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      getValue(refs) {
        if (!refs?.value || refs.value === "") {
          return '';
        }

        const onlyNumbers = refs.value.match(/[0-9]/g).join("");
        return onlyNumbers;
      },
    });
  }, [fieldName, registerField]);

  return (
    <fieldset className={styles.wrapper}>
      <label htmlFor={`field-${name}`} className={styles.label}>
        {label}
      </label>
      <InputMask
        className={[styles.field, error && styles.invalid].join(" ")}
        ref={inputRef}
        id={`field-${name}`}
        defaultValue={defaultValue}
        placeholder={placeholder}
        type="text"
        onFocus={clearError}
        aria-invalid={Boolean(error)}
        aria-describedby={`error_${name}`}
        mask="99999-999"
        maskPlaceholder=""
        {...rest}
      />
      {error && (
        <span
          className={styles.error}
          id={`error_${name}`}
          data-testid={`error-message-${name}`}
        >
          {error}
        </span>
      )}
    </fieldset>
  );
};

NumberCC.propTypes = {
  label: string.isRequired,
  name: string.isRequired,
  placeholder: string,
  defaultValue: oneOfType([string]),
};

NumberCC.defaultProps = {
  placeholder: null,
  defaultValue: null,
};

CodeCC.propTypes = {
  label: string.isRequired,
  name: string.isRequired,
  placeholder: string,
  defaultValue: oneOfType([string]),
};

CodeCC.defaultProps = {
  placeholder: null,
  defaultValue: null,
};

ExpDateCC.propTypes = {
  label: string.isRequired,
  name: string.isRequired,
  placeholder: string,
  defaultValue: oneOfType([string]),
};

ExpDateCC.defaultProps = {
  placeholder: null,
  defaultValue: null,
};

export { NumberCC, ExpDateCC, CodeCC, CustomerDocument, CEPCustomer };
