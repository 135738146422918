import { node } from "prop-types";

import styles from "./styles.module.scss";

const Steps = ({ children }) => {
  return <div className={styles.wrapper}>{children}</div>;
};

Steps.propTypes = {
  children: node.isRequired,
};

export { Steps };
