import React from "react";
import { oneOf, node, string, bool } from "prop-types";

import styles from "./styles.module.scss";

const SectionTitle = ({
  children,
  level,
  color,
  linebreak,
  small,
  centered,
  extraStyle,
}) => {
  const renderContent = () => ({
    __html: children,
  });

  if (level === "h2") {
    return (
      <h2
        className={[
          styles.title,
          extraStyle,
          centered && styles.centered,
          small && styles.small,
          linebreak && styles.linebreak,
          extraStyle,
        ]
          .filter((i) => i !== "")
          .join(" ")}
        style={{ color: color }}
        dangerouslySetInnerHTML={renderContent()}
        tabIndex="0"
        data-testid="section-title"
      />
    );
  }

  if (level === "span") {
    return (
      <span
        className={[
          styles.title,
          extraStyle,
          centered && styles.centered,
          small && styles.small,
          linebreak && styles.linebreak,
          extraStyle,
        ]
          .filter((i) => i !== "")
          .join(" ")}
        style={{ color: color }}
        dangerouslySetInnerHTML={renderContent()}
        tabIndex="0"
        data-testid="section-title"
      />
    );
  }

  return (
    <p
      className={[
        styles.title,
        extraStyle,
        centered && styles.centered,
        small && styles.small,
        linebreak && styles.linebreak,
        extraStyle,
      ]
        .filter((i) => i !== "")
        .join(" ")}
      style={{ color: color }}
      dangerouslySetInnerHTML={renderContent()}
      tabIndex="0"
      data-testid="section-title"
    />
  );
};

SectionTitle.propTypes = {
  children: node.isRequired,
  level: oneOf(["h2", "span", "p"]).isRequired,
  color: string,
  linebreak: bool,
  small: bool,
  centered: bool,
  extraStyle: node,
};

SectionTitle.defaultProps = {
  color: null,
  linebreak: true,
  small: false,
  centered: false,
  extraStyle: "",
};

export default SectionTitle;
