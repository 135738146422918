const PinIcon = ({
    color, ...rest
}) => {
    return (
        <svg width="10px" height="16px" viewBox="0 0 10 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...rest}>
            <g id="🖥️-Telas-DesktopView" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="componente-linha-de-vaga-1" transform="translate(-144.000000, -153.000000)">
                    <g id="salario-tag" transform="translate(128.000000, 145.000000)">
                        <rect id="bg-tag" fill="#F0F7F7" x="0" y="0" width="77" height="32" rx="16"></rect>
                        <g id="content" transform="translate(14.000000, 7.000000)" fill={color} stroke={color}>
                            <g id="cifrao-icon" transform="translate(0.000000, 2.000000)">
                                <g id="inkoin-icon">
                                    <path d="M10.6957687,7.92582939 C10.1189815,6.86641751 8.77349617,6.26015854 7.00201081,6.26015854 C6.98830289,6.2611861 6.97459497,6.26015854 6.95983259,6.26015854 C5.53737203,6.26015854 4.78449075,5.80906076 4.55356498,5.38570703 C4.38063427,5.06819174 4.4913521,4.79075119 4.6147234,4.61401128 C4.92367889,4.17216152 5.75458988,3.75189047 7.17915935,3.90910678 C7.8708822,3.98411848 8.58369417,4.2163465 9.40933288,4.48351147 L9.55801111,4.53283423 C9.96081314,4.66025137 10.3857587,4.45165718 10.5186201,4.06426797 C10.6504271,3.67687876 10.4353181,3.25763527 10.0377884,3.12816301 L9.88911016,3.08089536 C9.14677344,2.83941933 8.43712484,2.61541178 7.71587723,2.4962151 L7.71587723,0.924051989 C7.71587723,0.516111627 7.37528809,0.184210526 6.95666922,0.184210526 C6.53699591,0.184210526 6.19640676,0.516111627 6.19640676,0.924051989 L6.19640676,2.41914828 C4.94160463,2.50751823 3.91878273,2.98122228 3.35886682,3.7827172 C2.86749053,4.48659414 2.81265884,5.34563229 3.21335195,6.08033596 C3.78803024,7.13461004 5.1535502,7.7388139 6.95983259,7.7388139 L6.98408507,7.7388139 C8.33484265,7.7655304 9.12146651,8.19093924 9.35450119,8.61943076 C9.49474378,8.87734771 9.47470912,9.15684337 9.29861504,9.40859498 C8.99809521,9.84016917 8.17772877,10.2481095 6.77952069,10.0908932 C6.0909612,10.0158815 5.38553043,9.78673618 4.56832736,9.52059876 L4.4059412,9.46716577 C4.00630254,9.34180374 3.57819358,9.54834282 3.44533218,9.93573203 C3.31352523,10.3241488 3.52863417,10.7423647 3.92616392,10.871837 L4.08749562,10.9242424 C4.80874323,11.1585256 5.4951938,11.3743126 6.19640676,11.4976196 L6.19640676,13.0769756 C6.19640676,13.4849159 6.53699591,13.8157895 6.95666922,13.8157895 C7.37528809,13.8157895 7.71587723,13.4849159 7.71587723,13.0769756 L7.71587723,11.5705761 C8.96857045,11.4914542 9.99877354,11.0362462 10.5544716,10.2419442 C11.0479568,9.53498457 11.1006796,8.66978108 10.6957687,7.92582939" id="Fill-1" strokeWidth="0.8"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

PinIcon.defaultProps = {
    color: '#1B9B9E',
};

export default PinIcon;