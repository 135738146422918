import { NewInformationSummary } from "../../modules/Account/components/NewInformationSummary";
import AccountExclusion from "../../modules/Account/components/AccountExclusion";

import styles from "./styles.module.scss";

const DeleteAccountSummary = () => {
  return (
    <>
      <NewInformationSummary title="Deletar Conta" />
      <p className={styles.paragraph}>
        Desejo apagar todos os meus dados e informações cadastradas por mim no site da 
        Inklua e não receber mais contatos, anúncios de vagas ou convites para entrevistas.
      </p>
      <AccountExclusion />
    </>
  );
};

export { DeleteAccountSummary };
