import { useEffect, useRef, useState } from "react";
import styles from './styles.module.scss';
import { Input } from '../NewInput';

const InputAutoComplete = ({
  placeholder,
  label,
  options,
  defaultValue,
  ...rest
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [inputValue, setInputValue] = useState(defaultValue || '');
  const divRef = useRef(null);

  const optionsFiltered = options?.filter(option => option
    ?.toLowerCase()
    ?.normalize('NFD')
    ?.replace(/\p{Mn}/gu, "")
    ?.includes(inputValue?.toLowerCase())
  );

  const handleChange = (e) => {
    e.preventDefault();
    setInputValue(e.target.value);
  }

  const handleOptionSelect = (option) => {
    setInputValue(option);
    rest?.onChange && rest?.onChange(option);
    setShowOptions(false);
  }

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  useEffect(() => {
    if (!defaultValue) return;
    const option = options?.find(option => option === defaultValue) ;
    setInputValue(option || '');
  }, [defaultValue])

  return (
    <div
      className={styles.container}
      onFocus={() => setShowOptions(true)}
      ref={divRef}
      key={defaultValue}
    >
      <Input
        type='text'
        label={label}
        value={inputValue}
        error={rest?.error}
        onChange={handleChange}
        disabled={rest?.disabled}
        placeholder={placeholder}
        name={rest?.name || label.toLocaleLowerCase()}
      />
      {showOptions && inputValue && optionsFiltered && (
        <ul className={styles.optionsContainer}>
          {optionsFiltered.map((option, index) => (
            <li key={`${option?.value}-${index}`}>
              <button
                className={styles.option}
                onClick={() => handleOptionSelect(option)}
              >
                {option}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
};

export default InputAutoComplete;