import React from "react";
import { string, oneOf, func, bool, node } from "prop-types";

import styles from "./styles.module.scss";

const SquareButton = ({
  text,
  type,
  onClick,
  disabled,
  outlined,
  simple,
  icon,
  secondaryClass,
  dataTest,
}) => {
  return (
    <button
      disabled={disabled}
      aria-disabled={disabled}
      className={[
        styles.button,
        secondaryClass,
        disabled && styles.disabled,
        outlined && styles.outlined,
        simple && styles.simple,
      ].join(" ")}
      type={type}
      onClick={onClick}
      data-test={dataTest}
    >
      {icon && <span className={styles.iconWrapper}>{icon}</span>}
      {text}
    </button>
  );
};

SquareButton.propTypes = {
  icon: node,
  text: string.isRequired,
  type: oneOf(["button", "submit"]),
  outlined: bool,
  simple: bool,
  onClick: func,
  disabled: bool,
  secondaryClass: string,
  dataTest: string,
};

SquareButton.defaultProps = {
  icon: null,
  onClick: () => {},
  type: "button",
  disabled: false,
  outlined: false,
  simple: false,
  secondaryClass: "",
  dataTest: "square-button",
};

export default SquareButton;
