export const consultoria = {
    header: {
        title: "Consultoria de R&S",
        description: `
        Serviço de consultoria de recrutamento e 
        seleção de pessoas com deficiência. A Inklua 
        recruta, seleciona e apresenta de 3 a 5 candidatos 
        contratáveis para a posição em um prazo de 5 dias úteis.
        `,
    },
    fields: [
        {
            title: "Ideal para quem?",
            description: `
            Empresas que precisam de solução completa de 
            recrutamento e seleção, de forma ágil e assertiva.
            `,
        },
        {
            title: "Qual a abrangência?",
            description: `Nacional`,
        },
        {
            title: "Qual a forma de remuneração?",
            description: `
            Remuneração no sucesso (apenas se contratar um profissional).
            `,
        },
        {
            title: "Qual o custo?",
            description: `
            Variável, em função da remuneração do profissional.
            `,
        },
        {
            title: "Quais meios de pagamento?",
            description: `Depósito ou boleto.`,
        }
        ,
        {
            title: "Como contratar?",
            description: `Contrato com a área comercial.`,
        }
    ],
    button: {
        label: 'Conversar com a consultoria',
        icon: 'check',
    },
};

export const mapTech = {
    header: {
        title: "Mapeamento Tech",
        description: `
        A consultoria da Inklua disponibiliza o 
        briefing dos profissionais de tecnologia 
        já mapeados para consulta livre pelas empresas. 
        Uma vez interessada por um candidato, só entrar em contato com a Inklua.
        `,
    },
    fields: [
        {
            title: "Ideal para quem?",
            description: `
            Empresas com urgência na busca de profissionais de tecnologia.
            `,
        },
        {
            title: "Qual a abrangência?",
            description: `Nacional`,
        },
        {
            title: "Qual a forma de remuneração?",
            description: `
            Remuneração no sucesso (apenas se contratar um profissional).
            `,
        },
        {
            title: "Qual o custo?",
            description: `
            Variável, em função da remuneração do profissional.
            `,
        },
        {
            title: "Quais meios de pagamento?",
            description: `Depósito ou boleto.`,
        }
        ,
        {
            title: "Como contratar?",
            description: `Contrato com a área comercial.`,
        }
    ],
    button: {
        label: 'Conferir profissionais',
        icon: 'search',
    }
};

export const perfil = {
    header: {
        title: "Busca de Perfil",
        description: `
        A Inklua oferece a mesma ferramente que sua consultoria utiliza, 
        uma plataforma que permite abordar qualquer pessoa de nossa base para 
        ofertar uma oportunidade de emprego.
        `,
    },
    fields: [
        {
            title: "Ideal para quem?",
            description: `
            Ideal para empresas que queiram buscar ativamente por candidatos e precisam
            de acesso ilimitado a perfis profissionais de pessoas com deficiência.`,
        },
        {
            title: "Qual a abrangência?",
            description: `Nacional`,
        },
        {
            title: "Qual a forma de remuneração?",
            description: `Contrato anual.`,
        },
        {
            title: "Qual o custo?",
            description: `R$ 12.000,00 por ano.`,
        },
        {
            title: "Quais meios de pagamento?",
            description: `Depósito ou boleto.`,
        }
        ,
        {
            title: "Como contratar?",
            description: `Contrato com a área comercial.`,
        }
    ],
    button: {
        label: 'Converse com a Inklua',
        icon: 'search',
    }
};

export const anuncio = {
    header: {
        title: "Anúncio de vagas",
        description: `
        A Inklua oferece o serviço de anúncio de 
        vaga onde sua empresa cadastra as vagas no site 
        da Inklua e os candidatos interessados são direcionados 
        para o endereço de candidatura indicados por sua empresa.
        `,
    },
    fields: [
        {
            title: "Ideal para quem?",
            description: `
            Empresas que precisam apenas divulgar vagas e 
            dispõem de recursos internos capazes de conduzir 
            todo o processo de forma ágil e assertiva.
            `,
        },
        {
            title: "Qual a abrangência?",
            description: `Nacional`,
        },
        {
            title: "Qual a forma de remuneração?",
            description: `
            Pagamento na criação do anúncio.
            `,
        },
        {
            title: "Qual o custo?",
            description: `
            O 1º anúncio é gratuito para sua empresa! 
            A partir do segundo é cobrado R$ 250,00 (por anúncio).
            `,
        },
        {
            title: "Quais meios de pagamento?",
            description: `Cartão ou boleto.`,
        }
        ,
        {
            title: "Como contratar?",
            description: `Direto na plataforma`,
        }
    ],
    button: {
        label: 'Anunciar vaga',
        icon: 'folder',
    }
};
