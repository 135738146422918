import { useEffect, useState } from "react";
import { string, number, oneOfType, bool } from "prop-types";
import { useHistory } from "react-router-dom";

import { PositionInformationSummary } from "../../components/PositionInformationSummary";
import { Modal } from "../../components/Modal";
import ShareButton from "../../components/ShareButton";
import SquareButton from "../../components/SquareButton";
import SquareLinkButton from "../../components/SquareLinkButton";
import { LeftArrow } from "../../libs/icons";
import { toCurrency, isMobile, lockScroll } from "../../helpers";
import useAxios from "../../hooks/useAxios";
import { useRedirect } from "../../hooks/useRedirect";
import { useQuery } from "../../hooks/useQuery";

import SignInForm from "../../modules/authentication/components/SignInForm";

import { RecruiterInformation } from "./RecruiterInformation";
import { DesktopFooter } from "./DesktopFooter";
import { MobileFooter } from "./MobileFooter";

import styles from "./styles.module.scss";
import { isUserFullySignedUp } from "../../modules/Account/hooks/isUserFullySignedUp";
import { LoadingSpinner } from "../LoadingSpinner";

const PositionDescription = ({
  title,
  date,
  location,
  text,
  url,
  id,
  shares,
  company,
  application,
  applicationType,
  remote,
  hybrid,
  presential,
  salary,
  illustrationUrl,
  illustrationAltText,
  benefits,
  requirements,
  recruiterName,
  shift,
  englishLevel,
  disableLike,
  alreadyLiked,
  compleoJob,
  fetchUserData
}) => {
  const history = useHistory();
  const { setRedirect } = useRedirect();
  const [openModal, setOpenModal] = useState(false);
  const [isSignInModalVisible, setIsSignInModalVisible] = useState(false);
  const [likeSent, setLikeSent] = useState(false);
  const isExternCompany = company !== "Inklua" && company !== null;
  const isMobileViewport = isMobile();
  const isUserLoggedIn = localStorage.getItem("@user_token");
  const { checkUserStatusAndRedirect } = isUserFullySignedUp();

  checkUserStatusAndRedirect();

  const { exec, loading } = useAxios();

  const sendLike = async () => {
    if (!isUserLoggedIn) {
      setIsSignInModalVisible(true);
      setRedirect({ path: `${window.location.pathname}?like=true` });
      return;
    }

    if (isExternCompany) {
      setOpenModal(true);
    }

    if (likeSent) return;

    await exec({
      method: "POST",
      url: "/job/like",
      useCredentials: true,
      data: {
        job_id: id,
      },
    });

    setLikeSent(true);
  };

  const renderContent = (content) => ({
    __html: content.replaceAll("\n", "<br />"),
  });

  const handleGoBack = () => {
    history.goBack();
  };

  const onAuthSuccess = () => {
    fetchUserData();
    setIsSignInModalVisible(false);
  };

  useEffect(() => {
    lockScroll(isSignInModalVisible || openModal);
  }, [isSignInModalVisible, openModal]);

  return (
    <>
      <main className={styles.container}>
        <div className={styles.wrapper}>
          <div className={[styles.column, styles.content].join(" ")}>
            <h1 tabIndex="0">{title}</h1>
            <p className={styles.date} tabIndex="0">
              {company.length >= 14 && isMobileViewport &&
                <>
                  <b>
                    <span>{company}</span>
                  </b>
                  <br />
                  Publicada em: <b>{date}</b>
                </>
              }
              {(company.length < 14 || !isMobileViewport) &&
                <>
                  <b>
                    <span>{company}</span> | {" "}
                  </b>
                  {" "} Publicada em: <b>{date}</b>
                </>
              }
            </p>
            <div>
              <PositionInformationSummary
                location={location}
                remote={remote}
                hybrid={hybrid}
                presential={presential}
                salary={salary ? toCurrency({ value: salary }) : null}
                shift={shift}
                englishLevel={englishLevel}
              />
            </div>
            <div
              className={styles.descriptionWrapper}
              dangerouslySetInnerHTML={renderContent(text)}
              tabIndex="0"
            />
            {benefits && (
              <div className={styles.sectionWrapper}>
                <h2 className={styles.sectionTitle} tabIndex={0}>Benefícios</h2>
                <p tabIndex={0} dangerouslySetInnerHTML={renderContent(benefits)} />
              </div>
            )}
            {requirements && (
              <div className={styles.sectionWrapper}>
                <h2 className={styles.sectionTitle} tabIndex={0}>Requisitos</h2>
                <p tabIndex={0} dangerouslySetInnerHTML={renderContent(requirements)} />
              </div>
            )}
            {isMobileViewport ? (
              <>
                <RecruiterInformation recruiterName={recruiterName} />
                <MobileFooter
                  recruiterName={recruiterName}
                  handleGoBack={handleGoBack}
                  handleLike={sendLike}
                  disableButton={disableLike}
                  alreadyLiked={likeSent || alreadyLiked}
                  shareInformation={{
                    shares,
                    id,
                    title,
                  }}
                  compleoJob={compleoJob}
                  url={url}
                  externalJob={isExternCompany}
                  handleModal={setOpenModal}
                />
              </>
            ) : (
              <DesktopFooter
                handleLike={sendLike}
                disableButton={disableLike}
                alreadyLiked={likeSent || alreadyLiked}
                externalJob={isExternCompany}
                handleModal={setOpenModal}
                compleoJob={compleoJob}
                url={url}
                loading={loading}
              />
            )}
          </div>
          <div className={[styles.column, styles.sidebar].join(" ")}>
            {!isMobileViewport && (
              <>
                <button onClick={handleGoBack} className={styles.backButton}>
                  <LeftArrow /> Voltar
                </button>
                <img
                  className={styles.illustration}
                  src={illustrationUrl}
                  alt={illustrationAltText}
                  tabIndex={0}
                />
                <RecruiterInformation recruiterName={recruiterName} />
                <div className={styles.shareSection}>
                  <p className={styles.shareTitle} tabIndex={0}>Compartilhe esta vaga</p>
                  <ShareButton
                    text="Compartilhar esta vaga"
                    title={title}
                    url={window.location.href}
                    id={id}
                    shares={shares}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </main>
      <Modal
        title="Esta vaga não é da consultoria da Inklua"
        handleClose={() => setOpenModal(false)}
        visible={openModal}
      >
        <div className={styles.modalContent}>
          {applicationType === "url" ? (
            <>
              <span>
                Você será direcionado para o site da empresa anunciante para
                continuar o processo.
              </span>
              <SquareLinkButton
                testID="continue-process-button"
                inline
                text="Continuar processo"
                url={url || application}
                rel="external"
              />
            </>
          ) : (
            <>
              <span>
                Para concluir o processo é necessário que você envie o seu
                <strong> currículo</strong> por e-mail para a empresa
                anunciante.
              </span>
              <span><strong>{application}</strong></span>
              <SquareButton
                testID="send-my-resume-button"
                inline
                text="Copiar e-mail"
                onClick={() => navigator.clipboard.writeText(application)}
              />
            </>
          )}
        </div>
      </Modal>
      <Modal
        handleClose={() => setIsSignInModalVisible(false)}
        visible={isSignInModalVisible}
      >
        <div className={styles.modalContent}>
          <SignInForm
            title="Gostou dessa <em>vaga?</em>"
            description={
              <>
                Faça o login ou se ainda não tem conta,
                <br /> crie agora mesmo.
              </>
            }
            onSuccess={onAuthSuccess}
          />
        </div>
      </Modal>
    </>
  );
};

PositionDescription.propTypes = {
  title: string.isRequired,
  date: string.isRequired,
  text: string.isRequired,
  url: string,
  id: oneOfType([string, number]).isRequired,
  company: string.isRequired,
  location: string.isRequired,
  illustrationUrl: string,
  illustrationAltText: string,
  benefits: string,
  requirements: string,
  recruiterName: string.isRequired,
  shift: string.isRequired,
  englishLevel: string,
  disableLike: bool,
  alreadyLiked: bool,
  application: string,
};

PositionDescription.defaultProps = {
  illustrationUrl: null,
  illustrationAltText: null,
  benefits: null,
  requirements: null,
  shift: null,
  englishLevel: null,
  disableLike: false,
  alreadyLiked: false,
  url: null,
  application: null,
};

export default PositionDescription;
