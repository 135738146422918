import { useRef, useState, useEffect } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

import {
  errorParser,
  validateFileField,
  Input,
} from "../../../../components/NewInput";
import SquareButton from "../../../../components/SquareButton";
import SquareLinkButton from "../../../../components/SquareLinkButton";
import validationDictionary from "../../../../constants/validationDictionary";
import { SectionTitle } from "../../../Account/components/SectionTitle";
import useAxios from "../../../../hooks/useAxios";
import { BooleanCheckbox } from "../../../../components/NewInput";
import { focusElement } from "../../../../helpers";

import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";

import styles from "./styles.module.scss";
import { isValidDateMMYYYY } from "../../../Candidate/utils/isValidDate";

Yup.addMethod(Yup.string, 'dateMMYYYY', function (errorMessage) {
  return this.test('dateMMYYYY', errorMessage, function (value) {
    const { path, createError } = this;
    if (!value || !isValidDateMMYYYY(value)) {
      return createError({ path, message: errorMessage });
    }

    return true;
  });
});

const CandidateAddNewExperience = () => {
  const { exec, loading } = useAxios();
  const [isOnGoing, setOnGoing] = useState(false);
  const history = useHistory();
  const formRef = useRef();

  const handleOngoing = ({ checked }) => {
    setOnGoing(checked);
  };

  const handleSubmit = async (data) => {
    try {
      const schema = Yup.object().shape({
        resume: validateFileField,
        role: Yup.string()
          .min(3, validationDictionary.INVALID_ROLE)
          .required(validationDictionary.INVALID_ROLE),
        company: Yup.string()
          .min(3, validationDictionary.INVALID_COMPANY_NAME)
          .required(validationDictionary.INVALID_COMPANY_NAME),
        start_date: Yup.string()
          .dateMMYYYY(validationDictionary.INVALID_DATE)
          .min(3, validationDictionary.INVALID_DATE)
          .required(validationDictionary.INVALID_DATE),
        end_date:
          !isOnGoing &&
          Yup.string()
            .dateMMYYYY(validationDictionary.INVALID_DATE)
            .min(3, validationDictionary.INVALID_DATE)
            .required(validationDictionary.INVALID_DATE),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await exec({
        useCredentials: true,
        method: "POST",
        url: "/candidate/experience",
        data,
      });

      if (response.error) {
        return;
      }

      history.goBack();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        const parsedErrors = errorParser(validationErrors);

        formRef.current.setErrors(parsedErrors);
      }
    }
  };

  useEffect(() => {
    focusElement('[data-testid="section-title"]');
  }, []);

  return (
    <>
      <AuthenticationWrapper>
        <SectionTitle>Experiência</SectionTitle>
        <Form ref={formRef} onSubmit={handleSubmit} className={styles.form}>
          <Input
            label="Cargo"
            name="role"
            placeholder="Insira o cargo"
            type="text"
          />
          <Input
            label="Empresa"
            name="company"
            placeholder="Insira o nome da empresa"
            type="text"
          />
          <Input
            label="Data de início"
            name="start_date"
            placeholder="Ex: 01/2010"
            type="text"
            mask="99/9999"
            ariaLabel="Preenchimento da data de início, no formato de dois digitos para o mês e quatro digitos para o ano. Só pode ser inserido números neste campo"
          />
          <BooleanCheckbox
            name="ongoing"
            onChange={handleOngoing}
            alternativeStyle
          >
            É o meu emprego atualmente
          </BooleanCheckbox>
          {!isOnGoing && (
            <Input
              label="Data de término"
              name="end_date"
              placeholder="Ex: 01/2010"
              type="text"
              mask="99/9999"
              ariaLabel="Preenchimento da data de término, no formato de dois digitos para o mês e quatro digitos para o ano. Só pode ser inserido números neste campo"
            />
          )}
          <Input
            label="Descrição"
            name="description"
            placeholder="Descreva brevemente as suas atividades, no que você atua/atuava nessa função."
            type="textarea"
            enableFlag
          />
          <SquareButton text="Concluir" type="submit" disabled={loading} />
        </Form>
        <SquareLinkButton
          testID="back-to-experiences-list"
          text="Voltar"
          url="/candidato/minha-conta/experiencia"
          type="ghost"
        />
      </AuthenticationWrapper>
    </>
  );
};

export { CandidateAddNewExperience };
