import React from "react";
import { useHistory } from "react-router-dom";
import { bool } from "prop-types";

import { useUserContext } from "../../contexts/User";
import { useSnackBarContext } from "../../contexts/SnackBar";
import useAxios from "../../hooks/useAxios";
import SocialButton from "../../components/SocialButton";
import Config from "../../config";

import styles from "./styles.module.scss";
import { isUserFullySignedUp } from "../../modules/Account/hooks/isUserFullySignedUp";

const SocialAuthentication = ({ inverse }) => {
  const { showSnackBar } = useSnackBarContext();
  const { updateInkoins } = useUserContext();
  const { exec, loading } = useAxios();
  const history = useHistory();
  const { checkUserStatusAndRedirect } = isUserFullySignedUp();

  const handleSocialLogin = async (user) => {
    try {
      const response = await exec({
        method: "post",
        url: `/auth/${user?._provider}`,
        data: {
          token: user._token.accessToken,
        },
      });

      if (!response.token) {
        return;
      }

      if (!response.user.email_verified_at) {
        response.user.email_verified_at = true;
      }

      updateInkoins(response.user.wallet ? response.user.wallet.coins : 0);

      localStorage.setItem("@user_token", response.token);
      localStorage.setItem("@user_data", JSON.stringify(response.user));
      localStorage.setItem(
        "@user_inkoins",
        JSON.stringify(response.user.wallet ? response.user.wallet.coins : 0)
      );

      if (!response.user.accepted_terms) {
        // history.push("/autenticacao/aceite-os-termos-de-uso");
        return;
      }

      checkUserStatusAndRedirect();
    } catch (error) {
      console.log({ error });
    }
  };

  const handleSocialLoginFailure = (err) => {
    console.log(err);

    if (err?.toString()?.includes("popup_closed_by_user")) {
      showSnackBar({
        timeout: 2000,
        text: "Autenticação cancelada pelo usuário",
      });

      return;
    }
  };

  return (
    <div className={[styles.wrapper, inverse && styles.inverse].join(" ")}>
      <SocialButton
        appId={Config.FACEBOOK_APP_ID}
        serviceName="Facebook"
        provider="facebook"
        onLoginSuccess={handleSocialLogin}
        onLoginFailure={handleSocialLoginFailure}
        redirect={window.location.origin}
      />
      {/* <SocialButton
        appId={Config.GOOGLE_APP_ID}
        serviceName="Google"
        provider="google"
        onLoginSuccess={handleSocialLogin}
        onLoginFailure={handleSocialLoginFailure}
        disabled={loading}
        redirect={window.location.origin}
      /> */}
      <p className={styles.separator}>
        <span>ou</span>
      </p>
    </div>
  );
};

SocialAuthentication.propTypes = {
  inverse: bool,
};

SocialAuthentication.defaultProps = {
  inverse: false,
};

export default SocialAuthentication;
