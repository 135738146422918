import React, { useState, useEffect } from "react";
import styles from './styles.module.scss';
import { useHistory, useParams } from "react-router-dom";
import { Toggler } from "../../../../components/Toggler";
import { JobPostingStatus } from "../../components/JobPostingStatus";
import { JobForm } from "../../components/JobForm";
import { StepsGroup } from "../../components/StepsGroup";
import { JobPayment } from "../../components/JobPayment";
import { defaultValidation, firstAccessValidation, steps, stepsFirstAccess } from "./constants";
import useAxios from "../../../../hooks/useAxios";
import { ModalFirstAccess } from "../../components/ModalFirstAccess";
import useEnterPriseEnvCheck from "../../../../hooks/useEnterpriseEnvCheck";

const CreateListingScnd = () => {
  const [currentStep, setCurrentStep] = useState(null);
  const [customerDetails, setCustomerDetails] = useState({});
  const history = useHistory();
  const params = useParams();
  const { exec } = useAxios();
  const { isEnterpriseEnv } = useEnterPriseEnvCheck();
  const userData = localStorage.getItem("@user_data")
  ? JSON.parse(localStorage.getItem("@user_data"))
  : undefined;

  const goToNextStep = () => {
    if (currentStep < steps.length) {
      const newStep = currentStep + 1;
      setCurrentStep(newStep);
    }
  };

  const fetchCustomerData = async () => {
    const { existContentForCNPJ, contentsFromUser } = await exec({
      method: "GET",
      url: '/minhas-vagas/status',
      useCredentials: true,
    });

    setCustomerDetails({ hasFreePub: !existContentForCNPJ, contentsPublished: contentsFromUser });
  };

  useEffect(() => {
    if(userData.inkluer) return window.location = window.location.origin + "/recrutador/criar-vaga";
    
    fetchCustomerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params.action === 'pagamento') return setCurrentStep(3);

    setCurrentStep(1);
  }, [params.action]);


  return (
    <>
      <div className={styles.wrapper}>
        <StepsGroup
          currentStep={currentStep || 1}
          steps={customerDetails.hasFreePub ? stepsFirstAccess : steps}
        />
        <Toggler visible={currentStep === 1}>
          <JobForm goToNextStep={goToNextStep} />
        </Toggler>
        <Toggler visible={currentStep === 2}>
          <JobPostingStatus
            title={customerDetails.hasFreePub ? firstAccessValidation : defaultValidation}
            actionText="Voltar"
            handleOnClick={() => {
              if(isEnterpriseEnv) return history.push("/para-empresas/recrutador/minhas-vagas");
              window.location = window.location.origin + "/recrutador/minhas-vagas";
            }}
            banner
          >
            <p>
              Agora iremos validar se o conteúdo de sua vaga está de acordo com os termos de nossa plataforma.
            </p>
          </JobPostingStatus>
        </Toggler>
        <Toggler visible={currentStep === 3}>
          <JobPayment
            goToNextStep={goToNextStep}
            id={params.id}
          />
        </Toggler>
        <Toggler visible={currentStep === 4}>
          <JobPostingStatus
            title="Pagamento realizado!"
            actionText="Voltar"
            handleOnClick={() => history.push(isEnterpriseEnv ? "/para-empresas/recrutador/minhas-vagas" : "/recrutador/minhas-vagas")}
            banner
          >
            <p>
              Assim que a administradora do seu cartão/banco emissor aprovar o pagamento,
              a sua vaga será publicada em nosso site!
              A NF-e será enviada para o seu e-mail.
            </p>
          </JobPostingStatus>
        </Toggler>
      </div>
      <ModalFirstAccess showModal={currentStep === 1 && customerDetails?.contentsPublished < 2} />
    </>
  );
};

export { CreateListingScnd };
