import React from "react";
import { number, string, shape } from "prop-types";

import SquareLinkButton from "../../components/SquareLinkButton";

import styles from "./styles.module.scss";

const HeroBanner = ({ id, title, description, image, link, altImage, nextBtn }) => {
  const generateTitleWithHtml = () => ({
    __html: title,
  });

  return (
    <div className={styles.wrapper}>
      <div>
        <h1
          className={styles.title}
          dangerouslySetInnerHTML={generateTitleWithHtml()}
          tabIndex="0"
        />
        <p
          className={styles.description}
          tabIndex="0"
        >
          {description}
        </p>
        {link && (
          <SquareLinkButton
            key={`banner-link-${id}`}
            text={link.text}
            url={link.url}
          />
        )}
      </div>
      <div>
        <img
          className={styles.image}
          src={image}
          alt={altImage}
          tabIndex="0"
        />
      </div>
      {id === 1 && <div onFocus={nextBtn} tabIndex="0" />}
    </div>
  );
};

HeroBanner.propTypes = {
  id: number.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  image: string.isRequired,
  altImage: string.isRequired,
  link: shape({
    url: string.isRequired,
    text: string.isRequired,
  }),
};

HeroBanner.defaulProps = {
  link: null,
};

export default HeroBanner;
