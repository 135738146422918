import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSnackBarContext } from "../../contexts/SnackBar";

import styles from "./styles.module.scss";

const SnackBar = () => {
  const { setOpen, isOpen, setText, text, failure } = useSnackBarContext();
  const { pathname } = useLocation();
  const isEnterpriseVersion = pathname.includes("para-empresas");

  useEffect(() => {
    return () => {
      setOpen(false);
      setText("");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <p
      className={[
        styles.alert, 
        isOpen && styles.visible,
        isEnterpriseVersion && styles.secondaryLayout,
        failure && styles.error
      ].join(" ")}
      role="alert"
      aria-hidden={!isOpen}
      data-testid="snackbar-message"
    >
      {text}
    </p>
  );
};

export default SnackBar;
