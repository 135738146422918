import React from "react";
import { string, bool } from "prop-types";

const House = ({ color, hidden }) => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden={hidden}
    >
      <g
        id="home-icon-hover-state"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M19.997,7.87397281 L31.667,19.3882253 L31.6503333,32.7980388 L26.667,32.7980388 L26.667,21.5911746 C26.667,20.7058323 25.9203333,19.990194 25.0003333,19.990194 L15.0003333,19.990194 C14.0786667,19.990194 13.3336667,20.7058323 13.3336667,21.5911746 L13.3336667,32.7980388 L8.33366667,32.7980388 L8.34366667,19.3225851 L19.997,7.87397281 Z M23.3336667,23.1921552 L23.3336667,32.7980388 L16.667,32.7980388 L16.667,23.1921552 L23.3336667,23.1921552 Z M21.192,4.46228315 C20.5636667,3.84590562 19.437,3.84590562 18.8086667,4.46228315 L5.95866667,17.0860152 C5.35033333,17.6863829 5.00033333,18.5252967 5.00033333,19.3882253 L5.00033333,32.7980388 C5.00033333,34.5639204 6.412,36 8.147,36 L15.0003333,36 L25.0003333,36 L31.852,36 C33.587,36 35.0003333,34.5639204 35.0003333,32.7980388 L35.0003333,19.3882253 C35.0003333,18.5252967 34.6503333,17.6863829 34.0403333,17.0844142 L21.192,4.46228315 Z"
          fill={color}
        ></path>
      </g>
    </svg>
  );
};

House.propTypes = {
  color: string,
  hidden: bool,
};

House.defaultProps = {
  color: "#1B9B9E",
  hidden: true,
};

export default House;
