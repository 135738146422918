import { string } from "prop-types";
import '../../../../libs/newIcons/style.scss';
import styles from './styles.module.scss';

const Icons = {
    checkmark: <i className="icon-checkmark-circle-outline" />,
    star: <i className="icon-star-outline" />,
    pin: <i className="icon-pin-outline" />,
    flash: <i className="icon-flash-outline" />,
    file: <i className="icon-file-text-outline" />,
    map: <i className="icon-map-outline" />,
    swap: <i className="icon-swap-outline" />,
    volUp: <i className="icon-volume-up-outline" />,
    edit: <i className="icon-edit-2-outline" />,
    people: <i className="icon-people-outline" />,
    clipboard: <i className="icon-clipboard-outline" />,
};

const Card = ({ title, text, icon }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.icon}>
                {Icons[icon]}
            </div>
            <div>
                <span className={styles.title}>{title}</span>
                <p className={styles.paragraph}>{text}</p>
            </div>
        </div>
    )
};

Card.propTypes = {
    title: string,
    text: string,
    icon: string,
};

Card.defaultProps = {
    title: '',
    text: '',
    icon: '',
};

export default Card;