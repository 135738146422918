import React, { useRef, useEffect } from "react";
import { bool, string, node, func } from "prop-types";
import { useField } from "@unform/core";

import styles from "./styles.module.scss";
import useEnterPriseEnvCheck from "../../../hooks/useEnterpriseEnvCheck";

const BooleanCheckbox = ({
  name,
  children,
  checked,
  onChange,
  alternativeStyle,
  secondaryClass,
  paramValue,
  handleSubmit,
  showError,
  dataTest
}) => {
  const inputRef = useRef();
  const { fieldName, registerField, error, clearError } = useField(name);
  const { isEnterpriseEnv } = useEnterPriseEnvCheck();

  const handleOnChange = (event) => {
    clearError();

    onChange({ checked: event.target.checked, value: event.target.value });
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        if (paramValue) {
          if (window.innerWidth < 480) {
            return {
              value: paramValue,
              checked: ref.current?.checked,
            };
          } else {
            return {
              value: paramValue,
              checked: checked ? false : ref.current.checked,
            };
          }
        } else {
          return ref.current.checked;
        }
      },
      setValue: (ref, value) => {
        ref.current.value = ref.current.checked;
      },
      clearValue: (ref) => {
        ref.current.value = false;
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName, registerField]);

  return (
    <div
      className={[
        styles.wrapper,
        secondaryClass,
        isEnterpriseEnv && styles.secondaryLayout,
      ].join(" ")}
    >
      <input
        ref={inputRef}
        defaultChecked={checked}
        value={name}
        type="checkbox"
        id={`input-${name}`}
        className={styles.input}
        onChange={handleOnChange}
        aria-describedby={`error_${name}`}
        onClick={() => {
          if (handleSubmit) handleSubmit(name);
        }}
        data-test={dataTest}
        autoComplete="off"
      />
      <label
        htmlFor={`input-${name}`}
        className={[styles.label, error && styles.invalid].join(" ")}
      >
        {children && (
          <span
            className={[
              styles.falseLabel,
              alternativeStyle ? styles.alternativeStyle : "",
            ].join(" ")}
          >
            {children}
          </span>
        )}
      </label>
      {error && showError && (
        <span className={styles.errorMessage} id={`error_${name}`}>
          {error}
        </span>
      )}
    </div>
  );
};

BooleanCheckbox.propTypes = {
  name: string.isRequired,
  label: node,
  checked: bool,
  showError: bool,
  handleSubmit: () => {},
  multipleFilters: bool,
  alternativeStyle: bool,
  onChange: func,
  dataTest: string
};

BooleanCheckbox.defaultProps = {
  label: null,
  checked: false,
  showError: true,
  handleSubmit: null,
  multipleFilters: false,
  alternativeStyle: false,
  onChange: () => {},
  dataTest: "boolean-checkbox"
};

export default BooleanCheckbox;
