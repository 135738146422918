import React, { useState, useEffect } from "react";
import qs from "qs";
import { useLocation, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import SearchResultItem from "../../modules/Search/components/SearchResultItem";
import SearchResultList from "../../modules/Search/components/SearchResultList";
import SearchGrid from "../../modules/Search/components/SearchGrid";
import SearchSkeletonLoader from "../../modules/Search/components/SearchSkeletonLoader";
import SearchPagination from "../../modules/Search/components/SearchPagination";
import CheckboxFilter from "../../modules/Search/components/CheckboxFilter";
import CheckboxGroup from "../../modules/Search/components/CheckboxGroup";
import TextButton from "../../modules/Search/components/TextButton";
import MobileLoadingFeedback from "../../modules/Search/components/MobileLoadingFeedback";
// eslint-disable-next-line no-unused-vars
import EmptyResultsFeedback from "../../modules/Search/components/EmptyResultsFeedback";
import SearchActiveFilter from "../../modules/Search/components/SearchActiveFilter";
import SearchActiveFilterWrapper from "../../modules/Search/components/SearchActiveFilterWrapper";
import SearchSquareButton from "../../modules/Search/components/SearchSquareButton";

import isMobile from "../../helpers/isMobile";
import SearchSummary from "../../components/SearchSummary";
import SquareButton from "../../components/SquareButton";
import useAxios from "../../hooks/useAxios";
import {
  parseSearchParameters,
  getCounter,
} from "../../modules/Search/utils";
import { isUserFullySignedUp } from "../../modules/Account/hooks/isUserFullySignedUp";

const MetaTags = () => (
  <Helmet>
    <title>Buscar vaga | Inklua</title>
    <meta name="description" content="Encontre suas vagas de emprego na Inklua. São centenas de novas vagas todos os dias no site que é referência em emprego para pessoas com deficiência (PcD)." />
    <meta name="keywords" content="vaga de emprego pcd, emprego para pcd, vaga para pcd, vaga pcd Brasil, oportunidade de emprego pcd, oportunidade, trabalho, recrutamento e seleção, cadastrar currículo, curriculo, Inklua, vagas na Inklua, inkua, empresa inklua, PcD." />
    <meta property="og:title" content="Buscar vaga | inklua" />
    <meta property="og:type" content="website" />
    <meta property="og:description" content="Encontre suas vagas de emprego na Inklua, são centenas de novas vagas todos os dias no site que é referência em empregos para pessoas com deficiência (PcD)." />
    <meta property="og:image" content="https://s3.sa-east-1.amazonaws.com/public.inklua/logo_mini.png" />
    <meta property="og:image:type" content="image/jpeg" />
    <meta property="og:image:width" content="200" />
    <meta property="og:image:height" content="200" />
    <meta property="og:url" content="https://inklua.com/busca" />
    <meta property="og:site_name" content=" Inklua" />
    <link rel="canonical" href="https://inklua.com/busca" />
  </Helmet>
);

const Search = () => {
  const location = useLocation();
  const history = useHistory();
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    plainObjects: true,
  });
  // eslint-disable-next-line no-unused-vars
  const [keyword, setKeyword] = useState(query.termo);
  const [selectedState, setSelectedState] = useState(query.estado);
  const [selectedContractType, setSelectedContractType] = useState(query.tipo);
  const [selectedCity, setSelectedCity] = useState(query.cidade);

  const [counters, setCounters] = useState(null);
  const [isSearchDone, setIsSearchDone] = useState(false);
  const [results, setResults] = useState([]);
  const [allResults, setAllResults] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParameters, setSearchParameters] = useState(() => {
    const params = parseSearchParameters({
      urlParameters: {
        tipo: selectedContractType,
        termo: keyword,
        cidade: selectedCity,
        estado: selectedState,
      },
    })
    return params.api
  });
  const { exec, loading } = useAxios();

  const defaultFilterSize = 4;
  const [cityLimit, setCityLimit] = useState(defaultFilterSize);
  const [stateLimit, setStateLimit] = useState(defaultFilterSize);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const { checkUserStatusAndRedirect } = isUserFullySignedUp();

  checkUserStatusAndRedirect();

  const fetchData = async () => {
    const parameters = (!!searchParameters) ? `&${searchParameters}` : ''
    const queryString = `?page=${currentPage}${parameters}`

    const response = await exec({
      useCredentials: false,
      method: "GET",
      url: `/busca${queryString}`,
    });

    // const response = await exec({
    //   useCredentials: true,
    //   method: "GET",
    //   url: `/busca?${searchParameters}&page=${currentPage}`,
    // });

    if (currentPage === 1) {
      setResults(response.data);
      resetFilterLimit();
    } else {
      setResults([...results, ...response.data]);
    }

    setAllResults(response.total);
    setCounters(response.counters);
    setIsSearchDone(true);
  };

  const handlePagination = () => {
    setCurrentPage(currentPage + 1);
  };

  const updateParameters = () => {
    const parameters = parseSearchParameters({
      urlParameters: {
        tipo: selectedContractType,
        termo: keyword,
        cidade: selectedCity,
        estado: selectedState,
      },
    });

    setSearchParameters(parameters.api);
    history.push(`/busca?${parameters.url}`);
  };

  const handleContractTypeChange = ({ value }) => {
    if (value === selectedContractType) {
      setSelectedContractType(null);
    } else {
      setSelectedContractType(value);
    }

    updateParameters();
  };

  const handleStateChange = ({ value }) => {
    if (value === Number(selectedState)) {
      setSelectedState(null);
    } else {
      setSelectedState(value);
    }

    updateParameters();
  };

  const handleCityChange = ({ value }) => {
    if (value === selectedCity) {
      setSelectedCity(null);
    } else {
      setSelectedCity(value);
    }

    updateParameters();
  };

  const handleClearFilters = () => {
    setIsSearchDone(false);
    setSelectedContractType(null);
    setSelectedState(null);
    setSelectedCity(null);
    updateParameters();
  };

  const resetFilterLimit = () => {
    setCityLimit(defaultFilterSize);
    setStateLimit(defaultFilterSize);
  };

  const closeFiltersAndFetchItems = () => {
    fetchData();
    setIsSidebarOpen(false);
  };

  useEffect(() => {
    if (isMobile() && isSearchDone) return;

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParameters]);

  useEffect(() => {
    if (currentPage > 1) fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    updateParameters();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContractType, keyword, selectedState, selectedCity]);

  return (
    <>
      <MetaTags />
      {isMobile() && <MobileLoadingFeedback active={loading} />}
      <SearchSummary term={keyword} quantity={allResults} loading={loading} />
      <SearchGrid
        setOpenState={setIsSidebarOpen}
        open={isSidebarOpen}
        activeFilters={
          (selectedContractType || selectedCity || selectedState) && (
            <>
              <SearchActiveFilterWrapper>
                {selectedContractType && (
                  <SearchActiveFilter
                    text={
                      selectedContractType === "remote" ||
                        selectedContractType === "remoto"
                        ? "Remoto"
                        : "Presencial"
                    }
                    onClick={() => {
                      setIsSearchDone(false);
                      setSelectedContractType(null);
                    }}
                  />
                )}
                {selectedCity && (
                  <SearchActiveFilter
                    text={
                      counters?.cities.length &&
                      counters.cities.filter(city => city.id === Number(selectedCity))[0].name
                    }
                    onClick={() => {
                      setIsSearchDone(false);
                      setSelectedCity(null);
                    }}
                  />
                )}
                {selectedState && (
                  <SearchActiveFilter
                    text={
                      counters?.states.length &&
                      counters.states.filter(state => state.id === Number(selectedState))[0].name
                    }
                    onClick={() => {
                      setIsSearchDone(false);
                      setSelectedState(null);
                    }}
                  />
                )}
              </SearchActiveFilterWrapper>
              <TextButton onClick={handleClearFilters}>
                Desfazer todos os filtros
              </TextButton>
            </>
          )
        }
        sidebar={
          <>
            {Boolean(counters?.contract_type?.length) && (
              <CheckboxGroup title="Modelo de Trabalho">
                <CheckboxFilter
                  disabled={loading}
                  label="Remoto"
                  value="remote"
                  counter={counters?.contract_type[0]?.positions || 0}
                  onChange={handleContractTypeChange}
                  checked={
                    selectedContractType === "remote" ||
                    selectedContractType === "remoto"
                  }
                />
                <CheckboxFilter
                  disabled={loading}
                  label="Presencial"
                  value="local"
                  counter={counters?.contract_type[1]?.positions || 0}
                  onChange={handleContractTypeChange}
                  checked={
                    selectedContractType === "local" ||
                    selectedContractType === "presencial"
                  }
                />
                <CheckboxFilter
                  disabled={loading}
                  label="Híbrido"
                  value="hybrid"
                  counter={counters?.contract_type[2]?.positions || 0}
                  onChange={handleContractTypeChange}
                  checked={
                    selectedContractType === "hybrid" ||
                    selectedContractType === "hibrido"
                  }
                />
              </CheckboxGroup>
            )}
            {Boolean(counters?.states?.length) && (
              <CheckboxGroup title="Estados">
                {counters.states.map((state, index) => {
                  if (index > stateLimit) {
                    return null;
                  }

                  return (
                    <CheckboxFilter
                      key={`state_${state.name}`}
                      type="radio"
                      label={state.name}
                      value={state.id}
                      counter={state.positions}
                      onChange={handleStateChange}
                      checked={Number(selectedState) === state.id}
                    />
                  );
                })}
                {counters?.states.length > stateLimit && (
                  <SearchSquareButton
                    text="Mostrar mais"
                    onClick={() => setStateLimit(stateLimit + 5)}
                    size="small"
                    name="states-show-more"
                  />
                )}
              </CheckboxGroup>
            )}
            {Boolean(counters?.cities?.length) && (
              <CheckboxGroup title="Cidades">
                {counters.cities.map((city, index) => {
                  if (index > cityLimit) {
                    return null;
                  }

                  return (
                    <CheckboxFilter
                      key={`city+${city.name}`}
                      type="radio"
                      label={city.name}
                      value={city.id}
                      counter={getCounter({
                        counterList: counters.cities,
                        key: city.name,
                      })}
                      onChange={handleCityChange}
                      checked={selectedCity === city.id}
                    />
                  );
                })}
                {counters.cities.length > cityLimit && (
                  <SearchSquareButton
                    text="Mostrar mais"
                    onClick={() => setCityLimit(cityLimit + 5)}
                    size="small"
                    name="cities-show-more"
                  />
                )}
              </CheckboxGroup>
            )}
            {((isMobile() && !isSidebarOpen) || !isMobile()) && (
              <TextButton onClick={handleClearFilters}>
                Desfazer todos os filtros
              </TextButton>
            )}
            {isMobile() && (
              <>
                <SquareButton
                  onClick={() => closeFiltersAndFetchItems()}
                  text="Aplicar Filtros"
                />
                <SquareButton
                  simple
                  onClick={() => setIsSidebarOpen(false)}
                  text="Cancelar"
                />
              </>
            )}
          </>
        }
        content={
          <>
            {/* {!loading && !results.length && isSearchDone && (
              <EmptyResultsFeedback onClick={handleClearFilters} />
            )} */}
            {Boolean(results.length) && (
              <SearchResultList>
                {results.map((item) => (
                  <SearchResultItem
                    key={`result_item_${item.id}`}
                    id={item.id}
                    title={item.title}
                    location={item.location}
                    date={item.date}
                    company={item.company}
                    description={item.description}
                    salary={item.salary}
                    contractTypes={{
                      remote: Boolean(item.remote),
                      local: Boolean(item.presential),
                      hybrid: Boolean(item.hybrid),
                    }}
                  />
                ))}
              </SearchResultList>
            )}
            {loading &&
              [0, 1, 2, 3, 4, 5, 6].map((item) => (
                <SearchSkeletonLoader key={`skeleton_item_${item}`} />
              ))}
            {Boolean(
              !loading && results.length && results.length < allResults
            ) && <SearchPagination onClick={handlePagination} />}
          </>
        }
      />
    </>
  );
};

export default Search;
