import React from "react";
import { string, func, bool, node, oneOf } from "prop-types";

import styles from "./styles.module.scss";

const SearchSquareButton = ({
  text,
  onClick,
  disabled,
  icon,
  size,
  centered,
  name,
}) => {
  return (
    <button
      disabled={disabled}
      aria-disabled={disabled}
      className={[
        styles.button,
        styles[size],
        centered ? styles.alignCenter : "",
        disabled ? styles.disabled : "",
      ].join(" ")}
      type="button"
      onClick={onClick}
      data-testid={`search-square-button${name && `-${name}`}`}
    >
      {icon && (
        <span
          className={styles.iconWrapper}
          data-testid={`search-square-button${name && `-${name}`}-icon`}
        >
          {icon}
        </span>
      )}
      {text}
    </button>
  );
};

SearchSquareButton.propTypes = {
  text: string.isRequired,
  name: string,
  icon: node,
  onClick: func,
  size: oneOf(["small", "default"]),
  disabled: bool,
  centered: bool,
};

SearchSquareButton.defaultProps = {
  icon: null,
  onClick: () => {},
  disabled: false,
  centered: false,
  size: "default",
};

export default SearchSquareButton;
