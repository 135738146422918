import React from "react";
import { string } from "prop-types";

const WarningTriangle = ({ color }) => {
  return (
    <svg
      width="26px"
      height="22px"
      viewBox="0 0 26 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-testid="icon-warning-triangle"
    >
      <g
        id="⬅️➡️🖥️-07-fluxo:recrutador-cadastrando-vagas(DesktopView)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="09-minhas-vagas-detalhes-tooltip"
          transform="translate(-471.000000, -400.000000)"
        >
          <g id="card-vaga-1" transform="translate(174.000000, 358.000000)">
            <g id="bg">
              <g id="bg-shadow">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                ></use>
                <use
                  fill="#FFFFFF"
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                ></use>
              </g>
              <rect
                id="bg-solid"
                fill="transparent"
                x="0"
                y="0"
                width="348"
                height="314"
                rx="10"
              ></rect>
            </g>
            <g id="content-miolo" transform="translate(24.000000, 40.000000)">
              <g
                id="Group-3"
                transform="translate(272.000000, 0.000000)"
                fill={color}
                fillRule="nonzero"
              >
                <g id="alert-triangle-outline">
                  <polygon
                    id="Rectangle"
                    opacity="0"
                    transform="translate(14.000000, 14.000000) rotate(90.000000) translate(-14.000000, -14.000000) "
                    points="6.15268972e-13 -2.85105273e-13 28 -2.85105273e-13 28 28 6.15268972e-13 28"
                  ></polygon>
                  <path
                    d="M26.4808089,18.5584782 L17.4106772,3.83223983 C16.6653401,2.69072483 15.3769754,2 13.9931178,2 C12.6092601,2 11.3208954,2.69072483 10.5755584,3.83223983 L1.50542667,18.5584782 C0.853306677,19.6227119 0.83076656,20.9467299 1.44629935,22.0316476 C2.15816116,23.2532051 3.48646384,24.0051485 4.92298608,23.9997769 L23.0632495,23.9997769 C24.4899968,24.0146819 25.8160184,23.2816923 26.5399362,22.0779565 C27.1738828,20.9811738 27.1512546,19.6342574 26.4808089,18.5584782 L26.4808089,18.5584782 Z M24.4704798,20.9318106 C24.1808066,21.4192953 23.6387569,21.7091577 23.0632495,21.6843307 L4.92298608,21.6843307 C4.34747865,21.7091577 3.80542895,21.4192953 3.51575573,20.9318106 C3.30451415,20.5736098 3.30451415,20.1322883 3.51575573,19.7740875 L12.5977129,5.03627189 C12.9285467,4.60843626 13.4450047,4.35704552 13.9931178,4.35704552 C14.5412309,4.35704552 15.0576888,4.60843626 15.3885227,5.03627189 L24.4586544,19.7625102 C24.6781425,20.1215461 24.6826636,20.5685905 24.4704798,20.9318106 L24.4704798,20.9318106 Z"
                    id="Shape"
                  ></path>
                  <circle id="Oval" cx="14" cy="19" r="1"></circle>
                  <path
                    d="M14,9 C13.4477153,9 13,9.59695367 13,10.3333333 L13,15.6666667 C13,16.4030463 13.4477153,17 14,17 C14.5522847,17 15,16.4030463 15,15.6666667 L15,10.3333333 C15,9.59695367 14.5522847,9 14,9 Z"
                    id="Path"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

WarningTriangle.propTypes = {
  color: string,
};

WarningTriangle.defaultProps = {
  color: "#1B9B9E",
};

export default WarningTriangle;
