import React from "react";
import { string } from "prop-types";

const LeftArrow = ({ color }) => {
  return (
    <svg
      width="16px"
      height="14px"
      viewBox="0 0 16 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      aria-label="Voltar"
    >
      <defs>
        <path
          d="M19,11 L7.135,11 L10.768,6.64 C11.122,6.216 11.064,5.585 10.64,5.232 C10.215,4.878 9.585,4.936 9.232,5.36 L4.232,11.36 C4.193,11.407 4.173,11.462 4.144,11.514 C4.12,11.556 4.091,11.592 4.073,11.638 C4.028,11.753 4.001,11.874 4.001,11.996 C4.001,11.997 4,11.999 4,12 C4,12.001 4.001,12.003 4.001,12.004 C4.001,12.126 4.028,12.247 4.073,12.362 C4.091,12.408 4.12,12.444 4.144,12.486 C4.173,12.538 4.193,12.593 4.232,12.64 L9.232,18.64 C9.43,18.877 9.714,19 10,19 C10.226,19 10.453,18.924 10.64,18.768 C11.064,18.415 11.122,17.784 10.768,17.36 L7.135,13 L19,13 C19.552,13 20,12.552 20,12 C20,11.448 19.552,11 19,11"
          id="path-1-left-arrow"
        ></path>
      </defs>
      <g
        id="🖥️-03-home-inklua-computador"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="02-Detalhes-da-vaga"
          transform="translate(-177.000000, -164.000000)"
        >
          <g
            id="27)-Icon/arrow-left"
            transform="translate(173.000000, 159.000000)"
          >
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1-left-arrow"></use>
            </mask>
            <use
              id="🎨-Icon-Сolor"
              fill={color}
              fillRule="evenodd"
              xlinkHref="#path-1-left-arrow"
            ></use>
          </g>
        </g>
      </g>
    </svg>
  );
};

LeftArrow.propTypes = {
  color: string,
};

LeftArrow.defaultProps = {
  color: "#1B9B9E",
};

export default LeftArrow;
