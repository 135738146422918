import React from "react";
import { oneOf, string } from "prop-types";

import styles from "./styles.module.scss";

const SectionIntro = ({ text, level, color }) => {
  if (level === "h2") {
    return (
      <h2 className={styles.subtitle} style={{ color: color }}>
        {text}
      </h2>
    );
  }

  if (level === "span") {
    return (
      <span className={styles.subtitle} style={{ color: color }}>
        {text}
      </span>
    );
  }

  return (
    <p className={styles.subtitle} style={{ color: color }}>
      {text}
    </p>
  );
};

SectionIntro.propTypes = {
  text: string.isRequired,
  level: oneOf(["h2", "span", "p"]),
  color: string,
};
SectionIntro.defaultProps = {
  level: "span",
  color: null,
};

export default SectionIntro;
