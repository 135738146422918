import React, { useRef, useState } from 'react';
import styles from "./styles.module.scss";
import SectionTitle from '../SectionTitle';
import { Form } from "@unform/web";
import Input from '../Input';
import SelectInput from '../SelectInput';
import SquareButton from '../SquareButton';
import { Magnifier, Plus } from '../../libs/icons';
import { formatCurrency } from '../../helpers';

const SearchJobBlock = ({
    onSubmit,
    states,
    payments,
    setLoading,
    handleFiltereds,
    isMobile,
    enterpriseVersion,
}) => {
    const [cachedStates] = useState(states);
    const [cachedPayments] = useState(payments);
    const [inputValue, setInputValue] = useState('');
    const [stateValue, setStateValue] = useState('');
    const [paymentValue, setPaymentValue] = useState('');
    const [openMobSearch, setOpenMobSearch] = useState(false);
    const formRef = useRef();

    const handleSubmit = () => {
        if (!inputValue && !stateValue && !paymentValue) return;

        if (isMobile) setOpenMobSearch(false);

        let filterParams = {
            ...(inputValue && { title: inputValue }),
            ...(stateValue && { [`state_id_${stateValue}`]: stateValue }),
            ...(paymentValue && { payment_max: paymentValue }),
        };
        setLoading(true);
        return onSubmit(filterParams);
    };

    if (isMobile) {
        if (!openMobSearch && !handleFiltereds()) {
            return (
                <div className={[
                    styles.wrapperMobile,
                    enterpriseVersion && styles.secondaryLayout
                ].join(" ")}>
                    <SquareButton
                        text='Procurar profissional'
                        icon={<Magnifier color={enterpriseVersion ? "#326B99" : "#1B9B9E"} />}
                        type="button"
                        outlined
                        onClick={() => setOpenMobSearch(prev => !prev)}
                    />
                </div>
            )
        };

        if (openMobSearch) {
            return (
                <div className={[
                    styles.modal,
                    enterpriseVersion && styles.secondaryLayout
                ].join(" ")}>
                    <button
                        onClick={() => setOpenMobSearch(prev => !prev)}
                        aria-label="Fechar Modal"
                        className={styles.closeButton}
                    >
                        <Plus color={enterpriseVersion ? "#326B99" : "#1B9B9E"} />
                    </button>
                    <SectionTitle
                        children="O profissional <b>certo para você</b>"
                        extraStyle={styles.name}
                        level="p"
                        centered
                    />
                    <Form
                        ref={formRef}
                        onSubmit={handleSubmit}
                        className={styles.form}
                    >
                        <Input
                            name="job"
                            label="Pesquise um cargo"
                            placeholder="Exemplo: Analista de sistemas"
                            onChange={(event) => setInputValue(event.target.value)}
                            value={inputValue}
                        />
                        <SelectInput
                            name="location"
                            label="Em qual estado?"
                            placeholder="Selecione um estado"
                            setter={setStateValue}
                            keys={{ label: "name", value: "value" }}
                            options={[
                                { name: "Todo o Brasil", value: "" },
                                ...cachedStates.map((state) => ({ name: state.name, value: state.state_id })),
                            ]}
                        />
                        <SelectInput
                            name="payment"
                            label="Filtrar por salário"
                            placeholder="Selecione um valor"
                            setter={setPaymentValue}
                            keys={{ label: "name", value: "value" }}
                            options={[
                                { name: "Todos os valores", value: "" },
                                ...Object.entries(cachedPayments).map((payment) => ({ name: formatCurrency(payment[0]), value: payment[0] })),
                            ]}
                        />
                        <SquareButton
                            text='Procurar profissional'
                            icon={<Magnifier color="#fff" />}
                            type="submit"
                        />
                        <SquareButton
                            text='Cancelar'
                            type="button"
                            onClick={() => setOpenMobSearch(prev => !prev)}
                        />
                    </Form>
                </div>
            )
        };

        return null;
    };

    return (
        <div className={styles.wrapper}>
            <SectionTitle
                children="Encontre <b>o profissional certo</b> para você"
                extraStyle={[
                    styles.name,
                    enterpriseVersion && styles.secondaryLayout
                ].join(" ")}
                linebreak={false}
                level="p"
                centered={isMobile}
            />
            <Form
                ref={formRef}
                onSubmit={handleSubmit}
                className={[
                    styles.form,
                    enterpriseVersion && styles.secondaryLayout
                ].join(" ")}
            >
                <Input
                    name="job"
                    label="Pesquise um cargo"
                    placeholder="Exemplo: Analista de sistemas"
                    onChange={(event) => setInputValue(event.target.value)}
                    value={inputValue}
                />
                <SelectInput
                    name="location"
                    label="Em qual estado?"
                    placeholder="Selecione um estado"
                    setter={setStateValue}
                    keys={{ label: "name", value: "value" }}
                    options={[
                        { name: "Todo o Brasil", value: "" },
                        ...cachedStates.map((state) => ({ name: state.name, value: state.state_id })),
                    ]}
                />
                <SelectInput
                    name="payment"
                    label="Filtrar por salário"
                    placeholder="Selecione um valor"
                    setter={setPaymentValue}
                    keys={{ label: "name", value: "value" }}
                    options={[
                        { name: "Todos os valores", value: "" },
                        ...Object.entries(cachedPayments).map((payment) => ({ name: formatCurrency(payment[0]), value: payment[0] })),
                    ]}
                />
                <SquareButton
                    text='Procurar profissional'
                    icon={<Magnifier color="#fff" />}
                    type="submit"
                />
                <SquareButton
                    text='Cancelar'
                />
            </Form>
        </div >
    )
}

export default React.memo(SearchJobBlock);