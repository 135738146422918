import React, { useEffect, useState } from "react";

import SquareButton from "../../../../components/SquareButton";
import SectionTitle from "../../../../components/SectionTitle";
import useCounter from "../../../../hooks/useCounter";
import useAxios from "../../../../hooks/useAxios";
import { useSnackBarContext } from "../../../../contexts/SnackBar";

import AuthenticationParagraph from "../../components/AuthenticationParagraph";

const ResendNewPassword = () => {
  const [isLoading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const { active, startCounter, counter } = useCounter();
  const { exec } = useAxios();
  const { showSnackBar } = useSnackBarContext();

  const handleSubmit = async () => {
    startCounter(60);

    const response = await exec({
      method: "POST",
      url: "/user/password/email",
      data: {
        email: userEmail,
      },
    });

    if (response.success) {
      showSnackBar({
        timeout: 5000,
        text: "E-mail reenviado!",
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    const email = JSON.parse(localStorage.getItem("@temp_user_email"));

    if (email) {
      setUserEmail(email);
    }
  }, []);

  return (
    <>
      <SectionTitle
        small
        centered
        level="h2"
        children="Enviamos um link de <b>confirmação em:</b>"
      />
      {userEmail && (
        <AuthenticationParagraph>
          <b>{userEmail}</b>
        </AuthenticationParagraph>
      )}
      <AuthenticationParagraph>
        Enviamos um e-mail para você no endereço acima. Selecione o link dentro
        do e-mail para criar uma nova senha na Inklua.
      </AuthenticationParagraph>
      <SquareButton
        onClick={handleSubmit}
        type="button"
        text={`Reenviar e-mail ${active ? "(" + counter + ")" : ""}`}
        disabled={active || isLoading}
      />
    </>
  );
};

export default ResendNewPassword;
