import { useState } from "react";

import { candidateEnglishLevels } from "../../../../libs/data";
import useAxios from "../../../../hooks/useAxios";

export const useUserLanguage = () => {
  const { exec, loading } = useAxios();
  const [userLanguage, setUserLanguage] = useState(null);

  const validLevels = candidateEnglishLevels.map((l) => l.value);

  const getUserLanguage = async () => {
    const result = await exec({
      method: "GET",
      url: `/my-account`,
      useCredentials: true,
    });

    if (!result?.english_level || !validLevels.includes(result.english_level)) {
      console.error(
        `O nível de ingles retornado (${
          result?.english_level
        }) é inválido. Os valores válidos são: ${validLevels.join(
          ", "
        )}. O valor utilizado será "desconhece".`
      );

      setUserLanguage(1);
      return;
    }

    setUserLanguage(result.english_level);
  };

  const updateUserLanguage = async ({ levelId }) => {
    try {
      const response = await exec({
        useCredentials: true,
        method: "PUT",
        url: `/candidate/languages`,
        data: {
          english_level: candidateEnglishLevels.filter(
            (l) => l.value === levelId
          )[0].slug,
        },
      });

      if (response.error) {
        return false;
      }

      return true;
    } catch (error) {
      console.log({ error });
    }
  };

  return {
    loading,
    getUserLanguage,
    userLanguage,
    setUserLanguage,
    updateUserLanguage,
  };
};
