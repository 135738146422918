import React, { useEffect, useState } from "react";
import { bool, func } from "prop-types";
import { useHistory } from "react-router-dom";

import Config from "../../config";

import MenuLink from "../MenuLink";
import SocialMediaLink from "../SocialMediaLink";
import {
  Instagram,
  Facebook,
  LinkedIn,
  Suitcase,
  Mail,
  User,
  House,
  Plus,
  Heart,
  Folder,
  Star,
  CheckMark,
  Peek,
  Magnifier,
  TrendingUp,
  Search,
  Exit,
} from "../../libs/icons";
// import InkoinMenuCounter from "../../components/InkoinMenuCounter";

import backgroundImage from "./background.svg";
import styles from "./styles.module.scss";
import config from "../../config";

const Menu = ({ visible, handleClose }) => {
  const TIMING = 250;
  const [isVisible, setVisible] = useState(visible);
  const [isActive, setActive] = useState(visible);
  const hasUserToken = localStorage.getItem("@user_token");
  const userData = localStorage.getItem("@user_data")
    ? JSON.parse(localStorage.getItem("@user_data"))
    : undefined;
  const history = useHistory();
  const { PARA_EMPRESAS_URL } = config;

  const getMyAccountPath = () => {
    if (!hasUserToken) {
      return "/autenticacao/login";
    }

    if (!userData.type) return "/candidato/minha-conta";

    if (userData.type === "PJ" && !userData.inkluer) {
      return "/para-empresas/minha-conta";
    }

    if (userData.type === "PF") {
      return "/candidato/minha-conta";
    }
  };

  const getMyPositions = () => {
    if (!userData.inkluer) return (
      <>
        {userData.permissions?.externalHunting && (
          <MenuLink
            handleClose={handleClose}
            href={PARA_EMPRESAS_URL + "/busca-de-perfis"}
          >
            <Search color="#fff" /> Buscar perfis
          </MenuLink>
        )}
        <MenuLink handleClose={handleClose} to="/para-empresas/recrutador/minhas-vagas">
          <Folder color="#fff" /> Minhas vagas
        </MenuLink>
      </>
    )

    if (userData.profile === 'leader') return (
      <>
        <MenuLink
          handleClose={handleClose}
          href="/processos/recrutando"
        >
          <Folder color="#fff" /> Minha gestão
        </MenuLink>
        <MenuLink
          handleClose={handleClose}
          href="/recrutador/busca-inteligente"
        >
          <Magnifier color="#fff" /> Buscar candidatos
        </MenuLink>
      </>
    )

    if (userData.profile === 'director') return (
      <>
        <MenuLink
          handleClose={handleClose}
          href="/relatorios/carteira"
        >
          <Folder color="#fff" /> Minha gestão
        </MenuLink>
        <MenuLink
          handleClose={handleClose}
          href="/faturamento/emitir"
        >
          <TrendingUp color="#fff" /> Faturamento
        </MenuLink>
        <MenuLink
          handleClose={handleClose}
          href="/recrutador/busca-inteligente"
        >
          <Magnifier color="#fff" /> Buscar candidatos
        </MenuLink>
        <MenuLink
          handleClose={handleClose}
          href="/comercial"
        >
          <i className={["icon-volume-mute-outline", styles.icon].join(' ')} /> Comercial
        </MenuLink>
      </>
    )

    if (userData.profile === 'administrative') return (
      <>
        <MenuLink
          handleClose={handleClose}
          href="/faturamento/emitir"
        >
          <TrendingUp color="#fff" /> Faturamento
        </MenuLink>
      </>
    )

    if (userData.profile === 'manager') return (
      <>
        <MenuLink
          handleClose={handleClose}
          href="/processos/recrutando"
        >
          <Folder color="#fff" /> Minha gestão
        </MenuLink>
        <MenuLink
          handleClose={handleClose}
          href="/recrutador/busca-inteligente"
        >
          <Magnifier color="#fff" /> Buscar candidatos
        </MenuLink>
      </>
    )

    if (userData.profile === 'commercial') return (
      <MenuLink
        handleClose={handleClose}
        href="/comercial"
      >
        <i className={["icon-volume-mute-outline", styles.icon].join(' ')} /> Comercial
      </MenuLink>
    )

    return (
      <>
        <MenuLink
          handleClose={handleClose}
          href="/recrutador/minhas-vagas"
        >
          <Folder color="#fff" /> Minhas vagas
        </MenuLink>
        <MenuLink
          handleClose={handleClose}
          href="/recrutador/busca-inteligente"
        >
          <Magnifier color="#fff" /> Buscar candidatos
        </MenuLink>
      </>
    )
  };

  const handlePJLinks = () => {
    return (
      <nav className={styles.navigation}>
        <MenuLink handleClose={handleClose} to="/">
          <House color="#fff" />
          Início
        </MenuLink>
        {userData.profile !== 'director' && userData.profile !== 'administrative' &&
          <MenuLink handleClose={handleClose} href={PARA_EMPRESAS_URL + "/solucoes-rs"}>
            <Star color="#fff" />
            Soluções para R&S
          </MenuLink>
        }
        <MenuLink handleClose={handleClose} href={PARA_EMPRESAS_URL + "/mapeamento-tech"}>
          <CheckMark color="#fff" />
          Mapeamento Tech
        </MenuLink>
        <MenuLink handleClose={handleClose} href={PARA_EMPRESAS_URL + "/palestras"}>
          <Peek color="#fff" />
          Palestras
        </MenuLink>
        <MenuLink handleClose={handleClose} href={PARA_EMPRESAS_URL + "/contato"}>
          <Mail color="#fff" />
          Contato
        </MenuLink>
        {userData && userData.inkluer && (
          <MenuLink
            handleClose={handleClose}
            href={PARA_EMPRESAS_URL}
            blank
          >
            <Suitcase color="#fff" />
            Para empresas
          </MenuLink>
        )}
        <span className={styles.split} />
        {userData && getMyPositions()}
        <MenuLink
          handleClose={handleClose}
          href={(userData?.type === "PJ" && userData?.inkluer) && "/recrutador/minha-conta"}
          to={getMyAccountPath()}
          testid="my-account"
        >
          <User color="#fff" /> Minha conta
        </MenuLink>
      </nav>
    )
  };

  const handlePFLinks = () => {
    return (
      <nav className={styles.navigation}>
        <MenuLink handleClose={handleClose} to="/">
          <House color="#fff" />
          Início
        </MenuLink>
        <MenuLink
          handleClose={handleClose}
          to={getMyAccountPath()}
          testid="my-account"
        >
          <User color="#fff" /> Minha conta
        </MenuLink>
        {/* <MenuLink handleClose={handleClose} to="/como-funciona">
          <Heart color="#fff" /> Doe sem gastar
        </MenuLink> */}
        <MenuLink handleClose={handleClose} to="/contato">
          <Mail color="#fff" />
          Contato
        </MenuLink>
        <span className={styles.split} />
        <MenuLink
          handleClose={handleClose}
          href={PARA_EMPRESAS_URL}
          blank
        >
          <Suitcase color="#fff" />
          Para empresas
        </MenuLink>
      </nav>
    )
  };

  const handleLogout = () => {
    localStorage.removeItem("@user_data");
    localStorage.removeItem("@user_token");
    localStorage.removeItem("@user_inkoins");

    history.push("/autenticacao/login");
    setVisible(false);
  }

  useEffect(() => {
    if (visible) {
      setVisible(true);
      setTimeout(() => {
        setActive(true);
      }, TIMING);
      return;
    }

    setActive(false);
    setTimeout(() => {
      setVisible(false);
    }, TIMING);
  }, [visible]);

  return (
    <div
      aria-hidden={!visible}
      tabIndex={visible ? "1" : "-1"}
      className={[
        styles.wrapper,
        isActive && styles.isVisible,
        !isVisible && styles.isHidden,
      ].join(" ")}
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      {/* {hasUserToken && userData && userData.type === "PF" && <InkoinMenuCounter handleClose={handleClose} />} */}
      <button
        className={styles.closeButton}
        onClick={handleClose}
        id="close-button"
      >
        <Plus color="#fff" />
      </button>
      {userData && userData.type === "PJ" && handlePJLinks()}
      {(!userData || userData.type !== "PJ") && handlePFLinks()}
      {userData && (
        <div className={styles.logoutButton}>
          <button
            onClick={handleLogout}
            type="button"
          >
            <Exit color="#fff" />
            Sair da conta
          </button>
        </div>
      )}
    </div>
  );
};

Menu.propTypes = {
  handleClose: func.isRequired,
  visible: bool,
};

Menu.defaultProps = {
  visible: false,
};

export default Menu;
