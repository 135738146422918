import React from "react";

import AuthenticationWrapper from "../../../../authentication/components/AuthenticationWrapper";

import ChangePasswordForm from "../../components/ChangePasswordForm";

const ChangePassword = () => {
  return (
    <AuthenticationWrapper>
      <ChangePasswordForm />
    </AuthenticationWrapper>
  );
};

export default ChangePassword;
