import React from "react";
import { string } from "prop-types";

const Close = ({ color, height, width }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 8L16 16" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 8L8 16" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

Close.propTypes = {
    color: string,
    height: string,
    width: string,
};

Close.defaultProps = {
    color: "#fff",
    height: "max-content",
    wodth: "max-content",
};

export default Close;
