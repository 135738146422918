import React, { useRef, useEffect } from "react";
import { useField } from "@unform/core";
import { string, oneOfType, number } from "prop-types";
import InputMask from "react-input-mask";

import styles from "./styles.module.scss";

const maskToCurrency = ({ nextState }) => {
  const { value } = nextState || {};

  let amountFormatted = value?.replace?.(/\D/g, "");
  amountFormatted = amountFormatted?.replace?.(/^0+/g, "");

  if (amountFormatted?.length === 2) {
    return {
      ...nextState,
      value: `R$ ${amountFormatted}`,
      selection: {
        start: amountFormatted.length + 3,
        end: amountFormatted.length + 3,
      },
    };
  }

  const amountFormattedWithComma = amountFormatted?.replace?.(
    /(?=\d{2})(\d{2})$/,
    ",$1"
  );
  const amountFormattedWithDot = amountFormattedWithComma?.replace?.(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1."
  );

  if (amountFormattedWithDot) {
    return {
      ...nextState,
      value: `R$ ${amountFormattedWithDot}`,
      selection: {
        start: amountFormattedWithDot.length + 3,
        end: amountFormattedWithDot.length + 3,
      },
    };
  }

  return nextState;
};

const CurrencyInput = ({
  label,
  name,
  placeholder,
  defaultValue: inputDefaultValue,
  ...rest
}) => {
  const inputRef = useRef(null);
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      getValue(refs) {
        if (!refs?.value || refs.value === "") {
          return 0;
        }

        const onlyNumbers = refs.value.match(/[0-9]/g).join("");
        const asFloat = onlyNumbers.slice(0, -2) + "." + onlyNumbers.slice(-2);
        return onlyNumbers.length <= 2 ? onlyNumbers : asFloat;
      },
    });
  }, [fieldName, registerField]);


  return (
    <fieldset className={styles.wrapper}>
      <label htmlFor={`field-${name}`} className={styles.label}>
        {label}
      </label>
      <InputMask
        className={[styles.field, error && styles.invalid].join(" ")}
        ref={inputRef}
        id={`field-${name}`}
        defaultValue={inputDefaultValue || defaultValue}
        placeholder={placeholder}
        type="text"
        onFocus={clearError}
        autoComplete="one-time-code"
        aria-invalid={Boolean(error)}
        aria-describedby={`error_${name}`}
        mask="R$ 99999999999"
        maskPlaceholder=""
        beforeMaskedStateChange={maskToCurrency}
        data-testid={`field-${name}`}
        {...rest}
      />
      {error && (
        <span
          className={styles.error}
          id={`error_${name}`}
          data-testid={`error-message-${name}`}
        >
          {error}
        </span>
      )}
    </fieldset>
  );
};

CurrencyInput.propTypes = {
  label: string.isRequired,
  name: string.isRequired,
  placeholder: string,
  defaultValue: oneOfType([string, number]),
};

CurrencyInput.defaultProps = {
  placeholder: null,
  defaultValue: null,
};

export default CurrencyInput;
