import { useEffect } from "react";

import useUserData from "../../../../hooks/useUserData";

import useDoubleAuthPrevention from "../../../../hooks/useDoubleAuthPrevention";

import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";

import { SectionTitle } from "../../components/SectionTitle";
import RecruiterInfoForm from "../../components/RecruiterInfoForm";
import CandidateInfoForm from "../../components/CandidateInfoForm";

const EditInformation = () => {
  const { validate } = useDoubleAuthPrevention();
  const { getUserType } = useUserData();
  const userType = getUserType();

  useEffect(() => {
    validate({ redirect: false });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthenticationWrapper>
      <SectionTitle>Dados da conta</SectionTitle>
      {userType === "RECRUITER" ? <RecruiterInfoForm /> : <CandidateInfoForm />}
    </AuthenticationWrapper>
  );
};

export default EditInformation;
