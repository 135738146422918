import { string, arrayOf, node } from "prop-types";

const ResponsiveImage = ({ className, images, alt }) => {
  if (images.length === 1) {
    return <img className={className} src={images[0]} alt={alt} />;
  }

  return (
    <img
      className={className}
      src={images[0]}
      srcSet={`
        ${images[0] ? `${images[0]} 1x,` : ""} 
        ${images[1] ? `${images[1]} 2x,` : ""}
        ${images[2] ? `${images[2]} 3x` : ""}
      `}
      alt={alt}
    />
  );
};

ResponsiveImage.propTypes = {
  className: node.isRequired,
  images: arrayOf(string).isRequired,
  alt: string.isRequired,
};

ResponsiveImage.defaultProps = {
  alt: "",
};

export default ResponsiveImage;
