/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import useAxios from '../useAxios';

const useMapTechSearch = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [filtering, setFiltering] = useState(false);
    const [noResults, setNoResults] = useState(false);
    const [error, setError] = useState(null);

    const [filters, setFilters] = useState(null);
    const [candidates, setCandidates] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [filtersApplied, setFiltersApplied] = useState(null);
    const [filtered, setFiltered] = useState(null);
    const [isLastPage, setIsLastPage] = useState(false);

    const { exec } = useAxios();

    const getData = async (uploadPage) => {
        const response = await exec({
            method: "POST",
            url: "search",
            data: {
                page: uploadPage ? currentPage : 1,
                ...filtersApplied
            }
        });
        if (!uploadPage) setCurrentPage(1);

        if (response.error) return setError(true);

        if (Array.isArray(response) || response.candidates.length === 0) return setNoResults(true);

        let resCandidates = response.paginator;
        let resFilters = response.filters;
        let resFiltered = response.filtered;

        setFilters(resFilters);
        setFiltered(resFiltered);
        setIsLastPage(response.is_last_page);

        if(uploadPage && currentPage > 1) setCandidates(prev => [...prev, ...resCandidates]);
        else setCandidates(resCandidates);

        if (noResults) setNoResults(false);
        if (isLoading) setIsLoading(false);
        if (filtering) setFiltering(false);

    };

    const getDataFromID = async (id) => {
        const response = await exec({
            method: "POST",
            url: `detail/${id}`
        });

        if (response.error) return setError(true);

        return response;

    };

    const resetFilters = () => {
        setFiltersApplied({});
        setCurrentPage(1);
    };

    const handleFiltereds = () => {
        return Object.values(filtered).some(arr => !Array.isArray(arr));
    };

    const handleSearchMore = () => {
        setCurrentPage(prev => prev + 1);
    };

    // Get initial data;
    useEffect(() => {
        getData();
    }, []);

    // Get data from filters;
    useEffect(() => {
        if (filtersApplied) getData();
    }, [filtersApplied]);

    // Get data from current page;
    useEffect(() => {
        if (currentPage > 1) getData(true);
    }, [currentPage]);

    const values = {
        error,
        noResults,
        isLoading,
        candidates,
        filters,
        filtered,
        filtering,
        isLastPage,
        setFiltering,
        setFiltersApplied,
        resetFilters,
        getDataFromID,
        handleFiltereds,
        handleSearchMore,
        setCurrentPage,
    };

    return values;

}

export default useMapTechSearch;