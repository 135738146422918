import React from "react";
import { bool, func } from "prop-types";

import SquareButton from "../../components/SquareButton";

import styles from "./styles.module.scss";

const PaginationWrapper = ({ onPress, hidden }) => {
  const handlePress = () => {
    onPress();
  };

  if (hidden) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttonWrapper}>
        <SquareButton
          onClick={handlePress}
          aria-disabled="false"
          text="Carregar mais"
        />
      </div>
    </div>
  );
};

PaginationWrapper.propTypes = {
  onPress: func.isRequired,
  hidden: bool,
};

PaginationWrapper.defaultProps = {
  hidden: false,
};

export default PaginationWrapper;
