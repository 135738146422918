import { string, bool } from "prop-types";
import { englishLevels } from "../../libs/data";

import { Pin, House, Clock, Currency, Info } from "../../libs/icons";

import styles from "./styles.module.scss";

export const PositionInformationSummary = ({
  location,
  shift,
  salary,
  remote,
  hybrid,
  presential,
  englishLevel,
}) => {
  return (
    <ul className={styles.list}>
      {!remote && (presential || hybrid) &&
        <li tabIndex={0}>
          <Pin />
          <b>Local</b>: {location}
        </li>
      }
      {hybrid && !remote &&
        <li tabIndex={0}>
          <House />
          <b>Aceita trabalho híbrido</b>
        </li>
      }
      {hybrid && remote &&
        <li tabIndex={0}>
          <House />
          <b>Aceita trabalho híbrido ou somente remoto</b>
        </li>
      }
      {!hybrid && !presential && remote &&
        <li tabIndex={0}>
          <House />
          <b>Aceita trabalho somente remoto</b>
        </li>
      }
      {
        shift && (
          <li tabIndex={0}>
            <Clock />
            <b>Horário:</b> {shift}
          </li>
        )
      }
      {
        englishLevel && (
          <li tabIndex={0}>
            <Info />
            <b>Nível de inglês:</b> {englishLevel}
          </li>
        )
      }
      {
        salary && (
          <li tabIndex={0}>
            <Currency />
            <b>Remuneração:</b> {salary}
          </li>
        )
      }
    </ul >
  );
};

PositionInformationSummary.propTypes = {
  location: string.isRequired,
  shift: string,
  salary: string,
  remote: bool.isRequired,
  englishLevel: string,
};

PositionInformationSummary.defaultProps = {
  shift: null,
  englishLevel: null,
  salary: null,
};
