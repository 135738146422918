import React from "react";
import { string } from "prop-types";

const Facebook = ({ color, label }) => {
  return (
    <svg
      width="11px"
      height="18px"
      viewBox="0 0 11 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      aria-label={label}
    >
      <g
        id="🖥️-03-buscando-uma-vaga-inklua-(DesktopView)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="01-home-inklua-desktop"
          transform="translate(-1236.000000, -2773.000000)"
        >
          <g id="footer" transform="translate(0.000000, 2694.000000)">
            <rect id="bg-footer" x="0" y="0" width="1440" height="224"></rect>
            <g id="btn-facebook" transform="translate(1222.000000, 67.000000)">
              <g>
                <circle id="Oval" cx="20" cy="20" r="20"></circle>
                <g
                  id="27)-Icon/activity"
                  transform="translate(13.201531, 11.218750)"
                  fill={color}
                >
                  <path
                    d="M11.7144983,1.37239583 C11.7144983,1.10645833 11.493277,0.890625 11.2228954,0.890625 L8.76488095,0.890625 C6.04926658,0.890625 3.84885204,2.83216146 3.84885204,5.2265625 L3.84885204,7.828125 L1.39083759,7.828125 C1.11947279,7.828125 0.899234694,8.04395833 0.899234694,8.30989583 L0.899234694,10.8151042 C0.899234694,11.0810417 1.11947279,11.296875 1.39083759,11.296875 L3.84885204,11.296875 L3.84885204,17.7526042 C3.84885204,18.0185417 4.06909014,18.234375 4.34045493,18.234375 L7.29007228,18.234375 C7.56045387,18.234375 7.78167517,18.0185417 7.78167517,17.7526042 L7.78167517,11.296875 L10.3566911,11.296875 C10.5779124,11.296875 10.771604,11.1523438 10.8315795,10.9442188 L11.5404709,8.43901042 C11.6279762,8.13260417 11.3920068,7.828125 11.0675489,7.828125 L7.78167517,7.828125 L7.78167517,5.2265625 C7.78167517,4.74768229 8.22116815,4.359375 8.76488095,4.359375 L11.2228954,4.359375 C11.493277,4.359375 11.7144983,4.14354167 11.7144983,3.87760417 L11.7144983,1.37239583 Z"
                    id="🎨-Icon-Сolor"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Facebook.propTypes = {
  color: string,
  label: string,
};

Facebook.defaultProps = {
  color: "#1B9B9E",
  label: "Facebook",
};

export default Facebook;
