import { Inkoin } from "../../../../libs/icons";
import SquareLinkButton from "../../../../components/SquareLinkButton";

import styles from "./styles.module.scss";

const AvailableInkoinsWarning = () => {
  return (
    <div className={styles.wrapper}>
      <Inkoin size="48px" />
      <p>Existem Inkoins que ainda não foram doadas. Doe antes de sair.</p>
      <SquareLinkButton
        text="Doar minhas Inkoins antes"
        url="/area-de-doacoes"
      />
    </div>
  );
};

export default AvailableInkoinsWarning;
