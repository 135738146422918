import React from "react";
import { node, string } from "prop-types";

import styles from "./styles.module.scss";

const SocialMediaLink = ({ children, url, color, ...rest }) => (
  <a
    className={styles.button}
    style={{ backgroundColor: color }}
    href={url}
    rel="noreferrer"
    target="_blank"
    {...rest}
  >
    {children}
  </a>
);

SocialMediaLink.propTypes = {
  children: node.isRequired,
  url: string.isRequired,
  color: string,
};

SocialMediaLink.defaultProps = {
  color: "#137784",
};

export default SocialMediaLink;
