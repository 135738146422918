import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "../../../Recruiter/components/Container";
import { races, genders, disability } from "../../../../libs/data";
import SquareLinkButton from "../../../../components/SquareLinkButton";
import ButtonWithIcon from "../../../../components/ButtonWithIcon";
import { PersonalDataSummary } from "../../../../components/PersonalDataSummary";
import MissingInfo from "../../../../components/MissingInfo";
import { DisabilitySummary } from "../../../../components/DisabilitySummary";
import { UserEducationSummary } from "../../../../components/UserEducationSummary";
import { CandidateWorkExperienceSummary } from "../../../../components/CandidateWorkExperienceSummary";
import { Exit } from "../../../../libs/icons";
import { formatPhone } from "../../../../helpers/format";
import { toCurrency, toDate } from "../../../../helpers";
import { DeleteAccountSummary } from "../../../../components/DeleteAccountSummary";
import { UserAvailabilitySummary } from "../../../../components/UserAvailabilitySummary";
import useAxios from "../../../../hooks/useAxios";
import { MyAccountHeader } from "../../components/MyAccountHeader";
import WelcomeMessage from "../../components/WelcomeMessage";
import InformationSummary from "../../components/InformationSummary";
import { isUserFullySignedUp } from "../../hooks/isUserFullySignedUp";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { useStatesAndCities } from "../../../../hooks/useStatesAndCities";
import { states } from "../../../../libs/data";
import { useSnackBarContext } from "../../../../contexts/SnackBar";
import ResumeProcessingStatus from '../../../../components/ResumeProcessingStatus';

const MyAccount = () => {
  const isMobile = window.innerWidth <= 1024;
  const history = useHistory();
  const { checkUserStatusAndRedirect } = isUserFullySignedUp();
  const { exec, loading } = useAxios();
  const [data, setUserData] = useState(null);
  const [requestData, setRequestData] = useState(null);
  const [openMissingInfo, setOpenMissingInfo] = useState(false);
  const {
    getCities: fetchCityList,
  } = useStatesAndCities();
  const { showSnackBar } = useSnackBarContext();

  const handleLogout = () => {
    localStorage.removeItem("@user_data");
    localStorage.removeItem("@user_token");
    localStorage.removeItem("@user_inkoins");
    localStorage.removeItem("@user_shares");
    localStorage.removeItem("@user_redirect");
    localStorage.removeItem(`@user_viewed_tooltip_${data?.email}`);

    history.push("/");
  };

  const getCorrectCityAndState = async ({ state, city }) => {
    const newState = states.brazil.find(st => st.id === state)?.UF;
    const cities = await fetchCityList(Number(state));
    const newCity = cities.find((item) => item.id === city)?.name;

    return { newState, newCity }
  };

  const fetchData = async () => {
    try {
      const response = await exec({
        method: "GET",
        url: `/my-account`,
        useCredentials: true,
      });

      if (response.error) {
        showSnackBar({
          timeout: 5000,
          text: "Houve um erro, tente novamente em instantes.",
          error: true
        });

        handleLogout();
        return;
      };

      checkUserStatusAndRedirect();

      if (response.user.type === "PF" && response.user.state && response.user.city) {
        const { newState, newCity } = await getCorrectCityAndState({
          state: response.user.state,
          city: response.user.city
        });

        setOpenMissingInfo(response.missingData.length > 0);
        setRequestData(response)
        setUserData({ ...response.user, city: newCity, state: newState });
        return;
      }

      setOpenMissingInfo(response.missingData.length > 0);
      setUserData(response.user);
      setRequestData(response)
    } catch (error) {

      showSnackBar({
        timeout: 5000,
        text: "Houve um erro, tente novamente em instantes.",
        error: true
      });

      handleLogout();
    }
  };

  useEffect(() => {
    fetchData();

    return () => setUserData(null);
  }, []);

  if (!data && loading) return <LoadingSpinner />;

  return (
    <>
      <MyAccountHeader>
        <WelcomeMessage
          email={data?.email}
          name={`${data?.name} ${data?.lastname}`}
          path={data?.share_url}
        />
        {!isMobile && <ResumeProcessingStatus status={requestData?.message} />}
        <ButtonWithIcon onClick={handleLogout} text="Sair">
          <Exit />
        </ButtonWithIcon>
      </MyAccountHeader>
      {isMobile && (
        <MyAccountHeader smallPadding>
          <ResumeProcessingStatus status={requestData?.message} />
        </MyAccountHeader>
      )}
      {data?.type === "PF" ? (
        <>
          {openMissingInfo && (
            <Container error >
              <MissingInfo
                missingData={requestData?.missingData}
                showMissingInfo={setOpenMissingInfo}
                reFetch={fetchData}
              />
            </Container>
          )}
          <Container>
            <PersonalDataSummary
              name={data?.name}
              lastname={data?.lastname}
              birthDate={data?.birthdate && toDate(data?.birthdate, "DD/MM/YYYY")}
              email={data?.email}
              cellphone={data?.phone}
              city={data?.city}
              state={data?.state}
              neighborhood={data?.neighborhood}
              linkedinUrl={data?.linkedin}
              portfolioUrl={data?.portfolio}
              resumeUrl={data?.cv_path}
              actionButton={
                <SquareLinkButton
                  inline
                  text="Editar dados"
                  url="/minha-conta/dados-pessoais"
                  type="outline"
                  testID="edit-personal-data-button"
                />
              }
              salary={toCurrency({ value: data?.salary })}
              race={races.filter((r) => r.value === data?.race)[0]?.label}
              gender={
                genders.filter((g) => g.value === data?.gender)[0]?.label
              }
            />
          </Container>
          <Container>
            <DisabilitySummary
              type={
                data?.disability ?
                  disability.filter((d) => d.value === data?.disability.id)[0]?.label
                  : null
              }
              report={data?.disability ? data?.disability.report : ''}
              details={data?.disability ? data?.disability.details : ''}
              actionButton={
                <SquareLinkButton
                  testID="edit-disability-button"
                  inline
                  text="Editar dados PcD"
                  url="/minha-conta/deficiencia"
                  type="outline"
                />
              }
            />
          </Container>
          <Container>
            <UserEducationSummary
              data={data?.education}
              actionButton={
                <SquareLinkButton
                  testID="edit-education-button"
                  inline
                  text="Editar formação acadêmica"
                  url="/candidato/minha-conta/formacao-academica"
                  type="outline"
                />
              }
            />
          </Container>
          <Container>
            <CandidateWorkExperienceSummary
              data={data?.experience}
              actionButton={
                <SquareLinkButton
                  inline
                  text="Editar experiências"
                  url="/candidato/minha-conta/experiencia"
                  type="outline"
                  testID="edit-work-experiences-button"
                />
              }
            />
          </Container>
          <Container>
            <UserAvailabilitySummary
              local={data?.availability?.local}
              relocation={data?.availability?.relocation}
              hybrid={data?.availability?.hybrid}
              remote={data?.availability?.remote}
              actionButton={
                <SquareLinkButton
                  testID="edit-availability-button"
                  inline
                  text="Editar disponibilidade"
                  url="/minha-conta/disponibilidade"
                  type="outline"
                />
              }
            />
          </Container>
        </>
      ) : (
        <Container>
          <InformationSummary title="Dados da conta">
            <ul>
              {data?.fantasy_name && (
                <li>
                  <b>Empresa:</b> {data?.fantasy_name}
                </li>
              )}
              {data?.cnpj && (
                <li>
                  <b>CNPJ:</b> {data?.cnpj}
                </li>
              )}
              {data?.corporate_name && (
                <li>
                  <b>Razão Social:</b> {data?.corporate_name}
                </li>
              )}
              <li>
                <b>Nome:</b> {data?.name} {data?.lastname}
              </li>
              {data?.phone && (
                <li>
                  <b>Telefone:</b> {formatPhone(data?.phone)}
                </li>
              )}
              <li>
                <b>E-mail:</b> {data?.email}
              </li>
            </ul>
            <SquareLinkButton
              inline
              text="Editar dados"
              url="/recrutador/minha-conta/editar"
              type="outline"
              rel="noreferrer"
            />
          </InformationSummary>
        </Container>
      )}
      {data?.type === "PF" &&
        <Container>
          <DeleteAccountSummary />
        </Container>
      }
    </>
  );
};

export default MyAccount;
