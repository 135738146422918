import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams, useHistory } from "react-router-dom";

import styles from "./styles.module.scss";

import JobDetailsModal from "../../components/JobDetailsModal";
import ShareButton from "../../components/ShareButton";

import { LeftArrow } from "../../../../libs/icons";
import axios from "../../../../libs/axios";
import { stripHTML } from "../../../../helpers";

const MetaTags = ({
  jobTitle,
  description,
  url
}) => (
  <Helmet>
    <title>{`Profissional com deficiência (PcD): ${jobTitle} | Inklua`}</title>
    <meta name="description" content={`${description}...`} />
    <meta name="keywords" content="profissionais de tecnologia pcd, profissionais pcd, desenvolvedor pcd, designer pcd, analista pcd, QA pcd, recrutamento e seleção, inkua, empresa inklua, PcD, NOME_DA_FUNCAO + pcd. " />
    <meta property="og:title" content={jobTitle} />
    <meta property="og:type" content="website" />
    <meta property="og:description" content={`${description}...`} />
    <meta property="og:image" content="https://s3.sa-east-1.amazonaws.com/public.inklua/logo_mini.png" />
    <meta property="og:image:type" content="image/jpeg" />
    <meta property="og:image:width" content="200" />
    <meta property="og:image:height" content="200" />
    <meta property="og:url" content={url} />
    <meta property="og:site_name" content=" Inklua" />
    <link rel="canonical" href={url} />
  </Helmet>
);

const DetailsEnterprise = () => {
  let { id } = useParams();
  let history = useHistory();
  const [candidate, setCandidate] = useState(null);

  // Get initial data
  const fechData = async () => {
    try {
      const response = await axios({
        method: "POST",
        url: `detail/${id}`,
        header: [],
      });
      setCandidate(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const parseDescriptionToMetatags = (string) => {
    if (!string) return'';

    return stripHTML(string).slice(0, 160);
  };

  useEffect(() => {
    fechData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!candidate || !document) return;

    document.title = `Profissional com deficiência (PcD): ${candidate.title} | Inklua`;
  }, [candidate]);

  return (
    <>
      <MetaTags
        jobTitle={candidate?.title}
        description={parseDescriptionToMetatags(candidate?.description)}
        url={window.location.href}
      />
      <section
        className={styles.wrapper}
        style={{ paddingTop: "20px", maxWidth: "1075px", margin: "auto" }}
      >
        <div className={styles.modalContainer}>
          {candidate && (
            <div className={styles.headerDetails}>
              <button onClick={history.goBack}>
                <LeftArrow color="#438FCC" />
              </button>
              <span><strong>Detalhes do candidato</strong></span>
              <ShareButton
                text={`Compartilhar artigo ${candidate.title}`}
                title={candidate.title}
                url={window.location.href}
                id={candidate.gid}
              />
            </div>
          )}
          <JobDetailsModal
            details={candidate}
            setOpenDetails={history.goBack}
            loading={!candidate}
          />
        </div>
      </section>
    </>
  );
};

export default DetailsEnterprise;
