import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import useAxios from "../../../../hooks/useAxios";
// import { useQuery } from "../../../../hooks/useQuery";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { PersonalDataSummary } from "../../../../components/PersonalDataSummary";
import { toCurrency, isMobile } from "../../../../helpers";
import { races, genders } from "../../../../libs/data";
import { DisabilitySummary } from "../../../../components/DisabilitySummary";
import { UserEducationSummary } from "../../../../components/UserEducationSummary";

import { CandidateProfileHeader } from "../../components/CandidateProfileHeader";
import { CandidateRecruitmentStatusMobile } from "../../components/CandidateRecruitmentStatusMobile";
import { Container } from "../../components/Container";
import { CandidateWorkExperienceSummary } from "../../../../components/CandidateWorkExperienceSummary";
import { UserAvailabilitySummary } from "../../../../components/UserAvailabilitySummary";
import SquareLinkButton from "../../../../components/SquareLinkButton";
import { useQuery } from "../../../../hooks/useQuery";
// import { CandidateReportSummary } from "../../components/CandidateReportSummary";

const CandidateProfile = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const { exec, loading } = useAxios();
  const listingId = useQuery().get("vaga");

  const fetchData = async () => {
    try {
      const response = await exec({
        useCredentials: true,
        method: "GET",
        url: `/admin/hunting/candidate/${id}`,
      });

      setUserData(response);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (loading || !userData) {
    return (
      <>
        <br />
        <br />
        <LoadingSpinner />
        <br />
      </>
    );
  }

  return (
    <div
      style={{
        display: "block",
        width: "1092px",
        maxWidth: "calc(100vw - 40px)",
        margin: "0 auto",
        position: "relative",
      }}
    >
      <Container>
        <CandidateProfileHeader
          name={userData.name}
          status={userData.recruitment.status}
          recruiter={userData.recruitment?.recruiterName}
        />
      </Container>
      {isMobile() && (
        <div style={{ margin: "24px 0" }}>
          <CandidateRecruitmentStatusMobile
            status={userData.status}
            recruiter={userData?.recruiterName || null}
          />
        </div>
      )}
      <Container>
        <PersonalDataSummary
          name={userData.name}
          lastname={userData.lastname}
          age={userData.age}
          email={userData.email}
          cellphone={userData.cellphone}
          cellphoneLink={`https://wa.me/55${userData.cellphone.replace(/\D/g,'')}`}
          city={userData.location.city.name}
          state={userData.location.state.UF}
          salary={`R$ ${userData.payment}`}
          race={races.filter((r) => r.value === userData.race_id)[0].label}
          gender={
            genders.filter((g) => g.value === userData.gender_id)[0].label
          }
          linkedinUrl={userData.linkedin_url}
          portfolioUrl={userData.linkedin_url}
          resumeUrl={userData.cv_path}
          neighborhood={userData.neighborhood}
        />
      </Container>
      <Container>
        <DisabilitySummary
          type={userData.pcd_type.type}
          report={userData.pcd_report}
          details={userData.pcd_details}
        />
      </Container>
      <Container>
        <UserEducationSummary data={userData.education} />
      </Container>
      <Container>
        <CandidateWorkExperienceSummary data={userData.experience} />
      </Container>
      <Container>
        <UserAvailabilitySummary
          local={userData.presential}
          relocation={userData.move_out}
          hybrid={userData.hybrid}
          remote={userData.remote}
        />
      </Container>
      <Container>
        <SquareLinkButton 
          testID="back-to-list"
          text="Voltar"
          url="/recrutador/visualizar-vaga"
          type="ghost"
          rel="noreferrer"
        />
      </Container>
      {/* {Boolean(userData.report) && (
        <CandidateReportSummary
          listingId={listingId}
          candidateId={userData.gid}
          data={userData.report}
        />
      )} */}
    </div>
  );
};

export { CandidateProfile };
