import React from "react";
import { string, arrayOf, func, bool } from "prop-types";

import AutoCompleteSuggestion from "../AutoCompleteSuggestion";

import styles from "./styles.module.scss";

const AutoCompleteList = ({ suggestions, handleClick, filter, visible }) => {
  if (!suggestions.length || !visible || !filter) {
    return null;
  }

  return (
    <ul className={[styles.list, visible && styles.visible].join(" ")}>
      {suggestions.map((suggestion) => (
        <AutoCompleteSuggestion
          handleClick={handleClick}
          filter={filter}
          key={`suggestion_${suggestion}`}
        >
          {suggestion}
        </AutoCompleteSuggestion>
      ))}
    </ul>
  );
};

AutoCompleteList.propTypes = {
  visible: bool,
  suggestions: arrayOf(string).isRequired,
  handleClick: func.isRequired,
  filter: string,
};

AutoCompleteList.defaultProps = {
  visible: false,
  filter: null,
};

export default AutoCompleteList;
