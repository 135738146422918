import styles from "./styles.module.scss";

export const RecruiterInformation = ({ recruiterName }) => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.preffix} tabIndex={0}>Recrutador(a) responsável</span>
      <span className={styles.name} tabIndex={0}>{recruiterName ?? " - "}</span>
    </div>
  );
};
