import React from "react";
import { string } from "prop-types";

const Share = ({ color }) => {
  return (
    <svg
      width="19px"
      height="18px"
      viewBox="0 0 19 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      aria-hidden="true"
    >
      <defs>
        <path
          d="M16,16 C15.448,16 15,15.552 15,15 C15,14.448 15.448,14 16,14 C16.552,14 17,14.448 17,15 C17,15.552 16.552,16 16,16 M3,10 C2.448,10 2,9.552 2,9 C2,8.448 2.448,8 3,8 C3.552,8 4,8.448 4,9 C4,9.552 3.552,10 3,10 M16,2 C16.552,2 17,2.448 17,3 C17,3.552 16.552,4 16,4 C15.448,4 15,3.552 15,3 C15,2.448 15.448,2 16,2 M16,12 C15.183,12 14.443,12.33 13.901,12.861 L5.966,9.335 C5.979,9.224 6,9.114 6,9 C6,8.886 5.979,8.776 5.966,8.665 L13.901,5.139 C14.443,5.67 15.183,6 16,6 C17.654,6 19,4.654 19,3 C19,1.346 17.654,0 16,0 C14.346,0 13,1.346 13,3 C13,3.114 13.021,3.224 13.034,3.335 L5.099,6.861 C4.557,6.33 3.817,6 3,6 C1.346,6 0,7.346 0,9 C0,10.654 1.346,12 3,12 C3.817,12 4.557,11.67 5.099,11.139 L13.034,14.665 C13.021,14.776 13,14.886 13,15 C13,16.654 14.346,18 16,18 C17.654,18 19,16.654 19,15 C19,13.346 17.654,12 16,12"
          id="path-share-1"
        ></path>
      </defs>
      <g
        id="🖥️-03-home-inklua-computador"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="01-home-inklua-desktop"
          transform="translate(-380.000000, -1883.000000)"
        >
          <g id="content" transform="translate(89.000000, 878.000000)">
            <g id="card-vaga" transform="translate(5.000000, 546.000000)">
              <g id="footer" transform="translate(24.000000, 440.000000)">
                <g id="share-btn" transform="translate(244.000000, 0.000000)">
                  <g
                    id="27)-Icon/share"
                    transform="translate(18.000000, 19.000000)"
                  >
                    <mask id="mask-2" fill="white">
                      <use xlinkHref="#path-share-1" />
                    </mask>
                    <use
                      id="🎨-Icon-Сolor"
                      fill={color}
                      xlinkHref="#path-share-1"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Share.propTypes = {
  color: string,
};

Share.defaultProps = {
  color: "#1B9B9E",
};

export default Share;
