import { useState } from "react";
import axios from "../libs/axios";

const useAxios = () => {
  const [loading, setLoading] = useState(false);

  const exec = async ({ method, url, data, useCredentials, baseUrl }) => {
    try {
      setLoading(true);

      const { data: responseData } = await axios({
        method,
        url,
        data,
        useCredentials,
        baseURL: baseUrl ? baseUrl : process.env.REACT_APP_INKLUA_API_URL,
      });

      return responseData;
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  };

  return { loading, exec };
};

export default useAxios;
