import { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "@unform/web";
import * as Yup from "yup";

import SquareButton from "../../../../components/SquareButton";
import useAxios from "../../../../hooks/useAxios";
import SelectInput from "../../../../components/SelectInput";
import { candidateEnglishLevels } from "../../../../libs/data";
import validationDictionary from "../../../../constants/validationDictionary";
import { focusElement } from "../../../../helpers";

import { SectionTitle } from "../../../Account/components/SectionTitle";
import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";

import styles from "./styles.module.scss";

const CandidateAddLanguage = () => {
  const formRef = useRef();
  const history = useHistory();
  const [selectedEnglishLevel, setSelectedEnglishLevel] = useState(
    candidateEnglishLevels[0].slug
  );
  const { exec, loading } = useAxios();

  const handleSubmit = async (data) => {
    try {
      data.english_level = selectedEnglishLevel;

      const schema = Yup.object().shape({
        english_level: Yup.string().oneOf(
          candidateEnglishLevels.map((l) => l.slug),
          validationDictionary.GENERIC_INVALID_FIELD
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await exec({
        useCredentials: true,
        method: "PUT",
        url: "/candidate/languages",
        data,
      });

      if (response.user.status) {
        const userData = JSON.parse(localStorage.getItem("@user_data"));
				const url = userData.has_disability ? "/autenticacao/cadastro/candidato/cadastro-completo-pcd" : "/autenticacao/cadastro/candidato/cadastro-completo";
				history.push(url);
				localStorage.setItem("@user_data", JSON.stringify({ ...userData, status: "complete" }));
				return;

        // Caso a tela de indicação retorne, descomentar as linhas abaixo e remover as linhas acima
        //
        // localStorage.setItem("@user_data", JSON.stringify({...response.user, status: "pending-indication"}));
        // goToNextStep();
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  // Caso a tela de indicação retorne, descomentar as linhas abaixo
  //
  // const goToNextStep = () => {
  //   history.push("/autenticacao/cadastro/candidato/indicacao");
  // };

  useEffect(() => {
    focusElement('[data-testid="account-section-title"]');
  }, []);

  return (
    <AuthenticationWrapper>
      <SectionTitle>Nível de inglês</SectionTitle>
      <Form ref={formRef} onSubmit={handleSubmit} className={styles.form}>
        <SelectInput
          name="english_level"
          label="Nível de inglês"
          placeholder="Selecione o nível"
          setter={setSelectedEnglishLevel}
          keys={{ label: "name", value: "slug" }}
          options={candidateEnglishLevels}
        />
        <SquareButton text="Próxima etapa" type="submit" disabled={loading} />
      </Form>
    </AuthenticationWrapper>
  );
};

export { CandidateAddLanguage };
