const errorParser = (validationErrors) => {
  const response = {};

  Object.keys(validationErrors).forEach((error) => {
    const errorPreffix = error.split(".")[0];
    if (!response[errorPreffix]) {
      response[errorPreffix] = [validationErrors[error]];
    } else {
      response[errorPreffix].push(validationErrors[error]);
    }
  });

  Object.keys(response).forEach((error) => {
    response[error] = response[error].join("_");
  });

  return response;
};

export { errorParser };
