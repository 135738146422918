import React from "react";
import { string } from "prop-types";

const Exit = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="17"
      height="16"
    >
      <defs>
        <path
          d="M7 4c.55 0 1 .45 1 1s-.45 1-1 1h0-1v12h1c.55 0 1 .45 1 1s-.45 1-1 1h0-2c-.55 0-1-.45-1-1h0V5c0-.55.45-1 1-1h0zm9.611 3.182a1 1 0 0 1 1.393.243h0l2.814 4A1 1 0 0 1 20.8 12.6h0l-3 4a1 1 0 0 1-.801.4c-.208 0-.419-.065-.599-.2a1 1 0 0 1-.2-1.399h0L18.001 13H10a1 1 0 1 1 0-2h0 8c.03 0 .056.015.086.018h0l-1.718-2.443a1 1 0 0 1 .243-1.393z"
          id="A"
        />
      </defs>
      <use
        fill={color}
        xlinkHref="#A"
        transform="translate(-4 -4)"
        fillRule="evenodd"
      />
    </svg>
  );
};

Exit.propTypes = {
  color: string,
};

Exit.defaultProps = {
  color: "#1B9B9E",
};

export default Exit;
