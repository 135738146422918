import React from "react";
import { string, func, bool } from "prop-types";

import styles from "./styles.module.scss";

const GhostButton = ({ text, onClick, disabled, name }) => {
  return (
    <button
      disabled={disabled}
      aria-disabled={disabled}
      className={[styles.button, disabled && styles.disabled].join(" ")}
      type="button"
      onClick={onClick}
      data-testid={`ghost-button-${name}`}
    >
      {text}
    </button>
  );
};

GhostButton.propTypes = {
  text: string.isRequired,
  name: string.isRequired,
  onClick: func.isRequired,
  disabled: bool,
};

GhostButton.defaultProps = {
  disabled: false,
};

export { GhostButton };
