import { func, bool, string } from "prop-types";
import useEnterPriseEnvCheck from "../../hooks/useEnterpriseEnvCheck";

import styles from "./styles.module.scss";

const CheckboxFilter = ({ label, value, onChange, checked, error, invalid, name }) => {
  const { isEnterpriseEnv } = useEnterPriseEnvCheck();
  
  return (
    <div className={styles.label}>
      <button
        className={[
          styles.input,
          error ? styles.error : '',
          checked ? styles.active : styles.inactive,
          isEnterpriseEnv ? styles.secondaryLayout : '',
          invalid ? styles.invalid : "",
        ].join(" ")}
        onClick={() => onChange({ value: value })}
        data-testid={name ? `checkbox-button-${name}` : "checkbox-button"}
        type="button"
      >
        {label}
      </button>
    </div>
  );
};

CheckboxFilter.propTypes = {
  name: string,
  label: string.isRequired,
  value: string.isRequired,
  onChange: func.isRequired,
  checked: bool.isRequired,
  invalid: bool,
  error: bool,
};

CheckboxFilter.defaultProps = {
  name: null,
  invalid: false,
  error: false,
};

export default CheckboxFilter;
