import React from "react";
import { bool, string } from "prop-types";

const Folder = ({ color, hidden }) => {
  return (
    <svg
      width="25px"
      height="22px"
      viewBox="0 0 25 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden={hidden}
    >
      <g
        id="⬅️➡️📱02-fluxo:recrutador-cadastrando-vagas(MobileView)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="00-menu-recrutador-logado"
          transform="translate(-50.000000, -373.000000)"
        >
          <rect
            id="bg"
            fill="transparent"
            x="0"
            y="94"
            width="414"
            height="802"
          />
          <g
            id="Group-3"
            transform="translate(48.000000, 244.000000)"
            fill={color}
            fillRule="nonzero"
            stroke="#FFFFFF"
            strokeWidth="0.1"
          >
            <g
              id="para-empresas-copy"
              transform="translate(0.000000, 126.000000)"
            >
              <g
                id="unselected-state"
                transform="translate(3.000000, 0.000000)"
              >
                <g
                  id="folder-outline"
                  transform="translate(0.000000, 4.000000)"
                >
                  <path
                    d="M20.125,19.9997765 L2.875,19.9997765 C1.31061512,20.0194234 0.0252181937,18.7412875 0,17.1410169 L0,2.85898313 C0.0252181937,1.2587125 1.31061512,-0.0194234448 2.875,0.000223499096 L8.165,0.000223499096 C8.50894574,0.00255031993 8.83381696,0.162246955 9.0505,0.435507888 L12.0405,4.17660074 L20.0905,4.17660074 C20.8478173,4.15767782 21.5811486,4.44900419 22.1275012,4.98582858 C22.6738537,5.52265296 22.987958,6.26049544 23,7.03536038 L23,17.1410169 C22.9747818,18.7412875 21.6893849,20.0194234 20.125,19.9997765 Z M2.3,12.070542 L2.3,17.1410169 C2.30875064,17.2868624 2.37478177,17.4228899 2.48307756,17.5181659 C2.59137335,17.6134419 2.73272918,17.6598685 2.875,17.6468879 L20.125,17.6468879 C20.2672708,17.6598685 20.4086266,17.6134419 20.5169224,17.5181659 C20.6252182,17.4228899 20.6912494,17.2868624 20.7,17.1410169 L20.7,7.03536038 C20.6912494,6.8895148 20.6252182,6.75348733 20.5169224,6.65821132 C20.4086266,6.56293531 20.2672708,6.51650877 20.125,6.52948933 L11.5,6.52948933 C11.1560543,6.52716251 10.831183,6.36746588 10.6145,6.09420494 L7.6245,2.35311209 L2.875,2.35311209 C2.73272918,2.34013153 2.59137335,2.38655807 2.48307756,2.48183408 C2.37478177,2.57711009 2.30875064,2.71313755 2.3,2.85898313 L2.3,12.070542 Z"
                    id="Shape"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Folder.propTypes = {
  color: string,
  hidden: bool,
};

Folder.defaultProps = {
  color: "#1B9B9E",
  hidden: true,
};

export default Folder;
