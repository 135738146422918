import React from "react";
import { string, bool } from "prop-types";

const QuestionCircle = ({ color, hidden }) => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden={hidden}
    >
      <g
        id="question-mark-icon-hover-state"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M20,4 C28.836556,4 36,11.163444 36,20 C36,28.836556 28.836556,36 20,36 C11.163444,36 4,28.836556 4,20 C4,11.163444 11.163444,4 20,4 Z M20,7.5 C13.0964406,7.5 7.5,13.0964406 7.5,20 C7.5,26.9035594 13.0964406,32.5 20,32.5 C26.9035594,32.5 32.5,26.9035594 32.5,20 C32.5,13.0964406 26.9035594,7.5 20,7.5 Z M19.9873684,25.9789474 C20.7836884,25.9789474 21.4273684,26.6226274 21.4273684,27.4189474 C21.4273684,28.2152674 20.7836884,28.8589474 19.9873684,28.8589474 C19.1910484,28.8589474 18.5473684,28.2152674 18.5473684,27.4189474 C18.5473684,26.6226274 19.1910484,25.9789474 19.9873684,25.9789474 Z M19.9873684,11.5789474 C22.7665684,11.5789474 25.0273684,13.8397474 25.0273684,16.6189474 C25.0273684,18.8941474 23.5009684,20.7992674 21.4273684,21.4242274 L21.4273684,21.4242274 L21.4273684,23.0989474 C21.4273684,23.8952674 20.7836884,24.5389474 19.9873684,24.5389474 C19.1910484,24.5389474 18.5473684,23.8952674 18.5473684,23.0989474 L18.5473684,23.0989474 L18.5473684,20.2189474 C18.5473684,19.4226274 19.1910484,18.7789474 19.9873684,18.7789474 C21.1782484,18.7789474 22.1473684,17.8098274 22.1473684,16.6189474 C22.1473684,15.4280674 21.1782484,14.4589474 19.9873684,14.4589474 C18.7964884,14.4589474 17.8273684,15.4280674 17.8273684,16.6189474 C17.8273684,17.4152674 17.1836884,18.0589474 16.3873684,18.0589474 C15.5910484,18.0589474 14.9473684,17.4152674 14.9473684,16.6189474 C14.9473684,13.8397474 17.2081684,11.5789474 19.9873684,11.5789474 Z"
          fill={color}
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

QuestionCircle.propTypes = {
  color: string,
  hidden: bool,
};

QuestionCircle.defaultProps = {
  color: "#1B9B9E",
  hidden: true,
};

export default QuestionCircle;
