import { useEffect } from "react";

import useDoubleAuthPrevention from "../../../../hooks/useDoubleAuthPrevention";

import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";
import RecruiterSignUpForm from "../../authentication/RecruiterSignUpForm";

export const RecruiterSignUpEnterprise = () => {
  const { validate } = useDoubleAuthPrevention();

  useEffect(() => {
    validate({ redirect: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthenticationWrapper>
      <RecruiterSignUpForm />
    </AuthenticationWrapper>
  );
};
