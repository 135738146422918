import React from "react";
import { number } from "prop-types";

import { toCurrency } from "../../helpers";

import styles from "./styles.module.scss";
import useEnterPriseEnvCheck from "../../hooks/useEnterpriseEnvCheck";
import CashIcon from './CashIcon';

const SalaryChip = ({ value }) => {
  const { isEnterpriseEnv } = useEnterPriseEnvCheck();

  return (
    <span className={styles.location} data-testid="salary-chip-wrapper">
      <CashIcon
        data-testid="salary-chip-icon"
        aria-hidden="true"
        color={isEnterpriseEnv ? "#438FCC" : "#1B9B9E"}
      />
      <span data-testid="salary-chip-value">
        {toCurrency({ value, hideCurrencySymbol: true })}
      </span>
    </span>
  );
};

SalaryChip.propTypes = {
  value: number.isRequired,
};

export default SalaryChip;
