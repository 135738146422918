import React from "react";
import { string, bool } from "prop-types";

const Suitcase = ({ color, hidden }) => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden={hidden}
    >
      <g
        id="briefcase-icon-hover-state"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M16.8,9.16666667 C16.8,8.70666667 17.1584,8.33333333 17.6,8.33333333 L22.4,8.33333333 C22.8416,8.33333333 23.2,8.70666667 23.2,9.16666667 L23.2,11.6666667 L16.8,11.6666667 L16.8,9.16666667 Z M24.8,15 L24.8,31.6666667 L15.2,31.6666667 L15.2,15 L24.8,15 Z M31.2,15 C32.0816,15 32.8,15.7483333 32.8,16.6666667 L32.8,30 C32.8,30.9183333 32.0816,31.6666667 31.2,31.6666667 L28,31.6666667 L28,15 L31.2,15 Z M12,15 L12,31.6666667 L8.8,31.6666667 C7.9184,31.6666667 7.2,30.9183333 7.2,30 L7.2,16.6666667 C7.2,15.7483333 7.9184,15 8.8,15 L12,15 Z M31.2,11.6666667 L26.4,11.6666667 L26.4,9.16666667 C26.4,6.87 24.6048,5 22.4,5 L17.6,5 C15.3952,5 13.6,6.87 13.6,9.16666667 L13.6,11.6666667 L8.8,11.6666667 C6.1536,11.6666667 4,13.91 4,16.6666667 L4,30 C4,32.7566667 6.1536,35 8.8,35 L31.2,35 C33.8464,35 36,32.7566667 36,30 L36,16.6666667 C36,13.91 33.8464,11.6666667 31.2,11.6666667 L31.2,11.6666667 Z"
          id="briefcase-icon"
          fill={color}
        ></path>
      </g>
    </svg>
  );
};

Suitcase.propTypes = {
  color: string,
  hidden: bool,
};

Suitcase.defaultProps = {
  color: "#fff",
  hidden: true,
};

export default Suitcase;
