import ContentLoader from "react-content-loader";

import styles from "./styles.module.scss";

const Skeleton = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <ContentLoader
          speed={1}
          width={300}
          height={520}
          viewBox="0 0 300 520"
          backgroundColor="#EDF1F7"
          foregroundColor="#f7f9fc"
        >
          <rect x="0" y="0" rx="3" ry="3" width="300" height="183" />
          <rect x="0" y="200" rx="0" ry="0" width="300" height="32" />
          <rect x="0" y="250" rx="0" ry="0" width="300" height="90" />
          <rect x="0" y="360" rx="0" ry="0" width="120" height="30" />
          <rect x="0" y="416" rx="0" ry="0" width="228" height="56" />
          <circle cx="272" cy="445" r="28" />
        </ContentLoader>
      </div>
    </div>
  );
};

export default Skeleton;
