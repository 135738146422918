import React from "react";

import PasswordRecoveryForm from "../../components/PasswordRecoveryForm";
import AuthenticationWrapper from "../../components/AuthenticationWrapper";

const ForgotPassword = () => {
  return (
    <AuthenticationWrapper>
      <PasswordRecoveryForm />
    </AuthenticationWrapper>
  );
};

export default ForgotPassword;
