import { string, func, shape, bool } from "prop-types";
import styles from './styles.module.scss';
import SquareButton from '../../../../components/SquareButton';

const ButtonsGroup = ({
    firstButton,
    secondButton,
    whiteVersion
}) => {
    return (
        <div className={[
            styles.wrapper,
            whiteVersion && styles.secondaryLayout
        ].join(" ")}>
            {firstButton &&
                <SquareButton
                    type="button"
                    text={firstButton.text}
                    onClick={firstButton.action}
                    outlined
                />
            }
            {secondButton &&
                <SquareButton
                    type="button"
                    text={secondButton.text}
                    onClick={secondButton.action}
                    outlined
                />
            }
        </div>
    )
};

ButtonsGroup.propTypes = {
    firstButton: shape({
        text: string,
        action: func,
    }),
    secondButton: shape({
        text: string,
        action: func,
    }),
    whiteVersion: bool,
};


export default ButtonsGroup;
