import React, { useRef } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

import { Input } from "../../../../components/NewInput";
import SquareButton from "../../../../components/SquareButton";
import SectionTitle from "../../../../components/SectionTitle";
import useAxios from "../../../../hooks/useAxios";
import { useSnackBarContext } from "../../../../contexts/SnackBar";
import validationDictionary from "../../../../constants/validationDictionary";
import getUserData from "../../../../helpers/getUserData";

import FieldsGrid from "../../../authentication/components/FieldsGrid";

const ChangePasswordForm = () => {
  const formRef = useRef(null);
  const { exec, loading } = useAxios();
  const history = useHistory();
  const { showSnackBar } = useSnackBarContext();
  const { has_password } = getUserData();
  const havePassword = has_password;

  const handleSubmit = async (data) => {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        current_password:
          havePassword &&
          Yup.string()
            .min(6, validationDictionary.CURRENT_PASSWORD_MIN_CHARACTERS)
            .required(validationDictionary.INVALID_PASSWORD),
        password: Yup.string()
          .min(8, validationDictionary.PASSWORD_MIN_CHARACTERS)
          .required(validationDictionary.INVALID_PASSWORD),
        password_confirmation: Yup.string()
          .min(8, validationDictionary.PASSWORD_MIN_CHARACTERS)
          .required(validationDictionary.INVALID_PASSWORD)
          .oneOf(
            [Yup.ref("password"), null],
            validationDictionary.PASSWORDS_DO_NOT_MATCH
          ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await exec({
        useCredentials: true,
        method: "POST",
        url: "/user/password/update",
        data,
      });

      if (!response.error) {
        showSnackBar({
          timeout: 2000,
          text: response.message,
        });

        const currentStorage = JSON.parse(localStorage.getItem("@user_data"));
        currentStorage.has_password = true;
        localStorage.setItem("@user_data", JSON.stringify(currentStorage));

        history.push("/minha-conta");

        return;
      }

      formRef.current.setErrors({ current_password: response.data.errors[0] });
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <SectionTitle
        small
        centered
        level="h2"
        children={
          havePassword
            ? "Altere a senha <b>da sua conta</b>"
            : "Crie uma senha <b>para a sua conta</b>"
        }
      />
      <FieldsGrid>
        {havePassword && (
          <Input
            label="Senha atual"
            name="current_password"
            placeholder="Digite sua senha"
            type="password"
          />
        )}
        <Input
          label="Nova senha"
          name="password"
          placeholder="Digite sua senha"
          type="password"
        />
        <Input
          label="Confirme a senha"
          name="password_confirmation"
          placeholder="Confirme sua senha"
          type="password"
        />
      </FieldsGrid>
      <SquareButton disabled={loading} text="Alterar senha" type="submit" />
    </Form>
  );
};

export default ChangePasswordForm;
