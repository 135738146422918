import React, { useState, useEffect } from "react";
import useCounter from "../../../../hooks/useCounter";
import { AlertTriangle } from "../../../../libs/icons";
import AuthenticationParagraph from "../../../authentication/components/AuthenticationParagraph";
import SectionTitle from "../../components/SectionTitle";
import SquareButton from "../../components/SquareButton";

import styles from "./styles.module.scss";

const InvalidConfirmation = () => {
  const [isLoading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const { active, startCounter, counter } = useCounter();

  const handleSubmit = () => {
    setLoading(true);
    startCounter(60);

    setLoading(false);
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("@user_data"));

    if (userData?.email) {
      setUserEmail(userData.email);
    }
  }, []);

  return (
    <>
      <div aria-hidden="true" className={styles.iconWrapper}>
        <AlertTriangle color="#438fcc"/>
      </div>
      <SectionTitle
        small
        centered
        level="h2"
        children="Não foi possível <b>confirmar o e-mail</b>"
      />
      {userEmail && (
        <AuthenticationParagraph>
          <b>{userEmail}</b>
        </AuthenticationParagraph>
      )}
      <AuthenticationParagraph>
        Solicite um novo pedido de confirmação
        <br /> clicando no botão abaixo.
      </AuthenticationParagraph>
      <SquareButton
        onClick={handleSubmit}
        type="button"
        text={`Reenviar e-mail ${active ? "(" + counter + ")" : ""}`}
        disabled={isLoading || active}
      />
    </>
  );
};

export default InvalidConfirmation;
