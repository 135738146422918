import { string } from "prop-types";

import styles from "./styles.module.scss";

const PageTitle = ({ children }) => {
  return (
    <h1 className={styles.title} data-testid="page-title">
      {children}
    </h1>
  );
};

PageTitle.propTypes = {
  children: string.isRequired,
};

export { PageTitle };
