/* eslint-disable no-useless-escape */
import { useRef, useState, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { func } from "prop-types";

import styles from "./styles.module.scss";
import states from "../../../../libs/data/states";
import AccountCardContainer from "../../../Account/components/AccountCardContainer";
import useAxios from "../../../../hooks/useAxios";
import {
  BooleanCheckbox,
  CurrencyInput,
  Input,
} from "../../../../components/NewInput";
import { IllustrationInput } from "../IllustrationInput";
import { FormSectionTitle } from "../FormSectionTitle";
import Radio from "../../../../components/Radio";
import SelectInput from "../../../../components/SelectInput";
import SquareButton from "../../../../components/SquareButton";
import { illustrations, applicationLabel } from "./variables";
import { shuffleArr, toCurrency } from "../../../../helpers";
import useEnterPriseEnvCheck from "../../../../hooks/useEnterpriseEnvCheck";
import { englishLevels } from "../../../../libs/data";
import validationDictionary from "../../../../constants/validationDictionary";
import AutoCompleteInput from "../../../../components/AutoCompleteInput";
import { useStatesAndCities } from "../../../../hooks/useStatesAndCities";
import Checkbox from "../../../../components/Checkbox";
import { useSnackBarContext } from "../../../../contexts/SnackBar";

const JobForm = ({ goToNextStep }) => {
  const illustrationsShuffled = useMemo(() => shuffleArr(illustrations), []);
  const formRef = useRef();
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedModels, setselectedModels] = useState({
    local: false,
    remote: false,
    hybrid: false,
    hasError: false
  });
  const [selectedEnglishLevel, setSelectedEnglishLevel] = useState('');
  const [selectedIllustration, setSelectedIllustration] = useState(
    illustrationsShuffled[0].image
  );
  const [selectedApplication, setSelectedApplication] = useState("url");
  const [uploadedData, setUploadedData] = useState({});
  const [availableCities, setAvailableCities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { loading, exec } = useAxios();
  const history = useHistory();
  const params = useParams();
  const { isEnterpriseEnv } = useEnterPriseEnvCheck();
  const {
    loading: isLocationLoading,
    getCities: fetchCityList,
  } = useStatesAndCities();
  const { showSnackBar } = useSnackBarContext();

  const handleSelectModel = ({ value }) => {
    setselectedModels(prev => ({ ...prev, [value]: !prev[value] }));
  };

  const validateModels = () => {
    const { local, remote, hybrid } = selectedModels;
    const hasError = !local && !remote && !hybrid;
    if (hasError) {
      setselectedModels(prev => ({ ...prev, hasError }))
      return true;
    };
    return false;
  }

  const handleSubmit = async (data) => {
    const { hasError, ...rest } = selectedModels;
    try {
      const URL =
        /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

      const payload = {
        ...data,
        ...rest,
        state: selectedState,
        image: selectedIllustration,
        application_type: selectedApplication,
        english_level: selectedEnglishLevel,
        city: isNaN(selectedCity) ? availableCities.find(item => item.name === selectedCity).id : selectedCity,
      };

      const schema = Yup.object().shape(
        {
          title: Yup.string().required(validationDictionary.GENERIC_REQUIRED_FIELD),
          salary: Yup.number().min(10, validationDictionary.INVALID_SALARY_RANGE).required(),
          hours: Yup.string().required(
            validationDictionary.GENERIC_REQUIRED_FIELD
          ),
          state: Yup.string().required(validationDictionary.GENERIC_REQUIRED_FIELD),
          city: Yup.number()
            .typeError('Selecione uma cidade válida')
            .required(validationDictionary.INVALID_CITY_SELECT)
          ,
          english_level: Yup.string().required(
            validationDictionary.GENERIC_REQUIRED_FIELD
          ),
          local: Yup
            .boolean()
            .when([], {
              is: () => !payload.local && !payload.remote && !payload.hybrid,
              then: () => Yup.bool().oneOf([true], validationDictionary.GENERIC_REQUIRED_FIELD),
            }),
          remote: Yup
            .boolean()
            .when([], {
              is: () => !payload.local && !payload.remote && !payload.hybrid,
              then: () => Yup.bool().oneOf([true], validationDictionary.GENERIC_REQUIRED_FIELD),
            }),
          hybrid: Yup
            .boolean()
            .when([], {
              is: () => !payload.local && !payload.remote && !payload.hybrid,
              then: () => Yup.bool().oneOf([true], validationDictionary.GENERIC_REQUIRED_FIELD),
            }),
          benefits: Yup.string().required(
            validationDictionary.GENERIC_REQUIRED_FIELD
          ),
          requirements: Yup.string().required(
            validationDictionary.GENERIC_REQUIRED_FIELD
          ),
          description: Yup.string().required(validationDictionary.GENERIC_REQUIRED_FIELD),
          application: Yup.string().when("selectedApplication", {
            is: () => selectedApplication === "url",
            then: Yup.string().matches(
              URL,
              "Digite uma url válida (https://seu-link.com.br)"
            ),
            otherwise: Yup.string().required("Digite um email válido"),
          }),
        }
      );

      validateModels()

      await schema.validate(payload, {
        abortEarly: false,
      });

      const response = await exec({
        useCredentials: true,
        method: "POST",
        url: Object.keys(uploadedData).length
          ? `/minhas-vagas/${params.id}`
          : "/minhas-vagas/new",
        data: payload,
      });

      if (response.status === 'success') goToNextStep();

    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const getCities = async () => {
    try {
      const response = await fetchCityList(Number(selectedState));

      setAvailableCities(response);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleSelectCity = (value) => {
    if (value === '') setSelectedCity('');

    const filteredCities = availableCities.filter((city) => {
      const cityFromData = city.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(" ", "_").toLowerCase();
      const cityValue = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(" ", "_").toLowerCase();

      return cityFromData === cityValue;
    });

    if (!filteredCities.length) return;

    const selectedCityId = filteredCities[0].id;

    setSelectedCity(selectedCityId);
  };

  const applyData = async (id) => {
    const res = await exec({
      useCredentials: true,
      method: "GET",
      url: `/minhas-vagas/${id}`,
    });

    const {
      title,
      salary,
      image,
      remote,
      local,
      hybrid,
      hours,
      state,
      city,
      district,
      description,
      requirements,
      benefits,
      english_level,
      application_type,
      application,
    } = res;

    if (res.error) {
      showSnackBar({
        timeout: 2000,
        text: res.data.message,
      })
      history.goBack();
      return;
    }

    const currentState = states.brazil.find(st => st.id === state).id;
    const availableCities = await fetchCityList(Number(currentState));
    const currentCity = availableCities.find((item) => item.id === city);

    setSelectedState(currentState);
    setSelectedCity(currentCity?.name);
    setselectedModels({ remote, local, hybrid });
    setSelectedEnglishLevel(english_level);
    setSelectedIllustration(
      illustrations.find((illustration) => illustration.image === image).image
    );
    setSelectedApplication(application_type);
    setUploadedData({
      title,
      salary: toCurrency({ value: salary, hideCurrencySymbol: true }),
      hours,
      district: district ?? '',
      description,
      requirements,
      benefits,
      application,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (params?.action === "editar") {
      return applyData(params.id);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.slug]);

  useEffect(() => {
    getCities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);

  if (isLoading) return null;

  return (
    <AccountCardContainer>
      <h1>Cadastro de vaga</h1>
      <Form ref={formRef} onSubmit={handleSubmit} className={styles.wrapper}>
        <div className={styles.firstSection}>
          <div className={styles.jobName}>
            <Input
              label="Título"
              name="title"
              placeholder="Digite a função para a vaga"
              type="text"
              defaultValue={uploadedData.title}
            />
            <CurrencyInput
              label="Remuneração"
              name="salary"
              placeholder="Digite o valor da remuneração"
              type="text"
              defaultValue={uploadedData.salary}
            />
          </div>
          <div className={styles.jobIllustration}>
            <label>Selecione uma ilustração</label>
            <div className={styles.container}>
              {illustrationsShuffled.map((illustration) => (
                <IllustrationInput
                  key={`image_${illustration.value}`}
                  value={illustration.image}
                  image={illustration.image}
                  checked={selectedIllustration === illustration.image}
                  setter={setSelectedIllustration}
                />
              ))}
            </div>
          </div>
        </div>
        <div className={styles.secondSection}>
          <FormSectionTitle>Localização</FormSectionTitle>
          <div className={styles.jobLocalization}>
            <span>
              A vaga ofertada será:
              <span
                className={[
                  styles.subtitle,
                  selectedModels.hasError ? styles.error : "",
                ].join(" ")}>
                Selecione pelo menos uma opção
              </span>
            </span>
            <div className={styles.options}>
              <Checkbox
                label="Presencial"
                value="local"
                onChange={handleSelectModel}
                checked={selectedModels.local}
                error={selectedModels.hasError}
              />
              <Checkbox
                label="Remoto"
                value="remote"
                onChange={handleSelectModel}
                checked={selectedModels.remote}
                error={selectedModels.hasError}
              />
              <Checkbox
                label="Híbrido"
                value="hybrid"
                onChange={handleSelectModel}
                checked={selectedModels.hybrid}
                error={selectedModels.hasError}
              />
            </div>
          </div>
          <div className={styles.jobHour}>
            <Input
              label="Horário"
              name="hours"
              placeholder="Insira o horário da jornada"
              type="text"
              defaultValue={uploadedData.hours}
            />
          </div>
          <div className={styles.jobState}>
            <SelectInput
              name="state"
              label="Estado"
              placeholder="Selecione o estado"
              setter={setSelectedState}
              keys={{ label: "name", value: "id" }}
              options={states.brazil}
              disabled={loading}
              defaultValue={Number(selectedState)}
            />
            <AutoCompleteInput
              disabled={isLocationLoading}
              placeholder="Digite o nome da cidade"
              name="city"
              label="Cidade"
              options={
                loading ? [] : availableCities.map((city) => city.name)
              }
              fetch={() => { }}
              setter={handleSelectCity}
              defaultValue={selectedCity}
            />
            <Input
              label="Bairro (opcional)"
              name="district"
              placeholder="Digite o nome do bairro"
              type="text"
              defaultValue={uploadedData.district}
            />
          </div>
        </div>
        <div className={styles.thirdSection}>
          <FormSectionTitle>Detalhes da vaga</FormSectionTitle>
          <Input
            label="Descrição da Vaga"
            name="description"
            placeholder="Descreva a sua vaga o mais detalhado possível, inserindo informações para os candidatos sobre a atuação."
            type="textarea"
            defaultValue={uploadedData.description}
          />
          <Input
            label="Descrição dos benefícios"
            name="benefits"
            placeholder="Descreva os benefícios ofertados por essa vaga."
            type="textarea"
            defaultValue={uploadedData.benefits}
          />
          <Input
            label="Descrição dos requisitos"
            name="requirements"
            placeholder="Descreva os requisitos ofertados por essa vaga."
            type="textarea"
            defaultValue={uploadedData.requirements}
          />
        </div>
        <div className={styles.fourthSection}>
          <FormSectionTitle>Inglês</FormSectionTitle>
          <SelectInput
            name="english_level"
            label="Nível de inglês que a vaga exige "
            placeholder="Selecione um nível"
            setter={setSelectedEnglishLevel}
            keys={{ label: "name", value: "value" }}
            options={englishLevels}
            defaultValue={Number(selectedEnglishLevel)}
          />
        </div>
        <div className={styles.fifthSection}>
          <FormSectionTitle>Candidaturas</FormSectionTitle>
          <div className={styles.jobLink}>
            <span>
              Os usuários poderão se candidatar em suas vagas através de um:
            </span>
            <div className={styles.options}>
              <div className={styles.radios}>
                <Radio
                  label="Link externo"
                  value="url"
                  onChange={({ value }) => setSelectedApplication(value)}
                  checked={selectedApplication === "url"}
                />
                <Radio
                  label="E-mail"
                  value="email"
                  onChange={({ value }) => setSelectedApplication(value)}
                  checked={selectedApplication === "email"}
                />
              </div>
              <Input
                label={applicationLabel[selectedApplication]?.label}
                name="application"
                type="text"
                placeholder={applicationLabel[selectedApplication]?.placeholder}
                defaultValue={uploadedData.application}
              />
            </div>
          </div>
        </div>
        <div className={styles.sixtySection}>
          <SquareButton
            type="button"
            text="Cancelar"
            simple
            onClick={() => history.goBack()}
          />
          <SquareButton
            type="submit"
            text="Anunciar Vaga"
            disabled={loading}
            secondaryClass={isEnterpriseEnv ? styles.btnSecondaryLayout : ""}
          />
        </div>
      </Form>
    </AccountCardContainer>
  );
};

JobForm.propTypes = {
  goToNextStep: func.isRequired,
};

export { JobForm };
