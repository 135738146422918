import React, { useRef, useState } from "react";
import Slider from "react-slick";
import { node } from "prop-types";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./styles.module.scss";

import { Chevron } from "../../../../libs/icons";

const SliderComparation = ({ children }) => {
    const slider = useRef();
    const [currentSlide, setCurrentSlide] = useState(0);

    const visibleSlides = window.innerWidth >= 1100 ? 3 : (window.innerWidth >= 768 ? 2 : 1);

    const handleSlideChange = (current) => {
        setCurrentSlide(current);
    };

    const settings = window.innerWidth >= 1100 ? {
        dots: false,
        infinite: false,
        arrows: false,
        afterChange: handleSlideChange,
        slidesToShow: 3,
    } : {
        dots: false,
        infinite: false,
        arrows: false,
        afterChange: handleSlideChange,
        slidesToShow: window.innerWidth >= 768 ? 2 : 1,
        centerMode: window.innerWidth >= 768 ? false : true,
        centerPadding: "10px",
        slidesToScroll: 1,
    };

    const handleArrowPress = (direction) => {
        if (direction === "prev") {
            slider.current.slickPrev();

            return;
        } else if (direction === "next") {
            slider.current.slickNext();
        }
    };

    return (
        <section className={styles.wrapper}>
            <div>
                <div className={styles.controls}>
                    <button
                        className={styles.button}
                        onClick={() => handleArrowPress("prev")}
                        disabled={currentSlide === 0}
                    >
                        <Chevron color="#fff" />
                    </button>
                    <button
                        className={styles.button}
                        onClick={() => handleArrowPress("next")}
                        disabled={currentSlide === 4 - visibleSlides}
                    >
                        <Chevron color="#fff" />
                    </button>
                </div>
            </div>
            <Slider ref={slider} {...settings}>
                {children}
            </Slider>
        </section>
    );
};

SliderComparation.propTypes = {
    children: node.isRequired,
};

export default SliderComparation;
