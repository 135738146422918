import { useEffect } from "react";

import useDoubleAuthPrevention from "../../../../hooks/useDoubleAuthPrevention";
import { focusElement } from "../../../../helpers";

import SignInForm from "../../components/SignInForm";
import AuthenticationWrapper from "../../components/AuthenticationWrapper";

const SignIn = () => {
  const { validate } = useDoubleAuthPrevention();

  useEffect(() => {
    validate({ redirect: false });
    localStorage.removeItem("@user_inkoins");
    localStorage.removeItem("@user_token");
    localStorage.removeItem("@user_data");
    localStorage.removeItem("@user_shares");

    focusElement('[data-testid="section-title"]');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthenticationWrapper>
      <SignInForm
        title="Que tal se #inkluir <br /><b>nessa com a gente?</b>"
        description={
          <>
            Crie ou acesse sua conta na Inklua
            <br /> para ganhar e doar suas Inkoins.
          </>
        }
      />
    </AuthenticationWrapper>
  );
};

export default SignIn;
