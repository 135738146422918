import React from "react";
import { node, string, func, bool } from "prop-types";

import styles from "./styles.module.scss";

const ExperienceList = ({ children }) => (
  <div className={styles.list}>{children}</div>
);

ExperienceList.propTypes = {
  children: node.isRequired,
};

const ExperienceListItem = ({
  role,
  company,
  start_date,
  end_date,
  handleRemove,
  handleEdit,
  disableActions,
}) => (
  <article className={styles.item} data-testid="experience-list-item">
    <div>
      <p className={styles.text}>
        <b>Cargo:</b> {role}
        <br />
        <b>Empresa:</b> {company}
        <br />
        <b>Data de início:</b> {start_date}
        <br />
        {end_date && (
          <>
            <b>Data de término:</b> {end_date}
            <br />
          </>
        )}
        <br />
      </p>
      <div className={styles.buttonWrapper}>
        <button
          className={styles.removeButton}
          onClick={handleRemove}
          disabled={disableActions}
          data-testid="remove-experience-button"
        >
          Deletar
        </button>
        <button
          className={styles.editButton}
          onClick={handleEdit}
          disabled={disableActions}
          data-testid="edit-experience-button"
        >
          Editar
        </button>
      </div>
    </div>
  </article>
);

ExperienceListItem.propTypes = {
  role: string.isRequired,
  company: string.isRequired,
  start_date: string,
  end_date: string,
  handleRemove: func.isRequired,
  handleEdit: func.isRequired,
  disableActions: bool,
};

ExperienceListItem.defaultProps = {
  disableActions: false,
  end_date: null,
};

export { ExperienceList, ExperienceListItem };
