import { node } from "prop-types";

import styles from "./styles.module.scss";

const SearchActiveFilterWrapper = ({ children }) => {
  return (
    <div className={styles.wrapper} data-testid="search-active-filter-wrapper">
      <h1 className={styles.title}>Filtros ativos</h1>
      {children}
    </div>
  );
};

SearchActiveFilterWrapper.propTypes = {
  children: node.isRequired,
};

export default SearchActiveFilterWrapper;
