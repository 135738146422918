import React from "react";
import { Helmet } from "react-helmet";

import PrivacyPolicyContent from "../../components/PrivacyPolicyContent";

const PrivacyPolicy = () => (
  <>
    <Helmet>
      <title>Política de Privacidade – Inklua</title>
      <link rel="canonical" href={window.location.href} />
      <meta
        property="og:description"
        content="A política de privacidade e retenção de dados pessoais inicia vigência em 2 de abril de 2021"
      ></meta>
      <meta
        property="description"
        content="A política de privacidade e retenção de dados pessoais inicia vigência em 2 de abril de 2021"
      ></meta>
    </Helmet>
    <PrivacyPolicyContent />
  </>
);

export default PrivacyPolicy;
