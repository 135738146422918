import { string, arrayOf, shape, number } from "prop-types";

import { SimpleInformation } from "../SimpleInformation";

import styles from "./styles.module.scss";

const WorkExperiences = ({ data }) => {
  return (
    <>
      {data.map((experience) => (
        <div
          className={styles.position}
          key={`experience_${experience.id}`}
          data-testid="work-experience"
        >
          <SimpleInformation
            title="Cargo"
            value={experience.role}
            testID="role"
          />
          <SimpleInformation
            title="Empresa"
            value={experience.company}
            testID="company"
          />
          <SimpleInformation
            title="Data de início"
            value={experience.startDate}
            testID="start-date"
          />
          {experience.endDate ? (
            <SimpleInformation
              title="Data de término"
              value={experience.endDate}
              testID="end-date"
            />
          ) : (
            <></>
          )}
          <SimpleInformation
            title="Descrição"
            value={experience.description}
            name="description"
            testID="description"
          />
        </div>
      ))}
    </>
  );
};

WorkExperiences.propTypes = {
  data: arrayOf(
    shape({
      id: number.isRequired,
      role: string.isRequired,
      company: string.isRequired,
      startDate: string.isRequired,
      endDate: string,
      description: string.isRequired,
    })
  ).isRequired,
};

WorkExperiences.defaultProps = {
  data: {
    endDate: null,
  },
};

export { WorkExperiences };
