import { string, node, oneOf, number } from "prop-types";

import styles from "./styles.module.scss";

export const InputLabel = ({ name, label, icon, tabIndex }) => {
  return (
    <div className={styles.wrapper}>
      <label htmlFor={`field-${name}`} className={styles.label}>
        <span tabIndex={tabIndex}>{label}</span>
        <span className={styles.iconWrapper} aria-hidden="true">
          {icon}
        </span>
      </label>
    </div>
  );
};

InputLabel.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  node: node,
  tabIndex: oneOf([string, number]),
};

InputLabel.defaultProps = {
  node: null,
  tabIndex: "-1",
};
