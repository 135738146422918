import { string } from "prop-types";

import styles from "./styles.module.scss";

const SimpleInformation = ({ title, value, name, url, testID, onClick, loading }) => {
  const renderValue = () => {
    if (url) {
      return (
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          data-testid={`${testID}-link`}
        >
          {value}
        </a>
      );
    }

    if (onClick) {
      return (
        <button
          onClick={onClick}
          rel="noreferrer"
          data-testid={`${testID}-link`}
          className={[styles.openFileLink, loading ? styles.disabled : ''].join(" ")}
          disabled={loading}
        >
          {value}
        </button>
      );
    }

    return value;
  };

  return (
    <p className={styles.text} data-testid={testID} data-name={name}>
      <span>{title}:</span> {renderValue()}
    </p>
  );
};

SimpleInformation.propTypes = {
  title: string.isRequired,
  value: string.isRequired,
  url: string,
  name: string,
  testID: string,
};

SimpleInformation.defaultProps = {
  name: "",
  url: null,
  testID: "simple-information",
};

export { SimpleInformation };
