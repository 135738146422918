import React from "react";
import { string } from "prop-types";

const Trophy = ({ color }) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icons/info</title>
      <defs>
          <path d="M7.30571544,14.707 C6.99471544,14.536 6.61871544,14.539 6.31571544,14.72 L2.56771544,16.92 L4.03571544,11.21 C4.91071544,11.71 5.92171544,12 6.99971544,12 C8.07771544,12 9.08871544,11.71 9.96471544,11.21 L11.4537154,17.002 L7.30571544,14.707 Z M6.99971544,2 C9.20571544,2 10.9997154,3.794 10.9997154,6 C10.9997154,8.206 9.20571544,10 6.99971544,10 C4.79371544,10 2.99971544,8.206 2.99971544,6 C2.99971544,3.794 4.79371544,2 6.99971544,2 L6.99971544,2 Z M13.9687154,18.751 L11.6597154,9.772 C12.4957154,8.74 12.9997154,7.429 12.9997154,6 C12.9997154,2.691 10.3087154,0 6.99971544,0 C3.69171544,0 0.99971544,2.691 0.99971544,6 C0.99971544,7.429 1.50371544,8.74 2.33971544,9.772 L0.0317154396,18.751 C-0.0692845604,19.143 0.0757154396,19.556 0.39871544,19.799 C0.72071544,20.042 1.15871544,20.064 1.50571544,19.862 L6.83571544,16.733 L12.5157154,19.875 C12.6667154,19.959 12.8337154,20 12.9997154,20 C13.2167154,20 13.4327154,19.93 13.6127154,19.791 C13.9277154,19.547 14.0687154,19.138 13.9687154,18.751 L13.9687154,18.751 Z" id="path-1"></path>
      </defs>
      <g id="🖥01-cadastro-relampago(Desktop)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="06-perfil-candidato-completo" transform="translate(-718, -210)">
              <g id="Group" transform="translate(176, 182)">
                  <g id="Group-3" transform="translate(542, 28)">
                      <g id="Icons/info" transform="translate(12.0002, 12) scale(-1, -1) rotate(180) translate(-12.0002, -12)translate(5, 2)">
                          <mask id="mask-2" fill="white">
                              <use href="#path-1"></use>
                          </mask>
                          <use id="🎨-Icon-Сolor" fill={color} href="#path-1"></use>
                      </g>
                  </g>
              </g>
          </g>
      </g>
    </svg>
  );
};

Trophy.propTypes = {
  color: string
};

Trophy.defaultProps = {
  color: "#1B9B9E"
};

export default Trophy;
