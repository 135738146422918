import { useState } from "react";
import { node, string } from "prop-types";

import { Chevron } from "../../../../libs/icons";

import styles from "./styles.module.scss";

const CheckboxGroup = ({ title, children }) => {
  const [isOpen, setOpen] = useState(true);

  return (
    <div className={styles.wrapper} aria-expanded={isOpen}>
      <button
        className={styles.button}
        onClick={() => setOpen(!isOpen)}
        data-testid="checkbox-group-collapse-button"
      >
        <Chevron /> {isOpen ? "Fechar" : "Abrir"}
      </button>
      <h2
        className={[styles.title, isOpen && styles.open].join(" ")}
        data-testid="checkbox-group-title"
        tabIndex="0"
      >
        {title}
      </h2>
      <div
        className={[
          styles.childrenWrapper,
          isOpen ? styles.open : styles.closed,
        ].join(" ")}
      >
        {children}
      </div>
    </div>
  );
};

CheckboxGroup.propTypes = {
  children: node.isRequired,
  title: string.isRequired,
};

export default CheckboxGroup;
