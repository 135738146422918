import React from "react";
import { number } from "prop-types";

import Skeleton from "../../components/Skeleton";
import { Row, Column } from "../../components/Grid";

import styles from "./styles.module.scss";

const SkeletonList = ({ quantity }) => {
  const renderSkeletonLoading = () => {
    let skeletonLoadingComponents = [];

    for (var i = 0; i < quantity; i++) {
      skeletonLoadingComponents.push("");
    }

    return skeletonLoadingComponents.map((item, index) => (
      <Column key={`column_${index}`}>
        <Skeleton key={`skeleton_${index}`} />
      </Column>
    ));
  };

  return (
    <div className={styles.wrapper}>
      <Row>{renderSkeletonLoading()}</Row>
    </div>
  );
};

SkeletonList.propTypes = {
  quantity: number.isRequired,
};

SkeletonList.defaultProps = {
  quantity: 3,
};

export default SkeletonList;
