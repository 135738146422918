import { useState } from "react";
import AccordionItem from "../../components/AccordionItem";
import SectionIntro from "../../components/SectionIntro";
import SectionTitle from "../../components/SectionTitle";
import styles from "./styles.module.scss";
import { sectionsContent, sectionsTitle } from './texts';

const AccordionList = () => {
  const [activeTab, setActiveTab] = useState(null);
  const [currentSection, setCurrentSection] = useState(sectionsTitle[2]);
  const isMobile = window.innerWidth <= 1024;

  const handlePress = (index) => {
    if (index !== activeTab) {
      setActiveTab(index);
      return;
    }

    setActiveTab(null);
  };

  const handleQuestion = (index) => {
    if (index !== currentSection) return setCurrentSection(index);
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.leftColumn}>
        <SectionIntro level="span" text="FAQ" />
        <SectionTitle
          level="h2"
          children="Principais <b>perguntas</b>"
          linebreak={!isMobile}
          centered={isMobile}
        />
        <p className={styles.description}>
          Para encontrar as principais dúvidas
          sobre cada um dos serviços, basta selecionar a
          opção desejada abaixo:
        </p>
        <div className={styles.buttonsWrapper}>
          {sectionsTitle.map((item, index) => {
            return (
              <button
                type="button"
                className={[styles.selectBtn, currentSection === item && styles.active].join(" ")}
                onClick={() => handleQuestion(item)}
              >
                {item}
                <i className="icon-arrow-ios-forward-outline" />
              </button>
            )
          })
          }
        </div>
      </div>
      <div className={styles.rightColumn}>
        <dl>
          {sectionsContent[currentSection].map(({ title, description }, index) => {
            return (
              <AccordionItem
                handleClick={() => handlePress(index)}
                active={activeTab === index}
                title={title}
                description={description}
              />
            )
          })
          }
        </dl>
      </div>
    </section>
  );
};

export default AccordionList;
