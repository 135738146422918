import React from "react";
import { string, node, func } from "prop-types";

// eslint-disable-next-line no-unused-vars
import { parseHighlitedString } from "../AutoCompleteInput/utils";

import styles from "./styles.module.scss";

// eslint-disable-next-line no-unused-vars
const AutoCompleteSuggestion = ({ filter, children, handleClick }) => {
  // const parseText = () => {
  //   return (
  //     <>
  //       {parseHighlitedString(children, filter)[0]}
  //       <b data-testid="filter">{filter}</b>
  //       {parseHighlitedString(children, filter)[1]}
  //     </>
  //   );
  // };

  return (
    <li className={styles.item}>
      <button
        aria-label="button"
        className={styles.button}
        type="button"
        onClick={() => handleClick(children)}
        data-testid="autocomplete-suggestion-item"
      >
        {children}
      </button>
    </li>
  );
};

AutoCompleteSuggestion.propTypes = {
  filter: string.isRequired,
  children: node.isRequired,
  handleClick: func.isRequired,
};

export default AutoCompleteSuggestion;
